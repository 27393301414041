import { Bars2Icon } from '@heroicons/react/24/outline';
import useTranslation from 'next-translate/useTranslation';
import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import Menu from './Menu';
import Sidebar from './Sidebar';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const useHideSideBarWhenOnDesktop = ({
  isSidebarVisible,
  setIsSidebarVisible,
}: {
  isSidebarVisible: boolean;
  setIsSidebarVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { width } = useWindowSize();
  useEffect(() => {
    if (width > 1024 && isSidebarVisible) {
      setIsSidebarVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);
};

const MenuButton = ({ className }: { className?: string }) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const { t } = useTranslation('common');
  const sidebarId = 'side-menu';

  useHideSideBarWhenOnDesktop({
    isSidebarVisible,
    setIsSidebarVisible,
  });

  const showSidebar = () => {
    const sidebar = document.getElementById(sidebarId);

    if (sidebar) {
      disableBodyScroll(sidebar, { allowTouchMove: () => true });
    }

    setIsSidebarVisible(true);
  };

  const hideSidebar = () => {
    const sidebar = document.getElementById(sidebarId);

    if (sidebar) {
      enableBodyScroll(sidebar);
    }

    setIsSidebarVisible(false);
  };

  return (
    <>
      <div className={className}>
        <button
          onClick={() => showSidebar()}
          className="whitespace-nowrap rounded p-2 hover:bg-brand--2 dark:hover:bg-brand--5"
        >
          <Bars2Icon className="mr-1 mt-[-3px] inline h-6 w-6" />

          <span className="hidden sm:inline">{t('MENU')}</span>
        </button>
      </div>

      <Sidebar
        id={sidebarId}
        title={t('MENU')}
        isVisible={isSidebarVisible}
        onClose={() => hideSidebar()}
      >
        <Menu onClose={() => hideSidebar()} />
      </Sidebar>
    </>
  );
};

export default MenuButton;
