import { useEffect, useState } from 'react';

export const useIsPageAccessible = (accessChecker: () => Promise<boolean>) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    accessChecker().then(setIsVisible);
  }, [accessChecker]);

  return isVisible;
};
