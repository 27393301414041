import { Chain } from '../../types/wallet';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';

const ChainIcon = ({ chain }: { chain: Chain }) => {
  return (
    <div
      className="mr-3 inline-block h-6 w-6 overflow-hidden rounded-full"
      nonce="jLV1WDLekJz8r36RIGc4h339wVfPFukb"
      style={{
        background: chain.iconBackground,
      }}
    >
      {chain.iconUrl && (
        // eslint-disable-next-line @next/next/no-img-element
        <img className="h-6 w-6" alt="Chain icon" src={chain.iconUrl as string} />
      )}
    </div>
  );
};

const baseButtonStyles = [
  'px-4 py-2 border rounded-full bg-brand--2 dark:bg-brand--5 border-brand--3/50 dark:border-brand--4',
];

const NetworkPicker = ({
  chain,
  onClick,
  className,
}: {
  chain?: Chain;
  onClick: () => void;
  className?: string;
}) => {
  const { t } = useTranslation('common');

  return (
    <div className={className}>
      <h4 className="text-xs font-medium uppercase tracking-widest text-brand--4">
        {t('NETWORK')}
      </h4>

      <button
        className={clsx(baseButtonStyles, 'my-2 flex px-2')}
        onClick={onClick}
        type="button"
      >
        {!!chain?.hasIcon && <ChainIcon chain={chain} />}

        <span className="max-w-[130px] overflow-hidden overflow-ellipsis whitespace-nowrap">
          {chain?.name || ''}
        </span>

        <ChevronRightIcon className="ml-3 w-5" />
      </button>
    </div>
  );
};

export default NetworkPicker;
