import useTranslation from 'next-translate/useTranslation';
import { useModalsStore } from '../../stores/modals';
import BaseModal from '../base/BaseModal';
import ReportIssueForm from '../report/ReportIssueForm';
import ChargeUpRewardForm from '../rewards/ChargeUpRewardForm';
import { useChargeUpStakingStore } from '../../stores/chargeUpStaking';

const Modals = () => {
  const { t } = useTranslation('common');
  const { modalVisibility, closeModal } = useModalsStore((store) => store);
  const selectedStakingRewardAsset = useChargeUpStakingStore(
    (store) => store.selectedStakingRewardAsset,
  );
  return (
    <>
      {modalVisibility['report-issue'] && (
        <BaseModal
          title={t('REPORT_AN_ISSUE')}
          onClose={() => closeModal('report-issue')}
          disableAwayClickClose
        >
          <ReportIssueForm onClose={() => closeModal('report-issue')} />
        </BaseModal>
      )}
      {modalVisibility['charge-up'] && (
        <BaseModal
          title={t('CHARGE_UP_REWARD')}
          onClose={() => closeModal('charge-up')}
          disableAwayClickClose
        >
          <ChargeUpRewardForm
            onSuccess={() => closeModal('charge-up')}
            rewardSymbol={selectedStakingRewardAsset}
          />
        </BaseModal>
      )}
      <BaseModal.Root />
    </>
  );
};

export default Modals;
