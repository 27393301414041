import { configureChains, createConfig } from 'wagmi';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import { publicProvider } from 'wagmi/providers/public';
import { CHAINS } from '../utils/chains';
import {
  injectedWallet,
  metaMaskWallet,
  ledgerWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { env } from '../env';

// this import is needed in order to avoid ts error: The inferred type of 'chains' cannot be named
// without a reference to @wagmi/core
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Chain } from 'wagmi';

export const { chains, publicClient, webSocketPublicClient } = configureChains(CHAINS, [
  publicProvider(),
]);

export const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet({
        chains: CHAINS,
        shimDisconnect: true,
        projectId: env.NEXT_PUBLIC_PROJECT_ID,
      }),
      injectedWallet({ chains: CHAINS, shimDisconnect: true }),
      walletConnectWallet({
        chains: CHAINS,
        projectId: env.NEXT_PUBLIC_PROJECT_ID,
      }),
    ],
  },
  {
    groupName: 'Ledger',
    wallets: [ledgerWallet({ chains: CHAINS, projectId: env.NEXT_PUBLIC_PROJECT_ID })],
  },
]);

export const config = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});
