import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { Locale } from '../../types/next-translate';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import setLanguage from 'next-translate/setLanguage';

const FLAGS: Record<Locale, string> = {
  en: '🇬🇧',
  pl: '🇵🇱',
};

const LABELS: Record<Locale, string> = {
  en: 'English',
  pl: 'Polski',
};

const LanguageOption = ({
  value,
  label,
  current,
  onClick,
}: {
  value: Locale;
  label: string;
  current: Locale;
  onClick?: () => void;
}) => {
  const labelClasses = [
    'block rounded-lg  hover:cursor-pointer',
    'my-1 px-3 py-2',
    'hover:bg-brand--2 dark:hover:bg-brand--5',
    'focus:bg-brand--2 focus:dark:bg-brand--5',
  ];

  return (
    <label
      onClick={(e) => {
        e.stopPropagation();
        setLanguage(value);
        onClick?.();
      }}
      key={value}
      className={clsx(labelClasses, {
        'border dark:border-brand--5': value === current,
      })}
      tabIndex={0}
    >
      <input
        id={`${value}-language-field`}
        className="w-0 opacity-0"
        type="radio"
        tabIndex={0}
        value={value}
      />

      <span className="mr-2">{FLAGS[value as keyof typeof FLAGS]}</span>

      <span>{label}</span>
    </label>
  );
};

const LanguagePicker = ({ className }: { className?: string }) => {
  const { t, lang } = useTranslation('common');
  const [isExpanded, setIsExpanded] = useState(false);
  const pickerRef = useRef(null);
  useClickAway(pickerRef, () => setIsExpanded(false));

  return (
    <div
      ref={pickerRef}
      onClick={() => setIsExpanded((v) => !v)}
      className={clsx('relative', className)}
    >
      <div
        className={clsx('flex items-center rounded-xl py-3 px-6 hover:cursor-pointer')}
      >
        <span className="mr-2 hidden text-xs sm:inline">{t('LANGUAGE')}:</span>

        <span className="text-lg">{FLAGS[lang]}</span>

        {!isExpanded ? (
          <ChevronDownIcon className="ml-1 h-4 w-4" />
        ) : (
          <ChevronUpIcon className="ml-1 h-4 w-4" />
        )}

        <div
          className={clsx(
            `pointer-events-none absolute top-[100%] left-0 mt-[-4px] w-full min-w-[200px] rounded-lg
             bg-white p-2 opacity-0 shadow-xl dark:bg-brand--6`,
            {
              'pointer-events-auto opacity-100': isExpanded,
            },
          )}
        >
          {Object.entries(LABELS).map(([langKey, label]) => (
            <LanguageOption
              key={langKey}
              value={langKey as Locale}
              label={label}
              current={lang}
              onClick={() => setIsExpanded(false)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LanguagePicker;
