/* eslint-disable @next/next/no-img-element */
import useTranslation from 'next-translate/useTranslation';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import clsx from 'clsx';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import WalletDropdown from './WalletDropdown';
import { clearWalletConnectSession } from '../../utils/clearWalletConnectSession';

const baseButtonStyles =
  'flex items-center text-center px-10 mt-8 lg:mt-0 py-[10px] rounded-full hover:transform-none dark:hover:text-white';

const ConnectWalletInternalButton = ({
  openConnectModal,
}: {
  openConnectModal: () => void;
}) => {
  const { t } = useTranslation('common');

  return (
    <button
      className={clsx(
        baseButtonStyles,
        'bg-brand--4 font-bold text-white transition-colors duration-150',
      )}
      onClick={() => {
        clearWalletConnectSession();
        openConnectModal();
      }}
      type="button"
    >
      {t('CONNECT_WALLET')}
    </button>
  );
};

const WrongNetworkInternalButton = ({
  openChainModal,
}: {
  openChainModal: () => void;
}) => {
  const { t } = useTranslation('common');

  return (
    <button
      className={clsx(
        baseButtonStyles,
        'bg-red-700 font-bold text-white transition-colors duration-150 hover:bg-red-800',
      )}
      onClick={openChainModal}
      type="button"
    >
      {t('WRONG_NETWORK')} <ChevronDownIcon className="ml-1 w-5" />
    </button>
  );
};

// this component is copied from https://www.rainbowkit.com/docs/custom-connect-button
const ConnectWalletButton = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          authenticationStatus,
          mounted,
          accountModalOpen,
          chainModalOpen,
          connectModalOpen,
        }) => {
          const ready = mounted && authenticationStatus !== 'loading';
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === 'authenticated');

          return (
            <div
              className={clsx(
                'max-w-[320px]',
                !ready && 'aria-hidden pointer-events-none select-none opacity-0',
              )}
            >
              {(() => {
                if (!connected) {
                  return (
                    <ConnectWalletInternalButton openConnectModal={openConnectModal} />
                  );
                }

                if (chain.unsupported) {
                  return <WrongNetworkInternalButton openChainModal={openChainModal} />;
                }

                return (
                  <WalletDropdown
                    account={account}
                    chain={chain}
                    state={{
                      accountModalOpen,
                      chainModalOpen,
                      connectModalOpen,
                    }}
                    openAccountModal={openAccountModal}
                    openChainModal={openChainModal}
                  />
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
    </div>
  );
};

export default ConnectWalletButton;
