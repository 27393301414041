import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

type BaseInputProps = {
  id?: string;
  disabled?: boolean;
  readonly?: boolean;
  type?: string;
  isError?: boolean;
  className?: string;
  register?: UseFormRegisterReturn;
  value?: string;
  placeholder?: string;
};

function BaseInput(props: BaseInputProps) {
  return (
    <input
      id={props.id}
      placeholder={props.placeholder ?? ''}
      disabled={props.disabled}
      className={clsx(
        `w-full rounded-lg border-gray-300 py-4 px-6 text-lg font-bold
                     focus:border-brand--3 focus:ring-brand--3 dark:border-white/10 dark:bg-brand--5 dark:text-white`,
        props.disabled &&
          'border border-gray-100 font-medium dark:border-brand--5 dark:bg-brand--6',
        props.isError && 'border-red-600', // is error
        props.className,
      )}
      type={props.type ?? 'text'}
      {...props.register}
      value={props.value}
    />
  );
}

export default BaseInput;
