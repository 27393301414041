/* eslint-disable @typescript-eslint/no-var-requires */
const typography = require('@tailwindcss/typography');
const forms = require('@tailwindcss/forms');

module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './layouts/**/*.{js,ts,jsx,tsx}',
    './utils/**/*.{js,ts,jsx,tsx}',
  ],
  darkMode: 'class',
  theme: {
    extend: {
      colors: {
        'brand--1': '#ffffff',
        'brand--2': '#fff3fa',
        'brand--3': '#f44dac',
        'brand--4': '#c13888',
        'brand--5': '#410b44',
        'brand--6': '#2a0233',
      },
    },
  },
  plugins: [typography(), forms()],
};
