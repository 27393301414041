import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { FallbackProps } from 'react-error-boundary';

const ErrorBoundaryFallback = ({ error }: FallbackProps) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  return (
    <div className="flex min-h-screen flex-col items-center justify-center p-2 text-center dark:bg-brand--5 dark:text-white sm:p-8">
      <h2 className="mx-auto text-3xl font-bold ">
        {t('UNKNOWN_CLIENT_SIDE_ERROR_OCCURED')}
      </h2>
      <span className="mt-4">{t('UNKNOWN_CLIENT_SIDE_ERROR_OCCURED_DESCRIPTION')}</span>
      <button onClick={() => router.reload()} className="mt-2 font-bold underline">
        {t('REFRESH_PAGE').toUpperCase()}
      </button>
      <div className="container mt-8 break-all">
        {JSON.stringify(error, Object.getOwnPropertyNames(error), 2)}
      </div>
      <div className="mt-10"></div>
    </div>
  );
};

export default ErrorBoundaryFallback;
