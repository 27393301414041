import { useAccount } from 'wagmi';
import { Asset, RewardAsset } from '../../types/Asset';

export const useAddAssetToWallet = (asset: Asset | RewardAsset) => {
  const { connector } = useAccount();

  return async () => {
    if (!connector) return;
    return connector.watchAsset?.({
      address: asset.address,
      symbol: asset.symbol,
      decimals: asset.decimals,
      image: asset.image,
    });
  };
};
