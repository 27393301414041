import { Chain } from 'wagmi';
import * as chain from 'wagmi/chains';
import { env } from '../env';

const amoyChain: Chain = {
  id: 80_002,
  name: 'Polygon Amoy',
  network: 'polygonamoy',
  nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc-amoy.polygon.technology'],
    },
    public: {
      http: ['https://rpc-amoy.polygon.technology'],
    },
  },
  blockExplorers: {
    default: {
      name: 'OK LINK',
      url: 'https://www.oklink.com/amoy',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 3127388,
    },
  },
  testnet: true,
};

export const CHAINS = ([] as Chain[])
  .concat(env.NEXT_PUBLIC_APP_ENV === 'production' ? [chain.polygon] : [])
  .concat(env.NEXT_PUBLIC_APP_ENV === 'development' ? [amoyChain] : [])
  .concat(
    env.NEXT_PUBLIC_APP_ENV === '' ? [chain.polygon, chain.hardhat, amoyChain] : [],
  );
