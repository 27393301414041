import { Asset, RewardAsset } from '../types/Asset';
import { STAKING_ASSETS, STAKING_REWARDS } from './constants';
import { useChainId } from './hooks/useChainId';

export const useStakingAssets = (): Asset[] => {
  const chainId = useChainId() as keyof typeof STAKING_ASSETS[0]['address'];
  return STAKING_ASSETS.map((asset) => ({
    ...asset,
    address: asset.address[chainId],
  }));
};

export const useStakingAsset = (symbol: string) => {
  return useStakingAssets().find((asset) => asset.symbol === symbol);
};

export const useRewardsAssets = (): RewardAsset[] => {
  const chainId = useChainId() as keyof typeof STAKING_ASSETS[0]['address'];
  return STAKING_REWARDS.map((asset) => ({
    ...asset,
    address: asset.address[chainId],
    stakingContractAddress: asset.stakingContractAddress[chainId],
  }));
};

export const useRewardsAsset = (symbol: string) => {
  return useRewardsAssets().find((asset) => asset.symbol === symbol);
};
