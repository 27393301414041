import { event } from 'nextjs-google-analytics';
import { GOOGLE_ID } from './constants';

export const logEvent = (
  action: string,
  message: string,
  category?: string,
  userId?: string,
): void => {
  if (GOOGLE_ID?.length) {
    event(action, {
      category,
      label: message,
      userId,
    });
  }
};
