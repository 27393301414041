
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '@rainbow-me/rainbowkit/styles.css';
import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { WagmiConfig } from 'wagmi';
import Head from 'next/head';
import DefaultLayout from '../layouts/DefaultLayout';
import { ThemeProvider } from 'next-themes';
import WalletProvider from '../layouts/WalletProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Modals from '../components/common/Modals';
import { config } from '../utils/setupWagmi';
import useTranslation from 'next-translate/useTranslation';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { GOOGLE_ID } from '../utils/constants';
import { ApolloProvider } from '@apollo/client';
import apolloClient from '../utils/apolloClient';
import { useHotjar } from '../utils/hooks/useHotjar';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallback from '../components/base/ErrorBoundaryFallback';

const Toaster = dynamic(
  () => import('react-hot-toast').then((module) => module.Toaster),
  {
    ssr: false,
  },
);

const queryClient = new QueryClient();

const __Page_Next_Translate__ = function App({ Component, pageProps }: AppProps) {
  const { t } = useTranslation('common');

  useHotjar();

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <WagmiConfig config={config}>
        <ApolloProvider client={apolloClient}>
          <Head>
            <title>{t('MILKY_ICE_STAKING')}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="icon" href="/milkyIce-32x32.png" sizes="32x32" />
            <link rel="icon" href="/milkyIce-192x192.png" sizes="192x192" />
            <link rel="apple-touch-icon" href="/milkyIce-180x180.png" />
            <meta name="msapplication-TileImage" content="/milkyIce-270x270.png" />

            <meta
              name="description"
              content="Oficjalna strona do stakingu Milky Coin. Powiększaj swoje zyski, dzięki tokenom Milky Coin opartym o realny i szybko skalujący się biznes."
            />
            <meta
              name="robots"
              content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
            />
            <link rel="canonical" href="https://staking.milkyice.io/" />
            <meta property="og:locale" content="pl_PL" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Staking Milky Ice" />
            <meta
              property="og:description"
              content="Oficjalna strona do stakowania Milky Coin. Powiększaj swoje zyski, dzięki tokenom Milky Coin opartym o realny i szybko skalujący się biznes."
            />
            <meta property="og:url" content="https://milkyice.io/" />
            <meta property="og:site_name" content="MilkyIce" />
            <meta property="og:updated_time" content="2022-07-01T14:31:30+02:00" />
            <meta property="og:image" content="/milkyIce_logo.png" />
            <meta property="og:image:width" content="512" />
            <meta property="og:image:height" content="512" />
            <meta property="og:image:alt" content="Logo milkyice" />
            <meta property="og:image:type" content="image/png" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="Milky Ice - Milky Coin - Oficjalna strona MilkyIce.io"
            />
            <meta
              name="twitter:description"
              content="Oficjalna strona do stakowania Milky Ice. Powiększaj swoje zyski, dzięki tokenom Milky Coin opartym o realny i szybko skalujący się biznes."
            />
            <meta name="twitter:image" content="/milkyIce_logo.png" />
          </Head>

          {GOOGLE_ID?.length ? <GoogleAnalytics trackPageViews /> : <></>}

          <ThemeProvider attribute="class" nonce="jLV1WDLekJz8r36RIGc4h339wVfPFukb">
            <WalletProvider>
              <QueryClientProvider client={queryClient}>
                <DefaultLayout>
                  <Component {...pageProps} />
                  <Modals />
                </DefaultLayout>
              </QueryClientProvider>
            </WalletProvider>
            <Toaster
              toastOptions={{
                style: {
                  wordBreak: 'break-word',
                },
              }}
            />
          </ThemeProvider>
        </ApolloProvider>
      </WagmiConfig>
    </ErrorBoundary>
  );
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  