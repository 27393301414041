import { useEffect, useState } from 'react';

// This hook is needed to prevent the hydration errors caused by the automatic wallet connection
export const useIsAppInitialized = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isInitialized;
};
