import { MoonIcon, SunIcon } from '@heroicons/react/24/outline';
import { useTheme } from 'next-themes';
import { useIsAppInitialized } from '../../utils/hooks/useIsAppinitialized';

const ThemePicker = () => {
  const { theme, setTheme } = useTheme();

  if (!useIsAppInitialized()) {
    return null;
  }

  return (
    <div
      className="flex items-center justify-start rounded-lg p-2 hover:cursor-pointer hover:bg-brand--2 dark:hover:bg-brand--5"
      onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
    >
      {theme === 'dark' ? (
        <SunIcon className="mx-1 h-5 w-5" />
      ) : (
        <MoonIcon className="mx-1 h-5 w-5" />
      )}
    </div>
  );
};

export default ThemePicker;
