import LanguagePicker from './LanguagePicker';
import Logo from './Logo';
import MenuButton from './MenuButton';
import ThemePicker from './ThemePicker';
import dynamic from 'next/dynamic';
import { useRef } from 'react';
import clsx from 'clsx';
import AddTokenToWallet from './AddTokenToWallet';
import { useWindowScroll } from 'react-use';

const Menu = dynamic(() => import('./Menu'), { ssr: false });

const TopBar = () => {
  const topBarRef = useRef(null);
  const { y: scrolledFromTop } = useWindowScroll();

  return (
    <header
      ref={topBarRef}
      className={clsx(
        'fixed top-0 left-0 z-[102] w-screen bg-white px-4 transition dark:border-brand--5  dark:bg-brand--6',
        scrolledFromTop > 50 ? 'border-b shadow-lg shadow-brand--5/10' : 'py-2 sm:py-4',
      )}
    >
      <AddTokenToWallet />

      <div className="container mx-auto flex items-center justify-between">
        <div className="flex items-center">
          <Logo
            className={clsx(
              scrolledFromTop > 50 ? 'w-[100px]' : 'w-[130px]',
              'translate-y-1',
            )}
          />

          <LanguagePicker className="ml-3 text-sm sm:ml-6" />

          <ThemePicker />
        </div>

        <div className="flex items-center">
          <MenuButton className="ml-6 xl:hidden" />

          <Menu className="hidden xl:flex" />
        </div>
      </div>
    </header>
  );
};

export default TopBar;
