import BaseInput from '../base/BaseInput';
import BaseButton from '../base/BaseButton';
import useTranslation from 'next-translate/useTranslation';
import { useForm } from 'react-hook-form';
import IssueImageField from './IssueImageField';
import axios from 'axios';
import { useToast } from '../../utils/useToast';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { logEvent } from '../../utils/logEvent';

const SimpleMarkdownEditor = dynamic(() => import('../common/SimpleMarkdownEditor'), {
  ssr: false,
});

interface ReportIssueForm {
  email: string;
  title: string;
  description: string;
  attachments: File[];
}

const ReportIssueForm = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation('common');
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<ReportIssueForm>();
  const { displayToast, dismissToast } = useToast();
  const description = getValues('description');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [isLoading, setLoading] = useState(false);

  const toBase64 = (file: File): Promise<string | null | ArrayBuffer> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const sendFormAsync = async (values: ReportIssueForm): Promise<void> => {
    const toastId = displayToast('loading', t('UPLOADING_ISSUE'));
    setLoading(true);
    try {
      const base64files = await Promise.all(attachments.map(toBase64));
      const formattedAttachments = attachments.map((attachment, index) => ({
        fileName: attachment.name,
        data: base64files[index],
      }));

      const res = await axios.post('/api/issues', {
        ...values,
        attachments: formattedAttachments,
      });

      if (res.status === 200) {
        displayToast('success', t('ISSUE_REPORTED_SUCCESSFULLY'));
        onClose();
      } else {
        displayToast('error', t('SOMETHING_WENT_WRONG'));
      }
      logEvent(
        'submit_issue',
        `Issue has been submitted by ${values.email} with title ${values.title}. See discord for more`,
        'support',
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message ?? t('SOMETHING_WENT_WRONG');
      displayToast('error', errorMessage);
      logEvent('submit_issue_failed', errorMessage, 'error');
    } finally {
      dismissToast(toastId);
      setLoading(false);
    }
  };

  return (
    <div className="min-h-[300px] w-full lg:w-auto lg:min-w-[640px]">
      <div>
        <form onSubmit={handleSubmit(sendFormAsync)}>
          <fieldset>
            <label htmlFor="issueTopicField">
              {t('TOPIC')} <strong className="text-red-500">*</strong>
            </label>

            <BaseInput
              type="text"
              placeholder={t('TOPIC_PLACEHOLDER')}
              className="my-4"
              isError={!!errors.title}
              disabled={isLoading}
              register={register('title', {
                required: true,
                minLength: 3,
                maxLength: 255,
              })}
            />
          </fieldset>

          <fieldset>
            <label htmlFor="issueEmailField">
              {t('EMAIL')} <strong className="text-red-500">*</strong>
            </label>
            <BaseInput
              placeholder={t('EMAIL_PLACEHOLDER')}
              type="email"
              className="my-4"
              isError={!!errors.email}
              disabled={isLoading}
              register={register('email', {
                required: true,
                minLength: 3,
                maxLength: 255,
              })}
            />
          </fieldset>

          <fieldset>
            <label htmlFor="issueDescriptionField">
              {t('DESCRIPTION')} <strong className="text-red-500">*</strong>
            </label>
            <SimpleMarkdownEditor
              value={description}
              placeholder={t('DESCRIPTION_PLACEHOLDER')}
              onChange={(value) => !isLoading && setValue('description', value)}
            />
          </fieldset>

          <fieldset>
            <label>{t('ATTACHMENTS')}</label>

            <IssueImageField onChange={(files) => !isLoading && setAttachments(files)} />
          </fieldset>

          <BaseButton
            size="medium"
            variant="primary"
            disabled={isLoading}
            className="mt-10 w-full"
            type="submit"
          >
            {t('SUBMIT')}
          </BaseButton>
        </form>
      </div>
    </div>
  );
};

export default ReportIssueForm;
