import NetworkPicker from './NetworkPicker';
import AccountModalTrigger from './AccountModalTrigger';
import AccountBalance from './AccountBalance';
import { Account, Chain } from '../../types/wallet';
import useTranslation from 'next-translate/useTranslation';
import BaseButton from '../base/BaseButton';
import { useDisconnect } from 'wagmi';

const WalletState = ({
  chain,
  account,
  openAccountModal,
  openChainModal,
}: {
  chain?: Chain;
  account?: Account;
  openAccountModal: () => void;
  openChainModal: () => void;
}) => {
  const { t } = useTranslation('common');
  const { disconnect } = useDisconnect();

  const onDisconnect = () => {
    disconnect();
  };

  return (
    <>
      <h3 className="mb-6 text-xl font-bold">{t('YOUR_ACCOUNT')}</h3>

      <AccountBalance />

      <AccountModalTrigger
        account={account}
        onClick={openAccountModal}
        className="mb-5"
      />

      <NetworkPicker chain={chain} onClick={openChainModal} />

      <BaseButton onClick={onDisconnect} className="mt-5">
        {t('DISCONNECT')}
      </BaseButton>
    </>
  );
};

export default WalletState;
