import { useAccount, useContractRead, useContractReads } from 'wagmi';
import { Asset } from '../../../types/Asset';
import { useProtocolContractAddresses } from '../useProtocolContractAddresses';
import { multiErc20WeightedLockerABI } from '../generated';
import * as viem from 'viem';
import { DepositStruct } from '../../../components/deposits/types';

const useLockableAssets = () => {
  const { locker } = useProtocolContractAddresses();
  const { address } = useAccount();

  const { data: lockableAssetsCount, isLoading: isLoadingLockableAssetsCount } =
    useContractRead({
      address: locker,
      abi: multiErc20WeightedLockerABI,
      functionName: 'getLockableAssetsCount',
      enabled: viem.isAddress(address!),
    });

  const { data: lockableAssets, isLoading: isLoadingLockableAssets } = useContractReads({
    contracts: Array.from({ length: Number(lockableAssetsCount) }).map((_, index) => ({
      address: locker,
      abi: multiErc20WeightedLockerABI,
      functionName: 'getLockableAsset',
      args: [index],
      enabled: viem.isAddress(address!),
      watch: true,
    })),
    allowFailure: false,
  });

  return {
    lockableAssets: lockableAssets as { token: string }[],
    isLoading: isLoadingLockableAssetsCount || isLoadingLockableAssets,
  };
};

export function useStakedTokenAmount({ stakedAsset }: { stakedAsset: Asset }) {
  const { locker } = useProtocolContractAddresses();
  const { address } = useAccount();

  const { lockableAssets } = useLockableAssets();

  const lockableAssetIndex =
    lockableAssets?.findIndex((asset) => asset.token === stakedAsset.address) ?? -1;

  const {
    data: amountOfUserDeposits,
    isLoading: isLoadingAmountOfUserDeposits,
    refetch: refetchAmountOfUserDeposits,
  } = useContractRead({
    address: locker,
    abi: multiErc20WeightedLockerABI,
    functionName: 'userDepositsCount',
    args: [address!],
    enabled: viem.isAddress(address!),
    watch: true,
  });

  const {
    data: depositIds,
    isLoading: isLoadingDepositIds,
    refetch: refetchDepositIds,
  } = useContractReads({
    contracts: Array.from({ length: Number(amountOfUserDeposits) }).map((_, index) => ({
      address: locker,
      abi: multiErc20WeightedLockerABI,
      functionName: 'userDepositIds',
      args: [address!, index],
      enabled: viem.isAddress(address!),
      watch: true,
    })),
    allowFailure: false,
    enabled: viem.isAddress(address!) && !!amountOfUserDeposits,
  }) as { data: bigint[]; isLoading: boolean; refetch: () => void };

  const {
    data: userDeposits,
    isLoading: isLoadingUserDeposits,
    refetch: refetchUserDeposits,
  } = useContractReads({
    contracts:
      depositIds?.map((depositId) => ({
        address: locker,
        abi: multiErc20WeightedLockerABI,
        functionName: 'userDeposits',
        args: [address!, depositId],
        enabled: viem.isAddress(address!),
        watch: true,
      })) ?? [],
    allowFailure: false,
    enabled: viem.isAddress(address!) && !!depositIds,
    select: (data) =>
      data.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (x: any) =>
          ({
            lockableAssetIndex: x[0],
            stakingContractIndex: x[1],
            amountLocked: x[2],
            amountMinted: x[3],
            lockPeriod: {
              durationInSeconds: x[4].durationInSeconds,
              rewardModifier: x[4].rewardModifier,
            },
            depositTimestamp: x[5],
            unlockAvailibleTimestamp: x[6],
            isOngoing: x[7],
          } as DepositStruct),
      ),
  });

  const sumOfDeposits =
    (userDeposits?.reduce((acc, deposit) => {
      if (
        deposit.lockableAssetIndex === BigInt(lockableAssetIndex) &&
        deposit.isOngoing === true
      ) {
        return acc + deposit.amountLocked;
      }
      return acc;
    }, 0n) as bigint) || 0n;

  return {
    amountOfStakedTokens: sumOfDeposits || 0n,
    isLoading:
      isLoadingAmountOfUserDeposits || isLoadingDepositIds || isLoadingUserDeposits,
    refetch: () => {
      refetchAmountOfUserDeposits();
      refetchDepositIds();
      refetchUserDeposits();
    },
  };
}
