import { useAccount, useContractRead } from 'wagmi';
import { erc20ABI } from '../generated';

export function useTokenBalanceOfUser({
  tokenAddress,
}: {
  tokenAddress?: `0x${string}` | undefined;
}) {
  const account = useAccount();

  return useContractRead({
    address: tokenAddress,
    abi: erc20ABI,
    functionName: 'balanceOf',
    args: [account.address!],
    enabled: !!account.address,
    watch: true,
  });
}
