import { ChevronRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { Account } from '../../types/wallet';

const baseButtonStyles = [
  'pl-4 pr-2 py-2 border rounded-full bg-brand--2 dark:bg-brand--5 border-brand--3/50 dark:border-brand--4',
];

const AccountModalTrigger = ({
  account,
  onClick,
  className,
}: {
  account?: Account;
  onClick: () => void;
  className?: string;
}) => {
  const { t } = useTranslation('common');

  return (
    <div className={className}>
      <h4 className="flex justify-between text-xs font-medium uppercase tracking-widest text-brand--4">
        <span>{t('WALLET')}</span>

        {account?.displayBalance ? (
          <span className="text-[10px] text-brand--5 dark:text-white">
            {account.displayBalance}
          </span>
        ) : (
          <></>
        )}
      </h4>

      <button
        className={clsx('my-2 flex items-center justify-between', baseButtonStyles)}
        onClick={onClick}
        type="button"
      >
        <span>{account?.displayName}</span>

        <ChevronRightIcon className="ml-3 w-5" />
      </button>
    </div>
  );
};

export default AccountModalTrigger;
