/* eslint-disable @next/next/no-img-element */
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

const Logo = ({ className }: { className?: string }) => {
  return (
    <div className="relative" tabIndex={0}>
      <Link href="/">
        <img
          src="/milkyice-logo.png"
          alt="MilkyIce logo"
          className={clsx(
            'h-auto min-w-[80px] fill-brand--5 transition dark:fill-white',
            className,
          )}
        />
      </Link>
    </div>
  );
};

export default Logo;
