import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { forwardRef, PropsWithChildren, ReactNode, useRef } from 'react';
import { useClickAway } from 'react-use';
import SocialIcons from './SocialIcons';

type SidebarProps = PropsWithChildren & {
  isVisible: boolean;
  onClose?: () => void;
  title: string;
  id?: string;
};

const SidebarOverlay = ({ onClose }: { onClose?: () => void }) => (
  <div
    onClick={onClose}
    className="fixed inset-0 z-10 flex transform items-center justify-center bg-black bg-opacity-25 backdrop-blur-sm"
  />
);

// eslint-disable-next-line react/display-name
const SidebarContent = forwardRef<
  HTMLDivElement,
  SidebarProps & { footer?: ReactNode | undefined }
>(({ isVisible, onClose, children, title, footer }, ref) => (
  <Transition
    ref={ref}
    show={isVisible}
    className="fixed top-0 right-0 z-20 h-full w-[320px] overflow-x-hidden overflow-y-scroll 
    border-l bg-white px-8 pt-6 transition dark:border-brand--5 dark:bg-brand--6"
    enter="transform transition duration-300"
    enterFrom="translate-x-[320px]"
    enterTo="translate-x-0"
    leave="transform transition duration-300"
    leaveFrom="translate-x-0"
    leaveTo="translate-x-[320px]"
  >
    <div className="flex items-center justify-end">
      <h3 className="mr-auto text-xl font-bold">{title}</h3>
      <button
        onClick={onClose}
        className="ml-8 flex items-center rounded-lg border border-brand--2 p-2
        transition hover:bg-brand--2 dark:border-brand--5 dark:hover:bg-brand--5"
      >
        <XMarkIcon className="h-8 w-8 rotate-[360deg] transition delay-200" />
      </button>
    </div>

    <h3 className="my-5 text-4xl font-bold"></h3>

    <div>{children}</div>

    <div className="sticky bottom-0 left-0 mt-10 flex max-h-16 w-full justify-center border-t bg-white dark:border-brand--5 dark:bg-brand--6">
      {footer}
    </div>
  </Transition>
));

const Sidebar: React.FC<SidebarProps> = ({
  onClose,
  children,
  id = 'sidebar',
  title,
  isVisible,
}) => {
  const sideBar = useRef<HTMLDivElement>(null);

  useClickAway(sideBar, () => {
    // TODO: If WAGMI / RAINBOW modal is not open
    onClose?.();
  });

  return (
    <div id={id}>
      {isVisible && <SidebarOverlay />}

      <SidebarContent
        ref={sideBar}
        title={title}
        onClose={onClose}
        isVisible={isVisible}
        footer={<SocialIcons listClassName="p-4" />}
      >
        {children}
      </SidebarContent>
    </div>
  );
};

export default Sidebar;
