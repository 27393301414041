import { create } from 'zustand';

interface ChargeUpStakingStore {
  selectedStakingRewardAsset: string;
  setSelectedStakingRewardAsset: (asset: string) => void;
}

export const useChargeUpStakingStore = create<ChargeUpStakingStore>((set) => ({
  selectedStakingRewardAsset: '',
  setSelectedStakingRewardAsset: (asset: string) =>
    set((store) => ({
      ...store,
      selectedStakingRewardAsset: asset,
    })),
}));
