import { parseUnits } from 'viem';

export const parseToBigInt = (value: string | undefined, decimals = 18) => {
  try {
    const valueWithoutIncorrectCharacters = value
      ?.replace(/,/g, '.')
      .replace(/[a-zA-Z]/g, '');
    return BigInt(parseUnits(valueWithoutIncorrectCharacters || '0', decimals));
  } catch (error) {
    return 0n;
  }
};
