import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      className="absolute right-0 top-1/2 h-6 w-6 -translate-y-1/2"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  );
};

const WalletButton = ({
  walletDropdownVisible,
  onClick,
}: {
  walletDropdownVisible: boolean;
  onClick?: () => void;
}) => {
  const { t } = useTranslation('common');

  return (
    <button onClick={onClick} className="z-[101]">
      {walletDropdownVisible && <CloseIcon />}

      <span
        className={clsx(
          walletDropdownVisible ? 'blur' : 'opacity-100',
          'lowercase underline-offset-8 hover:underline',
        )}
      >
        {t('ACCOUNT')}
      </span>
    </button>
  );
};

export default WalletButton;
