import useTranslation from 'next-translate/useTranslation';
import * as viem from 'viem';
import { useForm } from 'react-hook-form';
import { useTokenBalanceOfUser } from '../../utils/hooks/token/useTokenBalanceOfUser';
import { useTransferToken } from '../../utils/hooks/token/useTransferToken';
import { useProtocolContractAddresses } from '../../utils/hooks/useProtocolContractAddresses';
import { parseToBigInt } from '../../utils/parseToBigInt';
import { useRewardsAsset } from '../../utils/useAssets';
import BaseButton from '../base/BaseButton';
import BaseInput from '../base/BaseInput';

const ChargeUpRewardForm = ({
  onSuccess,
  rewardSymbol,
}: {
  onSuccess: () => void;
  rewardSymbol: string;
}) => {
  const { t } = useTranslation('common');
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const { newStaking } = useProtocolContractAddresses();

  const rewardAsset = useRewardsAsset(rewardSymbol)!;

  const chargeUpAmount: string = watch('amount');

  const { data: userRewardBalance } = useTokenBalanceOfUser({
    tokenAddress: rewardAsset.address,
  });

  const { transferToken, transferStatus } = useTransferToken({
    amount: parseToBigInt(chargeUpAmount, rewardAsset.decimals),
    toAddress: newStaking,
    tokenAddress: rewardAsset.address,
    onTransactionSuccess() {
      onSuccess();
    },
  });

  const maxRewardAmount = viem
    .formatUnits(userRewardBalance || 0n, rewardAsset.decimals)
    .toString();

  const preventModalFromClosing = handleSubmit(() => {});

  const isChargeUpButtonDisabled =
    !transferToken ||
    parseToBigInt(chargeUpAmount, rewardAsset.decimals) === 0n ||
    transferStatus === 'loading';

  return (
    <form onSubmit={preventModalFromClosing}>
      <div
        className="mb-2 flex justify-end"
        onClick={() => setValue('amount', maxRewardAmount)}
      >
        <button type="button" className="text-brand--4 hover:underline">
          MAX
        </button>
      </div>
      <div className="relative">
        <BaseInput type="text" register={register('amount', { required: true })} />

        <div className="absolute inset-y-0 right-0 flex items-center">
          <div>
            <label htmlFor="currency" className="sr-only">
              {t('CURRENCY')}
            </label>

            <div className="mr-4 opacity-70">{rewardAsset.symbol}</div>
          </div>
        </div>
      </div>

      {errors.amount?.type === 'required' && (
        <div className="mt-3 rounded-lg border border-red-600 bg-red-600/10 p-3 text-red-600">
          {t('REQUIRED')}
        </div>
      )}

      <BaseButton
        variant="secondary"
        disabled={isChargeUpButtonDisabled}
        isLoading={transferStatus === 'loading'}
        type="submit"
        size="small"
        className="mx-auto mt-4 w-full max-w-[320px]"
        onClick={transferToken}
      >
        {t('CHARGE_UP_REWARD')}
      </BaseButton>
    </form>
  );
};

export default ChargeUpRewardForm;
