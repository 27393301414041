import BaseBox from '../base/BaseBox';
import { Account, Chain } from '../../types/wallet';
import { useState } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import WalletButton from './WalletButton';
import WalletState from './WalletState';

const Overlay = ({
  dropdownVisible,
  toggleDropdown,
}: {
  dropdownVisible: boolean;
  toggleDropdown: () => void;
}) =>
  dropdownVisible ? (
    <div
      onClick={() => toggleDropdown()}
      className={clsx(
        'fixed inset-0 z-[2] hidden transition xl:block',
        dropdownVisible
          ? 'bg-white/70 opacity-100 backdrop-blur dark:bg-brand--6/70'
          : 'opacity-0 backdrop-blur-0',
      )}
    ></div>
  ) : (
    <></>
  );

interface ExternalModalsState {
  accountModalOpen: boolean;
  chainModalOpen: boolean;
  connectModalOpen: boolean;
}

const WalletDropdown = ({
  chain,
  account,
  openChainModal,
  openAccountModal,
}: {
  chain?: Chain;
  account?: Account;
  openChainModal: () => void;
  openAccountModal: () => void;
  state?: ExternalModalsState;
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <div>
      <div className="mt-8 border-t pt-8 dark:border-brand--5 xl:hidden">
        <WalletState
          account={account}
          chain={chain}
          openAccountModal={openAccountModal}
          openChainModal={openChainModal}
        />
      </div>

      <div className="relative hidden flex-col gap-3 lg:flex-row xl:flex">
        <WalletButton walletDropdownVisible={dropdownVisible} onClick={toggleDropdown} />

        <div>
          <Overlay dropdownVisible={dropdownVisible} toggleDropdown={toggleDropdown} />

          <BaseBox
            className={twMerge(
              clsx(
                'absolute right-2 top-10 z-[101] w-[280px] bg-white transition-all',
                dropdownVisible
                  ? 'translate-y-[0] opacity-100'
                  : 'pointer-events-none translate-y-[-20px] opacity-0',
              ),
            )}
          >
            <WalletState
              account={account}
              chain={chain}
              openAccountModal={openAccountModal}
              openChainModal={openChainModal}
            />
          </BaseBox>
        </div>
      </div>
    </div>
  );
};

export default WalletDropdown;
