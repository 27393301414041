import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { env } from '../../env';

const id = Number(env.NEXT_PUBLIC_HOTJAR_ID);
const version = Number(env.NEXT_PUBLIC_HOTJAR_VERSION);

export const useHotjar = () => {
  useEffect(() => {
    if (id && version && !Number.isNaN(id) && !Number.isNaN(version)) {
      hotjar.initialize(id, version);
    }
  }, []);
};
