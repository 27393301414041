/* eslint-disable @next/next/no-img-element */
import { CloudArrowUpIcon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { ChangeEventHandler, useState } from 'react';
import BaseInput from '../base/BaseInput';

export interface IssueImageFieldProps {
  onChange: (files: File[]) => void;
}

const FILE_PICKER_ID = 'issue-new-attachment';

const toMegaBytes = (bytes: number) => (bytes / (1024 * 1024)).toFixed(3);

const FilePicker = ({ onChange }: { onChange: (files: File[]) => void }) => {
  const sendUpAsFiles: ChangeEventHandler<HTMLInputElement> = (event) => {
    const filesList = event?.target?.files || new FileList();
    const newFiles = Array.from(filesList);

    onChange(newFiles);
    event.target.value = '';
    event.target.files = null;
  };

  return (
    <input
      type="file"
      className="h-0 w-0 opacity-0"
      id={FILE_PICKER_ID}
      accept="image/*"
      multiple={true}
      onChange={sendUpAsFiles}
    />
  );
};

const IssueImageField = ({ onChange }: IssueImageFieldProps) => {
  const { t } = useTranslation('common');
  const [allFiles, setFiles] = useState<File[]>([]);

  const updateFilesList = (newFiles: File[]) => {
    const combined = [...allFiles, ...newFiles].reduce((result: File[], file: File) => {
      const exists = result.find(
        ({ size, name }) => name === file.name && size === file.size,
      );

      return exists ? result : [...result, file];
    }, []);

    setFiles(combined);
    onChange(combined);
  };

  const openFilePicker = () => {
    document.getElementById(FILE_PICKER_ID)?.click();
  };

  const removeFile = (index: number) => {
    setFiles(allFiles.filter((_file, i) => i !== index));
  };

  return (
    <div>
      <button
        type="button"
        className="w-full rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 p-10 text-center dark:border-white/20 dark:bg-brand--5/50"
        onClick={openFilePicker}
      >
        <CloudArrowUpIcon className="inline h-6 w-6" />

        {t('UPLOAD')}
      </button>

      <FilePicker onChange={updateFilesList} />

      {allFiles.map((file, index) => (
        <div key={`${file.name}.${file.lastModified}`} className="my-2">
          <div
            className={clsx(
              'relative w-full rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 p-10 text-center dark:border-white/20 dark:bg-brand--5/50',
            )}
          >
            <button
              type="button"
              className="absolute top-6 right-6 flex items-center justify-start rounded-lg p-2 hover:cursor-pointer hover:bg-brand--2 dark:hover:bg-brand--5"
              onClick={() => removeFile(index)}
            >
              <XMarkIcon className="mx-1 h-5 w-5" />
            </button>

            <img
              alt={file.name}
              src={URL.createObjectURL(file)}
              width="200"
              height="auto"
              className="mr-8"
            />

            <div className="text-left text-xs leading-10">
              <BaseInput
                disabled
                value={file.name}
                type="text"
                className="my-3 ml-[-8px] !p-3 !text-xs"
              />

              <p>{toMegaBytes(file.size)} MB</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default IssueImageField;
