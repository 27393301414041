import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import * as viem from 'viem';
import useTranslation from 'next-translate/useTranslation';
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi';
import { TransactionEventHandlers } from '../../../types/TransactionEventHandlers';
import { CONFIRMATIONS_AMOUNT } from '../../constants';
import { useNotifiacationHandlers } from '../../useToast';
import { erc20ABI } from '../generated';

export function useTransferToken({
  tokenAddress,
  toAddress,
  amount,
  onTransactionSubmitted,
  onTransactionSuccess,
  onSubmitError,
  onTransactionError,
  onTransactionSettled,
}: {
  tokenAddress: string | undefined;
  toAddress: string | undefined;
  amount: bigint | undefined;
} & TransactionEventHandlers) {
  const { t } = useTranslation('common');
  const handlers = useNotifiacationHandlers({
    onTransactionSubmitted,
    onTransactionSuccess,
    onSubmitError,
    onTransactionError,
    onTransactionSettled,
  });
  const addRecentTransaction = useAddRecentTransaction();

  const { config: transferConfig } = usePrepareContractWrite({
    address: tokenAddress as `0x${string}`,
    abi: erc20ABI,
    functionName: 'transfer',
    args: [toAddress as `0x${string}`, amount!],
    enabled: viem.isAddress(tokenAddress ?? '') && !!amount,
  });

  const { data: transferTokenData, write: transferToken } = useContractWrite({
    ...transferConfig,
    onSuccess(data) {
      handlers.onTransactionSubmitted?.(data.hash, t('TRANSFERRING_TOKENS'));
    },
    onError(error) {
      handlers.onSubmitError?.(error);
    },
  });

  const { data: transferData, status: transferStatus } = useWaitForTransaction({
    hash: transferTokenData?.hash,
    confirmations: CONFIRMATIONS_AMOUNT,
    onSuccess() {
      handlers.onTransactionSuccess?.(t('TOKENS_TRANSFERRED'));
      addRecentTransaction({
        hash: transferTokenData?.hash ?? '',
        description: t('TOKENS_TRANSFERRED'),
      });
    },
    onError(error) {
      handlers.onTransactionError?.(error);
    },
    onSettled(data, error) {
      handlers.onTransactionSettled?.(data?.transactionHash ?? '', error);
    },
  });

  return {
    transferData,
    transferStatus,
    transferToken: transferToken,
  };
}
