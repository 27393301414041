import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useIsAppInitialized } from '../../utils/hooks/useIsAppinitialized';

interface DismissableInfoProps {
  children?: string | JSX.Element | JSX.Element[];
  className?: string;
}

const DismissableInfo = ({ children, className }: DismissableInfoProps) => {
  const [dismissed, setDismissed] = useState(false);
  const initialized = useIsAppInitialized();

  useEffect(() => {
    const stored = !!sessionStorage.getItem('dismissedInfo');
    setDismissed(stored);
  }, []);

  const dismiss = () => {
    setDismissed(true);
    sessionStorage.setItem('dismissedInfo', 'true');
  };

  if (dismissed || !initialized) {
    return <></>;
  }

  return (
    <div
      className={clsx(
        'relative bg-brand--4 py-2 pl-4 pr-12 text-center text-sm text-white sm:px-16',
        className || '',
      )}
    >
      {children}

      <button
        onClick={() => dismiss()}
        className="absolute top-1/2 right-5 ml-8 flex translate-y-[-50%] items-center rounded border border-brand--2/50 p-1 text-white transition 
                  hover:border-brand--6 hover:bg-brand--5 hover:text-white"
      >
        <XMarkIcon className="h-4 w-4 rotate-[360deg] transition" />
      </button>
    </div>
  );
};

export default DismissableInfo;
