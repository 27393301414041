import {
  BaseError,
  UserRejectedRequestError,
  ExecutionRevertedError,
  InsufficientFundsError,
} from 'viem';

export const getCommonErrorMessage = (
  error: BaseError,
): {
  key: string;
  context: string;
} => {
  if (
    error.cause instanceof UserRejectedRequestError ||
    error.details?.includes?.('cancelled')
  ) {
    return {
      key: 'USER_REJECTED_TRANSACTION',
      context: UserRejectedRequestError.code.toString(),
    };
  }

  if (
    error.cause instanceof ExecutionRevertedError ||
    error.details?.includes?.('execution reverted')
  ) {
    return {
      key: 'EXECUTION_ERROR',
      context: `${error.name}: ${error.shortMessage}.\n ${
        (error.cause as BaseError).metaMessages
      }`,
    };
  }

  if (
    error.cause instanceof InsufficientFundsError ||
    error.details?.includes?.('insufficient funds')
  ) {
    return { key: 'INSUFFICIENT_FUNDS_FOR_GAS', context: '' };
  }

  if (error.message?.includes?.('transaction underpriced')) {
    return { key: 'TRANSACTION_UNDERPRICED', context: '' };
  }

  return {
    key: 'UNKNOWN_ERROR',
    context: `${error.name}: ${error.message}`,
  };
};
