import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { useClickAway } from 'react-use';

type BaseModalType = React.FC<
  PropsWithChildren & {
    onClose?: () => void;
    title: string;
    disableAwayClickClose?: boolean;
  }
> & { Root: React.FC };

const BaseModal: BaseModalType = ({
  onClose,
  children,
  title,
  disableAwayClickClose,
}) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  useClickAway(wrapperRef, () => !disableAwayClickClose && onClose?.());

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-[120] flex items-center justify-center bg-black bg-opacity-25 backdrop-blur-sm">
      <div
        id="modal-wrapper"
        className="flex h-full w-screen flex-wrap items-center justify-center overflow-y-scroll"
      >
        <div
          ref={wrapperRef}
          className="min-w-[320px]rounded-xl my-16 border bg-white py-6 px-8 shadow-2xl transition dark:border-brand--5 dark:bg-brand--6"
        >
          <div className="mb-6 flex items-center justify-between border-b pb-3 dark:border-brand--5">
            <h3 className="text-xl font-bold">{title}</h3>

            <button
              onClick={onClose}
              className="ml-8 flex items-center rounded-lg border border-brand--2 p-2 
                       transition hover:bg-brand--2 dark:border-brand--5 dark:hover:bg-brand--5"
            >
              <XMarkIcon className="h-8 w-8 rotate-[360deg] transition delay-200" />
            </button>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>,
    document.getElementById('modal-portal')!,
  );
};

const BaseModalRoot = () => <div id="modal-portal" />;

BaseModal.Root = BaseModalRoot;

export default BaseModal;
