import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useModalsStore } from '../../stores/modals';
import SocialIcons from './SocialIcons';
import dynamic from 'next/dynamic';
const BlockchainConnectionStatus = dynamic(
  () => import('../wallet/BlockchainConnectionStatus'),
  { ssr: false },
);

const SiteFooter = ({ className }: { className?: string }) => {
  const { t } = useTranslation('common');
  const openModal = useModalsStore((store) => store.openModal);

  return (
    <div
      className={clsx('bottom-0 mb-4 mt-6 w-full text-center text-sm lg:mt-0', className)}
    >
      <div className="container mx-auto flex flex-wrap items-center justify-between">
        <nav id="footer-menu" className="flex items-center justify-center">
          <div className="flex flex-wrap">
            <BlockchainConnectionStatus />

            <Link
              target="_blank"
              className="m-2 p-2 underline-offset-4 hover:underline dark:text-brand--3"
              href="https://stmilkyiceprod.blob.core.windows.net/files/MilkyIce_2023_Test_Report.pdf"
            >
              {t('SECURITY_AUDIT_REPORT_PDF')}
            </Link>

            <Link
              target="_blank"
              className="m-2 p-2 underline-offset-4 hover:underline dark:text-brand--3"
              href="https://stmilkyiceprod.blob.core.windows.net/files/P_03893389.pdf"
            >
              {t('INTERPRETATION')}
            </Link>

            <Link
              target="_blank"
              className="m-2 p-2 underline-offset-4 hover:underline dark:text-brand--3"
              href="https://stmilkyiceprod.blob.core.windows.net/files/Polityka prywatności.pdf"
            >
              {t('PRIVACY_POLICY')}
            </Link>

            <button
              className="m-2 p-2 underline-offset-4 hover:underline dark:text-brand--3"
              onClick={() => openModal('report-issue')}
            >
              {t('REPORT_AN_ISSUE')}
            </button>
          </div>
        </nav>

        <SocialIcons listClassName="p-4" />
      </div>
    </div>
  );
};

export default SiteFooter;
