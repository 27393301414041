/* eslint-disable @typescript-eslint/no-var-requires */
const { createEnv } = require('@t3-oss/env-nextjs');
const { z } = require('zod');

const env = createEnv({
  server: {
    NODE_ENV: z.enum(['production', 'development']).default('development'),
    VERCEL_URL: z.string().min(1).optional(),
  },
  client: {
    NEXT_PUBLIC_APP_NAME: z.string().min(1).default(''),
    NEXT_PUBLIC_VERCEL_URL: z
      .string()
      .min(1)
      .default('localhost:3000')
      .describe(
        'localhost:3002 for local development. In production vercel will automatically set this variable',
      ),
    NEXT_PUBLIC_APP_ENV: z
      .enum(['', 'production', 'development'])
      .default('')
      .describe('Used to deduct which chains should be available to be picked in app'),
    NEXT_PUBLIC_PROJECT_ID: z
      .string()
      .min(1)
      .default('ea0409b88db357135e6ecb9b00c27e9e')
      .describe('WalletConnect v2 client id'),
    NEXT_PUBLIC_GA_MEASUREMENT_ID: z.string().min(1).optional(),
    NEXT_PUBLIC_TX_CHAIN_CONFIRMATIONS: z.number().min(1).default(1),
    NEXT_PUBLIC_CORE_URL: z.string().min(1).default('http://localhost:3333'),
    NEXT_PUBLIC_INDEXER_URL: z
      .string()
      .min(1)
      .default('http://localhost:4350')
      .describe('Subsquid indexer url, Polygon mainnet only'),
    NEXT_PUBLIC_ADMIN_ADDRESSES: z.array(z.string()).default([]),
    NEXT_PUBLIC_SENTRY_DSN: z.string().min(1).optional(),
    NEXT_PUBLIC_PROJECT_API_KEY: z
      .string()
      .min(1)
      .default('local')
      .describe('Project API key for Core API'),
    NEXT_PUBLIC_HOTJAR_ID: z.string().default(''),
    NEXT_PUBLIC_HOTJAR_VERSION: z.string().default(''),
    // LOCAL NODE CONTRACT ADDRESSES
    NEXT_PUBLIC_MIC_TOKEN_ADDRESS: z.string().min(1).optional(),
    NEXT_PUBLIC_MIC_USDC_LP_TOKEN_ADDRESS: z.string().min(1).optional(),
    NEXT_PUBLIC_ICE_TOKEN_ADDRESS: z.string().min(1).optional(),
    NEXT_PUBLIC_USDC_TOKEN_ADDRESS: z.string().min(1).optional(),
    NEXT_PUBLIC_NEW_USDC_TOKEN_ADDRESS: z.string().min(1).optional(),
    NEXT_PUBLIC_LOCKER_CONTRACT_ADDRESS: z.string().min(1).optional(),
    NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS: z.string().min(1).optional(),
    NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS: z.string().min(1).optional(),
    NEXT_PUBLIC_MINT_STAKING_CONTRACT_ADDRESS: z.string().min(1).optional(),
    NEXT_PUBLIC_AIRDROP_CONTRACT_ADDRESS: z.string().min(1).optional(),
    NEXT_PUBLIC_PERIOD_STARTER_CONTRACT_ADDRESS: z.string().min(1).optional(),
    NEXT_PUBLIC_VESTING_CONTRACT_ADDRESS: z.string().min(1).optional(),
    // MAINNET CONTRACT ADDRESSES
    NEXT_PUBLIC_MIC_TOKEN_ADDRESS_MAINNET: z.string().min(1),
    NEXT_PUBLIC_MIC_USDC_LP_TOKEN_ADDRESS_MAINNET: z.string().min(1),
    NEXT_PUBLIC_ICE_TOKEN_ADDRESS_MAINNET: z.string().min(1),
    NEXT_PUBLIC_USDC_TOKEN_ADDRESS_MAINNET: z.string().min(1),
    NEXT_PUBLIC_NEW_USDC_TOKEN_ADDRESS_MAINNET: z.string().min(1).optional(),
    NEXT_PUBLIC_LOCKER_CONTRACT_ADDRESS_MAINNET: z.string().min(1),
    NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS_MAINNET: z.string().min(1),
    NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS_MAINNET: z.string().min(1).optional(),
    NEXT_PUBLIC_MINT_STAKING_CONTRACT_ADDRESS_MAINNET: z.string().min(1),
    NEXT_PUBLIC_AIRDROP_CONTRACT_ADDRESS_MAINNET: z.string().min(1),
    NEXT_PUBLIC_PERIOD_STARTER_CONTRACT_ADDRESS_MAINNET: z.string().min(1),
    NEXT_PUBLIC_VESTING_CONTRACT_ADDRESS_MAINNET: z.string().min(1),
    // AMOY CONTRACT ADDRESSES
    NEXT_PUBLIC_MIC_TOKEN_ADDRESS_AMOY: z.string().min(1).optional(),
    NEXT_PUBLIC_MIC_USDC_LP_TOKEN_ADDRESS_AMOY: z.string().min(1).optional(),
    NEXT_PUBLIC_ICE_TOKEN_ADDRESS_AMOY: z.string().min(1).optional(),
    NEXT_PUBLIC_USDC_TOKEN_ADDRESS_AMOY: z.string().min(1).optional(),
    NEXT_PUBLIC_NEW_USDC_TOKEN_ADDRESS_AMOY: z.string().min(1).optional(),
    NEXT_PUBLIC_LOCKER_CONTRACT_ADDRESS_AMOY: z.string().min(1).optional(),
    NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS_AMOY: z.string().min(1).optional(),
    NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS_AMOY: z.string().min(1).optional(),
    NEXT_PUBLIC_MINT_STAKING_CONTRACT_ADDRESS_AMOY: z.string().min(1).optional(),
    NEXT_PUBLIC_AIRDROP_CONTRACT_ADDRESS_AMOY: z.string().min(1).optional(),
    NEXT_PUBLIC_PERIOD_STARTER_CONTRACT_ADDRESS_AMOY: z.string().min(1).optional(),
    NEXT_PUBLIC_VESTING_CONTRACT_ADDRESS_AMOY: z.string().min(1).optional(),
  },
  runtimeEnv: {
    // SERVER
    NODE_ENV: process.env.NODE_ENV,
    VERCEL_URL: process.env.VERCEL_URL,
    // CLIENT
    NEXT_PUBLIC_APP_NAME: process.env.NEXT_PUBLIC_APP_NAME,
    NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
    NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
    NEXT_PUBLIC_PROJECT_ID: process.env.NEXT_PUBLIC_PROJECT_ID,
    NEXT_PUBLIC_GA_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
    NEXT_PUBLIC_TX_CHAIN_CONFIRMATIONS: process.env.NEXT_PUBLIC_TX_CHAIN_CONFIRMATIONS,
    NEXT_PUBLIC_CORE_URL: process.env.NEXT_PUBLIC_CORE_URL,
    NEXT_PUBLIC_INDEXER_URL: process.env.NEXT_PUBLIC_INDEXER_URL,
    NEXT_PUBLIC_ADMIN_ADDRESSES: (process.env.NEXT_PUBLIC_ADMIN_ADDRESSES ?? '').split(
      ',',
    ),
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_PROJECT_API_KEY: process.env.NEXT_PUBLIC_PROJECT_API_KEY,
    NEXT_PUBLIC_HOTJAR_ID: process.env.NEXT_PUBLIC_HOTJAR_ID,
    NEXT_PUBLIC_HOTJAR_VERSION: process.env.NEXT_PUBLIC_HOTJAR_VERSION,
    // LOCAL NODE CONTRACT ADDRESSES
    NEXT_PUBLIC_MIC_TOKEN_ADDRESS: process.env.NEXT_PUBLIC_MIC_TOKEN_ADDRESS,
    NEXT_PUBLIC_MIC_USDC_LP_TOKEN_ADDRESS:
      process.env.NEXT_PUBLIC_MIC_USDC_LP_TOKEN_ADDRESS,
    NEXT_PUBLIC_ICE_TOKEN_ADDRESS: process.env.NEXT_PUBLIC_ICE_TOKEN_ADDRESS,
    NEXT_PUBLIC_USDC_TOKEN_ADDRESS: process.env.NEXT_PUBLIC_USDC_TOKEN_ADDRESS,
    NEXT_PUBLIC_NEW_USDC_TOKEN_ADDRESS: process.env.NEXT_PUBLIC_NEW_USDC_TOKEN_ADDRESS,
    NEXT_PUBLIC_LOCKER_CONTRACT_ADDRESS: process.env.NEXT_PUBLIC_LOCKER_CONTRACT_ADDRESS,
    NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS:
      process.env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS,
    NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS:
      process.env.NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS,
    NEXT_PUBLIC_MINT_STAKING_CONTRACT_ADDRESS:
      process.env.NEXT_PUBLIC_MINT_STAKING_CONTRACT_ADDRESS,
    NEXT_PUBLIC_AIRDROP_CONTRACT_ADDRESS:
      process.env.NEXT_PUBLIC_AIRDROP_CONTRACT_ADDRESS,
    NEXT_PUBLIC_PERIOD_STARTER_CONTRACT_ADDRESS:
      process.env.NEXT_PUBLIC_PERIOD_STARTER_CONTRACT_ADDRESS,
    NEXT_PUBLIC_VESTING_CONTRACT_ADDRESS:
      process.env.NEXT_PUBLIC_VESTING_CONTRACT_ADDRESS,
    // MAINNET CONTRACT ADDRESSES
    NEXT_PUBLIC_MIC_TOKEN_ADDRESS_MAINNET:
      process.env.NEXT_PUBLIC_MIC_TOKEN_ADDRESS_MAINNET,
    NEXT_PUBLIC_MIC_USDC_LP_TOKEN_ADDRESS_MAINNET:
      process.env.NEXT_PUBLIC_MIC_USDC_LP_TOKEN_ADDRESS_MAINNET,
    NEXT_PUBLIC_ICE_TOKEN_ADDRESS_MAINNET:
      process.env.NEXT_PUBLIC_ICE_TOKEN_ADDRESS_MAINNET,
    NEXT_PUBLIC_USDC_TOKEN_ADDRESS_MAINNET:
      process.env.NEXT_PUBLIC_USDC_TOKEN_ADDRESS_MAINNET,
    NEXT_PUBLIC_NEW_USDC_TOKEN_ADDRESS_MAINNET:
      process.env.NEXT_PUBLIC_NEW_USDC_TOKEN_ADDRESS_MAINNET,
    NEXT_PUBLIC_LOCKER_CONTRACT_ADDRESS_MAINNET:
      process.env.NEXT_PUBLIC_LOCKER_CONTRACT_ADDRESS_MAINNET,
    NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS_MAINNET:
      process.env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS_MAINNET,
    NEXT_PUBLIC_MINT_STAKING_CONTRACT_ADDRESS_MAINNET:
      process.env.NEXT_PUBLIC_MINT_STAKING_CONTRACT_ADDRESS_MAINNET,
    NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS_MAINNET:
      process.env.NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS_MAINNET,
    NEXT_PUBLIC_AIRDROP_CONTRACT_ADDRESS_MAINNET:
      process.env.NEXT_PUBLIC_AIRDROP_CONTRACT_ADDRESS_MAINNET,
    NEXT_PUBLIC_PERIOD_STARTER_CONTRACT_ADDRESS_MAINNET:
      process.env.NEXT_PUBLIC_PERIOD_STARTER_CONTRACT_ADDRESS_MAINNET,
    NEXT_PUBLIC_VESTING_CONTRACT_ADDRESS_MAINNET:
      process.env.NEXT_PUBLIC_VESTING_CONTRACT_ADDRESS_MAINNET,
    // AMOY CONTRACT ADDRESSES
    NEXT_PUBLIC_MIC_TOKEN_ADDRESS_AMOY: process.env.NEXT_PUBLIC_MIC_TOKEN_ADDRESS_AMOY,
    NEXT_PUBLIC_MIC_USDC_LP_TOKEN_ADDRESS_AMOY:
      process.env.NEXT_PUBLIC_MIC_USDC_LP_TOKEN_ADDRESS_AMOY,
    NEXT_PUBLIC_ICE_TOKEN_ADDRESS_AMOY: process.env.NEXT_PUBLIC_ICE_TOKEN_ADDRESS_AMOY,
    NEXT_PUBLIC_USDC_TOKEN_ADDRESS_AMOY: process.env.NEXT_PUBLIC_USDC_TOKEN_ADDRESS_AMOY,
    NEXT_PUBLIC_NEW_USDC_TOKEN_ADDRESS_AMOY:
      process.env.NEXT_PUBLIC_NEW_USDC_TOKEN_ADDRESS_AMOY,
    NEXT_PUBLIC_LOCKER_CONTRACT_ADDRESS_AMOY:
      process.env.NEXT_PUBLIC_LOCKER_CONTRACT_ADDRESS_AMOY,
    NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS_AMOY:
      process.env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS_AMOY,
    NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS_AMOY:
      process.env.NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS_AMOY,
    NEXT_PUBLIC_MINT_STAKING_CONTRACT_ADDRESS_AMOY:
      process.env.NEXT_PUBLIC_MINT_STAKING_CONTRACT_ADDRESS_AMOY,
    NEXT_PUBLIC_AIRDROP_CONTRACT_ADDRESS_AMOY:
      process.env.NEXT_PUBLIC_AIRDROP_CONTRACT_ADDRESS_AMOY,
    NEXT_PUBLIC_PERIOD_STARTER_CONTRACT_ADDRESS_AMOY:
      process.env.NEXT_PUBLIC_PERIOD_STARTER_CONTRACT_ADDRESS_AMOY,
    NEXT_PUBLIC_VESTING_CONTRACT_ADDRESS_AMOY:
      process.env.NEXT_PUBLIC_VESTING_CONTRACT_ADDRESS_AMOY,
  },
});

module.exports = {
  env,
};
