import { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useAccount } from 'wagmi';
import { readContract } from '@wagmi/core';
import ConnectWalletButton from '../wallet/ConnectWalletButton';
import { ADMIN_ACCOUNTS } from '../../utils/constants';
import { useIsPageAccessible } from '../../utils/hooks/useIsPageAccessible';
import { useProtocolContractAddresses } from '../../utils/hooks/useProtocolContractAddresses';
import { stepVestingWalletABI } from '../../utils/hooks/generated';

const MenuLink = ({
  label,
  target,
  onClose,
  isAccessibleBy,
}: {
  label: string;
  target: string;
  onClose?: () => void;
  isAccessibleBy: (address?: string) => Promise<boolean>;
}) => {
  const { t } = useTranslation('common');
  const { pathname } = useRouter();
  const { address } = useAccount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const accessChecker = useCallback(() => isAccessibleBy(address), [address]);
  const isVisible = useIsPageAccessible(accessChecker);

  if (!isVisible) {
    return null;
  }

  return (
    <li key={target} className="my-2 xl:ml-6">
      <Link
        className={clsx('block py-2 lowercase underline-offset-8 hover:underline', {
          'pointer-events-none text-brand--4 underline dark:text-brand--3':
            pathname === target,
        })}
        href={target}
        onClick={onClose}
      >
        {t(label as keyof typeof t)}
      </Link>
    </li>
  );
};

const Menu = ({ className, onClose }: { className?: string; onClose?: () => void }) => {
  const { vesting } = useProtocolContractAddresses();

  const LINKS = useMemo(
    () =>
      [
        { label: 'HOME_PAGE', target: '/', isAccessibleBy: async () => true },
        { label: 'KNOWLEDGE_BASE', target: '/docs', isAccessibleBy: async () => true },
        { label: 'ADDRESSES', target: '/addresses', isAccessibleBy: async () => true },
        {
          label: 'ADMIN',
          target: '/admin',
          isAccessibleBy: async (address?: string) =>
            !!address && ADMIN_ACCOUNTS.includes(address),
        },
        {
          label: 'VESTING',
          target: '/vesting',
          isAccessibleBy: async (address?: string) => {
            const result = await readContract({
              abi: stepVestingWalletABI,
              address: vesting,
              functionName: 'isBeneficiary',
              args: [address as `0x${string}`],
            }).catch(() => false);
            return result ?? false;
          },
        },
      ] as const,
    [vesting],
  );

  return (
    <nav className={className}>
      <ul className="xl:flex xl:items-center">
        {LINKS.map((linkProps) => (
          <MenuLink key={linkProps.label} {...linkProps} onClose={onClose} />
        ))}

        <li className="my-2 xl:ml-6">
          <ConnectWalletButton className="connect-wallet-btn connect-wallet-btn--navbar" />
        </li>
      </ul>
    </nav>
  );
};

export default Menu;
