import { create } from 'zustand';

const MODAL_TYPES = ['report-issue', 'charge-up'] as const;
type ModalType = typeof MODAL_TYPES[number];

type ModalsVisibility = Partial<Record<ModalType, boolean>>;

interface ModalsStore {
  openModal: (type: ModalType) => void;
  closeModal: (type: ModalType) => void;
  toggleModal: (type: ModalType) => void;
  modalVisibility: ModalsVisibility;
}

export const useModalsStore = create<ModalsStore>((set) => ({
  modalVisibility: {
    'charge-up': false,
    'report-issue': false,
  },
  openModal: (type: ModalType) =>
    set((store) => ({
      ...store,
      modalVisibility: {
        ...store.modalVisibility,
        [type]: true,
      },
    })),
  closeModal: (type: ModalType) =>
    set((store) => ({
      ...store,
      modalVisibility: {
        ...store.modalVisibility,
        [type]: false,
      },
    })),
  toggleModal: (type: ModalType) =>
    set((store) => ({
      ...store,
      modalVisibility: {
        ...store.modalVisibility,
        [type]: !store.modalVisibility[type],
      },
    })),
}));
