import clsx from 'clsx';
import * as viem from 'viem';
import { useRewardsAsset, useStakingAsset } from '../../utils/useAssets';
import { useTokenBalanceOfUser } from '../../utils/hooks/token/useTokenBalanceOfUser';
import useTranslation from 'next-translate/useTranslation';
import { useStakedTokenAmount } from '../../utils/hooks/staking/useStakedTokensAmount';

const TokenBalance = ({
  token,
}: {
  token: {
    address: `0x${string}`;
    decimals: number;
    symbol: string;
    balance?: bigint;
  };
}) => {
  const { t } = useTranslation('common');
  const { data: stakingTokenBalace } = useTokenBalanceOfUser({
    tokenAddress: token.balance ? undefined : token?.address,
  });
  const balance = viem
    .formatUnits(token.balance ?? (stakingTokenBalace || 0n), token?.decimals || 0)
    .toString();

  return (
    <div className="mb-4">
      <h4 className="text-xs font-medium uppercase tracking-widest text-brand--4">
        {token.symbol}
      </h4>

      {balance === '0.0' ? (
        <div className="w-full py-2 text-xs">{t('NO_TOKENS_FOUND')}</div>
      ) : (
        <div className={clsx('overflow-hidden overflow-ellipsis py-2 text-2xl')}>
          <span>{balance}</span>
        </div>
      )}
    </div>
  );
};

const AccountBalance = () => {
  const { t } = useTranslation('common');
  const stakingAsset = useStakingAsset('MIC')!;
  const rewardsAsset = useRewardsAsset('ICE')!;

  const { amountOfStakedTokens } = useStakedTokenAmount({ stakedAsset: stakingAsset });
  const stakedAsset = {
    address: stakingAsset.address,
    decimals: stakingAsset.decimals,
    symbol: t('STAKED_TOKEN_SYMBOL'),
    balance: amountOfStakedTokens,
  };

  return (
    <div className="mb-8">
      {[stakingAsset, stakedAsset, rewardsAsset].map((token) => (
        <TokenBalance key={token.symbol} token={token} />
      ))}
    </div>
  );
};

export default AccountBalance;
