import tailwindConfig from '../tailwind.config';
import { env } from '../env';
import { getWebisteURL } from './getWebsiteURL';

export const COLORS = tailwindConfig.theme.extend.colors;

export const ADMIN_ACCOUNTS = env.NEXT_PUBLIC_ADMIN_ADDRESSES;

export const CONFIRMATIONS_AMOUNT = env.NEXT_PUBLIC_TX_CHAIN_CONFIRMATIONS;

export const CHAIN_ID_TO_NETWORK_NAME = {
  [137]: 'MAINNET',
  [80002]: 'TESTNET',
} as const;

export const PROTOCOL_CONCTRACT_ADDRESSES = {
  // polygon mainnet
  137: {
    ice: env.NEXT_PUBLIC_ICE_TOKEN_ADDRESS_MAINNET as `0x${string}`,
    mic: env.NEXT_PUBLIC_MIC_TOKEN_ADDRESS_MAINNET as `0x${string}`,
    usdc: env.NEXT_PUBLIC_USDC_TOKEN_ADDRESS_MAINNET as `0x${string}`,
    newUsdc: env.NEXT_PUBLIC_NEW_USDC_TOKEN_ADDRESS_MAINNET as `0x${string}`,
    airdrop: env.NEXT_PUBLIC_AIRDROP_CONTRACT_ADDRESS_MAINNET as `0x${string}`,
    locker: env.NEXT_PUBLIC_LOCKER_CONTRACT_ADDRESS_MAINNET as `0x${string}`,
    staking: env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS_MAINNET as `0x${string}`,
    newStaking: env.NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS_MAINNET as `0x${string}`,
    mintStaking: env.NEXT_PUBLIC_MINT_STAKING_CONTRACT_ADDRESS_MAINNET as `0x${string}`,
    periodStarter:
      env.NEXT_PUBLIC_PERIOD_STARTER_CONTRACT_ADDRESS_MAINNET as `0x${string}`,
    vesting: env.NEXT_PUBLIC_VESTING_CONTRACT_ADDRESS_MAINNET as `0x${string}`,
  },
  // hardhat local environment
  31337: {
    ice: env.NEXT_PUBLIC_ICE_TOKEN_ADDRESS as `0x${string}`,
    mic: env.NEXT_PUBLIC_MIC_TOKEN_ADDRESS as `0x${string}`,
    usdc: env.NEXT_PUBLIC_USDC_TOKEN_ADDRESS as `0x${string}`,
    newUsdc: env.NEXT_PUBLIC_NEW_USDC_TOKEN_ADDRESS as `0x${string}`,
    airdrop: env.NEXT_PUBLIC_AIRDROP_CONTRACT_ADDRESS as `0x${string}`,
    locker: env.NEXT_PUBLIC_LOCKER_CONTRACT_ADDRESS as `0x${string}`,
    staking: env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS as `0x${string}`,
    newStaking: env.NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS_AMOY as `0x${string}`,
    mintStaking: env.NEXT_PUBLIC_MINT_STAKING_CONTRACT_ADDRESS as `0x${string}`,
    periodStarter: env.NEXT_PUBLIC_PERIOD_STARTER_CONTRACT_ADDRESS as `0x${string}`,
    vesting: env.NEXT_PUBLIC_VESTING_CONTRACT_ADDRESS as `0x${string}`,
  },
  // amoy testnet
  80002: {
    ice: env.NEXT_PUBLIC_ICE_TOKEN_ADDRESS_AMOY as `0x${string}`,
    mic: env.NEXT_PUBLIC_MIC_TOKEN_ADDRESS_AMOY as `0x${string}`,
    usdc: env.NEXT_PUBLIC_USDC_TOKEN_ADDRESS_AMOY as `0x${string}`,
    newUsdc: env.NEXT_PUBLIC_NEW_USDC_TOKEN_ADDRESS_AMOY as `0x${string}`,
    airdrop: env.NEXT_PUBLIC_AIRDROP_CONTRACT_ADDRESS_AMOY as `0x${string}`,
    locker: env.NEXT_PUBLIC_LOCKER_CONTRACT_ADDRESS_AMOY as `0x${string}`,
    staking: env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS_AMOY as `0x${string}`,
    newStaking: env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS_AMOY as `0x${string}`, // fallback to old staking
    mintStaking: env.NEXT_PUBLIC_MINT_STAKING_CONTRACT_ADDRESS_AMOY as `0x${string}`,
    periodStarter: env.NEXT_PUBLIC_PERIOD_STARTER_CONTRACT_ADDRESS_AMOY as `0x${string}`,
    vesting: env.NEXT_PUBLIC_VESTING_CONTRACT_ADDRESS_AMOY as `0x${string}`,
  },
} as const;

export const STAKING_CONTRACTS = {
  'USDC.e': {
    address: {
      137: env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS_MAINNET as `0x${string}`,
      31337: env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS as `0x${string}`,
      80002: env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS_AMOY as `0x${string}`,
    },
  },
  USDC: {
    address: {
      137: env.NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS_MAINNET as `0x${string}`,
      31337: env.NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS as `0x${string}`,
      80002: env.NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS_AMOY as `0x${string}`,
    },
  },
};

export const STAKING_ASSETS = [
  {
    name: 'MilkyIce Token',
    symbol: 'MIC',
    decimals: 18,
    stakingAssetIndex: 0,
    image: `${getWebisteURL()}/milkyice-token-logo.jpeg`,
    address: {
      137: env.NEXT_PUBLIC_MIC_TOKEN_ADDRESS_MAINNET as `0x${string}`,
      31337: env.NEXT_PUBLIC_MIC_TOKEN_ADDRESS as `0x${string}`,
      80002: env.NEXT_PUBLIC_MIC_TOKEN_ADDRESS_AMOY as `0x${string}`,
    },
    supportsPermit: true,
  },
  {
    name: 'MilkyIce/USDC LP Token',
    symbol: 'MIC/USDC LP',
    decimals: 18,
    stakingAssetIndex: 1,
    image: `${getWebisteURL()}/milkyice-token-logo.jpeg`,
    address: {
      137: env.NEXT_PUBLIC_MIC_USDC_LP_TOKEN_ADDRESS as `0x${string}`,
      31337: env.NEXT_PUBLIC_MIC_USDC_LP_TOKEN_ADDRESS as `0x${string}`,
      80002: env.NEXT_PUBLIC_MIC_USDC_LP_TOKEN_ADDRESS_AMOY as `0x${string}`,
    },
  },
] as const;

export const STAKING_REWARDS = [
  {
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    image: `${getWebisteURL()}/usd-coin-token-logo.svg`,
    address: {
      137: env.NEXT_PUBLIC_NEW_USDC_TOKEN_ADDRESS_MAINNET as `0x${string}`,
      31337: env.NEXT_PUBLIC_NEW_USDC_TOKEN_ADDRESS as `0x${string}`,
      80002: env.NEXT_PUBLIC_NEW_USDC_TOKEN_ADDRESS_AMOY as `0x${string}`,
    },
    stakingContractAddress: {
      137: env.NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS_MAINNET as `0x${string}`,
      31337: env.NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS as `0x${string}`,
      80002: env.NEXT_PUBLIC_NEW_STAKING_CONTRACT_ADDRESS_AMOY as `0x${string}`,
    },
    stakingContractIndex: 2,
  },
  {
    name: 'USD Coin (PoS)',
    symbol: 'USDC.e',
    decimals: 6,
    image: `${getWebisteURL()}/usd-coin-token-logo.svg`,
    address: {
      137: env.NEXT_PUBLIC_USDC_TOKEN_ADDRESS_MAINNET as `0x${string}`,
      31337: env.NEXT_PUBLIC_USDC_TOKEN_ADDRESS as `0x${string}`,
      80002: env.NEXT_PUBLIC_USDC_TOKEN_ADDRESS_AMOY as `0x${string}`,
    },
    stakingContractAddress: {
      137: env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS_MAINNET as `0x${string}`,
      31337: env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS as `0x${string}`,
      80002: env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS_AMOY as `0x${string}`,
    },
    stakingContractIndex: 0,
  },
  {
    name: 'MilkyIce ICE Token',
    symbol: 'ICE',
    decimals: 18,
    image: `${getWebisteURL()}/milkyice-token-logo.jpeg`,
    address: {
      137: env.NEXT_PUBLIC_ICE_TOKEN_ADDRESS_MAINNET as `0x${string}`,
      31337: env.NEXT_PUBLIC_ICE_TOKEN_ADDRESS as `0x${string}`,
      80002: env.NEXT_PUBLIC_ICE_TOKEN_ADDRESS_AMOY as `0x${string}`,
    },
    stakingContractAddress: {
      137: env.NEXT_PUBLIC_MINT_STAKING_CONTRACT_ADDRESS_MAINNET as `0x${string}`,
      31337: env.NEXT_PUBLIC_MINT_STAKING_CONTRACT_ADDRESS as `0x${string}`,
      80002: env.NEXT_PUBLIC_MINT_STAKING_CONTRACT_ADDRESS_AMOY as `0x${string}`,
    },
    stakingContractIndex: 1,
  },
] as const;

export const LOCK_PERIODS = [
  {
    label: 'NO_LOCK',
    value: '0',
  },
  {
    label: '90_DAYS',
    value: '1',
  },
  {
    label: '180_DAYS',
    value: '2',
  },
  {
    label: '360_DAYS',
    value: '3',
  },
] as const;

export const CORE_SERVICE = {
  baseUrl: env.NEXT_PUBLIC_CORE_URL,
  projectApiKey: env.NEXT_PUBLIC_PROJECT_API_KEY,
} as const;

export const GOOGLE_ID = env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

export const MAX_UINT_256 = 2n ** 256n - 1n;
export const WEI_PER_ETHER = 1e18;
