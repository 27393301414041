import clsx from 'clsx';
import { useAccount } from 'wagmi';
import useTranslation from 'next-translate/useTranslation';
import { useWindowScroll } from 'react-use';
import DismissableInfo from '../base/DismissableInfo';
import { useStakingAssets } from '../../utils/useAssets';
import { useAddAssetToWallet } from '../../utils/hooks/useAddAssetToWallet';

const AddTokenToWallet = () => {
  const [dividendToken] = useStakingAssets();
  const addTokenToWallet = useAddAssetToWallet(dividendToken);
  const { address } = useAccount();
  const { t } = useTranslation('airdrops');
  const { y: scrolledFromTop } = useWindowScroll();

  if (!address) {
    return <></>;
  }

  return (
    <DismissableInfo
      className={clsx('-mx-4', scrolledFromTop > 50 ? '' : '-mt-2 sm:-mt-4')}
    >
      <span className="mr-1">{t('DONT_SEE_MIC_TOKENS_IN_YOUR_WALLET')}</span>

      <button
        className="font-bold underline underline-offset-4"
        onClick={() => {
          addTokenToWallet();
        }}
      >
        {t('CLICK_HERE_TO_ADD_IT')}
      </button>
    </DismissableInfo>
  );
};

export default AddTokenToWallet;
