import { ApolloClient, InMemoryCache } from '@apollo/client';
import { CORE_SERVICE } from './constants';

const apiUrl = CORE_SERVICE.baseUrl || '/';
const projectApiKey = CORE_SERVICE.projectApiKey || '';

export const client = new ApolloClient({
  uri: apiUrl + `/graphql`,
  headers: {
    'X-API-KEY': projectApiKey,
  },
  cache: new InMemoryCache(),
});

export default client;
