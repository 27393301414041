import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { PropsWithChildren } from 'react';

const BaseBox: React.FC<{ title?: string; className?: string } & PropsWithChildren> = ({
  children,
  title,
  className,
}) => {
  return (
    <div
      className={twMerge(
        clsx(
          `w-full rounded-2xl border p-6 shadow-2xl shadow-[#efefef]/80
       dark:border-brand--5 dark:bg-brand--6 dark:shadow-black/20 md:p-8`,
          className,
        ),
      )}
    >
      {title && (
        <div className="prose dark:prose-invert">
          <h2 className="mb-5 font-medium">{title}</h2>
        </div>
      )}

      {children}
    </div>
  );
};

export default BaseBox;
