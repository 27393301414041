import { useTheme } from 'next-themes';
import useTranslation from 'next-translate/useTranslation';
import toast from 'react-hot-toast';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationsStore } from '../stores/notifications';
import { TransactionEventHandlers } from '../types/TransactionEventHandlers';
import { getCommonErrorMessage } from './getCommonErrorMessage';
import { BaseError } from 'viem';

const commonTheme = {
  duration: 9000,
  position: 'top-right',
} as const;

const darkTheme = {
  ...commonTheme,
  className: '!bg-brand--5 !text-white !shadow-lg',
} as const;

export const useToast = () => {
  const { theme } = useTheme();

  const displayToast = (type: 'success' | 'error' | 'loading', message: string) => {
    return toast[type](message, theme === 'dark' ? darkTheme : commonTheme);
  };
  return { displayToast, dismissToast: toast.dismiss };
};

export const useNotifications = () => {
  const { notifications, addNotification, removeNotification } = useNotificationsStore();
  const { displayToast, dismissToast } = useToast();

  const addNotificationWithToast = (
    txHash: string,
    type: 'success' | 'error' | 'loading',
    message: string,
  ) => {
    const toastId = displayToast(type, message);
    addNotification(txHash, toastId);
  };

  const removeNotificationWithToast = (txHash: string) => {
    const toastId = notifications.find(
      (notification) => notification.tx === txHash,
    )?.toastId;
    dismissToast(toastId);
    removeNotification(txHash);
  };

  return { addNotificationWithToast, removeNotificationWithToast };
};

export const useNotifiacationHandlers = (handlers: TransactionEventHandlers) => {
  const { addNotificationWithToast, removeNotificationWithToast } = useNotifications();
  const { displayToast } = useToast();
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();

  const transactionNotificationHandlers = {
    onTransactionSubmitted: (txHash: string, message: string) => {
      addNotificationWithToast(txHash, 'loading', message);
      handlers.onTransactionSubmitted?.({ hash: txHash });
    },
    onTransactionSettled: (txHash: string, error: Error | null) => {
      removeNotificationWithToast(txHash);
      handlers.onTransactionSettled?.(txHash, error);
      queryClient.invalidateQueries(['pastStakingEvents']);
    },
    onSubmitError: (error: Error) => {
      const message = getCommonErrorMessage(error as BaseError);
      console.log({ error, message });
      displayToast(
        'error',
        t(message.key as keyof typeof t, { context: message.context }),
      );
      handlers.onSubmitError?.(error);
    },
    onTransactionError: (error: Error) => {
      const message = getCommonErrorMessage(error as BaseError);
      console.log({ error, message });
      displayToast('error', error?.message ?? '');
      handlers.onTransactionError?.(error);
    },
    onTransactionSuccess: (message: string) => {
      displayToast('success', message);
      handlers.onTransactionSuccess?.();
    },
  };

  return transactionNotificationHandlers;
};
