// Generated by @wagmi/cli@1.3.0 on 7/4/2023 at 7:14:12 PM
import {
  useContractRead,
  UseContractReadConfig,
  useContractWrite,
  UseContractWriteConfig,
  usePrepareContractWrite,
  UsePrepareContractWriteConfig,
} from 'wagmi';
import {
  ReadContractResult,
  WriteContractMode,
  PrepareWriteContractResult,
} from 'wagmi/actions';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// MerkleClaimableAirdrop
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const merkleClaimableAirdropABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_token', internalType: 'contract IERC20', type: 'address' },
      { name: '_rewardToken', internalType: 'contract IERC20', type: 'address' },
      { name: '_merkleRoot', internalType: 'bytes32', type: 'bytes32' },
    ],
  },
  { type: 'error', inputs: [], name: 'MerkleClaimableAirdrop__AlreadyClaimed' },
  { type: 'error', inputs: [], name: 'MerkleClaimableAirdrop__InvalidProof' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Claimed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'account', internalType: 'address', type: 'address', indexed: false },
    ],
    name: 'Paused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'account', internalType: 'address', type: 'address', indexed: false },
    ],
    name: 'Unpaused',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_rewardAmount', internalType: 'uint256', type: 'uint256' },
      { name: '_proof', internalType: 'bytes32[]', type: 'bytes32[]' },
    ],
    name: 'claim',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'finishAirdrop',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'hasClaimed',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'merkleRoot',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'rewardToken',
    outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'token',
    outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'withdrawRemaining',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// GovernanceDividendTokenWrapper
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const governanceDividendTokenWrapperABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_name', internalType: 'string', type: 'string' },
      { name: '_symbol', internalType: 'string', type: 'string' },
    ],
  },
  { type: 'error', inputs: [], name: 'NonTransferableToken__TransferIsNotAllowed' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'delegator', internalType: 'address', type: 'address', indexed: true },
      { name: 'fromDelegate', internalType: 'address', type: 'address', indexed: true },
      { name: 'toDelegate', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'DelegateChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'delegate', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'previousBalance',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      { name: 'newBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DelegateVotesChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'burn',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'pos', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'checkpoints',
    outputs: [
      {
        name: '',
        internalType: 'struct ERC20Votes.Checkpoint',
        type: 'tuple',
        components: [
          { name: 'fromBlock', internalType: 'uint32', type: 'uint32' },
          { name: 'votes', internalType: 'uint224', type: 'uint224' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'delegatee', internalType: 'address', type: 'address' }],
    name: 'delegate',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'delegatee', internalType: 'address', type: 'address' },
      { name: 'nonce', internalType: 'uint256', type: 'uint256' },
      { name: 'expiry', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'delegateBySig',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'delegates',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'blockNumber', internalType: 'uint256', type: 'uint256' }],
    name: 'getPastTotalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPastVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'getVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'mint',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'nonces',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'numCheckpoints',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'permit',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// GovernorContract
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const governorContractABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_token', internalType: 'contract IVotes', type: 'address' },
      { name: '_timelock', internalType: 'contract TimelockController', type: 'address' },
      { name: '_name', internalType: 'string', type: 'string' },
      { name: '_votingDelay', internalType: 'uint256', type: 'uint256' },
      { name: '_votingPeriod', internalType: 'uint256', type: 'uint256' },
      { name: '_proposalThreshold', internalType: 'uint256', type: 'uint256' },
      { name: '_quorum', internalType: 'uint256', type: 'uint256' },
    ],
  },
  { type: 'error', inputs: [], name: 'Empty' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalCanceled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'proposer', internalType: 'address', type: 'address', indexed: false },
      { name: 'targets', internalType: 'address[]', type: 'address[]', indexed: false },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
      { name: 'signatures', internalType: 'string[]', type: 'string[]', indexed: false },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]', indexed: false },
      { name: 'startBlock', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'endBlock', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'description', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'ProposalCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalExecuted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'eta', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalQueued',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'oldProposalThreshold',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'newProposalThreshold',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'ProposalThresholdSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'oldTimelock', internalType: 'address', type: 'address', indexed: false },
      { name: 'newTimelock', internalType: 'address', type: 'address', indexed: false },
    ],
    name: 'TimelockChange',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'voter', internalType: 'address', type: 'address', indexed: true },
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'support', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'weight', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reason', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'VoteCast',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'voter', internalType: 'address', type: 'address', indexed: true },
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'support', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'weight', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reason', internalType: 'string', type: 'string', indexed: false },
      { name: 'params', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'VoteCastWithParams',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'oldVotingDelay',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'newVotingDelay',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'VotingDelaySet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'oldVotingPeriod',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'newVotingPeriod',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'VotingPeriodSet',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'BALLOT_TYPEHASH',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'COUNTING_MODE',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'EXTENDED_BALLOT_TYPEHASH',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
    ],
    name: 'castVote',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castVoteBySig',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
    ],
    name: 'castVoteWithReason',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'castVoteWithReasonAndParams',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castVoteWithReasonAndParamsBySig',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'execute',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'getVotesWithParams',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasVoted',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'hashProposal',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155BatchReceived',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalDeadline',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalEta',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalSnapshot',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proposalThreshold',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalVotes',
    outputs: [
      { name: 'againstVotes', internalType: 'uint256', type: 'uint256' },
      { name: 'forVotes', internalType: 'uint256', type: 'uint256' },
      { name: 'abstainVotes', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'description', internalType: 'string', type: 'string' },
    ],
    name: 'propose',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'queue',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'quorum',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'target', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'relay',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newProposalThreshold', internalType: 'uint256', type: 'uint256' }],
    name: 'setProposalThreshold',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newVotingDelay', internalType: 'uint256', type: 'uint256' }],
    name: 'setVotingDelay',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newVotingPeriod', internalType: 'uint256', type: 'uint256' }],
    name: 'setVotingPeriod',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'state',
    outputs: [{ name: '', internalType: 'enum IGovernor.ProposalState', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'timelock',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'token',
    outputs: [{ name: '', internalType: 'contract IVotes', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'newTimelock',
        internalType: 'contract TimelockController',
        type: 'address',
      },
    ],
    name: 'updateTimelock',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'version',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'votingDelay',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'votingPeriod',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// TimeLock
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const timeLockABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: 'minDelay', internalType: 'uint256', type: 'uint256' },
      { name: 'proposers', internalType: 'address[]', type: 'address[]' },
      { name: 'executors', internalType: 'address[]', type: 'address[]' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'id', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'index', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'target', internalType: 'address', type: 'address', indexed: false },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'data', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'CallExecuted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'id', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'index', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'target', internalType: 'address', type: 'address', indexed: false },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'data', internalType: 'bytes', type: 'bytes', indexed: false },
      { name: 'predecessor', internalType: 'bytes32', type: 'bytes32', indexed: false },
      { name: 'delay', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'CallScheduled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'id', internalType: 'bytes32', type: 'bytes32', indexed: true }],
    name: 'Cancelled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'oldDuration', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'newDuration', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'MinDelayChange',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'previousAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      { name: 'newAdminRole', internalType: 'bytes32', type: 'bytes32', indexed: true },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleRevoked',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'CANCELLER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'EXECUTOR_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'PROPOSER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'TIMELOCK_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'id', internalType: 'bytes32', type: 'bytes32' }],
    name: 'cancel',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'target', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'payload', internalType: 'bytes', type: 'bytes' },
      { name: 'predecessor', internalType: 'bytes32', type: 'bytes32' },
      { name: 'salt', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'execute',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'payloads', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'predecessor', internalType: 'bytes32', type: 'bytes32' },
      { name: 'salt', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'executeBatch',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getMinDelay',
    outputs: [{ name: 'duration', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'id', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getTimestamp',
    outputs: [{ name: 'timestamp', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'target', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
      { name: 'predecessor', internalType: 'bytes32', type: 'bytes32' },
      { name: 'salt', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'hashOperation',
    outputs: [{ name: 'hash', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'payloads', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'predecessor', internalType: 'bytes32', type: 'bytes32' },
      { name: 'salt', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'hashOperationBatch',
    outputs: [{ name: 'hash', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'id', internalType: 'bytes32', type: 'bytes32' }],
    name: 'isOperation',
    outputs: [{ name: 'registered', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'id', internalType: 'bytes32', type: 'bytes32' }],
    name: 'isOperationDone',
    outputs: [{ name: 'done', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'id', internalType: 'bytes32', type: 'bytes32' }],
    name: 'isOperationPending',
    outputs: [{ name: 'pending', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'id', internalType: 'bytes32', type: 'bytes32' }],
    name: 'isOperationReady',
    outputs: [{ name: 'ready', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155BatchReceived',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'target', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
      { name: 'predecessor', internalType: 'bytes32', type: 'bytes32' },
      { name: 'salt', internalType: 'bytes32', type: 'bytes32' },
      { name: 'delay', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'schedule',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'payloads', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'predecessor', internalType: 'bytes32', type: 'bytes32' },
      { name: 'salt', internalType: 'bytes32', type: 'bytes32' },
      { name: 'delay', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'scheduleBatch',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newDelay', internalType: 'uint256', type: 'uint256' }],
    name: 'updateDelay',
    outputs: [],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LiquidityValueCalculatorMock
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const liquidityValueCalculatorMockABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_oracle', internalType: 'contract IUniswapV2TwapOracle', type: 'address' },
      { name: '_tokenA', internalType: 'address', type: 'address' },
      { name: '_amountOfLPTokens', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'calculateLiquidityValue',
    outputs: [
      { name: 'tokenAAmount', internalType: 'uint256', type: 'uint256' },
      { name: 'tokenBAmount', internalType: 'uint256', type: 'uint256' },
    ],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC20Decimals
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc20DecimalsABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// UniswapV2TwapOracle
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const uniswapV2TwapOracleABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_ops', internalType: 'address', type: 'address' },
      { name: '_pair', internalType: 'contract IUniswapV2Pair', type: 'address' },
      { name: '_period', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ReceivedNativeToken',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'taskId', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'TaskCancelled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'taskId', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'TaskScheduled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'receiver', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'WithdrawnNativeToken',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'PERIOD',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'automationTaskId',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'blockTimestampLast',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'cancelTask',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'token', internalType: 'address', type: 'address' },
      { name: 'amountIn', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'consult',
    outputs: [{ name: 'amountOut', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'createTask',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'dedicatedMsgSender',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenA', internalType: 'address', type: 'address' }],
    name: 'getObservedPrices',
    outputs: [
      { name: 'observedATokenPrice', internalType: 'uint256', type: 'uint256' },
      { name: 'observedBTokenPrice', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'denominatorToken', internalType: 'address', type: 'address' }],
    name: 'getPriceRatios',
    outputs: [
      { name: 'observedDenominatorTokenRatio', internalType: 'uint256', type: 'uint256' },
      { name: 'observedBTokenRatio', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ops',
    outputs: [{ name: '', internalType: 'contract IOps', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'pair',
    outputs: [{ name: '', internalType: 'contract IUniswapV2Pair', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'price0Average',
    outputs: [{ name: '_x', internalType: 'uint224', type: 'uint224' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'price0CumulativeLast',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'price1Average',
    outputs: [{ name: '_x', internalType: 'uint224', type: 'uint224' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'price1CumulativeLast',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'token0',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'token1',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'update',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'withdrawFunds',
    outputs: [],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// GelatoAutomation
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const gelatoAutomationABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'taskId', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'TaskCancelled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'taskId', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'TaskScheduled',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'automationTaskId',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'cancelTask',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'createTask',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// OpsReady
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const opsReadyABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ReceivedNativeToken',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'taskId', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'TaskCancelled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'taskId', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'TaskScheduled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'receiver', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'WithdrawnNativeToken',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'automationTaskId',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'cancelTask',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'createTask',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'dedicatedMsgSender',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ops',
    outputs: [{ name: '', internalType: 'contract IOps', type: 'address' }],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// OpsReadySol6
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const opsReadySol6ABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_ops', internalType: 'address', type: 'address' },
      { name: '_taskCreator', internalType: 'address', type: 'address' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ReceivedNativeToken',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'taskId', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'TaskCancelled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'taskId', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'TaskScheduled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'receiver', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'WithdrawnNativeToken',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'automationTaskId',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'cancelTask',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'createTask',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'dedicatedMsgSender',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ops',
    outputs: [{ name: '', internalType: 'contract IOps', type: 'address' }],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IOps
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iOpsABI = [
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'taskId', internalType: 'bytes32', type: 'bytes32' }],
    name: 'cancelTask',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'execAddress', internalType: 'address', type: 'address' },
      { name: 'execDataOrSelector', internalType: 'bytes', type: 'bytes' },
      {
        name: 'moduleData',
        internalType: 'struct ModuleData',
        type: 'tuple',
        components: [
          { name: 'modules', internalType: 'enum Module[]', type: 'uint8[]' },
          { name: 'args', internalType: 'bytes[]', type: 'bytes[]' },
        ],
      },
      { name: 'feeToken', internalType: 'address', type: 'address' },
    ],
    name: 'createTask',
    outputs: [{ name: 'taskId', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'gelato',
    outputs: [{ name: '', internalType: 'address payable', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getFeeDetails',
    outputs: [
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'address', type: 'address' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'taskTreasury',
    outputs: [
      { name: '', internalType: 'contract ITaskTreasuryUpgradable', type: 'address' },
    ],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IOpsProxyFactory
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iOpsProxyFactoryABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'getProxyOf',
    outputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'bool', type: 'bool' },
    ],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ITaskTreasuryUpgradable
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iTaskTreasuryUpgradableABI = [
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'receiver', internalType: 'address', type: 'address' },
      { name: 'token', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'depositFunds',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'receiver', internalType: 'address payable', type: 'address' },
      { name: 'token', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'withdrawFunds',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// MintStaking
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const mintStakingABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_stakingToken', internalType: 'address', type: 'address' },
      { name: '_rewardsToken', internalType: 'address', type: 'address' },
      { name: '_rewardRate', internalType: 'uint256', type: 'uint256' },
    ],
  },
  { type: 'error', inputs: [], name: 'Staking__StakeAmountCannotBe0' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'staker', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'RewardsCollected',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'staker', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'StakeDeposited',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'staker', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'StakeWithdrawn',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_user', internalType: 'address', type: 'address' }],
    name: 'collectRewardsFor',
    outputs: [{ name: 'reward', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_account', internalType: 'address', type: 'address' }],
    name: 'earnedReward',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getRewardToken',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'rewardPerToken',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'rewardPerTokenStored',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'rewardRate',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'rewards',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'rewardsToken',
    outputs: [{ name: '', internalType: 'contract MintableToken', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_user', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'stakeFor',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'stakingToken',
    outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'updatedAt',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'userRewardPerTokenPaid',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_user', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'withdrawFor',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PeriodStarter
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const periodStarterABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_ops', internalType: 'address', type: 'address' },
      { name: '_stakingContract', internalType: 'contract Staking', type: 'address' },
    ],
  },
  { type: 'error', inputs: [], name: 'PeriodStarter__CurrentPeriodNotYetFinished' },
  {
    type: 'error',
    inputs: [],
    name: 'PeriodStarter__NewTimestampIsOlderThanExistingOne',
  },
  { type: 'error', inputs: [], name: 'PeriodStarter__PeriodDurationCannotBe0' },
  { type: 'error', inputs: [], name: 'PeriodStarter__TimestampIsInPast' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ReceivedNativeToken',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'previousAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      { name: 'newAdminRole', internalType: 'bytes32', type: 'bytes32', indexed: true },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleRevoked',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'taskId', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'TaskCancelled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'taskId', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'TaskScheduled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'receiver', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'WithdrawnNativeToken',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'SCHEDULER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'automationTaskId',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'canStartNewRewardsPeriod',
    outputs: [
      { name: 'canExec', internalType: 'bool', type: 'bool' },
      { name: 'execPayload', internalType: 'bytes', type: 'bytes' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'cancelTask',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'createTask',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'dedicatedMsgSender',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'nextPeriodRewardsAmount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'nextStakingPeriodFinishAt',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ops',
    outputs: [{ name: '', internalType: 'contract IOps', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_nextPeriodFinishTimestamp', internalType: 'uint256', type: 'uint256' },
      { name: '_nextPeriodRewardsAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'scheduleNextRewardsPeriod',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'stakingContract',
    outputs: [{ name: '', internalType: 'contract Staking', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'startNewRewardsPeriod',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'withdrawFunds',
    outputs: [],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Staking
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const stakingABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_stakingToken', internalType: 'address', type: 'address' },
      { name: '_rewardToken', internalType: 'address', type: 'address' },
    ],
  },
  { type: 'error', inputs: [], name: 'Staking__DurationExceedsMaxPeriodDuration' },
  { type: 'error', inputs: [], name: 'Staking__RewardRateIs0' },
  { type: 'error', inputs: [], name: 'Staking__RewardsPeriodNotFinished' },
  { type: 'error', inputs: [], name: 'Staking__StakeAmountCannotBe0' },
  { type: 'error', inputs: [], name: 'Staking__WithdrawAmountBiggerThanStakedAmount' },
  { type: 'error', inputs: [], name: 'Staking__WithdrawAmountCannotBe0' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'staker', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'RewardsCollected',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'startTimestamp',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      { name: 'duration', internalType: 'uint256', type: 'uint256', indexed: false },
      {
        name: 'finishTimestamp',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      { name: 'rewardRate', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'rewardsAmount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'RewardsPeriodStarted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'staker', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'RewardsSlashed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'previousAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      { name: 'newAdminRole', internalType: 'bytes32', type: 'bytes32', indexed: true },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleRevoked',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'staker', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'StakeDeposited',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'staker', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'StakeWithdrawn',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'LOCKER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'PERIOD_STARTER',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_user', internalType: 'address', type: 'address' }],
    name: 'collectRewardsFor',
    outputs: [{ name: 'reward', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'collectedRewardsInCurrentPeriod',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'currentPeriodRewardsAmount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'duration',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_account', internalType: 'address', type: 'address' }],
    name: 'earnedReward',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'finishAt',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getRewardToken',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'lastTimeRewardApplicable',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'maxPeriodDuration',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'rewardPerToken',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'rewardPerTokenStored',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'rewardRate',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'rewards',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'rewardsToken',
    outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_maxPeriodDuration', internalType: 'uint256', type: 'uint256' }],
    name: 'setMaxPeriodDuration',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_user', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'stakeFor',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'stakingToken',
    outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_duration', internalType: 'uint256', type: 'uint256' },
      { name: '_rewardsAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'startNewRewardsPeriod',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'updatedAt',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'userRewardPerTokenPaid',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'user', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'withdrawFor',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// StaleDepositLiquidator
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const staleDepositLiquidatorABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_ops', internalType: 'address', type: 'address' },
      {
        name: '_lockerContract',
        internalType: 'contract MultiERC20WeightedLocker',
        type: 'address',
      },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ReceivedNativeToken',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'taskId', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'TaskCancelled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'taskId', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'TaskScheduled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'receiver', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'WithdrawnNativeToken',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'automationTaskId',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'cancelTask',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'createTask',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'dedicatedMsgSender',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getListOfStaleDepositsToLiquidate',
    outputs: [
      { name: 'canExec', internalType: 'bool', type: 'bool' },
      { name: 'execPayload', internalType: 'bytes', type: 'bytes' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'depositsToLiquidate',
        internalType: 'struct DepositToLiquidate[]',
        type: 'tuple[]',
        components: [
          { name: 'depositor', internalType: 'address', type: 'address' },
          { name: 'depositId', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
    name: 'liquidateStaleDeposits',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'locker',
    outputs: [
      { name: '', internalType: 'contract MultiERC20WeightedLocker', type: 'address' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'ops',
    outputs: [{ name: '', internalType: 'contract IOps', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'withdrawFunds',
    outputs: [],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DividendToken
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const dividendTokenABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_name', internalType: 'string', type: 'string' },
      { name: '_symbol', internalType: 'string', type: 'string' },
      { name: '_initialSupply', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'delegator', internalType: 'address', type: 'address', indexed: true },
      { name: 'fromDelegate', internalType: 'address', type: 'address', indexed: true },
      { name: 'toDelegate', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'DelegateChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'delegate', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'previousBalance',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      { name: 'newBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DelegateVotesChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
    name: 'burn',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'burnFrom',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'pos', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'checkpoints',
    outputs: [
      {
        name: '',
        internalType: 'struct ERC20Votes.Checkpoint',
        type: 'tuple',
        components: [
          { name: 'fromBlock', internalType: 'uint32', type: 'uint32' },
          { name: 'votes', internalType: 'uint224', type: 'uint224' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'delegatee', internalType: 'address', type: 'address' }],
    name: 'delegate',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'delegatee', internalType: 'address', type: 'address' },
      { name: 'nonce', internalType: 'uint256', type: 'uint256' },
      { name: 'expiry', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'delegateBySig',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'delegates',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'blockNumber', internalType: 'uint256', type: 'uint256' }],
    name: 'getPastTotalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPastVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'getVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'nonces',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'numCheckpoints',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'permit',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// MintableToken
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const mintableTokenABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_name', internalType: 'string', type: 'string' },
      { name: '_symbol', internalType: 'string', type: 'string' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'previousAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      { name: 'newAdminRole', internalType: 'bytes32', type: 'bytes32', indexed: true },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleRevoked',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'MINTER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
    name: 'burn',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'burnFrom',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'mint',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'nonces',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'permit',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// MockUSDC
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const mockUsdcABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'previousAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      { name: 'newAdminRole', internalType: 'bytes32', type: 'bytes32', indexed: true },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleRevoked',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'MINTER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
    name: 'burn',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'burnFrom',
    outputs: [],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'mint',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'nonces',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'permit',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// MultiERC20WeightedLocker
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const multiErc20WeightedLockerABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_name', internalType: 'string', type: 'string' },
      { name: '_symbol', internalType: 'string', type: 'string' },
      {
        name: '_lockableAssets',
        internalType: 'struct LockableAsset[]',
        type: 'tuple[]',
        components: [
          { name: 'token', internalType: 'address', type: 'address' },
          { name: 'isEntitledToVote', internalType: 'bool', type: 'bool' },
          {
            name: 'lockPeriods',
            internalType: 'struct LockPeriod[]',
            type: 'tuple[]',
            components: [
              { name: 'durationInSeconds', internalType: 'uint256', type: 'uint256' },
              { name: 'rewardModifier', internalType: 'uint256', type: 'uint256' },
            ],
          },
          { name: 'isLPToken', internalType: 'bool', type: 'bool' },
          { name: 'dividendTokenFromPair', internalType: 'address', type: 'address' },
          { name: 'priceOracle', internalType: 'address', type: 'address' },
        ],
      },
      {
        name: '_governanceToken',
        internalType: 'contract IMintableBurnableToken',
        type: 'address',
      },
    ],
  },
  { type: 'error', inputs: [], name: 'MultiERC20WeightedLocker__AddressAlreadyExists' },
  { type: 'error', inputs: [], name: 'MultiERC20WeightedLocker__AssetDoesNotExists' },
  {
    type: 'error',
    inputs: [],
    name: 'MultiERC20WeightedLocker__DepositCanBeWithdrawnNormally',
  },
  { type: 'error', inputs: [], name: 'MultiERC20WeightedLocker__DepositIsNotLocked' },
  { type: 'error', inputs: [], name: 'MultiERC20WeightedLocker__DepositIsNotOngoing' },
  { type: 'error', inputs: [], name: 'MultiERC20WeightedLocker__DepositIsStillLocked' },
  {
    type: 'error',
    inputs: [],
    name: 'MultiERC20WeightedLocker__DepositsInThisAssetHaveBeenDisabled',
  },
  { type: 'error', inputs: [], name: 'MultiERC20WeightedLocker__InvalidLockPeriodId' },
  {
    type: 'error',
    inputs: [],
    name: 'MultiERC20WeightedLocker__InvalidLockableAssetIndex',
  },
  { type: 'error', inputs: [], name: 'MultiERC20WeightedLocker__InvalidRewardModifier' },
  {
    type: 'error',
    inputs: [],
    name: 'MultiERC20WeightedLocker__InvalidSlashingPenaltyPercentage',
  },
  {
    type: 'error',
    inputs: [],
    name: 'MultiERC20WeightedLocker__InvalidStakingContractIndex',
  },
  { type: 'error', inputs: [], name: 'NonTransferableToken__TransferIsNotAllowed' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'user', internalType: 'address', type: 'address', indexed: true },
      { name: 'depositId', internalType: 'uint256', type: 'uint256', indexed: true },
      {
        name: 'lockedAssetAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'stakingContractAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      { name: 'amountLocked', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountMinted', internalType: 'uint256', type: 'uint256', indexed: false },
      {
        name: 'lockPeriodDuration',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'depositTimestamp',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'unlockAvailibleTimestamp',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'DepositCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'user', internalType: 'address', type: 'address', indexed: true },
      { name: 'depositId', internalType: 'uint256', type: 'uint256', indexed: true },
      {
        name: 'lockedAssetAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'stakingContractAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      { name: 'liquidator', internalType: 'address', type: 'address', indexed: false },
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DepositLiquidated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'user', internalType: 'address', type: 'address', indexed: true },
      { name: 'depositId', internalType: 'uint256', type: 'uint256', indexed: true },
      {
        name: 'lockedAssetAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'stakingContractAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'unlockTimestamp',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'DepositWithdrawn',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'user', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'stakingContractAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'rewardAssetAddress',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'RewardsCollected',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: '_lockableAsset',
        internalType: 'struct LockableAsset',
        type: 'tuple',
        components: [
          { name: 'token', internalType: 'address', type: 'address' },
          { name: 'isEntitledToVote', internalType: 'bool', type: 'bool' },
          {
            name: 'lockPeriods',
            internalType: 'struct LockPeriod[]',
            type: 'tuple[]',
            components: [
              { name: 'durationInSeconds', internalType: 'uint256', type: 'uint256' },
              { name: 'rewardModifier', internalType: 'uint256', type: 'uint256' },
            ],
          },
          { name: 'isLPToken', internalType: 'bool', type: 'bool' },
          { name: 'dividendTokenFromPair', internalType: 'address', type: 'address' },
          { name: 'priceOracle', internalType: 'address', type: 'address' },
        ],
      },
    ],
    name: 'addLockableAsset',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_stakingContract', internalType: 'contract IStaking', type: 'address' },
    ],
    name: 'addStakingContract',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
    name: 'burn',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'burnFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_stakingContractIndex', internalType: 'uint256', type: 'uint256' }],
    name: 'collectRewards',
    outputs: [{ name: 'reward', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'currentDepositId',
    outputs: [{ name: '_value', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_lockableAssetIndex', internalType: 'uint256', type: 'uint256' }],
    name: 'disableDepositsForAsset',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_user', internalType: 'address', type: 'address' },
      { name: '_stakingContractIndex', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'earnedReward',
    outputs: [{ name: 'reward', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_depositId', internalType: 'uint256', type: 'uint256' }],
    name: 'emergencyWithdraw',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_lockableAssetIndex', internalType: 'uint256', type: 'uint256' }],
    name: 'enableDepositsForAsset',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'depositor', internalType: 'address', type: 'address' },
      { name: 'depositId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getDeposit',
    outputs: [
      {
        name: '',
        internalType: 'struct Deposit',
        type: 'tuple',
        components: [
          { name: 'lockableAssetIndex', internalType: 'uint256', type: 'uint256' },
          { name: 'stakingContractIndex', internalType: 'uint256', type: 'uint256' },
          { name: 'amountLocked', internalType: 'uint256', type: 'uint256' },
          { name: 'amountMinted', internalType: 'uint256', type: 'uint256' },
          {
            name: 'lockPeriod',
            internalType: 'struct LockPeriod',
            type: 'tuple',
            components: [
              { name: 'durationInSeconds', internalType: 'uint256', type: 'uint256' },
              { name: 'rewardModifier', internalType: 'uint256', type: 'uint256' },
            ],
          },
          { name: 'depositTimestamp', internalType: 'uint256', type: 'uint256' },
          { name: 'unlockAvailibleTimestamp', internalType: 'uint256', type: 'uint256' },
          { name: 'isOngoing', internalType: 'bool', type: 'bool' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'index', internalType: 'uint256', type: 'uint256' }],
    name: 'getDepositor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getDepositorsCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'index', internalType: 'uint256', type: 'uint256' }],
    name: 'getLockableAsset',
    outputs: [
      {
        name: '',
        internalType: 'struct LockableAsset',
        type: 'tuple',
        components: [
          { name: 'token', internalType: 'address', type: 'address' },
          { name: 'isEntitledToVote', internalType: 'bool', type: 'bool' },
          {
            name: 'lockPeriods',
            internalType: 'struct LockPeriod[]',
            type: 'tuple[]',
            components: [
              { name: 'durationInSeconds', internalType: 'uint256', type: 'uint256' },
              { name: 'rewardModifier', internalType: 'uint256', type: 'uint256' },
            ],
          },
          { name: 'isLPToken', internalType: 'bool', type: 'bool' },
          { name: 'dividendTokenFromPair', internalType: 'address', type: 'address' },
          { name: 'priceOracle', internalType: 'address', type: 'address' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getLockableAssetsCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'index', internalType: 'uint256', type: 'uint256' }],
    name: 'getStakingContract',
    outputs: [{ name: '', internalType: 'contract IStaking', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getStakingContractCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governanceToken',
    outputs: [
      { name: '', internalType: 'contract IMintableBurnableToken', type: 'address' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'isDepositingDisabledForAsset',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'isDepositor',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_user', internalType: 'address', type: 'address' },
      { name: '_depositId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'liquidateStaleDeposit',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'nonces',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'permit',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_slashingPenaltyPercentage', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'setSlashingPenaltyPercentage',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'slashedTokensAmount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'slashingPenaltyPercentage',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_lockableAssetIndex', internalType: 'uint256', type: 'uint256' },
      { name: '_stakingContractIndex', internalType: 'uint256', type: 'uint256' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: 'lockPeriodId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'stake',
    outputs: [
      { name: 'depositId', internalType: 'uint256', type: 'uint256' },
      { name: 'mintedAmount', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_lockableAssetIndex', internalType: 'uint256', type: 'uint256' },
      { name: '_stakingContractIndex', internalType: 'uint256', type: 'uint256' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: 'lockPeriodId', internalType: 'uint256', type: 'uint256' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'stakeWithPermit',
    outputs: [
      { name: 'depositId', internalType: 'uint256', type: 'uint256' },
      { name: 'mintedAmount', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'stakingContracts',
    outputs: [{ name: '', internalType: 'contract IStaking', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: '_lockableAsset',
        internalType: 'struct LockableAsset',
        type: 'tuple',
        components: [
          { name: 'token', internalType: 'address', type: 'address' },
          { name: 'isEntitledToVote', internalType: 'bool', type: 'bool' },
          {
            name: 'lockPeriods',
            internalType: 'struct LockPeriod[]',
            type: 'tuple[]',
            components: [
              { name: 'durationInSeconds', internalType: 'uint256', type: 'uint256' },
              { name: 'rewardModifier', internalType: 'uint256', type: 'uint256' },
            ],
          },
          { name: 'isLPToken', internalType: 'bool', type: 'bool' },
          { name: 'dividendTokenFromPair', internalType: 'address', type: 'address' },
          { name: 'priceOracle', internalType: 'address', type: 'address' },
        ],
      },
    ],
    name: 'updateLockableAsset',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'userDepositIds',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'userDeposits',
    outputs: [
      { name: 'lockableAssetIndex', internalType: 'uint256', type: 'uint256' },
      { name: 'stakingContractIndex', internalType: 'uint256', type: 'uint256' },
      { name: 'amountLocked', internalType: 'uint256', type: 'uint256' },
      { name: 'amountMinted', internalType: 'uint256', type: 'uint256' },
      {
        name: 'lockPeriod',
        internalType: 'struct LockPeriod',
        type: 'tuple',
        components: [
          { name: 'durationInSeconds', internalType: 'uint256', type: 'uint256' },
          { name: 'rewardModifier', internalType: 'uint256', type: 'uint256' },
        ],
      },
      { name: 'depositTimestamp', internalType: 'uint256', type: 'uint256' },
      { name: 'unlockAvailibleTimestamp', internalType: 'uint256', type: 'uint256' },
      { name: 'isOngoing', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'userDepositsCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
    ],
    name: 'userLockedAssetAmount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_depositId', internalType: 'uint256', type: 'uint256' }],
    name: 'withdraw',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_token', internalType: 'address', type: 'address' },
      { name: '_benficiary', internalType: 'address', type: 'address' },
    ],
    name: 'withdrawSlashedTokens',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_depositId', internalType: 'uint256', type: 'uint256' }],
    name: 'withdrawStakeAndReward',
    outputs: [{ name: 'reward', internalType: 'uint256', type: 'uint256' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// NonTransferableToken
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const nonTransferableTokenABI = [
  { type: 'error', inputs: [], name: 'NonTransferableToken__TransferIsNotAllowed' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// BasisPointNumberMath
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const basisPointNumberMathABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'BASIS_POINT',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// NativeTokenReceiver
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const nativeTokenReceiverABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ReceivedNativeToken',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'receiver', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'WithdrawnNativeToken',
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// StepVestingWallet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const stepVestingWalletABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_vestedToken', internalType: 'contract IERC20', type: 'address' },
      { name: '_cliffDuration', internalType: 'uint256', type: 'uint256' },
      { name: '_periodDuration', internalType: 'uint256', type: 'uint256' },
      { name: '_stepsCount', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'beneficiary', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'timelocks', internalType: 'address[]', type: 'address[]', indexed: false },
    ],
    name: 'BeneficiaryAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'CLIFF_DURATION',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'PERIOD_DURATION',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'STEPS_COUNT',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'VESTED_TOKEN',
    outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'beneficiary', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'addBeneficiary',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_beneficiariesToAdd', internalType: 'address[]', type: 'address[]' },
      { name: '_amounts', internalType: 'uint256[]', type: 'uint256[]' },
    ],
    name: 'batchAddBeneficiary',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getAllTimelocks',
    outputs: [{ name: '', internalType: 'contract TokenTimelock[]', type: 'address[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getBeneficiaries',
    outputs: [{ name: '', internalType: 'address[]', type: 'address[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'beneficiary', internalType: 'address', type: 'address' }],
    name: 'getBeneficiaryTimelocks',
    outputs: [{ name: '', internalType: 'contract TokenTimelock[]', type: 'address[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'beneficiary', internalType: 'address', type: 'address' }],
    name: 'isBeneficiary',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_token', internalType: 'contract IERC20', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'withdrawTokens',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// AccessControl
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const accessControlABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'previousAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      { name: 'newAdminRole', internalType: 'bytes32', type: 'bytes32', indexed: true },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleRevoked',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IAccessControl
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iAccessControlABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'previousAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      { name: 'newAdminRole', internalType: 'bytes32', type: 'bytes32', indexed: true },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleRevoked',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ownable
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ownableABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Governor
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const governorABI = [
  { type: 'error', inputs: [], name: 'Empty' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalCanceled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'proposer', internalType: 'address', type: 'address', indexed: false },
      { name: 'targets', internalType: 'address[]', type: 'address[]', indexed: false },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
      { name: 'signatures', internalType: 'string[]', type: 'string[]', indexed: false },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]', indexed: false },
      { name: 'startBlock', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'endBlock', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'description', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'ProposalCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalExecuted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'voter', internalType: 'address', type: 'address', indexed: true },
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'support', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'weight', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reason', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'VoteCast',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'voter', internalType: 'address', type: 'address', indexed: true },
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'support', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'weight', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reason', internalType: 'string', type: 'string', indexed: false },
      { name: 'params', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'VoteCastWithParams',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'BALLOT_TYPEHASH',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'COUNTING_MODE',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'EXTENDED_BALLOT_TYPEHASH',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
    ],
    name: 'castVote',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castVoteBySig',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
    ],
    name: 'castVoteWithReason',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'castVoteWithReasonAndParams',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castVoteWithReasonAndParamsBySig',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'execute',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'getVotesWithParams',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasVoted',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'hashProposal',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155BatchReceived',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalDeadline',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalSnapshot',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proposalThreshold',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'description', internalType: 'string', type: 'string' },
    ],
    name: 'propose',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'blockNumber', internalType: 'uint256', type: 'uint256' }],
    name: 'quorum',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'target', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'relay',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'state',
    outputs: [{ name: '', internalType: 'enum IGovernor.ProposalState', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'version',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'votingDelay',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'votingPeriod',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IGovernor
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iGovernorABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalCanceled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'proposer', internalType: 'address', type: 'address', indexed: false },
      { name: 'targets', internalType: 'address[]', type: 'address[]', indexed: false },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
      { name: 'signatures', internalType: 'string[]', type: 'string[]', indexed: false },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]', indexed: false },
      { name: 'startBlock', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'endBlock', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'description', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'ProposalCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalExecuted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'voter', internalType: 'address', type: 'address', indexed: true },
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'support', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'weight', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reason', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'VoteCast',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'voter', internalType: 'address', type: 'address', indexed: true },
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'support', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'weight', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reason', internalType: 'string', type: 'string', indexed: false },
      { name: 'params', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'VoteCastWithParams',
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'COUNTING_MODE',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
    ],
    name: 'castVote',
    outputs: [{ name: 'balance', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castVoteBySig',
    outputs: [{ name: 'balance', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
    ],
    name: 'castVoteWithReason',
    outputs: [{ name: 'balance', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'castVoteWithReasonAndParams',
    outputs: [{ name: 'balance', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castVoteWithReasonAndParamsBySig',
    outputs: [{ name: 'balance', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'execute',
    outputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'getVotesWithParams',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasVoted',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'hashProposal',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalDeadline',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalSnapshot',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'description', internalType: 'string', type: 'string' },
    ],
    name: 'propose',
    outputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'blockNumber', internalType: 'uint256', type: 'uint256' }],
    name: 'quorum',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'state',
    outputs: [{ name: '', internalType: 'enum IGovernor.ProposalState', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'version',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'votingDelay',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'votingPeriod',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// TimelockController
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const timelockControllerABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: 'minDelay', internalType: 'uint256', type: 'uint256' },
      { name: 'proposers', internalType: 'address[]', type: 'address[]' },
      { name: 'executors', internalType: 'address[]', type: 'address[]' },
      { name: 'admin', internalType: 'address', type: 'address' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'id', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'index', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'target', internalType: 'address', type: 'address', indexed: false },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'data', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'CallExecuted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'id', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'index', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'target', internalType: 'address', type: 'address', indexed: false },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'data', internalType: 'bytes', type: 'bytes', indexed: false },
      { name: 'predecessor', internalType: 'bytes32', type: 'bytes32', indexed: false },
      { name: 'delay', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'CallScheduled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'id', internalType: 'bytes32', type: 'bytes32', indexed: true }],
    name: 'Cancelled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'oldDuration', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'newDuration', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'MinDelayChange',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'previousAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      { name: 'newAdminRole', internalType: 'bytes32', type: 'bytes32', indexed: true },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleRevoked',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'CANCELLER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'EXECUTOR_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'PROPOSER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'TIMELOCK_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'id', internalType: 'bytes32', type: 'bytes32' }],
    name: 'cancel',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'target', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'payload', internalType: 'bytes', type: 'bytes' },
      { name: 'predecessor', internalType: 'bytes32', type: 'bytes32' },
      { name: 'salt', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'execute',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'payloads', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'predecessor', internalType: 'bytes32', type: 'bytes32' },
      { name: 'salt', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'executeBatch',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getMinDelay',
    outputs: [{ name: 'duration', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'id', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getTimestamp',
    outputs: [{ name: 'timestamp', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'target', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
      { name: 'predecessor', internalType: 'bytes32', type: 'bytes32' },
      { name: 'salt', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'hashOperation',
    outputs: [{ name: 'hash', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'payloads', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'predecessor', internalType: 'bytes32', type: 'bytes32' },
      { name: 'salt', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'hashOperationBatch',
    outputs: [{ name: 'hash', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'id', internalType: 'bytes32', type: 'bytes32' }],
    name: 'isOperation',
    outputs: [{ name: 'registered', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'id', internalType: 'bytes32', type: 'bytes32' }],
    name: 'isOperationDone',
    outputs: [{ name: 'done', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'id', internalType: 'bytes32', type: 'bytes32' }],
    name: 'isOperationPending',
    outputs: [{ name: 'pending', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'id', internalType: 'bytes32', type: 'bytes32' }],
    name: 'isOperationReady',
    outputs: [{ name: 'ready', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155BatchReceived',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'target', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
      { name: 'predecessor', internalType: 'bytes32', type: 'bytes32' },
      { name: 'salt', internalType: 'bytes32', type: 'bytes32' },
      { name: 'delay', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'schedule',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'payloads', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'predecessor', internalType: 'bytes32', type: 'bytes32' },
      { name: 'salt', internalType: 'bytes32', type: 'bytes32' },
      { name: 'delay', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'scheduleBatch',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newDelay', internalType: 'uint256', type: 'uint256' }],
    name: 'updateDelay',
    outputs: [],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Pausable
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const pausableABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'account', internalType: 'address', type: 'address', indexed: false },
    ],
    name: 'Paused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'account', internalType: 'address', type: 'address', indexed: false },
    ],
    name: 'Unpaused',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ILiquidityValueCalculator
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iLiquidityValueCalculatorABI = [
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'amountOfLPTokens', internalType: 'uint256', type: 'uint256' },
      { name: 'tokenA', internalType: 'address', type: 'address' },
      { name: 'tokenB', internalType: 'address', type: 'address' },
    ],
    name: 'computeLiquidityShareValue',
    outputs: [
      { name: 'tokenAAmount', internalType: 'uint256', type: 'uint256' },
      { name: 'tokenBAmount', internalType: 'uint256', type: 'uint256' },
    ],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IUniswapV2TwapOracle
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iUniswapV2TwapOracleABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'token', internalType: 'address', type: 'address' },
      { name: 'amountIn', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'consult',
    outputs: [{ name: 'amountOut', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenA', internalType: 'address', type: 'address' }],
    name: 'getObservedPrices',
    outputs: [
      { name: 'observedATokenPrice', internalType: 'uint256', type: 'uint256' },
      { name: 'observedBTokenPrice', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'denominatorToken', internalType: 'address', type: 'address' }],
    name: 'getPriceRatios',
    outputs: [
      { name: 'observedDenominatorTokenRatio', internalType: 'uint256', type: 'uint256' },
      { name: 'observedBTokenRatio', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'pair',
    outputs: [{ name: '', internalType: 'contract IUniswapV2Pair', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'update',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FixedPoint
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const fixedPointABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'Q112',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'RESOLUTION',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IGelatoAutomation
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iGelatoAutomationABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'taskId', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'TaskCancelled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'timestamp', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'taskId', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'TaskScheduled',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'cancelTask',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'createTask',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// OwnableSol6
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ownableSol6ABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IStaking
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iStakingABI = [
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_beneficiary', internalType: 'address', type: 'address' }],
    name: 'collectRewardsFor',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_beneficiary', internalType: 'address', type: 'address' }],
    name: 'earnedReward',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getRewardToken',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_beneficiary', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'stakeFor',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_beneficiary', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'withdrawFor',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IMintableBurnableToken
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iMintableBurnableTokenABI = [
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_from', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'burn',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'mint',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC20Sol6
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc20Sol6ABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// GovernorCountingSimple
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const governorCountingSimpleABI = [
  { type: 'error', inputs: [], name: 'Empty' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalCanceled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'proposer', internalType: 'address', type: 'address', indexed: false },
      { name: 'targets', internalType: 'address[]', type: 'address[]', indexed: false },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
      { name: 'signatures', internalType: 'string[]', type: 'string[]', indexed: false },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]', indexed: false },
      { name: 'startBlock', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'endBlock', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'description', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'ProposalCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalExecuted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'voter', internalType: 'address', type: 'address', indexed: true },
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'support', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'weight', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reason', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'VoteCast',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'voter', internalType: 'address', type: 'address', indexed: true },
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'support', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'weight', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reason', internalType: 'string', type: 'string', indexed: false },
      { name: 'params', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'VoteCastWithParams',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'BALLOT_TYPEHASH',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'COUNTING_MODE',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'EXTENDED_BALLOT_TYPEHASH',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
    ],
    name: 'castVote',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castVoteBySig',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
    ],
    name: 'castVoteWithReason',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'castVoteWithReasonAndParams',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castVoteWithReasonAndParamsBySig',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'execute',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'getVotesWithParams',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasVoted',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'hashProposal',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155BatchReceived',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalDeadline',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalSnapshot',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proposalThreshold',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalVotes',
    outputs: [
      { name: 'againstVotes', internalType: 'uint256', type: 'uint256' },
      { name: 'forVotes', internalType: 'uint256', type: 'uint256' },
      { name: 'abstainVotes', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'description', internalType: 'string', type: 'string' },
    ],
    name: 'propose',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'blockNumber', internalType: 'uint256', type: 'uint256' }],
    name: 'quorum',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'target', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'relay',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'state',
    outputs: [{ name: '', internalType: 'enum IGovernor.ProposalState', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'version',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'votingDelay',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'votingPeriod',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// GovernorSettings
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const governorSettingsABI = [
  { type: 'error', inputs: [], name: 'Empty' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalCanceled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'proposer', internalType: 'address', type: 'address', indexed: false },
      { name: 'targets', internalType: 'address[]', type: 'address[]', indexed: false },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
      { name: 'signatures', internalType: 'string[]', type: 'string[]', indexed: false },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]', indexed: false },
      { name: 'startBlock', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'endBlock', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'description', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'ProposalCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalExecuted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'oldProposalThreshold',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'newProposalThreshold',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'ProposalThresholdSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'voter', internalType: 'address', type: 'address', indexed: true },
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'support', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'weight', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reason', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'VoteCast',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'voter', internalType: 'address', type: 'address', indexed: true },
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'support', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'weight', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reason', internalType: 'string', type: 'string', indexed: false },
      { name: 'params', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'VoteCastWithParams',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'oldVotingDelay',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'newVotingDelay',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'VotingDelaySet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'oldVotingPeriod',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'newVotingPeriod',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'VotingPeriodSet',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'BALLOT_TYPEHASH',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'COUNTING_MODE',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'EXTENDED_BALLOT_TYPEHASH',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
    ],
    name: 'castVote',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castVoteBySig',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
    ],
    name: 'castVoteWithReason',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'castVoteWithReasonAndParams',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castVoteWithReasonAndParamsBySig',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'execute',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'getVotesWithParams',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasVoted',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'hashProposal',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155BatchReceived',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalDeadline',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalSnapshot',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proposalThreshold',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'description', internalType: 'string', type: 'string' },
    ],
    name: 'propose',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'blockNumber', internalType: 'uint256', type: 'uint256' }],
    name: 'quorum',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'target', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'relay',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newProposalThreshold', internalType: 'uint256', type: 'uint256' }],
    name: 'setProposalThreshold',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newVotingDelay', internalType: 'uint256', type: 'uint256' }],
    name: 'setVotingDelay',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newVotingPeriod', internalType: 'uint256', type: 'uint256' }],
    name: 'setVotingPeriod',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'state',
    outputs: [{ name: '', internalType: 'enum IGovernor.ProposalState', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'version',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'votingDelay',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'votingPeriod',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// GovernorVotes
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const governorVotesABI = [
  { type: 'error', inputs: [], name: 'Empty' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalCanceled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'proposer', internalType: 'address', type: 'address', indexed: false },
      { name: 'targets', internalType: 'address[]', type: 'address[]', indexed: false },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
      { name: 'signatures', internalType: 'string[]', type: 'string[]', indexed: false },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]', indexed: false },
      { name: 'startBlock', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'endBlock', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'description', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'ProposalCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalExecuted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'voter', internalType: 'address', type: 'address', indexed: true },
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'support', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'weight', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reason', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'VoteCast',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'voter', internalType: 'address', type: 'address', indexed: true },
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'support', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'weight', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reason', internalType: 'string', type: 'string', indexed: false },
      { name: 'params', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'VoteCastWithParams',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'BALLOT_TYPEHASH',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'COUNTING_MODE',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'EXTENDED_BALLOT_TYPEHASH',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
    ],
    name: 'castVote',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castVoteBySig',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
    ],
    name: 'castVoteWithReason',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'castVoteWithReasonAndParams',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castVoteWithReasonAndParamsBySig',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'execute',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'getVotesWithParams',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasVoted',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'hashProposal',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155BatchReceived',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalDeadline',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalSnapshot',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proposalThreshold',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'description', internalType: 'string', type: 'string' },
    ],
    name: 'propose',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'blockNumber', internalType: 'uint256', type: 'uint256' }],
    name: 'quorum',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'target', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'relay',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'state',
    outputs: [{ name: '', internalType: 'enum IGovernor.ProposalState', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'token',
    outputs: [{ name: '', internalType: 'contract IVotes', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'version',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'votingDelay',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'votingPeriod',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// GovernorTimelockControl
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const governorTimelockControlABI = [
  { type: 'error', inputs: [], name: 'Empty' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalCanceled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'proposer', internalType: 'address', type: 'address', indexed: false },
      { name: 'targets', internalType: 'address[]', type: 'address[]', indexed: false },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
      { name: 'signatures', internalType: 'string[]', type: 'string[]', indexed: false },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]', indexed: false },
      { name: 'startBlock', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'endBlock', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'description', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'ProposalCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalExecuted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'eta', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalQueued',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'oldTimelock', internalType: 'address', type: 'address', indexed: false },
      { name: 'newTimelock', internalType: 'address', type: 'address', indexed: false },
    ],
    name: 'TimelockChange',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'voter', internalType: 'address', type: 'address', indexed: true },
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'support', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'weight', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reason', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'VoteCast',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'voter', internalType: 'address', type: 'address', indexed: true },
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'support', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'weight', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reason', internalType: 'string', type: 'string', indexed: false },
      { name: 'params', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'VoteCastWithParams',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'BALLOT_TYPEHASH',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'COUNTING_MODE',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'EXTENDED_BALLOT_TYPEHASH',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
    ],
    name: 'castVote',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castVoteBySig',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
    ],
    name: 'castVoteWithReason',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'castVoteWithReasonAndParams',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castVoteWithReasonAndParamsBySig',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'execute',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'getVotesWithParams',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasVoted',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'hashProposal',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'uint256[]', type: 'uint256[]' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155BatchReceived',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalDeadline',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalEta',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalSnapshot',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proposalThreshold',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'description', internalType: 'string', type: 'string' },
    ],
    name: 'propose',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'queue',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'blockNumber', internalType: 'uint256', type: 'uint256' }],
    name: 'quorum',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'target', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'relay',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'state',
    outputs: [{ name: '', internalType: 'enum IGovernor.ProposalState', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'timelock',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'newTimelock',
        internalType: 'contract TimelockController',
        type: 'address',
      },
    ],
    name: 'updateTimelock',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'version',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'votingDelay',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'votingPeriod',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IGovernorTimelock
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iGovernorTimelockABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalCanceled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'proposer', internalType: 'address', type: 'address', indexed: false },
      { name: 'targets', internalType: 'address[]', type: 'address[]', indexed: false },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
      { name: 'signatures', internalType: 'string[]', type: 'string[]', indexed: false },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]', indexed: false },
      { name: 'startBlock', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'endBlock', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'description', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'ProposalCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalExecuted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'eta', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ProposalQueued',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'voter', internalType: 'address', type: 'address', indexed: true },
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'support', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'weight', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reason', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'VoteCast',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'voter', internalType: 'address', type: 'address', indexed: true },
      { name: 'proposalId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'support', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'weight', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'reason', internalType: 'string', type: 'string', indexed: false },
      { name: 'params', internalType: 'bytes', type: 'bytes', indexed: false },
    ],
    name: 'VoteCastWithParams',
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'COUNTING_MODE',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
    ],
    name: 'castVote',
    outputs: [{ name: 'balance', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castVoteBySig',
    outputs: [{ name: 'balance', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
    ],
    name: 'castVoteWithReason',
    outputs: [{ name: 'balance', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'castVoteWithReasonAndParams',
    outputs: [{ name: 'balance', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'support', internalType: 'uint8', type: 'uint8' },
      { name: 'reason', internalType: 'string', type: 'string' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'castVoteWithReasonAndParamsBySig',
    outputs: [{ name: 'balance', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'execute',
    outputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
      { name: 'params', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'getVotesWithParams',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'proposalId', internalType: 'uint256', type: 'uint256' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasVoted',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'hashProposal',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalDeadline',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalEta',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'proposalSnapshot',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'description', internalType: 'string', type: 'string' },
    ],
    name: 'propose',
    outputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'targets', internalType: 'address[]', type: 'address[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'calldatas', internalType: 'bytes[]', type: 'bytes[]' },
      { name: 'descriptionHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'queue',
    outputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'blockNumber', internalType: 'uint256', type: 'uint256' }],
    name: 'quorum',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'proposalId', internalType: 'uint256', type: 'uint256' }],
    name: 'state',
    outputs: [{ name: '', internalType: 'enum IGovernor.ProposalState', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'timelock',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'version',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'votingDelay',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'votingPeriod',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IVotes
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iVotesABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'delegator', internalType: 'address', type: 'address', indexed: true },
      { name: 'fromDelegate', internalType: 'address', type: 'address', indexed: true },
      { name: 'toDelegate', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'DelegateChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'delegate', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'previousBalance',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      { name: 'newBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DelegateVotesChanged',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'delegatee', internalType: 'address', type: 'address' }],
    name: 'delegate',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'delegatee', internalType: 'address', type: 'address' },
      { name: 'nonce', internalType: 'uint256', type: 'uint256' },
      { name: 'expiry', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'delegateBySig',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'delegates',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'blockNumber', internalType: 'uint256', type: 'uint256' }],
    name: 'getPastTotalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPastVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'getVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC1155Receiver
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc1155ReceiverABI = [
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'operator', internalType: 'address', type: 'address' },
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'ids', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155BatchReceived',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'operator', internalType: 'address', type: 'address' },
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'id', internalType: 'uint256', type: 'uint256' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC1155Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC20
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc20ABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: 'name_', internalType: 'string', type: 'string' },
      { name: 'symbol_', internalType: 'string', type: 'string' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC20
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc20ABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC721Receiver
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc721ReceiverABI = [
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'operator', internalType: 'address', type: 'address' },
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC165
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc165ABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC165
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc165ABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DoubleEndedQueue
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const doubleEndedQueueABI = [
  { type: 'error', inputs: [], name: 'Empty' },
  { type: 'error', inputs: [], name: 'OutOfBounds' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IUniswapV2Factory
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iUniswapV2FactoryABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'token0', internalType: 'address', type: 'address', indexed: true },
      { name: 'token1', internalType: 'address', type: 'address', indexed: true },
      { name: 'pair', internalType: 'address', type: 'address', indexed: false },
      { name: '', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'PairCreated',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'allPairs',
    outputs: [{ name: 'pair', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'allPairsLength',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'tokenA', internalType: 'address', type: 'address' },
      { name: 'tokenB', internalType: 'address', type: 'address' },
    ],
    name: 'createPair',
    outputs: [{ name: 'pair', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'feeTo',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'feeToSetter',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'tokenA', internalType: 'address', type: 'address' },
      { name: 'tokenB', internalType: 'address', type: 'address' },
    ],
    name: 'getPair',
    outputs: [{ name: 'pair', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'setFeeTo',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'setFeeToSetter',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IUniswapV2Pair
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iUniswapV2PairABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount0', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amount1', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Burn',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount0', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amount1', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Mint',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount0In', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amount1In', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amount0Out', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amount1Out', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'Swap',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'reserve0', internalType: 'uint112', type: 'uint112', indexed: false },
      { name: 'reserve1', internalType: 'uint112', type: 'uint112', indexed: false },
    ],
    name: 'Sync',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'MINIMUM_LIQUIDITY',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'PERMIT_TYPEHASH',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'to', internalType: 'address', type: 'address' }],
    name: 'burn',
    outputs: [
      { name: 'amount0', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'factory',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getReserves',
    outputs: [
      { name: 'reserve0', internalType: 'uint112', type: 'uint112' },
      { name: 'reserve1', internalType: 'uint112', type: 'uint112' },
      { name: 'blockTimestampLast', internalType: 'uint32', type: 'uint32' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'kLast',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'to', internalType: 'address', type: 'address' }],
    name: 'mint',
    outputs: [{ name: 'liquidity', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'nonces',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'permit',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'price0CumulativeLast',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'price1CumulativeLast',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'to', internalType: 'address', type: 'address' }],
    name: 'skim',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'amount0Out', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1Out', internalType: 'uint256', type: 'uint256' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'swap',
    outputs: [],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'sync',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'token0',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'token1',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IUniswapV2Router01
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iUniswapV2Router01ABI = [
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'WETH',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'tokenA', internalType: 'address', type: 'address' },
      { name: 'tokenB', internalType: 'address', type: 'address' },
      { name: 'amountADesired', internalType: 'uint256', type: 'uint256' },
      { name: 'amountBDesired', internalType: 'uint256', type: 'uint256' },
      { name: 'amountAMin', internalType: 'uint256', type: 'uint256' },
      { name: 'amountBMin', internalType: 'uint256', type: 'uint256' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'addLiquidity',
    outputs: [
      { name: 'amountA', internalType: 'uint256', type: 'uint256' },
      { name: 'amountB', internalType: 'uint256', type: 'uint256' },
      { name: 'liquidity', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'token', internalType: 'address', type: 'address' },
      { name: 'amountTokenDesired', internalType: 'uint256', type: 'uint256' },
      { name: 'amountTokenMin', internalType: 'uint256', type: 'uint256' },
      { name: 'amountETHMin', internalType: 'uint256', type: 'uint256' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'addLiquidityETH',
    outputs: [
      { name: 'amountToken', internalType: 'uint256', type: 'uint256' },
      { name: 'amountETH', internalType: 'uint256', type: 'uint256' },
      { name: 'liquidity', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'factory',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'amountOut', internalType: 'uint256', type: 'uint256' },
      { name: 'reserveIn', internalType: 'uint256', type: 'uint256' },
      { name: 'reserveOut', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getAmountIn',
    outputs: [{ name: 'amountIn', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'amountIn', internalType: 'uint256', type: 'uint256' },
      { name: 'reserveIn', internalType: 'uint256', type: 'uint256' },
      { name: 'reserveOut', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getAmountOut',
    outputs: [{ name: 'amountOut', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'amountOut', internalType: 'uint256', type: 'uint256' },
      { name: 'path', internalType: 'address[]', type: 'address[]' },
    ],
    name: 'getAmountsIn',
    outputs: [{ name: 'amounts', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'amountIn', internalType: 'uint256', type: 'uint256' },
      { name: 'path', internalType: 'address[]', type: 'address[]' },
    ],
    name: 'getAmountsOut',
    outputs: [{ name: 'amounts', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'amountA', internalType: 'uint256', type: 'uint256' },
      { name: 'reserveA', internalType: 'uint256', type: 'uint256' },
      { name: 'reserveB', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'quote',
    outputs: [{ name: 'amountB', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'tokenA', internalType: 'address', type: 'address' },
      { name: 'tokenB', internalType: 'address', type: 'address' },
      { name: 'liquidity', internalType: 'uint256', type: 'uint256' },
      { name: 'amountAMin', internalType: 'uint256', type: 'uint256' },
      { name: 'amountBMin', internalType: 'uint256', type: 'uint256' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'removeLiquidity',
    outputs: [
      { name: 'amountA', internalType: 'uint256', type: 'uint256' },
      { name: 'amountB', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'token', internalType: 'address', type: 'address' },
      { name: 'liquidity', internalType: 'uint256', type: 'uint256' },
      { name: 'amountTokenMin', internalType: 'uint256', type: 'uint256' },
      { name: 'amountETHMin', internalType: 'uint256', type: 'uint256' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'removeLiquidityETH',
    outputs: [
      { name: 'amountToken', internalType: 'uint256', type: 'uint256' },
      { name: 'amountETH', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'token', internalType: 'address', type: 'address' },
      { name: 'liquidity', internalType: 'uint256', type: 'uint256' },
      { name: 'amountTokenMin', internalType: 'uint256', type: 'uint256' },
      { name: 'amountETHMin', internalType: 'uint256', type: 'uint256' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
      { name: 'approveMax', internalType: 'bool', type: 'bool' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'removeLiquidityETHWithPermit',
    outputs: [
      { name: 'amountToken', internalType: 'uint256', type: 'uint256' },
      { name: 'amountETH', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'tokenA', internalType: 'address', type: 'address' },
      { name: 'tokenB', internalType: 'address', type: 'address' },
      { name: 'liquidity', internalType: 'uint256', type: 'uint256' },
      { name: 'amountAMin', internalType: 'uint256', type: 'uint256' },
      { name: 'amountBMin', internalType: 'uint256', type: 'uint256' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
      { name: 'approveMax', internalType: 'bool', type: 'bool' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'removeLiquidityWithPermit',
    outputs: [
      { name: 'amountA', internalType: 'uint256', type: 'uint256' },
      { name: 'amountB', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'amountOut', internalType: 'uint256', type: 'uint256' },
      { name: 'path', internalType: 'address[]', type: 'address[]' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'swapETHForExactTokens',
    outputs: [{ name: 'amounts', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'amountOutMin', internalType: 'uint256', type: 'uint256' },
      { name: 'path', internalType: 'address[]', type: 'address[]' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'swapExactETHForTokens',
    outputs: [{ name: 'amounts', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'amountIn', internalType: 'uint256', type: 'uint256' },
      { name: 'amountOutMin', internalType: 'uint256', type: 'uint256' },
      { name: 'path', internalType: 'address[]', type: 'address[]' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'swapExactTokensForETH',
    outputs: [{ name: 'amounts', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'amountIn', internalType: 'uint256', type: 'uint256' },
      { name: 'amountOutMin', internalType: 'uint256', type: 'uint256' },
      { name: 'path', internalType: 'address[]', type: 'address[]' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'swapExactTokensForTokens',
    outputs: [{ name: 'amounts', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'amountOut', internalType: 'uint256', type: 'uint256' },
      { name: 'amountInMax', internalType: 'uint256', type: 'uint256' },
      { name: 'path', internalType: 'address[]', type: 'address[]' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'swapTokensForExactETH',
    outputs: [{ name: 'amounts', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'amountOut', internalType: 'uint256', type: 'uint256' },
      { name: 'amountInMax', internalType: 'uint256', type: 'uint256' },
      { name: 'path', internalType: 'address[]', type: 'address[]' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'swapTokensForExactTokens',
    outputs: [{ name: 'amounts', internalType: 'uint256[]', type: 'uint256[]' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC20Burnable
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc20BurnableABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
    name: 'burn',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'burnFrom',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC20Votes
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc20VotesABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'delegator', internalType: 'address', type: 'address', indexed: true },
      { name: 'fromDelegate', internalType: 'address', type: 'address', indexed: true },
      { name: 'toDelegate', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'DelegateChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'delegate', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'previousBalance',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      { name: 'newBalance', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'DelegateVotesChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'pos', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'checkpoints',
    outputs: [
      {
        name: '',
        internalType: 'struct ERC20Votes.Checkpoint',
        type: 'tuple',
        components: [
          { name: 'fromBlock', internalType: 'uint32', type: 'uint32' },
          { name: 'votes', internalType: 'uint224', type: 'uint224' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'delegatee', internalType: 'address', type: 'address' }],
    name: 'delegate',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'delegatee', internalType: 'address', type: 'address' },
      { name: 'nonce', internalType: 'uint256', type: 'uint256' },
      { name: 'expiry', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'delegateBySig',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'delegates',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'blockNumber', internalType: 'uint256', type: 'uint256' }],
    name: 'getPastTotalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'blockNumber', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPastVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'getVotes',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'nonces',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'numCheckpoints',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'permit',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC20Metadata
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc20MetadataABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC20Permit
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc20PermitABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'nonces',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'permit',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC20Permit
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc20PermitABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'nonces',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
      { name: 'v', internalType: 'uint8', type: 'uint8' },
      { name: 'r', internalType: 'bytes32', type: 'bytes32' },
      { name: 's', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'permit',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// TokenTimelock
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const tokenTimelockABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: 'token_', internalType: 'contract IERC20', type: 'address' },
      { name: 'beneficiary_', internalType: 'address', type: 'address' },
      { name: 'releaseTime_', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'beneficiary',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'release',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'releaseTime',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'token',
    outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link merkleClaimableAirdropABI}__.
 */
export function useMerkleClaimableAirdropRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof merkleClaimableAirdropABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof merkleClaimableAirdropABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: merkleClaimableAirdropABI,
    ...config,
  } as UseContractReadConfig<
    typeof merkleClaimableAirdropABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link merkleClaimableAirdropABI}__ and `functionName` set to `"hasClaimed"`.
 */
export function useMerkleClaimableAirdropHasClaimed<
  TFunctionName extends 'hasClaimed',
  TSelectData = ReadContractResult<typeof merkleClaimableAirdropABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof merkleClaimableAirdropABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: merkleClaimableAirdropABI,
    functionName: 'hasClaimed',
    ...config,
  } as UseContractReadConfig<
    typeof merkleClaimableAirdropABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link merkleClaimableAirdropABI}__ and `functionName` set to `"merkleRoot"`.
 */
export function useMerkleClaimableAirdropMerkleRoot<
  TFunctionName extends 'merkleRoot',
  TSelectData = ReadContractResult<typeof merkleClaimableAirdropABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof merkleClaimableAirdropABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: merkleClaimableAirdropABI,
    functionName: 'merkleRoot',
    ...config,
  } as UseContractReadConfig<
    typeof merkleClaimableAirdropABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link merkleClaimableAirdropABI}__ and `functionName` set to `"owner"`.
 */
export function useMerkleClaimableAirdropOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof merkleClaimableAirdropABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof merkleClaimableAirdropABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: merkleClaimableAirdropABI,
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<
    typeof merkleClaimableAirdropABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link merkleClaimableAirdropABI}__ and `functionName` set to `"paused"`.
 */
export function useMerkleClaimableAirdropPaused<
  TFunctionName extends 'paused',
  TSelectData = ReadContractResult<typeof merkleClaimableAirdropABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof merkleClaimableAirdropABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: merkleClaimableAirdropABI,
    functionName: 'paused',
    ...config,
  } as UseContractReadConfig<
    typeof merkleClaimableAirdropABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link merkleClaimableAirdropABI}__ and `functionName` set to `"rewardToken"`.
 */
export function useMerkleClaimableAirdropRewardToken<
  TFunctionName extends 'rewardToken',
  TSelectData = ReadContractResult<typeof merkleClaimableAirdropABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof merkleClaimableAirdropABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: merkleClaimableAirdropABI,
    functionName: 'rewardToken',
    ...config,
  } as UseContractReadConfig<
    typeof merkleClaimableAirdropABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link merkleClaimableAirdropABI}__ and `functionName` set to `"token"`.
 */
export function useMerkleClaimableAirdropToken<
  TFunctionName extends 'token',
  TSelectData = ReadContractResult<typeof merkleClaimableAirdropABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof merkleClaimableAirdropABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: merkleClaimableAirdropABI,
    functionName: 'token',
    ...config,
  } as UseContractReadConfig<
    typeof merkleClaimableAirdropABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link merkleClaimableAirdropABI}__.
 */
export function useMerkleClaimableAirdropWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof merkleClaimableAirdropABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof merkleClaimableAirdropABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof merkleClaimableAirdropABI, TFunctionName, TMode>({
    abi: merkleClaimableAirdropABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link merkleClaimableAirdropABI}__ and `functionName` set to `"claim"`.
 */
export function useMerkleClaimableAirdropClaim<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof merkleClaimableAirdropABI,
          'claim'
        >['request']['abi'],
        'claim',
        TMode
      > & { functionName?: 'claim' }
    : UseContractWriteConfig<typeof merkleClaimableAirdropABI, 'claim', TMode> & {
        abi?: never;
        functionName?: 'claim';
      } = {} as any,
) {
  return useContractWrite<typeof merkleClaimableAirdropABI, 'claim', TMode>({
    abi: merkleClaimableAirdropABI,
    functionName: 'claim',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link merkleClaimableAirdropABI}__ and `functionName` set to `"finishAirdrop"`.
 */
export function useMerkleClaimableAirdropFinishAirdrop<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof merkleClaimableAirdropABI,
          'finishAirdrop'
        >['request']['abi'],
        'finishAirdrop',
        TMode
      > & { functionName?: 'finishAirdrop' }
    : UseContractWriteConfig<typeof merkleClaimableAirdropABI, 'finishAirdrop', TMode> & {
        abi?: never;
        functionName?: 'finishAirdrop';
      } = {} as any,
) {
  return useContractWrite<typeof merkleClaimableAirdropABI, 'finishAirdrop', TMode>({
    abi: merkleClaimableAirdropABI,
    functionName: 'finishAirdrop',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link merkleClaimableAirdropABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useMerkleClaimableAirdropRenounceOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof merkleClaimableAirdropABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & { functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<
        typeof merkleClaimableAirdropABI,
        'renounceOwnership',
        TMode
      > & {
        abi?: never;
        functionName?: 'renounceOwnership';
      } = {} as any,
) {
  return useContractWrite<typeof merkleClaimableAirdropABI, 'renounceOwnership', TMode>({
    abi: merkleClaimableAirdropABI,
    functionName: 'renounceOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link merkleClaimableAirdropABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useMerkleClaimableAirdropTransferOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof merkleClaimableAirdropABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & { functionName?: 'transferOwnership' }
    : UseContractWriteConfig<
        typeof merkleClaimableAirdropABI,
        'transferOwnership',
        TMode
      > & {
        abi?: never;
        functionName?: 'transferOwnership';
      } = {} as any,
) {
  return useContractWrite<typeof merkleClaimableAirdropABI, 'transferOwnership', TMode>({
    abi: merkleClaimableAirdropABI,
    functionName: 'transferOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link merkleClaimableAirdropABI}__ and `functionName` set to `"withdrawRemaining"`.
 */
export function useMerkleClaimableAirdropWithdrawRemaining<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof merkleClaimableAirdropABI,
          'withdrawRemaining'
        >['request']['abi'],
        'withdrawRemaining',
        TMode
      > & { functionName?: 'withdrawRemaining' }
    : UseContractWriteConfig<
        typeof merkleClaimableAirdropABI,
        'withdrawRemaining',
        TMode
      > & {
        abi?: never;
        functionName?: 'withdrawRemaining';
      } = {} as any,
) {
  return useContractWrite<typeof merkleClaimableAirdropABI, 'withdrawRemaining', TMode>({
    abi: merkleClaimableAirdropABI,
    functionName: 'withdrawRemaining',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link merkleClaimableAirdropABI}__.
 */
export function usePrepareMerkleClaimableAirdropWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof merkleClaimableAirdropABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: merkleClaimableAirdropABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof merkleClaimableAirdropABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link merkleClaimableAirdropABI}__ and `functionName` set to `"claim"`.
 */
export function usePrepareMerkleClaimableAirdropClaim(
  config: Omit<
    UsePrepareContractWriteConfig<typeof merkleClaimableAirdropABI, 'claim'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: merkleClaimableAirdropABI,
    functionName: 'claim',
    ...config,
  } as UsePrepareContractWriteConfig<typeof merkleClaimableAirdropABI, 'claim'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link merkleClaimableAirdropABI}__ and `functionName` set to `"finishAirdrop"`.
 */
export function usePrepareMerkleClaimableAirdropFinishAirdrop(
  config: Omit<
    UsePrepareContractWriteConfig<typeof merkleClaimableAirdropABI, 'finishAirdrop'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: merkleClaimableAirdropABI,
    functionName: 'finishAirdrop',
    ...config,
  } as UsePrepareContractWriteConfig<typeof merkleClaimableAirdropABI, 'finishAirdrop'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link merkleClaimableAirdropABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareMerkleClaimableAirdropRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof merkleClaimableAirdropABI, 'renounceOwnership'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: merkleClaimableAirdropABI,
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof merkleClaimableAirdropABI,
    'renounceOwnership'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link merkleClaimableAirdropABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareMerkleClaimableAirdropTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof merkleClaimableAirdropABI, 'transferOwnership'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: merkleClaimableAirdropABI,
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof merkleClaimableAirdropABI,
    'transferOwnership'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link merkleClaimableAirdropABI}__ and `functionName` set to `"withdrawRemaining"`.
 */
export function usePrepareMerkleClaimableAirdropWithdrawRemaining(
  config: Omit<
    UsePrepareContractWriteConfig<typeof merkleClaimableAirdropABI, 'withdrawRemaining'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: merkleClaimableAirdropABI,
    functionName: 'withdrawRemaining',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof merkleClaimableAirdropABI,
    'withdrawRemaining'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__.
 */
export function useGovernanceDividendTokenWrapperRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName,
      TSelectData
    >,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: governanceDividendTokenWrapperABI,
    ...config,
  } as UseContractReadConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"DOMAIN_SEPARATOR"`.
 */
export function useGovernanceDividendTokenWrapperDomainSeparator<
  TFunctionName extends 'DOMAIN_SEPARATOR',
  TSelectData = ReadContractResult<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'DOMAIN_SEPARATOR',
    ...config,
  } as UseContractReadConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"allowance"`.
 */
export function useGovernanceDividendTokenWrapperAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useGovernanceDividendTokenWrapperBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"checkpoints"`.
 */
export function useGovernanceDividendTokenWrapperCheckpoints<
  TFunctionName extends 'checkpoints',
  TSelectData = ReadContractResult<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'checkpoints',
    ...config,
  } as UseContractReadConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"decimals"`.
 */
export function useGovernanceDividendTokenWrapperDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"delegates"`.
 */
export function useGovernanceDividendTokenWrapperDelegates<
  TFunctionName extends 'delegates',
  TSelectData = ReadContractResult<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'delegates',
    ...config,
  } as UseContractReadConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"getPastTotalSupply"`.
 */
export function useGovernanceDividendTokenWrapperGetPastTotalSupply<
  TFunctionName extends 'getPastTotalSupply',
  TSelectData = ReadContractResult<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'getPastTotalSupply',
    ...config,
  } as UseContractReadConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"getPastVotes"`.
 */
export function useGovernanceDividendTokenWrapperGetPastVotes<
  TFunctionName extends 'getPastVotes',
  TSelectData = ReadContractResult<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'getPastVotes',
    ...config,
  } as UseContractReadConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"getVotes"`.
 */
export function useGovernanceDividendTokenWrapperGetVotes<
  TFunctionName extends 'getVotes',
  TSelectData = ReadContractResult<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'getVotes',
    ...config,
  } as UseContractReadConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"name"`.
 */
export function useGovernanceDividendTokenWrapperName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"nonces"`.
 */
export function useGovernanceDividendTokenWrapperNonces<
  TFunctionName extends 'nonces',
  TSelectData = ReadContractResult<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'nonces',
    ...config,
  } as UseContractReadConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"numCheckpoints"`.
 */
export function useGovernanceDividendTokenWrapperNumCheckpoints<
  TFunctionName extends 'numCheckpoints',
  TSelectData = ReadContractResult<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'numCheckpoints',
    ...config,
  } as UseContractReadConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"owner"`.
 */
export function useGovernanceDividendTokenWrapperOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"symbol"`.
 */
export function useGovernanceDividendTokenWrapperSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useGovernanceDividendTokenWrapperTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__.
 */
export function useGovernanceDividendTokenWrapperWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governanceDividendTokenWrapperABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<
        typeof governanceDividendTokenWrapperABI,
        TFunctionName,
        TMode
      > & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof governanceDividendTokenWrapperABI, TFunctionName, TMode>(
    { abi: governanceDividendTokenWrapperABI, ...config } as any,
  );
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"approve"`.
 */
export function useGovernanceDividendTokenWrapperApprove<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governanceDividendTokenWrapperABI,
          'approve'
        >['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<
        typeof governanceDividendTokenWrapperABI,
        'approve',
        TMode
      > & {
        abi?: never;
        functionName?: 'approve';
      } = {} as any,
) {
  return useContractWrite<typeof governanceDividendTokenWrapperABI, 'approve', TMode>({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'approve',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"burn"`.
 */
export function useGovernanceDividendTokenWrapperBurn<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governanceDividendTokenWrapperABI,
          'burn'
        >['request']['abi'],
        'burn',
        TMode
      > & { functionName?: 'burn' }
    : UseContractWriteConfig<typeof governanceDividendTokenWrapperABI, 'burn', TMode> & {
        abi?: never;
        functionName?: 'burn';
      } = {} as any,
) {
  return useContractWrite<typeof governanceDividendTokenWrapperABI, 'burn', TMode>({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'burn',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useGovernanceDividendTokenWrapperDecreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governanceDividendTokenWrapperABI,
          'decreaseAllowance'
        >['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<
        typeof governanceDividendTokenWrapperABI,
        'decreaseAllowance',
        TMode
      > & {
        abi?: never;
        functionName?: 'decreaseAllowance';
      } = {} as any,
) {
  return useContractWrite<
    typeof governanceDividendTokenWrapperABI,
    'decreaseAllowance',
    TMode
  >({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"delegate"`.
 */
export function useGovernanceDividendTokenWrapperDelegate<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governanceDividendTokenWrapperABI,
          'delegate'
        >['request']['abi'],
        'delegate',
        TMode
      > & { functionName?: 'delegate' }
    : UseContractWriteConfig<
        typeof governanceDividendTokenWrapperABI,
        'delegate',
        TMode
      > & {
        abi?: never;
        functionName?: 'delegate';
      } = {} as any,
) {
  return useContractWrite<typeof governanceDividendTokenWrapperABI, 'delegate', TMode>({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'delegate',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"delegateBySig"`.
 */
export function useGovernanceDividendTokenWrapperDelegateBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governanceDividendTokenWrapperABI,
          'delegateBySig'
        >['request']['abi'],
        'delegateBySig',
        TMode
      > & { functionName?: 'delegateBySig' }
    : UseContractWriteConfig<
        typeof governanceDividendTokenWrapperABI,
        'delegateBySig',
        TMode
      > & {
        abi?: never;
        functionName?: 'delegateBySig';
      } = {} as any,
) {
  return useContractWrite<
    typeof governanceDividendTokenWrapperABI,
    'delegateBySig',
    TMode
  >({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'delegateBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useGovernanceDividendTokenWrapperIncreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governanceDividendTokenWrapperABI,
          'increaseAllowance'
        >['request']['abi'],
        'increaseAllowance',
        TMode
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<
        typeof governanceDividendTokenWrapperABI,
        'increaseAllowance',
        TMode
      > & {
        abi?: never;
        functionName?: 'increaseAllowance';
      } = {} as any,
) {
  return useContractWrite<
    typeof governanceDividendTokenWrapperABI,
    'increaseAllowance',
    TMode
  >({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'increaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"mint"`.
 */
export function useGovernanceDividendTokenWrapperMint<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governanceDividendTokenWrapperABI,
          'mint'
        >['request']['abi'],
        'mint',
        TMode
      > & { functionName?: 'mint' }
    : UseContractWriteConfig<typeof governanceDividendTokenWrapperABI, 'mint', TMode> & {
        abi?: never;
        functionName?: 'mint';
      } = {} as any,
) {
  return useContractWrite<typeof governanceDividendTokenWrapperABI, 'mint', TMode>({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'mint',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"permit"`.
 */
export function useGovernanceDividendTokenWrapperPermit<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governanceDividendTokenWrapperABI,
          'permit'
        >['request']['abi'],
        'permit',
        TMode
      > & { functionName?: 'permit' }
    : UseContractWriteConfig<
        typeof governanceDividendTokenWrapperABI,
        'permit',
        TMode
      > & {
        abi?: never;
        functionName?: 'permit';
      } = {} as any,
) {
  return useContractWrite<typeof governanceDividendTokenWrapperABI, 'permit', TMode>({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'permit',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useGovernanceDividendTokenWrapperRenounceOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governanceDividendTokenWrapperABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & { functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<
        typeof governanceDividendTokenWrapperABI,
        'renounceOwnership',
        TMode
      > & {
        abi?: never;
        functionName?: 'renounceOwnership';
      } = {} as any,
) {
  return useContractWrite<
    typeof governanceDividendTokenWrapperABI,
    'renounceOwnership',
    TMode
  >({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'renounceOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"transfer"`.
 */
export function useGovernanceDividendTokenWrapperTransfer<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governanceDividendTokenWrapperABI,
          'transfer'
        >['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<
        typeof governanceDividendTokenWrapperABI,
        'transfer',
        TMode
      > & {
        abi?: never;
        functionName?: 'transfer';
      } = {} as any,
) {
  return useContractWrite<typeof governanceDividendTokenWrapperABI, 'transfer', TMode>({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'transfer',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useGovernanceDividendTokenWrapperTransferFrom<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governanceDividendTokenWrapperABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<
        typeof governanceDividendTokenWrapperABI,
        'transferFrom',
        TMode
      > & {
        abi?: never;
        functionName?: 'transferFrom';
      } = {} as any,
) {
  return useContractWrite<
    typeof governanceDividendTokenWrapperABI,
    'transferFrom',
    TMode
  >({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'transferFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useGovernanceDividendTokenWrapperTransferOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governanceDividendTokenWrapperABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & { functionName?: 'transferOwnership' }
    : UseContractWriteConfig<
        typeof governanceDividendTokenWrapperABI,
        'transferOwnership',
        TMode
      > & {
        abi?: never;
        functionName?: 'transferOwnership';
      } = {} as any,
) {
  return useContractWrite<
    typeof governanceDividendTokenWrapperABI,
    'transferOwnership',
    TMode
  >({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'transferOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__.
 */
export function usePrepareGovernanceDividendTokenWrapperWrite<
  TFunctionName extends string,
>(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governanceDividendTokenWrapperABI,
      TFunctionName
    >,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governanceDividendTokenWrapperABI,
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governanceDividendTokenWrapperABI,
    TFunctionName
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareGovernanceDividendTokenWrapperApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governanceDividendTokenWrapperABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governanceDividendTokenWrapperABI,
    'approve'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"burn"`.
 */
export function usePrepareGovernanceDividendTokenWrapperBurn(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governanceDividendTokenWrapperABI, 'burn'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'burn',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governanceDividendTokenWrapperABI, 'burn'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareGovernanceDividendTokenWrapperDecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governanceDividendTokenWrapperABI,
      'decreaseAllowance'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governanceDividendTokenWrapperABI,
    'decreaseAllowance'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"delegate"`.
 */
export function usePrepareGovernanceDividendTokenWrapperDelegate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governanceDividendTokenWrapperABI, 'delegate'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'delegate',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governanceDividendTokenWrapperABI,
    'delegate'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"delegateBySig"`.
 */
export function usePrepareGovernanceDividendTokenWrapperDelegateBySig(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governanceDividendTokenWrapperABI,
      'delegateBySig'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'delegateBySig',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governanceDividendTokenWrapperABI,
    'delegateBySig'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareGovernanceDividendTokenWrapperIncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governanceDividendTokenWrapperABI,
      'increaseAllowance'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governanceDividendTokenWrapperABI,
    'increaseAllowance'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"mint"`.
 */
export function usePrepareGovernanceDividendTokenWrapperMint(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governanceDividendTokenWrapperABI, 'mint'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'mint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governanceDividendTokenWrapperABI, 'mint'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"permit"`.
 */
export function usePrepareGovernanceDividendTokenWrapperPermit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governanceDividendTokenWrapperABI, 'permit'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'permit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governanceDividendTokenWrapperABI, 'permit'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareGovernanceDividendTokenWrapperRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governanceDividendTokenWrapperABI,
      'renounceOwnership'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governanceDividendTokenWrapperABI,
    'renounceOwnership'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareGovernanceDividendTokenWrapperTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governanceDividendTokenWrapperABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governanceDividendTokenWrapperABI,
    'transfer'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareGovernanceDividendTokenWrapperTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governanceDividendTokenWrapperABI,
      'transferFrom'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governanceDividendTokenWrapperABI,
    'transferFrom'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governanceDividendTokenWrapperABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareGovernanceDividendTokenWrapperTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governanceDividendTokenWrapperABI,
      'transferOwnership'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governanceDividendTokenWrapperABI,
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governanceDividendTokenWrapperABI,
    'transferOwnership'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__.
 */
export function useGovernorContractRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: governorContractABI, ...config } as UseContractReadConfig<
    typeof governorContractABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"BALLOT_TYPEHASH"`.
 */
export function useGovernorContractBallotTypehash<
  TFunctionName extends 'BALLOT_TYPEHASH',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'BALLOT_TYPEHASH',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"COUNTING_MODE"`.
 */
export function useGovernorContractCountingMode<
  TFunctionName extends 'COUNTING_MODE',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'COUNTING_MODE',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"EXTENDED_BALLOT_TYPEHASH"`.
 */
export function useGovernorContractExtendedBallotTypehash<
  TFunctionName extends 'EXTENDED_BALLOT_TYPEHASH',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'EXTENDED_BALLOT_TYPEHASH',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"getVotes"`.
 */
export function useGovernorContractGetVotes<
  TFunctionName extends 'getVotes',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'getVotes',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"getVotesWithParams"`.
 */
export function useGovernorContractGetVotesWithParams<
  TFunctionName extends 'getVotesWithParams',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'getVotesWithParams',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"hasVoted"`.
 */
export function useGovernorContractHasVoted<
  TFunctionName extends 'hasVoted',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'hasVoted',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"hashProposal"`.
 */
export function useGovernorContractHashProposal<
  TFunctionName extends 'hashProposal',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'hashProposal',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"name"`.
 */
export function useGovernorContractName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"proposalDeadline"`.
 */
export function useGovernorContractProposalDeadline<
  TFunctionName extends 'proposalDeadline',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'proposalDeadline',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"proposalEta"`.
 */
export function useGovernorContractProposalEta<
  TFunctionName extends 'proposalEta',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'proposalEta',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"proposalSnapshot"`.
 */
export function useGovernorContractProposalSnapshot<
  TFunctionName extends 'proposalSnapshot',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'proposalSnapshot',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"proposalThreshold"`.
 */
export function useGovernorContractProposalThreshold<
  TFunctionName extends 'proposalThreshold',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'proposalThreshold',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"proposalVotes"`.
 */
export function useGovernorContractProposalVotes<
  TFunctionName extends 'proposalVotes',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'proposalVotes',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"quorum"`.
 */
export function useGovernorContractQuorum<
  TFunctionName extends 'quorum',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'quorum',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"state"`.
 */
export function useGovernorContractState<
  TFunctionName extends 'state',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'state',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useGovernorContractSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"timelock"`.
 */
export function useGovernorContractTimelock<
  TFunctionName extends 'timelock',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'timelock',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"token"`.
 */
export function useGovernorContractToken<
  TFunctionName extends 'token',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'token',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"version"`.
 */
export function useGovernorContractVersion<
  TFunctionName extends 'version',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'version',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"votingDelay"`.
 */
export function useGovernorContractVotingDelay<
  TFunctionName extends 'votingDelay',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'votingDelay',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"votingPeriod"`.
 */
export function useGovernorContractVotingPeriod<
  TFunctionName extends 'votingPeriod',
  TSelectData = ReadContractResult<typeof governorContractABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorContractABI,
    functionName: 'votingPeriod',
    ...config,
  } as UseContractReadConfig<typeof governorContractABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__.
 */
export function useGovernorContractWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof governorContractABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof governorContractABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof governorContractABI, TFunctionName, TMode>({
    abi: governorContractABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"castVote"`.
 */
export function useGovernorContractCastVote<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorContractABI,
          'castVote'
        >['request']['abi'],
        'castVote',
        TMode
      > & { functionName?: 'castVote' }
    : UseContractWriteConfig<typeof governorContractABI, 'castVote', TMode> & {
        abi?: never;
        functionName?: 'castVote';
      } = {} as any,
) {
  return useContractWrite<typeof governorContractABI, 'castVote', TMode>({
    abi: governorContractABI,
    functionName: 'castVote',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"castVoteBySig"`.
 */
export function useGovernorContractCastVoteBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorContractABI,
          'castVoteBySig'
        >['request']['abi'],
        'castVoteBySig',
        TMode
      > & { functionName?: 'castVoteBySig' }
    : UseContractWriteConfig<typeof governorContractABI, 'castVoteBySig', TMode> & {
        abi?: never;
        functionName?: 'castVoteBySig';
      } = {} as any,
) {
  return useContractWrite<typeof governorContractABI, 'castVoteBySig', TMode>({
    abi: governorContractABI,
    functionName: 'castVoteBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"castVoteWithReason"`.
 */
export function useGovernorContractCastVoteWithReason<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorContractABI,
          'castVoteWithReason'
        >['request']['abi'],
        'castVoteWithReason',
        TMode
      > & { functionName?: 'castVoteWithReason' }
    : UseContractWriteConfig<typeof governorContractABI, 'castVoteWithReason', TMode> & {
        abi?: never;
        functionName?: 'castVoteWithReason';
      } = {} as any,
) {
  return useContractWrite<typeof governorContractABI, 'castVoteWithReason', TMode>({
    abi: governorContractABI,
    functionName: 'castVoteWithReason',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"castVoteWithReasonAndParams"`.
 */
export function useGovernorContractCastVoteWithReasonAndParams<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorContractABI,
          'castVoteWithReasonAndParams'
        >['request']['abi'],
        'castVoteWithReasonAndParams',
        TMode
      > & { functionName?: 'castVoteWithReasonAndParams' }
    : UseContractWriteConfig<
        typeof governorContractABI,
        'castVoteWithReasonAndParams',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReasonAndParams';
      } = {} as any,
) {
  return useContractWrite<
    typeof governorContractABI,
    'castVoteWithReasonAndParams',
    TMode
  >({
    abi: governorContractABI,
    functionName: 'castVoteWithReasonAndParams',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"castVoteWithReasonAndParamsBySig"`.
 */
export function useGovernorContractCastVoteWithReasonAndParamsBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorContractABI,
          'castVoteWithReasonAndParamsBySig'
        >['request']['abi'],
        'castVoteWithReasonAndParamsBySig',
        TMode
      > & { functionName?: 'castVoteWithReasonAndParamsBySig' }
    : UseContractWriteConfig<
        typeof governorContractABI,
        'castVoteWithReasonAndParamsBySig',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReasonAndParamsBySig';
      } = {} as any,
) {
  return useContractWrite<
    typeof governorContractABI,
    'castVoteWithReasonAndParamsBySig',
    TMode
  >({
    abi: governorContractABI,
    functionName: 'castVoteWithReasonAndParamsBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"execute"`.
 */
export function useGovernorContractExecute<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorContractABI,
          'execute'
        >['request']['abi'],
        'execute',
        TMode
      > & { functionName?: 'execute' }
    : UseContractWriteConfig<typeof governorContractABI, 'execute', TMode> & {
        abi?: never;
        functionName?: 'execute';
      } = {} as any,
) {
  return useContractWrite<typeof governorContractABI, 'execute', TMode>({
    abi: governorContractABI,
    functionName: 'execute',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function useGovernorContractOnErc1155BatchReceived<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorContractABI,
          'onERC1155BatchReceived'
        >['request']['abi'],
        'onERC1155BatchReceived',
        TMode
      > & { functionName?: 'onERC1155BatchReceived' }
    : UseContractWriteConfig<
        typeof governorContractABI,
        'onERC1155BatchReceived',
        TMode
      > & {
        abi?: never;
        functionName?: 'onERC1155BatchReceived';
      } = {} as any,
) {
  return useContractWrite<typeof governorContractABI, 'onERC1155BatchReceived', TMode>({
    abi: governorContractABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function useGovernorContractOnErc1155Received<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorContractABI,
          'onERC1155Received'
        >['request']['abi'],
        'onERC1155Received',
        TMode
      > & { functionName?: 'onERC1155Received' }
    : UseContractWriteConfig<typeof governorContractABI, 'onERC1155Received', TMode> & {
        abi?: never;
        functionName?: 'onERC1155Received';
      } = {} as any,
) {
  return useContractWrite<typeof governorContractABI, 'onERC1155Received', TMode>({
    abi: governorContractABI,
    functionName: 'onERC1155Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function useGovernorContractOnErc721Received<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorContractABI,
          'onERC721Received'
        >['request']['abi'],
        'onERC721Received',
        TMode
      > & { functionName?: 'onERC721Received' }
    : UseContractWriteConfig<typeof governorContractABI, 'onERC721Received', TMode> & {
        abi?: never;
        functionName?: 'onERC721Received';
      } = {} as any,
) {
  return useContractWrite<typeof governorContractABI, 'onERC721Received', TMode>({
    abi: governorContractABI,
    functionName: 'onERC721Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"propose"`.
 */
export function useGovernorContractPropose<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorContractABI,
          'propose'
        >['request']['abi'],
        'propose',
        TMode
      > & { functionName?: 'propose' }
    : UseContractWriteConfig<typeof governorContractABI, 'propose', TMode> & {
        abi?: never;
        functionName?: 'propose';
      } = {} as any,
) {
  return useContractWrite<typeof governorContractABI, 'propose', TMode>({
    abi: governorContractABI,
    functionName: 'propose',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"queue"`.
 */
export function useGovernorContractQueue<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof governorContractABI, 'queue'>['request']['abi'],
        'queue',
        TMode
      > & { functionName?: 'queue' }
    : UseContractWriteConfig<typeof governorContractABI, 'queue', TMode> & {
        abi?: never;
        functionName?: 'queue';
      } = {} as any,
) {
  return useContractWrite<typeof governorContractABI, 'queue', TMode>({
    abi: governorContractABI,
    functionName: 'queue',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"relay"`.
 */
export function useGovernorContractRelay<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof governorContractABI, 'relay'>['request']['abi'],
        'relay',
        TMode
      > & { functionName?: 'relay' }
    : UseContractWriteConfig<typeof governorContractABI, 'relay', TMode> & {
        abi?: never;
        functionName?: 'relay';
      } = {} as any,
) {
  return useContractWrite<typeof governorContractABI, 'relay', TMode>({
    abi: governorContractABI,
    functionName: 'relay',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"setProposalThreshold"`.
 */
export function useGovernorContractSetProposalThreshold<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorContractABI,
          'setProposalThreshold'
        >['request']['abi'],
        'setProposalThreshold',
        TMode
      > & { functionName?: 'setProposalThreshold' }
    : UseContractWriteConfig<
        typeof governorContractABI,
        'setProposalThreshold',
        TMode
      > & {
        abi?: never;
        functionName?: 'setProposalThreshold';
      } = {} as any,
) {
  return useContractWrite<typeof governorContractABI, 'setProposalThreshold', TMode>({
    abi: governorContractABI,
    functionName: 'setProposalThreshold',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"setVotingDelay"`.
 */
export function useGovernorContractSetVotingDelay<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorContractABI,
          'setVotingDelay'
        >['request']['abi'],
        'setVotingDelay',
        TMode
      > & { functionName?: 'setVotingDelay' }
    : UseContractWriteConfig<typeof governorContractABI, 'setVotingDelay', TMode> & {
        abi?: never;
        functionName?: 'setVotingDelay';
      } = {} as any,
) {
  return useContractWrite<typeof governorContractABI, 'setVotingDelay', TMode>({
    abi: governorContractABI,
    functionName: 'setVotingDelay',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"setVotingPeriod"`.
 */
export function useGovernorContractSetVotingPeriod<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorContractABI,
          'setVotingPeriod'
        >['request']['abi'],
        'setVotingPeriod',
        TMode
      > & { functionName?: 'setVotingPeriod' }
    : UseContractWriteConfig<typeof governorContractABI, 'setVotingPeriod', TMode> & {
        abi?: never;
        functionName?: 'setVotingPeriod';
      } = {} as any,
) {
  return useContractWrite<typeof governorContractABI, 'setVotingPeriod', TMode>({
    abi: governorContractABI,
    functionName: 'setVotingPeriod',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"updateTimelock"`.
 */
export function useGovernorContractUpdateTimelock<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorContractABI,
          'updateTimelock'
        >['request']['abi'],
        'updateTimelock',
        TMode
      > & { functionName?: 'updateTimelock' }
    : UseContractWriteConfig<typeof governorContractABI, 'updateTimelock', TMode> & {
        abi?: never;
        functionName?: 'updateTimelock';
      } = {} as any,
) {
  return useContractWrite<typeof governorContractABI, 'updateTimelock', TMode>({
    abi: governorContractABI,
    functionName: 'updateTimelock',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__.
 */
export function usePrepareGovernorContractWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorContractABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorContractABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"castVote"`.
 */
export function usePrepareGovernorContractCastVote(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorContractABI, 'castVote'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    functionName: 'castVote',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorContractABI, 'castVote'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"castVoteBySig"`.
 */
export function usePrepareGovernorContractCastVoteBySig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorContractABI, 'castVoteBySig'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    functionName: 'castVoteBySig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorContractABI, 'castVoteBySig'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"castVoteWithReason"`.
 */
export function usePrepareGovernorContractCastVoteWithReason(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorContractABI, 'castVoteWithReason'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    functionName: 'castVoteWithReason',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorContractABI, 'castVoteWithReason'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"castVoteWithReasonAndParams"`.
 */
export function usePrepareGovernorContractCastVoteWithReasonAndParams(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governorContractABI,
      'castVoteWithReasonAndParams'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    functionName: 'castVoteWithReasonAndParams',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorContractABI,
    'castVoteWithReasonAndParams'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"castVoteWithReasonAndParamsBySig"`.
 */
export function usePrepareGovernorContractCastVoteWithReasonAndParamsBySig(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governorContractABI,
      'castVoteWithReasonAndParamsBySig'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    functionName: 'castVoteWithReasonAndParamsBySig',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorContractABI,
    'castVoteWithReasonAndParamsBySig'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"execute"`.
 */
export function usePrepareGovernorContractExecute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorContractABI, 'execute'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    functionName: 'execute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorContractABI, 'execute'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function usePrepareGovernorContractOnErc1155BatchReceived(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorContractABI, 'onERC1155BatchReceived'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorContractABI,
    'onERC1155BatchReceived'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function usePrepareGovernorContractOnErc1155Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorContractABI, 'onERC1155Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    functionName: 'onERC1155Received',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorContractABI, 'onERC1155Received'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function usePrepareGovernorContractOnErc721Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorContractABI, 'onERC721Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    functionName: 'onERC721Received',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorContractABI, 'onERC721Received'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"propose"`.
 */
export function usePrepareGovernorContractPropose(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorContractABI, 'propose'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    functionName: 'propose',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorContractABI, 'propose'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"queue"`.
 */
export function usePrepareGovernorContractQueue(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorContractABI, 'queue'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    functionName: 'queue',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorContractABI, 'queue'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"relay"`.
 */
export function usePrepareGovernorContractRelay(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorContractABI, 'relay'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    functionName: 'relay',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorContractABI, 'relay'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"setProposalThreshold"`.
 */
export function usePrepareGovernorContractSetProposalThreshold(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorContractABI, 'setProposalThreshold'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    functionName: 'setProposalThreshold',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorContractABI, 'setProposalThreshold'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"setVotingDelay"`.
 */
export function usePrepareGovernorContractSetVotingDelay(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorContractABI, 'setVotingDelay'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    functionName: 'setVotingDelay',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorContractABI, 'setVotingDelay'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"setVotingPeriod"`.
 */
export function usePrepareGovernorContractSetVotingPeriod(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorContractABI, 'setVotingPeriod'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    functionName: 'setVotingPeriod',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorContractABI, 'setVotingPeriod'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorContractABI}__ and `functionName` set to `"updateTimelock"`.
 */
export function usePrepareGovernorContractUpdateTimelock(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorContractABI, 'updateTimelock'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorContractABI,
    functionName: 'updateTimelock',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorContractABI, 'updateTimelock'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__.
 */
export function useTimeLockRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: timeLockABI, ...config } as UseContractReadConfig<
    typeof timeLockABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"CANCELLER_ROLE"`.
 */
export function useTimeLockCancellerRole<
  TFunctionName extends 'CANCELLER_ROLE',
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timeLockABI,
    functionName: 'CANCELLER_ROLE',
    ...config,
  } as UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"DEFAULT_ADMIN_ROLE"`.
 */
export function useTimeLockDefaultAdminRole<
  TFunctionName extends 'DEFAULT_ADMIN_ROLE',
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timeLockABI,
    functionName: 'DEFAULT_ADMIN_ROLE',
    ...config,
  } as UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"EXECUTOR_ROLE"`.
 */
export function useTimeLockExecutorRole<
  TFunctionName extends 'EXECUTOR_ROLE',
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timeLockABI,
    functionName: 'EXECUTOR_ROLE',
    ...config,
  } as UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"PROPOSER_ROLE"`.
 */
export function useTimeLockProposerRole<
  TFunctionName extends 'PROPOSER_ROLE',
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timeLockABI,
    functionName: 'PROPOSER_ROLE',
    ...config,
  } as UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"TIMELOCK_ADMIN_ROLE"`.
 */
export function useTimeLockTimelockAdminRole<
  TFunctionName extends 'TIMELOCK_ADMIN_ROLE',
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timeLockABI,
    functionName: 'TIMELOCK_ADMIN_ROLE',
    ...config,
  } as UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"getMinDelay"`.
 */
export function useTimeLockGetMinDelay<
  TFunctionName extends 'getMinDelay',
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timeLockABI,
    functionName: 'getMinDelay',
    ...config,
  } as UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"getRoleAdmin"`.
 */
export function useTimeLockGetRoleAdmin<
  TFunctionName extends 'getRoleAdmin',
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timeLockABI,
    functionName: 'getRoleAdmin',
    ...config,
  } as UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"getTimestamp"`.
 */
export function useTimeLockGetTimestamp<
  TFunctionName extends 'getTimestamp',
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timeLockABI,
    functionName: 'getTimestamp',
    ...config,
  } as UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"hasRole"`.
 */
export function useTimeLockHasRole<
  TFunctionName extends 'hasRole',
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timeLockABI,
    functionName: 'hasRole',
    ...config,
  } as UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"hashOperation"`.
 */
export function useTimeLockHashOperation<
  TFunctionName extends 'hashOperation',
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timeLockABI,
    functionName: 'hashOperation',
    ...config,
  } as UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"hashOperationBatch"`.
 */
export function useTimeLockHashOperationBatch<
  TFunctionName extends 'hashOperationBatch',
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timeLockABI,
    functionName: 'hashOperationBatch',
    ...config,
  } as UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"isOperation"`.
 */
export function useTimeLockIsOperation<
  TFunctionName extends 'isOperation',
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timeLockABI,
    functionName: 'isOperation',
    ...config,
  } as UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"isOperationDone"`.
 */
export function useTimeLockIsOperationDone<
  TFunctionName extends 'isOperationDone',
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timeLockABI,
    functionName: 'isOperationDone',
    ...config,
  } as UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"isOperationPending"`.
 */
export function useTimeLockIsOperationPending<
  TFunctionName extends 'isOperationPending',
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timeLockABI,
    functionName: 'isOperationPending',
    ...config,
  } as UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"isOperationReady"`.
 */
export function useTimeLockIsOperationReady<
  TFunctionName extends 'isOperationReady',
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timeLockABI,
    functionName: 'isOperationReady',
    ...config,
  } as UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useTimeLockSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof timeLockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timeLockABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof timeLockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timeLockABI}__.
 */
export function useTimeLockWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof timeLockABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof timeLockABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof timeLockABI, TFunctionName, TMode>({
    abi: timeLockABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"cancel"`.
 */
export function useTimeLockCancel<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof timeLockABI, 'cancel'>['request']['abi'],
        'cancel',
        TMode
      > & { functionName?: 'cancel' }
    : UseContractWriteConfig<typeof timeLockABI, 'cancel', TMode> & {
        abi?: never;
        functionName?: 'cancel';
      } = {} as any,
) {
  return useContractWrite<typeof timeLockABI, 'cancel', TMode>({
    abi: timeLockABI,
    functionName: 'cancel',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"execute"`.
 */
export function useTimeLockExecute<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof timeLockABI, 'execute'>['request']['abi'],
        'execute',
        TMode
      > & { functionName?: 'execute' }
    : UseContractWriteConfig<typeof timeLockABI, 'execute', TMode> & {
        abi?: never;
        functionName?: 'execute';
      } = {} as any,
) {
  return useContractWrite<typeof timeLockABI, 'execute', TMode>({
    abi: timeLockABI,
    functionName: 'execute',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"executeBatch"`.
 */
export function useTimeLockExecuteBatch<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof timeLockABI, 'executeBatch'>['request']['abi'],
        'executeBatch',
        TMode
      > & { functionName?: 'executeBatch' }
    : UseContractWriteConfig<typeof timeLockABI, 'executeBatch', TMode> & {
        abi?: never;
        functionName?: 'executeBatch';
      } = {} as any,
) {
  return useContractWrite<typeof timeLockABI, 'executeBatch', TMode>({
    abi: timeLockABI,
    functionName: 'executeBatch',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"grantRole"`.
 */
export function useTimeLockGrantRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof timeLockABI, 'grantRole'>['request']['abi'],
        'grantRole',
        TMode
      > & { functionName?: 'grantRole' }
    : UseContractWriteConfig<typeof timeLockABI, 'grantRole', TMode> & {
        abi?: never;
        functionName?: 'grantRole';
      } = {} as any,
) {
  return useContractWrite<typeof timeLockABI, 'grantRole', TMode>({
    abi: timeLockABI,
    functionName: 'grantRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function useTimeLockOnErc1155BatchReceived<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof timeLockABI,
          'onERC1155BatchReceived'
        >['request']['abi'],
        'onERC1155BatchReceived',
        TMode
      > & { functionName?: 'onERC1155BatchReceived' }
    : UseContractWriteConfig<typeof timeLockABI, 'onERC1155BatchReceived', TMode> & {
        abi?: never;
        functionName?: 'onERC1155BatchReceived';
      } = {} as any,
) {
  return useContractWrite<typeof timeLockABI, 'onERC1155BatchReceived', TMode>({
    abi: timeLockABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function useTimeLockOnErc1155Received<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof timeLockABI,
          'onERC1155Received'
        >['request']['abi'],
        'onERC1155Received',
        TMode
      > & { functionName?: 'onERC1155Received' }
    : UseContractWriteConfig<typeof timeLockABI, 'onERC1155Received', TMode> & {
        abi?: never;
        functionName?: 'onERC1155Received';
      } = {} as any,
) {
  return useContractWrite<typeof timeLockABI, 'onERC1155Received', TMode>({
    abi: timeLockABI,
    functionName: 'onERC1155Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function useTimeLockOnErc721Received<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof timeLockABI,
          'onERC721Received'
        >['request']['abi'],
        'onERC721Received',
        TMode
      > & { functionName?: 'onERC721Received' }
    : UseContractWriteConfig<typeof timeLockABI, 'onERC721Received', TMode> & {
        abi?: never;
        functionName?: 'onERC721Received';
      } = {} as any,
) {
  return useContractWrite<typeof timeLockABI, 'onERC721Received', TMode>({
    abi: timeLockABI,
    functionName: 'onERC721Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"renounceRole"`.
 */
export function useTimeLockRenounceRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof timeLockABI, 'renounceRole'>['request']['abi'],
        'renounceRole',
        TMode
      > & { functionName?: 'renounceRole' }
    : UseContractWriteConfig<typeof timeLockABI, 'renounceRole', TMode> & {
        abi?: never;
        functionName?: 'renounceRole';
      } = {} as any,
) {
  return useContractWrite<typeof timeLockABI, 'renounceRole', TMode>({
    abi: timeLockABI,
    functionName: 'renounceRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"revokeRole"`.
 */
export function useTimeLockRevokeRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof timeLockABI, 'revokeRole'>['request']['abi'],
        'revokeRole',
        TMode
      > & { functionName?: 'revokeRole' }
    : UseContractWriteConfig<typeof timeLockABI, 'revokeRole', TMode> & {
        abi?: never;
        functionName?: 'revokeRole';
      } = {} as any,
) {
  return useContractWrite<typeof timeLockABI, 'revokeRole', TMode>({
    abi: timeLockABI,
    functionName: 'revokeRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"schedule"`.
 */
export function useTimeLockSchedule<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof timeLockABI, 'schedule'>['request']['abi'],
        'schedule',
        TMode
      > & { functionName?: 'schedule' }
    : UseContractWriteConfig<typeof timeLockABI, 'schedule', TMode> & {
        abi?: never;
        functionName?: 'schedule';
      } = {} as any,
) {
  return useContractWrite<typeof timeLockABI, 'schedule', TMode>({
    abi: timeLockABI,
    functionName: 'schedule',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"scheduleBatch"`.
 */
export function useTimeLockScheduleBatch<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof timeLockABI, 'scheduleBatch'>['request']['abi'],
        'scheduleBatch',
        TMode
      > & { functionName?: 'scheduleBatch' }
    : UseContractWriteConfig<typeof timeLockABI, 'scheduleBatch', TMode> & {
        abi?: never;
        functionName?: 'scheduleBatch';
      } = {} as any,
) {
  return useContractWrite<typeof timeLockABI, 'scheduleBatch', TMode>({
    abi: timeLockABI,
    functionName: 'scheduleBatch',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"updateDelay"`.
 */
export function useTimeLockUpdateDelay<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof timeLockABI, 'updateDelay'>['request']['abi'],
        'updateDelay',
        TMode
      > & { functionName?: 'updateDelay' }
    : UseContractWriteConfig<typeof timeLockABI, 'updateDelay', TMode> & {
        abi?: never;
        functionName?: 'updateDelay';
      } = {} as any,
) {
  return useContractWrite<typeof timeLockABI, 'updateDelay', TMode>({
    abi: timeLockABI,
    functionName: 'updateDelay',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timeLockABI}__.
 */
export function usePrepareTimeLockWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timeLockABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timeLockABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof timeLockABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"cancel"`.
 */
export function usePrepareTimeLockCancel(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timeLockABI, 'cancel'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timeLockABI,
    functionName: 'cancel',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timeLockABI, 'cancel'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"execute"`.
 */
export function usePrepareTimeLockExecute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timeLockABI, 'execute'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timeLockABI,
    functionName: 'execute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timeLockABI, 'execute'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"executeBatch"`.
 */
export function usePrepareTimeLockExecuteBatch(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timeLockABI, 'executeBatch'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timeLockABI,
    functionName: 'executeBatch',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timeLockABI, 'executeBatch'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"grantRole"`.
 */
export function usePrepareTimeLockGrantRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timeLockABI, 'grantRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timeLockABI,
    functionName: 'grantRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timeLockABI, 'grantRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function usePrepareTimeLockOnErc1155BatchReceived(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timeLockABI, 'onERC1155BatchReceived'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timeLockABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timeLockABI, 'onERC1155BatchReceived'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function usePrepareTimeLockOnErc1155Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timeLockABI, 'onERC1155Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timeLockABI,
    functionName: 'onERC1155Received',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timeLockABI, 'onERC1155Received'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function usePrepareTimeLockOnErc721Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timeLockABI, 'onERC721Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timeLockABI,
    functionName: 'onERC721Received',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timeLockABI, 'onERC721Received'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"renounceRole"`.
 */
export function usePrepareTimeLockRenounceRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timeLockABI, 'renounceRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timeLockABI,
    functionName: 'renounceRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timeLockABI, 'renounceRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"revokeRole"`.
 */
export function usePrepareTimeLockRevokeRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timeLockABI, 'revokeRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timeLockABI,
    functionName: 'revokeRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timeLockABI, 'revokeRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"schedule"`.
 */
export function usePrepareTimeLockSchedule(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timeLockABI, 'schedule'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timeLockABI,
    functionName: 'schedule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timeLockABI, 'schedule'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"scheduleBatch"`.
 */
export function usePrepareTimeLockScheduleBatch(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timeLockABI, 'scheduleBatch'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timeLockABI,
    functionName: 'scheduleBatch',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timeLockABI, 'scheduleBatch'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timeLockABI}__ and `functionName` set to `"updateDelay"`.
 */
export function usePrepareTimeLockUpdateDelay(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timeLockABI, 'updateDelay'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timeLockABI,
    functionName: 'updateDelay',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timeLockABI, 'updateDelay'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link liquidityValueCalculatorMockABI}__.
 */
export function useLiquidityValueCalculatorMockRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof liquidityValueCalculatorMockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<
      typeof liquidityValueCalculatorMockABI,
      TFunctionName,
      TSelectData
    >,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: liquidityValueCalculatorMockABI,
    ...config,
  } as UseContractReadConfig<
    typeof liquidityValueCalculatorMockABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link liquidityValueCalculatorMockABI}__ and `functionName` set to `"calculateLiquidityValue"`.
 */
export function useLiquidityValueCalculatorMockCalculateLiquidityValue<
  TFunctionName extends 'calculateLiquidityValue',
  TSelectData = ReadContractResult<typeof liquidityValueCalculatorMockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<
      typeof liquidityValueCalculatorMockABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: liquidityValueCalculatorMockABI,
    functionName: 'calculateLiquidityValue',
    ...config,
  } as UseContractReadConfig<
    typeof liquidityValueCalculatorMockABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20DecimalsABI}__.
 */
export function useErc20DecimalsRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof erc20DecimalsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20DecimalsABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: erc20DecimalsABI, ...config } as UseContractReadConfig<
    typeof erc20DecimalsABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20DecimalsABI}__ and `functionName` set to `"decimals"`.
 */
export function useErc20DecimalsDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof erc20DecimalsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20DecimalsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20DecimalsABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof erc20DecimalsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__.
 */
export function useUniswapV2TwapOracleRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"PERIOD"`.
 */
export function useUniswapV2TwapOraclePeriod<
  TFunctionName extends 'PERIOD',
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    functionName: 'PERIOD',
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"automationTaskId"`.
 */
export function useUniswapV2TwapOracleAutomationTaskId<
  TFunctionName extends 'automationTaskId',
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    functionName: 'automationTaskId',
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"blockTimestampLast"`.
 */
export function useUniswapV2TwapOracleBlockTimestampLast<
  TFunctionName extends 'blockTimestampLast',
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    functionName: 'blockTimestampLast',
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"consult"`.
 */
export function useUniswapV2TwapOracleConsult<
  TFunctionName extends 'consult',
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    functionName: 'consult',
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"dedicatedMsgSender"`.
 */
export function useUniswapV2TwapOracleDedicatedMsgSender<
  TFunctionName extends 'dedicatedMsgSender',
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    functionName: 'dedicatedMsgSender',
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"getObservedPrices"`.
 */
export function useUniswapV2TwapOracleGetObservedPrices<
  TFunctionName extends 'getObservedPrices',
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    functionName: 'getObservedPrices',
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"getPriceRatios"`.
 */
export function useUniswapV2TwapOracleGetPriceRatios<
  TFunctionName extends 'getPriceRatios',
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    functionName: 'getPriceRatios',
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"ops"`.
 */
export function useUniswapV2TwapOracleOps<
  TFunctionName extends 'ops',
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    functionName: 'ops',
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"owner"`.
 */
export function useUniswapV2TwapOracleOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"pair"`.
 */
export function useUniswapV2TwapOraclePair<
  TFunctionName extends 'pair',
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    functionName: 'pair',
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"price0Average"`.
 */
export function useUniswapV2TwapOraclePrice0Average<
  TFunctionName extends 'price0Average',
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    functionName: 'price0Average',
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"price0CumulativeLast"`.
 */
export function useUniswapV2TwapOraclePrice0CumulativeLast<
  TFunctionName extends 'price0CumulativeLast',
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    functionName: 'price0CumulativeLast',
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"price1Average"`.
 */
export function useUniswapV2TwapOraclePrice1Average<
  TFunctionName extends 'price1Average',
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    functionName: 'price1Average',
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"price1CumulativeLast"`.
 */
export function useUniswapV2TwapOraclePrice1CumulativeLast<
  TFunctionName extends 'price1CumulativeLast',
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    functionName: 'price1CumulativeLast',
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"token0"`.
 */
export function useUniswapV2TwapOracleToken0<
  TFunctionName extends 'token0',
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    functionName: 'token0',
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"token1"`.
 */
export function useUniswapV2TwapOracleToken1<
  TFunctionName extends 'token1',
  TSelectData = ReadContractResult<typeof uniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: uniswapV2TwapOracleABI,
    functionName: 'token1',
    ...config,
  } as UseContractReadConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__.
 */
export function useUniswapV2TwapOracleWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof uniswapV2TwapOracleABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof uniswapV2TwapOracleABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof uniswapV2TwapOracleABI, TFunctionName, TMode>({
    abi: uniswapV2TwapOracleABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"cancelTask"`.
 */
export function useUniswapV2TwapOracleCancelTask<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof uniswapV2TwapOracleABI,
          'cancelTask'
        >['request']['abi'],
        'cancelTask',
        TMode
      > & { functionName?: 'cancelTask' }
    : UseContractWriteConfig<typeof uniswapV2TwapOracleABI, 'cancelTask', TMode> & {
        abi?: never;
        functionName?: 'cancelTask';
      } = {} as any,
) {
  return useContractWrite<typeof uniswapV2TwapOracleABI, 'cancelTask', TMode>({
    abi: uniswapV2TwapOracleABI,
    functionName: 'cancelTask',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"createTask"`.
 */
export function useUniswapV2TwapOracleCreateTask<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof uniswapV2TwapOracleABI,
          'createTask'
        >['request']['abi'],
        'createTask',
        TMode
      > & { functionName?: 'createTask' }
    : UseContractWriteConfig<typeof uniswapV2TwapOracleABI, 'createTask', TMode> & {
        abi?: never;
        functionName?: 'createTask';
      } = {} as any,
) {
  return useContractWrite<typeof uniswapV2TwapOracleABI, 'createTask', TMode>({
    abi: uniswapV2TwapOracleABI,
    functionName: 'createTask',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useUniswapV2TwapOracleRenounceOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof uniswapV2TwapOracleABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & { functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<
        typeof uniswapV2TwapOracleABI,
        'renounceOwnership',
        TMode
      > & {
        abi?: never;
        functionName?: 'renounceOwnership';
      } = {} as any,
) {
  return useContractWrite<typeof uniswapV2TwapOracleABI, 'renounceOwnership', TMode>({
    abi: uniswapV2TwapOracleABI,
    functionName: 'renounceOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useUniswapV2TwapOracleTransferOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof uniswapV2TwapOracleABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & { functionName?: 'transferOwnership' }
    : UseContractWriteConfig<
        typeof uniswapV2TwapOracleABI,
        'transferOwnership',
        TMode
      > & {
        abi?: never;
        functionName?: 'transferOwnership';
      } = {} as any,
) {
  return useContractWrite<typeof uniswapV2TwapOracleABI, 'transferOwnership', TMode>({
    abi: uniswapV2TwapOracleABI,
    functionName: 'transferOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"update"`.
 */
export function useUniswapV2TwapOracleUpdate<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof uniswapV2TwapOracleABI,
          'update'
        >['request']['abi'],
        'update',
        TMode
      > & { functionName?: 'update' }
    : UseContractWriteConfig<typeof uniswapV2TwapOracleABI, 'update', TMode> & {
        abi?: never;
        functionName?: 'update';
      } = {} as any,
) {
  return useContractWrite<typeof uniswapV2TwapOracleABI, 'update', TMode>({
    abi: uniswapV2TwapOracleABI,
    functionName: 'update',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"withdrawFunds"`.
 */
export function useUniswapV2TwapOracleWithdrawFunds<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof uniswapV2TwapOracleABI,
          'withdrawFunds'
        >['request']['abi'],
        'withdrawFunds',
        TMode
      > & { functionName?: 'withdrawFunds' }
    : UseContractWriteConfig<typeof uniswapV2TwapOracleABI, 'withdrawFunds', TMode> & {
        abi?: never;
        functionName?: 'withdrawFunds';
      } = {} as any,
) {
  return useContractWrite<typeof uniswapV2TwapOracleABI, 'withdrawFunds', TMode>({
    abi: uniswapV2TwapOracleABI,
    functionName: 'withdrawFunds',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__.
 */
export function usePrepareUniswapV2TwapOracleWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV2TwapOracleABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: uniswapV2TwapOracleABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV2TwapOracleABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"cancelTask"`.
 */
export function usePrepareUniswapV2TwapOracleCancelTask(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV2TwapOracleABI, 'cancelTask'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: uniswapV2TwapOracleABI,
    functionName: 'cancelTask',
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV2TwapOracleABI, 'cancelTask'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"createTask"`.
 */
export function usePrepareUniswapV2TwapOracleCreateTask(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV2TwapOracleABI, 'createTask'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: uniswapV2TwapOracleABI,
    functionName: 'createTask',
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV2TwapOracleABI, 'createTask'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareUniswapV2TwapOracleRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV2TwapOracleABI, 'renounceOwnership'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: uniswapV2TwapOracleABI,
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV2TwapOracleABI, 'renounceOwnership'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareUniswapV2TwapOracleTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV2TwapOracleABI, 'transferOwnership'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: uniswapV2TwapOracleABI,
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV2TwapOracleABI, 'transferOwnership'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"update"`.
 */
export function usePrepareUniswapV2TwapOracleUpdate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV2TwapOracleABI, 'update'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: uniswapV2TwapOracleABI,
    functionName: 'update',
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV2TwapOracleABI, 'update'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV2TwapOracleABI}__ and `functionName` set to `"withdrawFunds"`.
 */
export function usePrepareUniswapV2TwapOracleWithdrawFunds(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV2TwapOracleABI, 'withdrawFunds'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: uniswapV2TwapOracleABI,
    functionName: 'withdrawFunds',
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV2TwapOracleABI, 'withdrawFunds'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link gelatoAutomationABI}__.
 */
export function useGelatoAutomationRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof gelatoAutomationABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof gelatoAutomationABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: gelatoAutomationABI, ...config } as UseContractReadConfig<
    typeof gelatoAutomationABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link gelatoAutomationABI}__ and `functionName` set to `"automationTaskId"`.
 */
export function useGelatoAutomationAutomationTaskId<
  TFunctionName extends 'automationTaskId',
  TSelectData = ReadContractResult<typeof gelatoAutomationABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof gelatoAutomationABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: gelatoAutomationABI,
    functionName: 'automationTaskId',
    ...config,
  } as UseContractReadConfig<typeof gelatoAutomationABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link gelatoAutomationABI}__.
 */
export function useGelatoAutomationWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof gelatoAutomationABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof gelatoAutomationABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof gelatoAutomationABI, TFunctionName, TMode>({
    abi: gelatoAutomationABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link gelatoAutomationABI}__ and `functionName` set to `"cancelTask"`.
 */
export function useGelatoAutomationCancelTask<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof gelatoAutomationABI,
          'cancelTask'
        >['request']['abi'],
        'cancelTask',
        TMode
      > & { functionName?: 'cancelTask' }
    : UseContractWriteConfig<typeof gelatoAutomationABI, 'cancelTask', TMode> & {
        abi?: never;
        functionName?: 'cancelTask';
      } = {} as any,
) {
  return useContractWrite<typeof gelatoAutomationABI, 'cancelTask', TMode>({
    abi: gelatoAutomationABI,
    functionName: 'cancelTask',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link gelatoAutomationABI}__ and `functionName` set to `"createTask"`.
 */
export function useGelatoAutomationCreateTask<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof gelatoAutomationABI,
          'createTask'
        >['request']['abi'],
        'createTask',
        TMode
      > & { functionName?: 'createTask' }
    : UseContractWriteConfig<typeof gelatoAutomationABI, 'createTask', TMode> & {
        abi?: never;
        functionName?: 'createTask';
      } = {} as any,
) {
  return useContractWrite<typeof gelatoAutomationABI, 'createTask', TMode>({
    abi: gelatoAutomationABI,
    functionName: 'createTask',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link gelatoAutomationABI}__.
 */
export function usePrepareGelatoAutomationWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof gelatoAutomationABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: gelatoAutomationABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof gelatoAutomationABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link gelatoAutomationABI}__ and `functionName` set to `"cancelTask"`.
 */
export function usePrepareGelatoAutomationCancelTask(
  config: Omit<
    UsePrepareContractWriteConfig<typeof gelatoAutomationABI, 'cancelTask'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: gelatoAutomationABI,
    functionName: 'cancelTask',
    ...config,
  } as UsePrepareContractWriteConfig<typeof gelatoAutomationABI, 'cancelTask'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link gelatoAutomationABI}__ and `functionName` set to `"createTask"`.
 */
export function usePrepareGelatoAutomationCreateTask(
  config: Omit<
    UsePrepareContractWriteConfig<typeof gelatoAutomationABI, 'createTask'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: gelatoAutomationABI,
    functionName: 'createTask',
    ...config,
  } as UsePrepareContractWriteConfig<typeof gelatoAutomationABI, 'createTask'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link opsReadyABI}__.
 */
export function useOpsReadyRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof opsReadyABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof opsReadyABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: opsReadyABI, ...config } as UseContractReadConfig<
    typeof opsReadyABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link opsReadyABI}__ and `functionName` set to `"automationTaskId"`.
 */
export function useOpsReadyAutomationTaskId<
  TFunctionName extends 'automationTaskId',
  TSelectData = ReadContractResult<typeof opsReadyABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof opsReadyABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: opsReadyABI,
    functionName: 'automationTaskId',
    ...config,
  } as UseContractReadConfig<typeof opsReadyABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link opsReadyABI}__ and `functionName` set to `"dedicatedMsgSender"`.
 */
export function useOpsReadyDedicatedMsgSender<
  TFunctionName extends 'dedicatedMsgSender',
  TSelectData = ReadContractResult<typeof opsReadyABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof opsReadyABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: opsReadyABI,
    functionName: 'dedicatedMsgSender',
    ...config,
  } as UseContractReadConfig<typeof opsReadyABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link opsReadyABI}__ and `functionName` set to `"ops"`.
 */
export function useOpsReadyOps<
  TFunctionName extends 'ops',
  TSelectData = ReadContractResult<typeof opsReadyABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof opsReadyABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: opsReadyABI,
    functionName: 'ops',
    ...config,
  } as UseContractReadConfig<typeof opsReadyABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link opsReadyABI}__.
 */
export function useOpsReadyWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof opsReadyABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof opsReadyABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof opsReadyABI, TFunctionName, TMode>({
    abi: opsReadyABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link opsReadyABI}__ and `functionName` set to `"cancelTask"`.
 */
export function useOpsReadyCancelTask<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof opsReadyABI, 'cancelTask'>['request']['abi'],
        'cancelTask',
        TMode
      > & { functionName?: 'cancelTask' }
    : UseContractWriteConfig<typeof opsReadyABI, 'cancelTask', TMode> & {
        abi?: never;
        functionName?: 'cancelTask';
      } = {} as any,
) {
  return useContractWrite<typeof opsReadyABI, 'cancelTask', TMode>({
    abi: opsReadyABI,
    functionName: 'cancelTask',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link opsReadyABI}__ and `functionName` set to `"createTask"`.
 */
export function useOpsReadyCreateTask<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof opsReadyABI, 'createTask'>['request']['abi'],
        'createTask',
        TMode
      > & { functionName?: 'createTask' }
    : UseContractWriteConfig<typeof opsReadyABI, 'createTask', TMode> & {
        abi?: never;
        functionName?: 'createTask';
      } = {} as any,
) {
  return useContractWrite<typeof opsReadyABI, 'createTask', TMode>({
    abi: opsReadyABI,
    functionName: 'createTask',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link opsReadyABI}__.
 */
export function usePrepareOpsReadyWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof opsReadyABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: opsReadyABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof opsReadyABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link opsReadyABI}__ and `functionName` set to `"cancelTask"`.
 */
export function usePrepareOpsReadyCancelTask(
  config: Omit<
    UsePrepareContractWriteConfig<typeof opsReadyABI, 'cancelTask'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: opsReadyABI,
    functionName: 'cancelTask',
    ...config,
  } as UsePrepareContractWriteConfig<typeof opsReadyABI, 'cancelTask'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link opsReadyABI}__ and `functionName` set to `"createTask"`.
 */
export function usePrepareOpsReadyCreateTask(
  config: Omit<
    UsePrepareContractWriteConfig<typeof opsReadyABI, 'createTask'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: opsReadyABI,
    functionName: 'createTask',
    ...config,
  } as UsePrepareContractWriteConfig<typeof opsReadyABI, 'createTask'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link opsReadySol6ABI}__.
 */
export function useOpsReadySol6Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof opsReadySol6ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof opsReadySol6ABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: opsReadySol6ABI, ...config } as UseContractReadConfig<
    typeof opsReadySol6ABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link opsReadySol6ABI}__ and `functionName` set to `"automationTaskId"`.
 */
export function useOpsReadySol6AutomationTaskId<
  TFunctionName extends 'automationTaskId',
  TSelectData = ReadContractResult<typeof opsReadySol6ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof opsReadySol6ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: opsReadySol6ABI,
    functionName: 'automationTaskId',
    ...config,
  } as UseContractReadConfig<typeof opsReadySol6ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link opsReadySol6ABI}__ and `functionName` set to `"dedicatedMsgSender"`.
 */
export function useOpsReadySol6DedicatedMsgSender<
  TFunctionName extends 'dedicatedMsgSender',
  TSelectData = ReadContractResult<typeof opsReadySol6ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof opsReadySol6ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: opsReadySol6ABI,
    functionName: 'dedicatedMsgSender',
    ...config,
  } as UseContractReadConfig<typeof opsReadySol6ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link opsReadySol6ABI}__ and `functionName` set to `"ops"`.
 */
export function useOpsReadySol6Ops<
  TFunctionName extends 'ops',
  TSelectData = ReadContractResult<typeof opsReadySol6ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof opsReadySol6ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: opsReadySol6ABI,
    functionName: 'ops',
    ...config,
  } as UseContractReadConfig<typeof opsReadySol6ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link opsReadySol6ABI}__.
 */
export function useOpsReadySol6Write<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof opsReadySol6ABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof opsReadySol6ABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof opsReadySol6ABI, TFunctionName, TMode>({
    abi: opsReadySol6ABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link opsReadySol6ABI}__ and `functionName` set to `"cancelTask"`.
 */
export function useOpsReadySol6CancelTask<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof opsReadySol6ABI,
          'cancelTask'
        >['request']['abi'],
        'cancelTask',
        TMode
      > & { functionName?: 'cancelTask' }
    : UseContractWriteConfig<typeof opsReadySol6ABI, 'cancelTask', TMode> & {
        abi?: never;
        functionName?: 'cancelTask';
      } = {} as any,
) {
  return useContractWrite<typeof opsReadySol6ABI, 'cancelTask', TMode>({
    abi: opsReadySol6ABI,
    functionName: 'cancelTask',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link opsReadySol6ABI}__ and `functionName` set to `"createTask"`.
 */
export function useOpsReadySol6CreateTask<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof opsReadySol6ABI,
          'createTask'
        >['request']['abi'],
        'createTask',
        TMode
      > & { functionName?: 'createTask' }
    : UseContractWriteConfig<typeof opsReadySol6ABI, 'createTask', TMode> & {
        abi?: never;
        functionName?: 'createTask';
      } = {} as any,
) {
  return useContractWrite<typeof opsReadySol6ABI, 'createTask', TMode>({
    abi: opsReadySol6ABI,
    functionName: 'createTask',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link opsReadySol6ABI}__.
 */
export function usePrepareOpsReadySol6Write<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof opsReadySol6ABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: opsReadySol6ABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof opsReadySol6ABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link opsReadySol6ABI}__ and `functionName` set to `"cancelTask"`.
 */
export function usePrepareOpsReadySol6CancelTask(
  config: Omit<
    UsePrepareContractWriteConfig<typeof opsReadySol6ABI, 'cancelTask'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: opsReadySol6ABI,
    functionName: 'cancelTask',
    ...config,
  } as UsePrepareContractWriteConfig<typeof opsReadySol6ABI, 'cancelTask'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link opsReadySol6ABI}__ and `functionName` set to `"createTask"`.
 */
export function usePrepareOpsReadySol6CreateTask(
  config: Omit<
    UsePrepareContractWriteConfig<typeof opsReadySol6ABI, 'createTask'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: opsReadySol6ABI,
    functionName: 'createTask',
    ...config,
  } as UsePrepareContractWriteConfig<typeof opsReadySol6ABI, 'createTask'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iOpsABI}__.
 */
export function useIOpsRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof iOpsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iOpsABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: iOpsABI, ...config } as UseContractReadConfig<
    typeof iOpsABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iOpsABI}__ and `functionName` set to `"gelato"`.
 */
export function useIOpsGelato<
  TFunctionName extends 'gelato',
  TSelectData = ReadContractResult<typeof iOpsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iOpsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iOpsABI,
    functionName: 'gelato',
    ...config,
  } as UseContractReadConfig<typeof iOpsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iOpsABI}__ and `functionName` set to `"getFeeDetails"`.
 */
export function useIOpsGetFeeDetails<
  TFunctionName extends 'getFeeDetails',
  TSelectData = ReadContractResult<typeof iOpsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iOpsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iOpsABI,
    functionName: 'getFeeDetails',
    ...config,
  } as UseContractReadConfig<typeof iOpsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iOpsABI}__ and `functionName` set to `"taskTreasury"`.
 */
export function useIOpsTaskTreasury<
  TFunctionName extends 'taskTreasury',
  TSelectData = ReadContractResult<typeof iOpsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iOpsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iOpsABI,
    functionName: 'taskTreasury',
    ...config,
  } as UseContractReadConfig<typeof iOpsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iOpsABI}__.
 */
export function useIOpsWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iOpsABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iOpsABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof iOpsABI, TFunctionName, TMode>({
    abi: iOpsABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iOpsABI}__ and `functionName` set to `"cancelTask"`.
 */
export function useIOpsCancelTask<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iOpsABI, 'cancelTask'>['request']['abi'],
        'cancelTask',
        TMode
      > & { functionName?: 'cancelTask' }
    : UseContractWriteConfig<typeof iOpsABI, 'cancelTask', TMode> & {
        abi?: never;
        functionName?: 'cancelTask';
      } = {} as any,
) {
  return useContractWrite<typeof iOpsABI, 'cancelTask', TMode>({
    abi: iOpsABI,
    functionName: 'cancelTask',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iOpsABI}__ and `functionName` set to `"createTask"`.
 */
export function useIOpsCreateTask<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iOpsABI, 'createTask'>['request']['abi'],
        'createTask',
        TMode
      > & { functionName?: 'createTask' }
    : UseContractWriteConfig<typeof iOpsABI, 'createTask', TMode> & {
        abi?: never;
        functionName?: 'createTask';
      } = {} as any,
) {
  return useContractWrite<typeof iOpsABI, 'createTask', TMode>({
    abi: iOpsABI,
    functionName: 'createTask',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iOpsABI}__.
 */
export function usePrepareIOpsWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iOpsABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iOpsABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof iOpsABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iOpsABI}__ and `functionName` set to `"cancelTask"`.
 */
export function usePrepareIOpsCancelTask(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iOpsABI, 'cancelTask'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iOpsABI,
    functionName: 'cancelTask',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iOpsABI, 'cancelTask'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iOpsABI}__ and `functionName` set to `"createTask"`.
 */
export function usePrepareIOpsCreateTask(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iOpsABI, 'createTask'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iOpsABI,
    functionName: 'createTask',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iOpsABI, 'createTask'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iOpsProxyFactoryABI}__.
 */
export function useIOpsProxyFactoryRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof iOpsProxyFactoryABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iOpsProxyFactoryABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: iOpsProxyFactoryABI, ...config } as UseContractReadConfig<
    typeof iOpsProxyFactoryABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iOpsProxyFactoryABI}__ and `functionName` set to `"getProxyOf"`.
 */
export function useIOpsProxyFactoryGetProxyOf<
  TFunctionName extends 'getProxyOf',
  TSelectData = ReadContractResult<typeof iOpsProxyFactoryABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iOpsProxyFactoryABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iOpsProxyFactoryABI,
    functionName: 'getProxyOf',
    ...config,
  } as UseContractReadConfig<typeof iOpsProxyFactoryABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iTaskTreasuryUpgradableABI}__.
 */
export function useITaskTreasuryUpgradableWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iTaskTreasuryUpgradableABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iTaskTreasuryUpgradableABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof iTaskTreasuryUpgradableABI, TFunctionName, TMode>({
    abi: iTaskTreasuryUpgradableABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iTaskTreasuryUpgradableABI}__ and `functionName` set to `"depositFunds"`.
 */
export function useITaskTreasuryUpgradableDepositFunds<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iTaskTreasuryUpgradableABI,
          'depositFunds'
        >['request']['abi'],
        'depositFunds',
        TMode
      > & { functionName?: 'depositFunds' }
    : UseContractWriteConfig<typeof iTaskTreasuryUpgradableABI, 'depositFunds', TMode> & {
        abi?: never;
        functionName?: 'depositFunds';
      } = {} as any,
) {
  return useContractWrite<typeof iTaskTreasuryUpgradableABI, 'depositFunds', TMode>({
    abi: iTaskTreasuryUpgradableABI,
    functionName: 'depositFunds',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iTaskTreasuryUpgradableABI}__ and `functionName` set to `"withdrawFunds"`.
 */
export function useITaskTreasuryUpgradableWithdrawFunds<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iTaskTreasuryUpgradableABI,
          'withdrawFunds'
        >['request']['abi'],
        'withdrawFunds',
        TMode
      > & { functionName?: 'withdrawFunds' }
    : UseContractWriteConfig<
        typeof iTaskTreasuryUpgradableABI,
        'withdrawFunds',
        TMode
      > & {
        abi?: never;
        functionName?: 'withdrawFunds';
      } = {} as any,
) {
  return useContractWrite<typeof iTaskTreasuryUpgradableABI, 'withdrawFunds', TMode>({
    abi: iTaskTreasuryUpgradableABI,
    functionName: 'withdrawFunds',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iTaskTreasuryUpgradableABI}__.
 */
export function usePrepareITaskTreasuryUpgradableWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iTaskTreasuryUpgradableABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iTaskTreasuryUpgradableABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof iTaskTreasuryUpgradableABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iTaskTreasuryUpgradableABI}__ and `functionName` set to `"depositFunds"`.
 */
export function usePrepareITaskTreasuryUpgradableDepositFunds(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iTaskTreasuryUpgradableABI, 'depositFunds'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iTaskTreasuryUpgradableABI,
    functionName: 'depositFunds',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iTaskTreasuryUpgradableABI, 'depositFunds'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iTaskTreasuryUpgradableABI}__ and `functionName` set to `"withdrawFunds"`.
 */
export function usePrepareITaskTreasuryUpgradableWithdrawFunds(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iTaskTreasuryUpgradableABI, 'withdrawFunds'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iTaskTreasuryUpgradableABI,
    functionName: 'withdrawFunds',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iTaskTreasuryUpgradableABI, 'withdrawFunds'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintStakingABI}__.
 */
export function useMintStakingRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof mintStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: mintStakingABI, ...config } as UseContractReadConfig<
    typeof mintStakingABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useMintStakingBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof mintStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintStakingABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"earnedReward"`.
 */
export function useMintStakingEarnedReward<
  TFunctionName extends 'earnedReward',
  TSelectData = ReadContractResult<typeof mintStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintStakingABI,
    functionName: 'earnedReward',
    ...config,
  } as UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"getRewardToken"`.
 */
export function useMintStakingGetRewardToken<
  TFunctionName extends 'getRewardToken',
  TSelectData = ReadContractResult<typeof mintStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintStakingABI,
    functionName: 'getRewardToken',
    ...config,
  } as UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"owner"`.
 */
export function useMintStakingOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof mintStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintStakingABI,
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"rewardPerToken"`.
 */
export function useMintStakingRewardPerToken<
  TFunctionName extends 'rewardPerToken',
  TSelectData = ReadContractResult<typeof mintStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintStakingABI,
    functionName: 'rewardPerToken',
    ...config,
  } as UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"rewardPerTokenStored"`.
 */
export function useMintStakingRewardPerTokenStored<
  TFunctionName extends 'rewardPerTokenStored',
  TSelectData = ReadContractResult<typeof mintStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintStakingABI,
    functionName: 'rewardPerTokenStored',
    ...config,
  } as UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"rewardRate"`.
 */
export function useMintStakingRewardRate<
  TFunctionName extends 'rewardRate',
  TSelectData = ReadContractResult<typeof mintStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintStakingABI,
    functionName: 'rewardRate',
    ...config,
  } as UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"rewards"`.
 */
export function useMintStakingRewards<
  TFunctionName extends 'rewards',
  TSelectData = ReadContractResult<typeof mintStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintStakingABI,
    functionName: 'rewards',
    ...config,
  } as UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"rewardsToken"`.
 */
export function useMintStakingRewardsToken<
  TFunctionName extends 'rewardsToken',
  TSelectData = ReadContractResult<typeof mintStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintStakingABI,
    functionName: 'rewardsToken',
    ...config,
  } as UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"stakingToken"`.
 */
export function useMintStakingStakingToken<
  TFunctionName extends 'stakingToken',
  TSelectData = ReadContractResult<typeof mintStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintStakingABI,
    functionName: 'stakingToken',
    ...config,
  } as UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useMintStakingTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof mintStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintStakingABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"updatedAt"`.
 */
export function useMintStakingUpdatedAt<
  TFunctionName extends 'updatedAt',
  TSelectData = ReadContractResult<typeof mintStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintStakingABI,
    functionName: 'updatedAt',
    ...config,
  } as UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"userRewardPerTokenPaid"`.
 */
export function useMintStakingUserRewardPerTokenPaid<
  TFunctionName extends 'userRewardPerTokenPaid',
  TSelectData = ReadContractResult<typeof mintStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintStakingABI,
    functionName: 'userRewardPerTokenPaid',
    ...config,
  } as UseContractReadConfig<typeof mintStakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintStakingABI}__.
 */
export function useMintStakingWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mintStakingABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof mintStakingABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof mintStakingABI, TFunctionName, TMode>({
    abi: mintStakingABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"collectRewardsFor"`.
 */
export function useMintStakingCollectRewardsFor<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof mintStakingABI,
          'collectRewardsFor'
        >['request']['abi'],
        'collectRewardsFor',
        TMode
      > & { functionName?: 'collectRewardsFor' }
    : UseContractWriteConfig<typeof mintStakingABI, 'collectRewardsFor', TMode> & {
        abi?: never;
        functionName?: 'collectRewardsFor';
      } = {} as any,
) {
  return useContractWrite<typeof mintStakingABI, 'collectRewardsFor', TMode>({
    abi: mintStakingABI,
    functionName: 'collectRewardsFor',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useMintStakingRenounceOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof mintStakingABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & { functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<typeof mintStakingABI, 'renounceOwnership', TMode> & {
        abi?: never;
        functionName?: 'renounceOwnership';
      } = {} as any,
) {
  return useContractWrite<typeof mintStakingABI, 'renounceOwnership', TMode>({
    abi: mintStakingABI,
    functionName: 'renounceOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"stakeFor"`.
 */
export function useMintStakingStakeFor<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mintStakingABI, 'stakeFor'>['request']['abi'],
        'stakeFor',
        TMode
      > & { functionName?: 'stakeFor' }
    : UseContractWriteConfig<typeof mintStakingABI, 'stakeFor', TMode> & {
        abi?: never;
        functionName?: 'stakeFor';
      } = {} as any,
) {
  return useContractWrite<typeof mintStakingABI, 'stakeFor', TMode>({
    abi: mintStakingABI,
    functionName: 'stakeFor',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useMintStakingTransferOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof mintStakingABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & { functionName?: 'transferOwnership' }
    : UseContractWriteConfig<typeof mintStakingABI, 'transferOwnership', TMode> & {
        abi?: never;
        functionName?: 'transferOwnership';
      } = {} as any,
) {
  return useContractWrite<typeof mintStakingABI, 'transferOwnership', TMode>({
    abi: mintStakingABI,
    functionName: 'transferOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"withdrawFor"`.
 */
export function useMintStakingWithdrawFor<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof mintStakingABI,
          'withdrawFor'
        >['request']['abi'],
        'withdrawFor',
        TMode
      > & { functionName?: 'withdrawFor' }
    : UseContractWriteConfig<typeof mintStakingABI, 'withdrawFor', TMode> & {
        abi?: never;
        functionName?: 'withdrawFor';
      } = {} as any,
) {
  return useContractWrite<typeof mintStakingABI, 'withdrawFor', TMode>({
    abi: mintStakingABI,
    functionName: 'withdrawFor',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintStakingABI}__.
 */
export function usePrepareMintStakingWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintStakingABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintStakingABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintStakingABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"collectRewardsFor"`.
 */
export function usePrepareMintStakingCollectRewardsFor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintStakingABI, 'collectRewardsFor'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintStakingABI,
    functionName: 'collectRewardsFor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintStakingABI, 'collectRewardsFor'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareMintStakingRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintStakingABI, 'renounceOwnership'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintStakingABI,
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintStakingABI, 'renounceOwnership'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"stakeFor"`.
 */
export function usePrepareMintStakingStakeFor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintStakingABI, 'stakeFor'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintStakingABI,
    functionName: 'stakeFor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintStakingABI, 'stakeFor'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareMintStakingTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintStakingABI, 'transferOwnership'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintStakingABI,
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintStakingABI, 'transferOwnership'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintStakingABI}__ and `functionName` set to `"withdrawFor"`.
 */
export function usePrepareMintStakingWithdrawFor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintStakingABI, 'withdrawFor'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintStakingABI,
    functionName: 'withdrawFor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintStakingABI, 'withdrawFor'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link periodStarterABI}__.
 */
export function usePeriodStarterRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof periodStarterABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: periodStarterABI, ...config } as UseContractReadConfig<
    typeof periodStarterABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"DEFAULT_ADMIN_ROLE"`.
 */
export function usePeriodStarterDefaultAdminRole<
  TFunctionName extends 'DEFAULT_ADMIN_ROLE',
  TSelectData = ReadContractResult<typeof periodStarterABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: periodStarterABI,
    functionName: 'DEFAULT_ADMIN_ROLE',
    ...config,
  } as UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"SCHEDULER_ROLE"`.
 */
export function usePeriodStarterSchedulerRole<
  TFunctionName extends 'SCHEDULER_ROLE',
  TSelectData = ReadContractResult<typeof periodStarterABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: periodStarterABI,
    functionName: 'SCHEDULER_ROLE',
    ...config,
  } as UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"automationTaskId"`.
 */
export function usePeriodStarterAutomationTaskId<
  TFunctionName extends 'automationTaskId',
  TSelectData = ReadContractResult<typeof periodStarterABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: periodStarterABI,
    functionName: 'automationTaskId',
    ...config,
  } as UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"canStartNewRewardsPeriod"`.
 */
export function usePeriodStarterCanStartNewRewardsPeriod<
  TFunctionName extends 'canStartNewRewardsPeriod',
  TSelectData = ReadContractResult<typeof periodStarterABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: periodStarterABI,
    functionName: 'canStartNewRewardsPeriod',
    ...config,
  } as UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"dedicatedMsgSender"`.
 */
export function usePeriodStarterDedicatedMsgSender<
  TFunctionName extends 'dedicatedMsgSender',
  TSelectData = ReadContractResult<typeof periodStarterABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: periodStarterABI,
    functionName: 'dedicatedMsgSender',
    ...config,
  } as UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"getRoleAdmin"`.
 */
export function usePeriodStarterGetRoleAdmin<
  TFunctionName extends 'getRoleAdmin',
  TSelectData = ReadContractResult<typeof periodStarterABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: periodStarterABI,
    functionName: 'getRoleAdmin',
    ...config,
  } as UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"hasRole"`.
 */
export function usePeriodStarterHasRole<
  TFunctionName extends 'hasRole',
  TSelectData = ReadContractResult<typeof periodStarterABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: periodStarterABI,
    functionName: 'hasRole',
    ...config,
  } as UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"nextPeriodRewardsAmount"`.
 */
export function usePeriodStarterNextPeriodRewardsAmount<
  TFunctionName extends 'nextPeriodRewardsAmount',
  TSelectData = ReadContractResult<typeof periodStarterABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: periodStarterABI,
    functionName: 'nextPeriodRewardsAmount',
    ...config,
  } as UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"nextStakingPeriodFinishAt"`.
 */
export function usePeriodStarterNextStakingPeriodFinishAt<
  TFunctionName extends 'nextStakingPeriodFinishAt',
  TSelectData = ReadContractResult<typeof periodStarterABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: periodStarterABI,
    functionName: 'nextStakingPeriodFinishAt',
    ...config,
  } as UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"ops"`.
 */
export function usePeriodStarterOps<
  TFunctionName extends 'ops',
  TSelectData = ReadContractResult<typeof periodStarterABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: periodStarterABI,
    functionName: 'ops',
    ...config,
  } as UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"stakingContract"`.
 */
export function usePeriodStarterStakingContract<
  TFunctionName extends 'stakingContract',
  TSelectData = ReadContractResult<typeof periodStarterABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: periodStarterABI,
    functionName: 'stakingContract',
    ...config,
  } as UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function usePeriodStarterSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof periodStarterABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: periodStarterABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof periodStarterABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link periodStarterABI}__.
 */
export function usePeriodStarterWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof periodStarterABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof periodStarterABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof periodStarterABI, TFunctionName, TMode>({
    abi: periodStarterABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"cancelTask"`.
 */
export function usePeriodStarterCancelTask<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof periodStarterABI,
          'cancelTask'
        >['request']['abi'],
        'cancelTask',
        TMode
      > & { functionName?: 'cancelTask' }
    : UseContractWriteConfig<typeof periodStarterABI, 'cancelTask', TMode> & {
        abi?: never;
        functionName?: 'cancelTask';
      } = {} as any,
) {
  return useContractWrite<typeof periodStarterABI, 'cancelTask', TMode>({
    abi: periodStarterABI,
    functionName: 'cancelTask',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"createTask"`.
 */
export function usePeriodStarterCreateTask<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof periodStarterABI,
          'createTask'
        >['request']['abi'],
        'createTask',
        TMode
      > & { functionName?: 'createTask' }
    : UseContractWriteConfig<typeof periodStarterABI, 'createTask', TMode> & {
        abi?: never;
        functionName?: 'createTask';
      } = {} as any,
) {
  return useContractWrite<typeof periodStarterABI, 'createTask', TMode>({
    abi: periodStarterABI,
    functionName: 'createTask',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"grantRole"`.
 */
export function usePeriodStarterGrantRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof periodStarterABI,
          'grantRole'
        >['request']['abi'],
        'grantRole',
        TMode
      > & { functionName?: 'grantRole' }
    : UseContractWriteConfig<typeof periodStarterABI, 'grantRole', TMode> & {
        abi?: never;
        functionName?: 'grantRole';
      } = {} as any,
) {
  return useContractWrite<typeof periodStarterABI, 'grantRole', TMode>({
    abi: periodStarterABI,
    functionName: 'grantRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"renounceRole"`.
 */
export function usePeriodStarterRenounceRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof periodStarterABI,
          'renounceRole'
        >['request']['abi'],
        'renounceRole',
        TMode
      > & { functionName?: 'renounceRole' }
    : UseContractWriteConfig<typeof periodStarterABI, 'renounceRole', TMode> & {
        abi?: never;
        functionName?: 'renounceRole';
      } = {} as any,
) {
  return useContractWrite<typeof periodStarterABI, 'renounceRole', TMode>({
    abi: periodStarterABI,
    functionName: 'renounceRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"revokeRole"`.
 */
export function usePeriodStarterRevokeRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof periodStarterABI,
          'revokeRole'
        >['request']['abi'],
        'revokeRole',
        TMode
      > & { functionName?: 'revokeRole' }
    : UseContractWriteConfig<typeof periodStarterABI, 'revokeRole', TMode> & {
        abi?: never;
        functionName?: 'revokeRole';
      } = {} as any,
) {
  return useContractWrite<typeof periodStarterABI, 'revokeRole', TMode>({
    abi: periodStarterABI,
    functionName: 'revokeRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"scheduleNextRewardsPeriod"`.
 */
export function usePeriodStarterScheduleNextRewardsPeriod<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof periodStarterABI,
          'scheduleNextRewardsPeriod'
        >['request']['abi'],
        'scheduleNextRewardsPeriod',
        TMode
      > & { functionName?: 'scheduleNextRewardsPeriod' }
    : UseContractWriteConfig<
        typeof periodStarterABI,
        'scheduleNextRewardsPeriod',
        TMode
      > & {
        abi?: never;
        functionName?: 'scheduleNextRewardsPeriod';
      } = {} as any,
) {
  return useContractWrite<typeof periodStarterABI, 'scheduleNextRewardsPeriod', TMode>({
    abi: periodStarterABI,
    functionName: 'scheduleNextRewardsPeriod',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"startNewRewardsPeriod"`.
 */
export function usePeriodStarterStartNewRewardsPeriod<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof periodStarterABI,
          'startNewRewardsPeriod'
        >['request']['abi'],
        'startNewRewardsPeriod',
        TMode
      > & { functionName?: 'startNewRewardsPeriod' }
    : UseContractWriteConfig<typeof periodStarterABI, 'startNewRewardsPeriod', TMode> & {
        abi?: never;
        functionName?: 'startNewRewardsPeriod';
      } = {} as any,
) {
  return useContractWrite<typeof periodStarterABI, 'startNewRewardsPeriod', TMode>({
    abi: periodStarterABI,
    functionName: 'startNewRewardsPeriod',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"withdrawFunds"`.
 */
export function usePeriodStarterWithdrawFunds<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof periodStarterABI,
          'withdrawFunds'
        >['request']['abi'],
        'withdrawFunds',
        TMode
      > & { functionName?: 'withdrawFunds' }
    : UseContractWriteConfig<typeof periodStarterABI, 'withdrawFunds', TMode> & {
        abi?: never;
        functionName?: 'withdrawFunds';
      } = {} as any,
) {
  return useContractWrite<typeof periodStarterABI, 'withdrawFunds', TMode>({
    abi: periodStarterABI,
    functionName: 'withdrawFunds',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link periodStarterABI}__.
 */
export function usePreparePeriodStarterWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof periodStarterABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: periodStarterABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof periodStarterABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"cancelTask"`.
 */
export function usePreparePeriodStarterCancelTask(
  config: Omit<
    UsePrepareContractWriteConfig<typeof periodStarterABI, 'cancelTask'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: periodStarterABI,
    functionName: 'cancelTask',
    ...config,
  } as UsePrepareContractWriteConfig<typeof periodStarterABI, 'cancelTask'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"createTask"`.
 */
export function usePreparePeriodStarterCreateTask(
  config: Omit<
    UsePrepareContractWriteConfig<typeof periodStarterABI, 'createTask'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: periodStarterABI,
    functionName: 'createTask',
    ...config,
  } as UsePrepareContractWriteConfig<typeof periodStarterABI, 'createTask'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"grantRole"`.
 */
export function usePreparePeriodStarterGrantRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof periodStarterABI, 'grantRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: periodStarterABI,
    functionName: 'grantRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof periodStarterABI, 'grantRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"renounceRole"`.
 */
export function usePreparePeriodStarterRenounceRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof periodStarterABI, 'renounceRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: periodStarterABI,
    functionName: 'renounceRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof periodStarterABI, 'renounceRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"revokeRole"`.
 */
export function usePreparePeriodStarterRevokeRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof periodStarterABI, 'revokeRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: periodStarterABI,
    functionName: 'revokeRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof periodStarterABI, 'revokeRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"scheduleNextRewardsPeriod"`.
 */
export function usePreparePeriodStarterScheduleNextRewardsPeriod(
  config: Omit<
    UsePrepareContractWriteConfig<typeof periodStarterABI, 'scheduleNextRewardsPeriod'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: periodStarterABI,
    functionName: 'scheduleNextRewardsPeriod',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof periodStarterABI,
    'scheduleNextRewardsPeriod'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"startNewRewardsPeriod"`.
 */
export function usePreparePeriodStarterStartNewRewardsPeriod(
  config: Omit<
    UsePrepareContractWriteConfig<typeof periodStarterABI, 'startNewRewardsPeriod'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: periodStarterABI,
    functionName: 'startNewRewardsPeriod',
    ...config,
  } as UsePrepareContractWriteConfig<typeof periodStarterABI, 'startNewRewardsPeriod'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link periodStarterABI}__ and `functionName` set to `"withdrawFunds"`.
 */
export function usePreparePeriodStarterWithdrawFunds(
  config: Omit<
    UsePrepareContractWriteConfig<typeof periodStarterABI, 'withdrawFunds'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: periodStarterABI,
    functionName: 'withdrawFunds',
    ...config,
  } as UsePrepareContractWriteConfig<typeof periodStarterABI, 'withdrawFunds'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__.
 */
export function useStakingRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: stakingABI, ...config } as UseContractReadConfig<
    typeof stakingABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"DEFAULT_ADMIN_ROLE"`.
 */
export function useStakingDefaultAdminRole<
  TFunctionName extends 'DEFAULT_ADMIN_ROLE',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'DEFAULT_ADMIN_ROLE',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"LOCKER_ROLE"`.
 */
export function useStakingLockerRole<
  TFunctionName extends 'LOCKER_ROLE',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'LOCKER_ROLE',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"PERIOD_STARTER"`.
 */
export function useStakingPeriodStarter<
  TFunctionName extends 'PERIOD_STARTER',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'PERIOD_STARTER',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useStakingBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"collectedRewardsInCurrentPeriod"`.
 */
export function useStakingCollectedRewardsInCurrentPeriod<
  TFunctionName extends 'collectedRewardsInCurrentPeriod',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'collectedRewardsInCurrentPeriod',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"currentPeriodRewardsAmount"`.
 */
export function useStakingCurrentPeriodRewardsAmount<
  TFunctionName extends 'currentPeriodRewardsAmount',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'currentPeriodRewardsAmount',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"duration"`.
 */
export function useStakingDuration<
  TFunctionName extends 'duration',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'duration',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"earnedReward"`.
 */
export function useStakingEarnedReward<
  TFunctionName extends 'earnedReward',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'earnedReward',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"finishAt"`.
 */
export function useStakingFinishAt<
  TFunctionName extends 'finishAt',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'finishAt',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"getRewardToken"`.
 */
export function useStakingGetRewardToken<
  TFunctionName extends 'getRewardToken',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'getRewardToken',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"getRoleAdmin"`.
 */
export function useStakingGetRoleAdmin<
  TFunctionName extends 'getRoleAdmin',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'getRoleAdmin',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"hasRole"`.
 */
export function useStakingHasRole<
  TFunctionName extends 'hasRole',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'hasRole',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"lastTimeRewardApplicable"`.
 */
export function useStakingLastTimeRewardApplicable<
  TFunctionName extends 'lastTimeRewardApplicable',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'lastTimeRewardApplicable',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"maxPeriodDuration"`.
 */
export function useStakingMaxPeriodDuration<
  TFunctionName extends 'maxPeriodDuration',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'maxPeriodDuration',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"rewardPerToken"`.
 */
export function useStakingRewardPerToken<
  TFunctionName extends 'rewardPerToken',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'rewardPerToken',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"rewardPerTokenStored"`.
 */
export function useStakingRewardPerTokenStored<
  TFunctionName extends 'rewardPerTokenStored',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'rewardPerTokenStored',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"rewardRate"`.
 */
export function useStakingRewardRate<
  TFunctionName extends 'rewardRate',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'rewardRate',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"rewards"`.
 */
export function useStakingRewards<
  TFunctionName extends 'rewards',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'rewards',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"rewardsToken"`.
 */
export function useStakingRewardsToken<
  TFunctionName extends 'rewardsToken',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'rewardsToken',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"stakingToken"`.
 */
export function useStakingStakingToken<
  TFunctionName extends 'stakingToken',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'stakingToken',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useStakingSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useStakingTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"updatedAt"`.
 */
export function useStakingUpdatedAt<
  TFunctionName extends 'updatedAt',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'updatedAt',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"userRewardPerTokenPaid"`.
 */
export function useStakingUserRewardPerTokenPaid<
  TFunctionName extends 'userRewardPerTokenPaid',
  TSelectData = ReadContractResult<typeof stakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stakingABI,
    functionName: 'userRewardPerTokenPaid',
    ...config,
  } as UseContractReadConfig<typeof stakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link stakingABI}__.
 */
export function useStakingWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof stakingABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof stakingABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof stakingABI, TFunctionName, TMode>({
    abi: stakingABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"collectRewardsFor"`.
 */
export function useStakingCollectRewardsFor<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof stakingABI,
          'collectRewardsFor'
        >['request']['abi'],
        'collectRewardsFor',
        TMode
      > & { functionName?: 'collectRewardsFor' }
    : UseContractWriteConfig<typeof stakingABI, 'collectRewardsFor', TMode> & {
        abi?: never;
        functionName?: 'collectRewardsFor';
      } = {} as any,
) {
  return useContractWrite<typeof stakingABI, 'collectRewardsFor', TMode>({
    abi: stakingABI,
    functionName: 'collectRewardsFor',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"grantRole"`.
 */
export function useStakingGrantRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof stakingABI, 'grantRole'>['request']['abi'],
        'grantRole',
        TMode
      > & { functionName?: 'grantRole' }
    : UseContractWriteConfig<typeof stakingABI, 'grantRole', TMode> & {
        abi?: never;
        functionName?: 'grantRole';
      } = {} as any,
) {
  return useContractWrite<typeof stakingABI, 'grantRole', TMode>({
    abi: stakingABI,
    functionName: 'grantRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"renounceRole"`.
 */
export function useStakingRenounceRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof stakingABI, 'renounceRole'>['request']['abi'],
        'renounceRole',
        TMode
      > & { functionName?: 'renounceRole' }
    : UseContractWriteConfig<typeof stakingABI, 'renounceRole', TMode> & {
        abi?: never;
        functionName?: 'renounceRole';
      } = {} as any,
) {
  return useContractWrite<typeof stakingABI, 'renounceRole', TMode>({
    abi: stakingABI,
    functionName: 'renounceRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"revokeRole"`.
 */
export function useStakingRevokeRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof stakingABI, 'revokeRole'>['request']['abi'],
        'revokeRole',
        TMode
      > & { functionName?: 'revokeRole' }
    : UseContractWriteConfig<typeof stakingABI, 'revokeRole', TMode> & {
        abi?: never;
        functionName?: 'revokeRole';
      } = {} as any,
) {
  return useContractWrite<typeof stakingABI, 'revokeRole', TMode>({
    abi: stakingABI,
    functionName: 'revokeRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"setMaxPeriodDuration"`.
 */
export function useStakingSetMaxPeriodDuration<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof stakingABI,
          'setMaxPeriodDuration'
        >['request']['abi'],
        'setMaxPeriodDuration',
        TMode
      > & { functionName?: 'setMaxPeriodDuration' }
    : UseContractWriteConfig<typeof stakingABI, 'setMaxPeriodDuration', TMode> & {
        abi?: never;
        functionName?: 'setMaxPeriodDuration';
      } = {} as any,
) {
  return useContractWrite<typeof stakingABI, 'setMaxPeriodDuration', TMode>({
    abi: stakingABI,
    functionName: 'setMaxPeriodDuration',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"stakeFor"`.
 */
export function useStakingStakeFor<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof stakingABI, 'stakeFor'>['request']['abi'],
        'stakeFor',
        TMode
      > & { functionName?: 'stakeFor' }
    : UseContractWriteConfig<typeof stakingABI, 'stakeFor', TMode> & {
        abi?: never;
        functionName?: 'stakeFor';
      } = {} as any,
) {
  return useContractWrite<typeof stakingABI, 'stakeFor', TMode>({
    abi: stakingABI,
    functionName: 'stakeFor',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"startNewRewardsPeriod"`.
 */
export function useStakingStartNewRewardsPeriod<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof stakingABI,
          'startNewRewardsPeriod'
        >['request']['abi'],
        'startNewRewardsPeriod',
        TMode
      > & { functionName?: 'startNewRewardsPeriod' }
    : UseContractWriteConfig<typeof stakingABI, 'startNewRewardsPeriod', TMode> & {
        abi?: never;
        functionName?: 'startNewRewardsPeriod';
      } = {} as any,
) {
  return useContractWrite<typeof stakingABI, 'startNewRewardsPeriod', TMode>({
    abi: stakingABI,
    functionName: 'startNewRewardsPeriod',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"withdrawFor"`.
 */
export function useStakingWithdrawFor<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof stakingABI, 'withdrawFor'>['request']['abi'],
        'withdrawFor',
        TMode
      > & { functionName?: 'withdrawFor' }
    : UseContractWriteConfig<typeof stakingABI, 'withdrawFor', TMode> & {
        abi?: never;
        functionName?: 'withdrawFor';
      } = {} as any,
) {
  return useContractWrite<typeof stakingABI, 'withdrawFor', TMode>({
    abi: stakingABI,
    functionName: 'withdrawFor',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link stakingABI}__.
 */
export function usePrepareStakingWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof stakingABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: stakingABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof stakingABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"collectRewardsFor"`.
 */
export function usePrepareStakingCollectRewardsFor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof stakingABI, 'collectRewardsFor'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: stakingABI,
    functionName: 'collectRewardsFor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof stakingABI, 'collectRewardsFor'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"grantRole"`.
 */
export function usePrepareStakingGrantRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof stakingABI, 'grantRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: stakingABI,
    functionName: 'grantRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof stakingABI, 'grantRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"renounceRole"`.
 */
export function usePrepareStakingRenounceRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof stakingABI, 'renounceRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: stakingABI,
    functionName: 'renounceRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof stakingABI, 'renounceRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"revokeRole"`.
 */
export function usePrepareStakingRevokeRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof stakingABI, 'revokeRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: stakingABI,
    functionName: 'revokeRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof stakingABI, 'revokeRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"setMaxPeriodDuration"`.
 */
export function usePrepareStakingSetMaxPeriodDuration(
  config: Omit<
    UsePrepareContractWriteConfig<typeof stakingABI, 'setMaxPeriodDuration'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: stakingABI,
    functionName: 'setMaxPeriodDuration',
    ...config,
  } as UsePrepareContractWriteConfig<typeof stakingABI, 'setMaxPeriodDuration'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"stakeFor"`.
 */
export function usePrepareStakingStakeFor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof stakingABI, 'stakeFor'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: stakingABI,
    functionName: 'stakeFor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof stakingABI, 'stakeFor'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"startNewRewardsPeriod"`.
 */
export function usePrepareStakingStartNewRewardsPeriod(
  config: Omit<
    UsePrepareContractWriteConfig<typeof stakingABI, 'startNewRewardsPeriod'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: stakingABI,
    functionName: 'startNewRewardsPeriod',
    ...config,
  } as UsePrepareContractWriteConfig<typeof stakingABI, 'startNewRewardsPeriod'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link stakingABI}__ and `functionName` set to `"withdrawFor"`.
 */
export function usePrepareStakingWithdrawFor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof stakingABI, 'withdrawFor'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: stakingABI,
    functionName: 'withdrawFor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof stakingABI, 'withdrawFor'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link staleDepositLiquidatorABI}__.
 */
export function useStaleDepositLiquidatorRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof staleDepositLiquidatorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof staleDepositLiquidatorABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: staleDepositLiquidatorABI,
    ...config,
  } as UseContractReadConfig<
    typeof staleDepositLiquidatorABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"automationTaskId"`.
 */
export function useStaleDepositLiquidatorAutomationTaskId<
  TFunctionName extends 'automationTaskId',
  TSelectData = ReadContractResult<typeof staleDepositLiquidatorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof staleDepositLiquidatorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: staleDepositLiquidatorABI,
    functionName: 'automationTaskId',
    ...config,
  } as UseContractReadConfig<
    typeof staleDepositLiquidatorABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"dedicatedMsgSender"`.
 */
export function useStaleDepositLiquidatorDedicatedMsgSender<
  TFunctionName extends 'dedicatedMsgSender',
  TSelectData = ReadContractResult<typeof staleDepositLiquidatorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof staleDepositLiquidatorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: staleDepositLiquidatorABI,
    functionName: 'dedicatedMsgSender',
    ...config,
  } as UseContractReadConfig<
    typeof staleDepositLiquidatorABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"getListOfStaleDepositsToLiquidate"`.
 */
export function useStaleDepositLiquidatorGetListOfStaleDepositsToLiquidate<
  TFunctionName extends 'getListOfStaleDepositsToLiquidate',
  TSelectData = ReadContractResult<typeof staleDepositLiquidatorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof staleDepositLiquidatorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: staleDepositLiquidatorABI,
    functionName: 'getListOfStaleDepositsToLiquidate',
    ...config,
  } as UseContractReadConfig<
    typeof staleDepositLiquidatorABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"locker"`.
 */
export function useStaleDepositLiquidatorLocker<
  TFunctionName extends 'locker',
  TSelectData = ReadContractResult<typeof staleDepositLiquidatorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof staleDepositLiquidatorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: staleDepositLiquidatorABI,
    functionName: 'locker',
    ...config,
  } as UseContractReadConfig<
    typeof staleDepositLiquidatorABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"ops"`.
 */
export function useStaleDepositLiquidatorOps<
  TFunctionName extends 'ops',
  TSelectData = ReadContractResult<typeof staleDepositLiquidatorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof staleDepositLiquidatorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: staleDepositLiquidatorABI,
    functionName: 'ops',
    ...config,
  } as UseContractReadConfig<
    typeof staleDepositLiquidatorABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"owner"`.
 */
export function useStaleDepositLiquidatorOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof staleDepositLiquidatorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof staleDepositLiquidatorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: staleDepositLiquidatorABI,
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<
    typeof staleDepositLiquidatorABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link staleDepositLiquidatorABI}__.
 */
export function useStaleDepositLiquidatorWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof staleDepositLiquidatorABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof staleDepositLiquidatorABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof staleDepositLiquidatorABI, TFunctionName, TMode>({
    abi: staleDepositLiquidatorABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"cancelTask"`.
 */
export function useStaleDepositLiquidatorCancelTask<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof staleDepositLiquidatorABI,
          'cancelTask'
        >['request']['abi'],
        'cancelTask',
        TMode
      > & { functionName?: 'cancelTask' }
    : UseContractWriteConfig<typeof staleDepositLiquidatorABI, 'cancelTask', TMode> & {
        abi?: never;
        functionName?: 'cancelTask';
      } = {} as any,
) {
  return useContractWrite<typeof staleDepositLiquidatorABI, 'cancelTask', TMode>({
    abi: staleDepositLiquidatorABI,
    functionName: 'cancelTask',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"createTask"`.
 */
export function useStaleDepositLiquidatorCreateTask<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof staleDepositLiquidatorABI,
          'createTask'
        >['request']['abi'],
        'createTask',
        TMode
      > & { functionName?: 'createTask' }
    : UseContractWriteConfig<typeof staleDepositLiquidatorABI, 'createTask', TMode> & {
        abi?: never;
        functionName?: 'createTask';
      } = {} as any,
) {
  return useContractWrite<typeof staleDepositLiquidatorABI, 'createTask', TMode>({
    abi: staleDepositLiquidatorABI,
    functionName: 'createTask',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"liquidateStaleDeposits"`.
 */
export function useStaleDepositLiquidatorLiquidateStaleDeposits<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof staleDepositLiquidatorABI,
          'liquidateStaleDeposits'
        >['request']['abi'],
        'liquidateStaleDeposits',
        TMode
      > & { functionName?: 'liquidateStaleDeposits' }
    : UseContractWriteConfig<
        typeof staleDepositLiquidatorABI,
        'liquidateStaleDeposits',
        TMode
      > & {
        abi?: never;
        functionName?: 'liquidateStaleDeposits';
      } = {} as any,
) {
  return useContractWrite<
    typeof staleDepositLiquidatorABI,
    'liquidateStaleDeposits',
    TMode
  >({
    abi: staleDepositLiquidatorABI,
    functionName: 'liquidateStaleDeposits',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useStaleDepositLiquidatorRenounceOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof staleDepositLiquidatorABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & { functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<
        typeof staleDepositLiquidatorABI,
        'renounceOwnership',
        TMode
      > & {
        abi?: never;
        functionName?: 'renounceOwnership';
      } = {} as any,
) {
  return useContractWrite<typeof staleDepositLiquidatorABI, 'renounceOwnership', TMode>({
    abi: staleDepositLiquidatorABI,
    functionName: 'renounceOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useStaleDepositLiquidatorTransferOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof staleDepositLiquidatorABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & { functionName?: 'transferOwnership' }
    : UseContractWriteConfig<
        typeof staleDepositLiquidatorABI,
        'transferOwnership',
        TMode
      > & {
        abi?: never;
        functionName?: 'transferOwnership';
      } = {} as any,
) {
  return useContractWrite<typeof staleDepositLiquidatorABI, 'transferOwnership', TMode>({
    abi: staleDepositLiquidatorABI,
    functionName: 'transferOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"withdrawFunds"`.
 */
export function useStaleDepositLiquidatorWithdrawFunds<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof staleDepositLiquidatorABI,
          'withdrawFunds'
        >['request']['abi'],
        'withdrawFunds',
        TMode
      > & { functionName?: 'withdrawFunds' }
    : UseContractWriteConfig<typeof staleDepositLiquidatorABI, 'withdrawFunds', TMode> & {
        abi?: never;
        functionName?: 'withdrawFunds';
      } = {} as any,
) {
  return useContractWrite<typeof staleDepositLiquidatorABI, 'withdrawFunds', TMode>({
    abi: staleDepositLiquidatorABI,
    functionName: 'withdrawFunds',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link staleDepositLiquidatorABI}__.
 */
export function usePrepareStaleDepositLiquidatorWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof staleDepositLiquidatorABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: staleDepositLiquidatorABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof staleDepositLiquidatorABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"cancelTask"`.
 */
export function usePrepareStaleDepositLiquidatorCancelTask(
  config: Omit<
    UsePrepareContractWriteConfig<typeof staleDepositLiquidatorABI, 'cancelTask'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: staleDepositLiquidatorABI,
    functionName: 'cancelTask',
    ...config,
  } as UsePrepareContractWriteConfig<typeof staleDepositLiquidatorABI, 'cancelTask'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"createTask"`.
 */
export function usePrepareStaleDepositLiquidatorCreateTask(
  config: Omit<
    UsePrepareContractWriteConfig<typeof staleDepositLiquidatorABI, 'createTask'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: staleDepositLiquidatorABI,
    functionName: 'createTask',
    ...config,
  } as UsePrepareContractWriteConfig<typeof staleDepositLiquidatorABI, 'createTask'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"liquidateStaleDeposits"`.
 */
export function usePrepareStaleDepositLiquidatorLiquidateStaleDeposits(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof staleDepositLiquidatorABI,
      'liquidateStaleDeposits'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: staleDepositLiquidatorABI,
    functionName: 'liquidateStaleDeposits',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof staleDepositLiquidatorABI,
    'liquidateStaleDeposits'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareStaleDepositLiquidatorRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof staleDepositLiquidatorABI, 'renounceOwnership'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: staleDepositLiquidatorABI,
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof staleDepositLiquidatorABI,
    'renounceOwnership'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareStaleDepositLiquidatorTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof staleDepositLiquidatorABI, 'transferOwnership'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: staleDepositLiquidatorABI,
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof staleDepositLiquidatorABI,
    'transferOwnership'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link staleDepositLiquidatorABI}__ and `functionName` set to `"withdrawFunds"`.
 */
export function usePrepareStaleDepositLiquidatorWithdrawFunds(
  config: Omit<
    UsePrepareContractWriteConfig<typeof staleDepositLiquidatorABI, 'withdrawFunds'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: staleDepositLiquidatorABI,
    functionName: 'withdrawFunds',
    ...config,
  } as UsePrepareContractWriteConfig<typeof staleDepositLiquidatorABI, 'withdrawFunds'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dividendTokenABI}__.
 */
export function useDividendTokenRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof dividendTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: dividendTokenABI, ...config } as UseContractReadConfig<
    typeof dividendTokenABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"DOMAIN_SEPARATOR"`.
 */
export function useDividendTokenDomainSeparator<
  TFunctionName extends 'DOMAIN_SEPARATOR',
  TSelectData = ReadContractResult<typeof dividendTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: dividendTokenABI,
    functionName: 'DOMAIN_SEPARATOR',
    ...config,
  } as UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"allowance"`.
 */
export function useDividendTokenAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof dividendTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: dividendTokenABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useDividendTokenBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof dividendTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: dividendTokenABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"checkpoints"`.
 */
export function useDividendTokenCheckpoints<
  TFunctionName extends 'checkpoints',
  TSelectData = ReadContractResult<typeof dividendTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: dividendTokenABI,
    functionName: 'checkpoints',
    ...config,
  } as UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"decimals"`.
 */
export function useDividendTokenDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof dividendTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: dividendTokenABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"delegates"`.
 */
export function useDividendTokenDelegates<
  TFunctionName extends 'delegates',
  TSelectData = ReadContractResult<typeof dividendTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: dividendTokenABI,
    functionName: 'delegates',
    ...config,
  } as UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"getPastTotalSupply"`.
 */
export function useDividendTokenGetPastTotalSupply<
  TFunctionName extends 'getPastTotalSupply',
  TSelectData = ReadContractResult<typeof dividendTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: dividendTokenABI,
    functionName: 'getPastTotalSupply',
    ...config,
  } as UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"getPastVotes"`.
 */
export function useDividendTokenGetPastVotes<
  TFunctionName extends 'getPastVotes',
  TSelectData = ReadContractResult<typeof dividendTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: dividendTokenABI,
    functionName: 'getPastVotes',
    ...config,
  } as UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"getVotes"`.
 */
export function useDividendTokenGetVotes<
  TFunctionName extends 'getVotes',
  TSelectData = ReadContractResult<typeof dividendTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: dividendTokenABI,
    functionName: 'getVotes',
    ...config,
  } as UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"name"`.
 */
export function useDividendTokenName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof dividendTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: dividendTokenABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"nonces"`.
 */
export function useDividendTokenNonces<
  TFunctionName extends 'nonces',
  TSelectData = ReadContractResult<typeof dividendTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: dividendTokenABI,
    functionName: 'nonces',
    ...config,
  } as UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"numCheckpoints"`.
 */
export function useDividendTokenNumCheckpoints<
  TFunctionName extends 'numCheckpoints',
  TSelectData = ReadContractResult<typeof dividendTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: dividendTokenABI,
    functionName: 'numCheckpoints',
    ...config,
  } as UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"symbol"`.
 */
export function useDividendTokenSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof dividendTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: dividendTokenABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useDividendTokenTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof dividendTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: dividendTokenABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof dividendTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dividendTokenABI}__.
 */
export function useDividendTokenWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof dividendTokenABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof dividendTokenABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof dividendTokenABI, TFunctionName, TMode>({
    abi: dividendTokenABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"approve"`.
 */
export function useDividendTokenApprove<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof dividendTokenABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof dividendTokenABI, 'approve', TMode> & {
        abi?: never;
        functionName?: 'approve';
      } = {} as any,
) {
  return useContractWrite<typeof dividendTokenABI, 'approve', TMode>({
    abi: dividendTokenABI,
    functionName: 'approve',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"burn"`.
 */
export function useDividendTokenBurn<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof dividendTokenABI, 'burn'>['request']['abi'],
        'burn',
        TMode
      > & { functionName?: 'burn' }
    : UseContractWriteConfig<typeof dividendTokenABI, 'burn', TMode> & {
        abi?: never;
        functionName?: 'burn';
      } = {} as any,
) {
  return useContractWrite<typeof dividendTokenABI, 'burn', TMode>({
    abi: dividendTokenABI,
    functionName: 'burn',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"burnFrom"`.
 */
export function useDividendTokenBurnFrom<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof dividendTokenABI, 'burnFrom'>['request']['abi'],
        'burnFrom',
        TMode
      > & { functionName?: 'burnFrom' }
    : UseContractWriteConfig<typeof dividendTokenABI, 'burnFrom', TMode> & {
        abi?: never;
        functionName?: 'burnFrom';
      } = {} as any,
) {
  return useContractWrite<typeof dividendTokenABI, 'burnFrom', TMode>({
    abi: dividendTokenABI,
    functionName: 'burnFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useDividendTokenDecreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dividendTokenABI,
          'decreaseAllowance'
        >['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<typeof dividendTokenABI, 'decreaseAllowance', TMode> & {
        abi?: never;
        functionName?: 'decreaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof dividendTokenABI, 'decreaseAllowance', TMode>({
    abi: dividendTokenABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"delegate"`.
 */
export function useDividendTokenDelegate<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof dividendTokenABI, 'delegate'>['request']['abi'],
        'delegate',
        TMode
      > & { functionName?: 'delegate' }
    : UseContractWriteConfig<typeof dividendTokenABI, 'delegate', TMode> & {
        abi?: never;
        functionName?: 'delegate';
      } = {} as any,
) {
  return useContractWrite<typeof dividendTokenABI, 'delegate', TMode>({
    abi: dividendTokenABI,
    functionName: 'delegate',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"delegateBySig"`.
 */
export function useDividendTokenDelegateBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dividendTokenABI,
          'delegateBySig'
        >['request']['abi'],
        'delegateBySig',
        TMode
      > & { functionName?: 'delegateBySig' }
    : UseContractWriteConfig<typeof dividendTokenABI, 'delegateBySig', TMode> & {
        abi?: never;
        functionName?: 'delegateBySig';
      } = {} as any,
) {
  return useContractWrite<typeof dividendTokenABI, 'delegateBySig', TMode>({
    abi: dividendTokenABI,
    functionName: 'delegateBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useDividendTokenIncreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dividendTokenABI,
          'increaseAllowance'
        >['request']['abi'],
        'increaseAllowance',
        TMode
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<typeof dividendTokenABI, 'increaseAllowance', TMode> & {
        abi?: never;
        functionName?: 'increaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof dividendTokenABI, 'increaseAllowance', TMode>({
    abi: dividendTokenABI,
    functionName: 'increaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"permit"`.
 */
export function useDividendTokenPermit<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof dividendTokenABI, 'permit'>['request']['abi'],
        'permit',
        TMode
      > & { functionName?: 'permit' }
    : UseContractWriteConfig<typeof dividendTokenABI, 'permit', TMode> & {
        abi?: never;
        functionName?: 'permit';
      } = {} as any,
) {
  return useContractWrite<typeof dividendTokenABI, 'permit', TMode>({
    abi: dividendTokenABI,
    functionName: 'permit',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"transfer"`.
 */
export function useDividendTokenTransfer<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof dividendTokenABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof dividendTokenABI, 'transfer', TMode> & {
        abi?: never;
        functionName?: 'transfer';
      } = {} as any,
) {
  return useContractWrite<typeof dividendTokenABI, 'transfer', TMode>({
    abi: dividendTokenABI,
    functionName: 'transfer',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useDividendTokenTransferFrom<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dividendTokenABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof dividendTokenABI, 'transferFrom', TMode> & {
        abi?: never;
        functionName?: 'transferFrom';
      } = {} as any,
) {
  return useContractWrite<typeof dividendTokenABI, 'transferFrom', TMode>({
    abi: dividendTokenABI,
    functionName: 'transferFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dividendTokenABI}__.
 */
export function usePrepareDividendTokenWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dividendTokenABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: dividendTokenABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof dividendTokenABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareDividendTokenApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dividendTokenABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: dividendTokenABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dividendTokenABI, 'approve'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"burn"`.
 */
export function usePrepareDividendTokenBurn(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dividendTokenABI, 'burn'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: dividendTokenABI,
    functionName: 'burn',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dividendTokenABI, 'burn'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"burnFrom"`.
 */
export function usePrepareDividendTokenBurnFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dividendTokenABI, 'burnFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: dividendTokenABI,
    functionName: 'burnFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dividendTokenABI, 'burnFrom'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareDividendTokenDecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dividendTokenABI, 'decreaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: dividendTokenABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dividendTokenABI, 'decreaseAllowance'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"delegate"`.
 */
export function usePrepareDividendTokenDelegate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dividendTokenABI, 'delegate'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: dividendTokenABI,
    functionName: 'delegate',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dividendTokenABI, 'delegate'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"delegateBySig"`.
 */
export function usePrepareDividendTokenDelegateBySig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dividendTokenABI, 'delegateBySig'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: dividendTokenABI,
    functionName: 'delegateBySig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dividendTokenABI, 'delegateBySig'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareDividendTokenIncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dividendTokenABI, 'increaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: dividendTokenABI,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dividendTokenABI, 'increaseAllowance'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"permit"`.
 */
export function usePrepareDividendTokenPermit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dividendTokenABI, 'permit'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: dividendTokenABI,
    functionName: 'permit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dividendTokenABI, 'permit'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareDividendTokenTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dividendTokenABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: dividendTokenABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dividendTokenABI, 'transfer'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dividendTokenABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareDividendTokenTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dividendTokenABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: dividendTokenABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dividendTokenABI, 'transferFrom'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintableTokenABI}__.
 */
export function useMintableTokenRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof mintableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: mintableTokenABI, ...config } as UseContractReadConfig<
    typeof mintableTokenABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"DEFAULT_ADMIN_ROLE"`.
 */
export function useMintableTokenDefaultAdminRole<
  TFunctionName extends 'DEFAULT_ADMIN_ROLE',
  TSelectData = ReadContractResult<typeof mintableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintableTokenABI,
    functionName: 'DEFAULT_ADMIN_ROLE',
    ...config,
  } as UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"DOMAIN_SEPARATOR"`.
 */
export function useMintableTokenDomainSeparator<
  TFunctionName extends 'DOMAIN_SEPARATOR',
  TSelectData = ReadContractResult<typeof mintableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintableTokenABI,
    functionName: 'DOMAIN_SEPARATOR',
    ...config,
  } as UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"MINTER_ROLE"`.
 */
export function useMintableTokenMinterRole<
  TFunctionName extends 'MINTER_ROLE',
  TSelectData = ReadContractResult<typeof mintableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintableTokenABI,
    functionName: 'MINTER_ROLE',
    ...config,
  } as UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"allowance"`.
 */
export function useMintableTokenAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof mintableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintableTokenABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useMintableTokenBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof mintableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintableTokenABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"decimals"`.
 */
export function useMintableTokenDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof mintableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintableTokenABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"getRoleAdmin"`.
 */
export function useMintableTokenGetRoleAdmin<
  TFunctionName extends 'getRoleAdmin',
  TSelectData = ReadContractResult<typeof mintableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintableTokenABI,
    functionName: 'getRoleAdmin',
    ...config,
  } as UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"hasRole"`.
 */
export function useMintableTokenHasRole<
  TFunctionName extends 'hasRole',
  TSelectData = ReadContractResult<typeof mintableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintableTokenABI,
    functionName: 'hasRole',
    ...config,
  } as UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"name"`.
 */
export function useMintableTokenName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof mintableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintableTokenABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"nonces"`.
 */
export function useMintableTokenNonces<
  TFunctionName extends 'nonces',
  TSelectData = ReadContractResult<typeof mintableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintableTokenABI,
    functionName: 'nonces',
    ...config,
  } as UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useMintableTokenSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof mintableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintableTokenABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"symbol"`.
 */
export function useMintableTokenSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof mintableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintableTokenABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useMintableTokenTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof mintableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mintableTokenABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof mintableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintableTokenABI}__.
 */
export function useMintableTokenWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mintableTokenABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof mintableTokenABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof mintableTokenABI, TFunctionName, TMode>({
    abi: mintableTokenABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"approve"`.
 */
export function useMintableTokenApprove<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mintableTokenABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof mintableTokenABI, 'approve', TMode> & {
        abi?: never;
        functionName?: 'approve';
      } = {} as any,
) {
  return useContractWrite<typeof mintableTokenABI, 'approve', TMode>({
    abi: mintableTokenABI,
    functionName: 'approve',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"burn"`.
 */
export function useMintableTokenBurn<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mintableTokenABI, 'burn'>['request']['abi'],
        'burn',
        TMode
      > & { functionName?: 'burn' }
    : UseContractWriteConfig<typeof mintableTokenABI, 'burn', TMode> & {
        abi?: never;
        functionName?: 'burn';
      } = {} as any,
) {
  return useContractWrite<typeof mintableTokenABI, 'burn', TMode>({
    abi: mintableTokenABI,
    functionName: 'burn',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"burnFrom"`.
 */
export function useMintableTokenBurnFrom<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mintableTokenABI, 'burnFrom'>['request']['abi'],
        'burnFrom',
        TMode
      > & { functionName?: 'burnFrom' }
    : UseContractWriteConfig<typeof mintableTokenABI, 'burnFrom', TMode> & {
        abi?: never;
        functionName?: 'burnFrom';
      } = {} as any,
) {
  return useContractWrite<typeof mintableTokenABI, 'burnFrom', TMode>({
    abi: mintableTokenABI,
    functionName: 'burnFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useMintableTokenDecreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof mintableTokenABI,
          'decreaseAllowance'
        >['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<typeof mintableTokenABI, 'decreaseAllowance', TMode> & {
        abi?: never;
        functionName?: 'decreaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof mintableTokenABI, 'decreaseAllowance', TMode>({
    abi: mintableTokenABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"grantRole"`.
 */
export function useMintableTokenGrantRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof mintableTokenABI,
          'grantRole'
        >['request']['abi'],
        'grantRole',
        TMode
      > & { functionName?: 'grantRole' }
    : UseContractWriteConfig<typeof mintableTokenABI, 'grantRole', TMode> & {
        abi?: never;
        functionName?: 'grantRole';
      } = {} as any,
) {
  return useContractWrite<typeof mintableTokenABI, 'grantRole', TMode>({
    abi: mintableTokenABI,
    functionName: 'grantRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useMintableTokenIncreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof mintableTokenABI,
          'increaseAllowance'
        >['request']['abi'],
        'increaseAllowance',
        TMode
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<typeof mintableTokenABI, 'increaseAllowance', TMode> & {
        abi?: never;
        functionName?: 'increaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof mintableTokenABI, 'increaseAllowance', TMode>({
    abi: mintableTokenABI,
    functionName: 'increaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"mint"`.
 */
export function useMintableTokenMint<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mintableTokenABI, 'mint'>['request']['abi'],
        'mint',
        TMode
      > & { functionName?: 'mint' }
    : UseContractWriteConfig<typeof mintableTokenABI, 'mint', TMode> & {
        abi?: never;
        functionName?: 'mint';
      } = {} as any,
) {
  return useContractWrite<typeof mintableTokenABI, 'mint', TMode>({
    abi: mintableTokenABI,
    functionName: 'mint',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"permit"`.
 */
export function useMintableTokenPermit<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mintableTokenABI, 'permit'>['request']['abi'],
        'permit',
        TMode
      > & { functionName?: 'permit' }
    : UseContractWriteConfig<typeof mintableTokenABI, 'permit', TMode> & {
        abi?: never;
        functionName?: 'permit';
      } = {} as any,
) {
  return useContractWrite<typeof mintableTokenABI, 'permit', TMode>({
    abi: mintableTokenABI,
    functionName: 'permit',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"renounceRole"`.
 */
export function useMintableTokenRenounceRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof mintableTokenABI,
          'renounceRole'
        >['request']['abi'],
        'renounceRole',
        TMode
      > & { functionName?: 'renounceRole' }
    : UseContractWriteConfig<typeof mintableTokenABI, 'renounceRole', TMode> & {
        abi?: never;
        functionName?: 'renounceRole';
      } = {} as any,
) {
  return useContractWrite<typeof mintableTokenABI, 'renounceRole', TMode>({
    abi: mintableTokenABI,
    functionName: 'renounceRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"revokeRole"`.
 */
export function useMintableTokenRevokeRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof mintableTokenABI,
          'revokeRole'
        >['request']['abi'],
        'revokeRole',
        TMode
      > & { functionName?: 'revokeRole' }
    : UseContractWriteConfig<typeof mintableTokenABI, 'revokeRole', TMode> & {
        abi?: never;
        functionName?: 'revokeRole';
      } = {} as any,
) {
  return useContractWrite<typeof mintableTokenABI, 'revokeRole', TMode>({
    abi: mintableTokenABI,
    functionName: 'revokeRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"transfer"`.
 */
export function useMintableTokenTransfer<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mintableTokenABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof mintableTokenABI, 'transfer', TMode> & {
        abi?: never;
        functionName?: 'transfer';
      } = {} as any,
) {
  return useContractWrite<typeof mintableTokenABI, 'transfer', TMode>({
    abi: mintableTokenABI,
    functionName: 'transfer',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useMintableTokenTransferFrom<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof mintableTokenABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof mintableTokenABI, 'transferFrom', TMode> & {
        abi?: never;
        functionName?: 'transferFrom';
      } = {} as any,
) {
  return useContractWrite<typeof mintableTokenABI, 'transferFrom', TMode>({
    abi: mintableTokenABI,
    functionName: 'transferFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintableTokenABI}__.
 */
export function usePrepareMintableTokenWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintableTokenABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintableTokenABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintableTokenABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareMintableTokenApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintableTokenABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintableTokenABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintableTokenABI, 'approve'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"burn"`.
 */
export function usePrepareMintableTokenBurn(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintableTokenABI, 'burn'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintableTokenABI,
    functionName: 'burn',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintableTokenABI, 'burn'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"burnFrom"`.
 */
export function usePrepareMintableTokenBurnFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintableTokenABI, 'burnFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintableTokenABI,
    functionName: 'burnFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintableTokenABI, 'burnFrom'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareMintableTokenDecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintableTokenABI, 'decreaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintableTokenABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintableTokenABI, 'decreaseAllowance'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"grantRole"`.
 */
export function usePrepareMintableTokenGrantRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintableTokenABI, 'grantRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintableTokenABI,
    functionName: 'grantRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintableTokenABI, 'grantRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareMintableTokenIncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintableTokenABI, 'increaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintableTokenABI,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintableTokenABI, 'increaseAllowance'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"mint"`.
 */
export function usePrepareMintableTokenMint(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintableTokenABI, 'mint'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintableTokenABI,
    functionName: 'mint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintableTokenABI, 'mint'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"permit"`.
 */
export function usePrepareMintableTokenPermit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintableTokenABI, 'permit'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintableTokenABI,
    functionName: 'permit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintableTokenABI, 'permit'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"renounceRole"`.
 */
export function usePrepareMintableTokenRenounceRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintableTokenABI, 'renounceRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintableTokenABI,
    functionName: 'renounceRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintableTokenABI, 'renounceRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"revokeRole"`.
 */
export function usePrepareMintableTokenRevokeRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintableTokenABI, 'revokeRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintableTokenABI,
    functionName: 'revokeRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintableTokenABI, 'revokeRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareMintableTokenTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintableTokenABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintableTokenABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintableTokenABI, 'transfer'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mintableTokenABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareMintableTokenTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mintableTokenABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mintableTokenABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mintableTokenABI, 'transferFrom'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mockUsdcABI}__.
 */
export function useMockUsdcRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof mockUsdcABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: mockUsdcABI, ...config } as UseContractReadConfig<
    typeof mockUsdcABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"DEFAULT_ADMIN_ROLE"`.
 */
export function useMockUsdcDefaultAdminRole<
  TFunctionName extends 'DEFAULT_ADMIN_ROLE',
  TSelectData = ReadContractResult<typeof mockUsdcABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mockUsdcABI,
    functionName: 'DEFAULT_ADMIN_ROLE',
    ...config,
  } as UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"DOMAIN_SEPARATOR"`.
 */
export function useMockUsdcDomainSeparator<
  TFunctionName extends 'DOMAIN_SEPARATOR',
  TSelectData = ReadContractResult<typeof mockUsdcABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mockUsdcABI,
    functionName: 'DOMAIN_SEPARATOR',
    ...config,
  } as UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"MINTER_ROLE"`.
 */
export function useMockUsdcMinterRole<
  TFunctionName extends 'MINTER_ROLE',
  TSelectData = ReadContractResult<typeof mockUsdcABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mockUsdcABI,
    functionName: 'MINTER_ROLE',
    ...config,
  } as UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"allowance"`.
 */
export function useMockUsdcAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof mockUsdcABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mockUsdcABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useMockUsdcBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof mockUsdcABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mockUsdcABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"decimals"`.
 */
export function useMockUsdcDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof mockUsdcABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mockUsdcABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"getRoleAdmin"`.
 */
export function useMockUsdcGetRoleAdmin<
  TFunctionName extends 'getRoleAdmin',
  TSelectData = ReadContractResult<typeof mockUsdcABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mockUsdcABI,
    functionName: 'getRoleAdmin',
    ...config,
  } as UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"hasRole"`.
 */
export function useMockUsdcHasRole<
  TFunctionName extends 'hasRole',
  TSelectData = ReadContractResult<typeof mockUsdcABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mockUsdcABI,
    functionName: 'hasRole',
    ...config,
  } as UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"name"`.
 */
export function useMockUsdcName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof mockUsdcABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mockUsdcABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"nonces"`.
 */
export function useMockUsdcNonces<
  TFunctionName extends 'nonces',
  TSelectData = ReadContractResult<typeof mockUsdcABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mockUsdcABI,
    functionName: 'nonces',
    ...config,
  } as UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useMockUsdcSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof mockUsdcABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mockUsdcABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"symbol"`.
 */
export function useMockUsdcSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof mockUsdcABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mockUsdcABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useMockUsdcTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof mockUsdcABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: mockUsdcABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof mockUsdcABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mockUsdcABI}__.
 */
export function useMockUsdcWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mockUsdcABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof mockUsdcABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof mockUsdcABI, TFunctionName, TMode>({
    abi: mockUsdcABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"approve"`.
 */
export function useMockUsdcApprove<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mockUsdcABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof mockUsdcABI, 'approve', TMode> & {
        abi?: never;
        functionName?: 'approve';
      } = {} as any,
) {
  return useContractWrite<typeof mockUsdcABI, 'approve', TMode>({
    abi: mockUsdcABI,
    functionName: 'approve',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"burn"`.
 */
export function useMockUsdcBurn<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mockUsdcABI, 'burn'>['request']['abi'],
        'burn',
        TMode
      > & { functionName?: 'burn' }
    : UseContractWriteConfig<typeof mockUsdcABI, 'burn', TMode> & {
        abi?: never;
        functionName?: 'burn';
      } = {} as any,
) {
  return useContractWrite<typeof mockUsdcABI, 'burn', TMode>({
    abi: mockUsdcABI,
    functionName: 'burn',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"burnFrom"`.
 */
export function useMockUsdcBurnFrom<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mockUsdcABI, 'burnFrom'>['request']['abi'],
        'burnFrom',
        TMode
      > & { functionName?: 'burnFrom' }
    : UseContractWriteConfig<typeof mockUsdcABI, 'burnFrom', TMode> & {
        abi?: never;
        functionName?: 'burnFrom';
      } = {} as any,
) {
  return useContractWrite<typeof mockUsdcABI, 'burnFrom', TMode>({
    abi: mockUsdcABI,
    functionName: 'burnFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useMockUsdcDecreaseAllowance<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof mockUsdcABI,
          'decreaseAllowance'
        >['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<typeof mockUsdcABI, 'decreaseAllowance', TMode> & {
        abi?: never;
        functionName?: 'decreaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof mockUsdcABI, 'decreaseAllowance', TMode>({
    abi: mockUsdcABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"grantRole"`.
 */
export function useMockUsdcGrantRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mockUsdcABI, 'grantRole'>['request']['abi'],
        'grantRole',
        TMode
      > & { functionName?: 'grantRole' }
    : UseContractWriteConfig<typeof mockUsdcABI, 'grantRole', TMode> & {
        abi?: never;
        functionName?: 'grantRole';
      } = {} as any,
) {
  return useContractWrite<typeof mockUsdcABI, 'grantRole', TMode>({
    abi: mockUsdcABI,
    functionName: 'grantRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useMockUsdcIncreaseAllowance<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof mockUsdcABI,
          'increaseAllowance'
        >['request']['abi'],
        'increaseAllowance',
        TMode
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<typeof mockUsdcABI, 'increaseAllowance', TMode> & {
        abi?: never;
        functionName?: 'increaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof mockUsdcABI, 'increaseAllowance', TMode>({
    abi: mockUsdcABI,
    functionName: 'increaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"mint"`.
 */
export function useMockUsdcMint<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mockUsdcABI, 'mint'>['request']['abi'],
        'mint',
        TMode
      > & { functionName?: 'mint' }
    : UseContractWriteConfig<typeof mockUsdcABI, 'mint', TMode> & {
        abi?: never;
        functionName?: 'mint';
      } = {} as any,
) {
  return useContractWrite<typeof mockUsdcABI, 'mint', TMode>({
    abi: mockUsdcABI,
    functionName: 'mint',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"permit"`.
 */
export function useMockUsdcPermit<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mockUsdcABI, 'permit'>['request']['abi'],
        'permit',
        TMode
      > & { functionName?: 'permit' }
    : UseContractWriteConfig<typeof mockUsdcABI, 'permit', TMode> & {
        abi?: never;
        functionName?: 'permit';
      } = {} as any,
) {
  return useContractWrite<typeof mockUsdcABI, 'permit', TMode>({
    abi: mockUsdcABI,
    functionName: 'permit',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"renounceRole"`.
 */
export function useMockUsdcRenounceRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mockUsdcABI, 'renounceRole'>['request']['abi'],
        'renounceRole',
        TMode
      > & { functionName?: 'renounceRole' }
    : UseContractWriteConfig<typeof mockUsdcABI, 'renounceRole', TMode> & {
        abi?: never;
        functionName?: 'renounceRole';
      } = {} as any,
) {
  return useContractWrite<typeof mockUsdcABI, 'renounceRole', TMode>({
    abi: mockUsdcABI,
    functionName: 'renounceRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"revokeRole"`.
 */
export function useMockUsdcRevokeRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mockUsdcABI, 'revokeRole'>['request']['abi'],
        'revokeRole',
        TMode
      > & { functionName?: 'revokeRole' }
    : UseContractWriteConfig<typeof mockUsdcABI, 'revokeRole', TMode> & {
        abi?: never;
        functionName?: 'revokeRole';
      } = {} as any,
) {
  return useContractWrite<typeof mockUsdcABI, 'revokeRole', TMode>({
    abi: mockUsdcABI,
    functionName: 'revokeRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"transfer"`.
 */
export function useMockUsdcTransfer<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mockUsdcABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof mockUsdcABI, 'transfer', TMode> & {
        abi?: never;
        functionName?: 'transfer';
      } = {} as any,
) {
  return useContractWrite<typeof mockUsdcABI, 'transfer', TMode>({
    abi: mockUsdcABI,
    functionName: 'transfer',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useMockUsdcTransferFrom<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof mockUsdcABI, 'transferFrom'>['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof mockUsdcABI, 'transferFrom', TMode> & {
        abi?: never;
        functionName?: 'transferFrom';
      } = {} as any,
) {
  return useContractWrite<typeof mockUsdcABI, 'transferFrom', TMode>({
    abi: mockUsdcABI,
    functionName: 'transferFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mockUsdcABI}__.
 */
export function usePrepareMockUsdcWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mockUsdcABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mockUsdcABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof mockUsdcABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareMockUsdcApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mockUsdcABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mockUsdcABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mockUsdcABI, 'approve'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"burn"`.
 */
export function usePrepareMockUsdcBurn(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mockUsdcABI, 'burn'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mockUsdcABI,
    functionName: 'burn',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mockUsdcABI, 'burn'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"burnFrom"`.
 */
export function usePrepareMockUsdcBurnFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mockUsdcABI, 'burnFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mockUsdcABI,
    functionName: 'burnFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mockUsdcABI, 'burnFrom'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareMockUsdcDecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mockUsdcABI, 'decreaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mockUsdcABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mockUsdcABI, 'decreaseAllowance'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"grantRole"`.
 */
export function usePrepareMockUsdcGrantRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mockUsdcABI, 'grantRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mockUsdcABI,
    functionName: 'grantRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mockUsdcABI, 'grantRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareMockUsdcIncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mockUsdcABI, 'increaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mockUsdcABI,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mockUsdcABI, 'increaseAllowance'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"mint"`.
 */
export function usePrepareMockUsdcMint(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mockUsdcABI, 'mint'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mockUsdcABI,
    functionName: 'mint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mockUsdcABI, 'mint'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"permit"`.
 */
export function usePrepareMockUsdcPermit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mockUsdcABI, 'permit'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mockUsdcABI,
    functionName: 'permit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mockUsdcABI, 'permit'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"renounceRole"`.
 */
export function usePrepareMockUsdcRenounceRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mockUsdcABI, 'renounceRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mockUsdcABI,
    functionName: 'renounceRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mockUsdcABI, 'renounceRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"revokeRole"`.
 */
export function usePrepareMockUsdcRevokeRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mockUsdcABI, 'revokeRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mockUsdcABI,
    functionName: 'revokeRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mockUsdcABI, 'revokeRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareMockUsdcTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mockUsdcABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mockUsdcABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mockUsdcABI, 'transfer'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link mockUsdcABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareMockUsdcTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof mockUsdcABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: mockUsdcABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof mockUsdcABI, 'transferFrom'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__.
 */
export function useMultiErc20WeightedLockerRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"DOMAIN_SEPARATOR"`.
 */
export function useMultiErc20WeightedLockerDomainSeparator<
  TFunctionName extends 'DOMAIN_SEPARATOR',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'DOMAIN_SEPARATOR',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"allowance"`.
 */
export function useMultiErc20WeightedLockerAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useMultiErc20WeightedLockerBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"currentDepositId"`.
 */
export function useMultiErc20WeightedLockerCurrentDepositId<
  TFunctionName extends 'currentDepositId',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'currentDepositId',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"decimals"`.
 */
export function useMultiErc20WeightedLockerDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"earnedReward"`.
 */
export function useMultiErc20WeightedLockerEarnedReward<
  TFunctionName extends 'earnedReward',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'earnedReward',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"getDeposit"`.
 */
export function useMultiErc20WeightedLockerGetDeposit<
  TFunctionName extends 'getDeposit',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'getDeposit',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"getDepositor"`.
 */
export function useMultiErc20WeightedLockerGetDepositor<
  TFunctionName extends 'getDepositor',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'getDepositor',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"getDepositorsCount"`.
 */
export function useMultiErc20WeightedLockerGetDepositorsCount<
  TFunctionName extends 'getDepositorsCount',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'getDepositorsCount',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"getLockableAsset"`.
 */
export function useMultiErc20WeightedLockerGetLockableAsset<
  TFunctionName extends 'getLockableAsset',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'getLockableAsset',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"getLockableAssetsCount"`.
 */
export function useMultiErc20WeightedLockerGetLockableAssetsCount<
  TFunctionName extends 'getLockableAssetsCount',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'getLockableAssetsCount',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"getStakingContract"`.
 */
export function useMultiErc20WeightedLockerGetStakingContract<
  TFunctionName extends 'getStakingContract',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'getStakingContract',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"getStakingContractCount"`.
 */
export function useMultiErc20WeightedLockerGetStakingContractCount<
  TFunctionName extends 'getStakingContractCount',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'getStakingContractCount',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"governanceToken"`.
 */
export function useMultiErc20WeightedLockerGovernanceToken<
  TFunctionName extends 'governanceToken',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'governanceToken',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"isDepositingDisabledForAsset"`.
 */
export function useMultiErc20WeightedLockerIsDepositingDisabledForAsset<
  TFunctionName extends 'isDepositingDisabledForAsset',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'isDepositingDisabledForAsset',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"isDepositor"`.
 */
export function useMultiErc20WeightedLockerIsDepositor<
  TFunctionName extends 'isDepositor',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'isDepositor',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"name"`.
 */
export function useMultiErc20WeightedLockerName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"nonces"`.
 */
export function useMultiErc20WeightedLockerNonces<
  TFunctionName extends 'nonces',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'nonces',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"owner"`.
 */
export function useMultiErc20WeightedLockerOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"slashedTokensAmount"`.
 */
export function useMultiErc20WeightedLockerSlashedTokensAmount<
  TFunctionName extends 'slashedTokensAmount',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'slashedTokensAmount',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"slashingPenaltyPercentage"`.
 */
export function useMultiErc20WeightedLockerSlashingPenaltyPercentage<
  TFunctionName extends 'slashingPenaltyPercentage',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'slashingPenaltyPercentage',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"stakingContracts"`.
 */
export function useMultiErc20WeightedLockerStakingContracts<
  TFunctionName extends 'stakingContracts',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'stakingContracts',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"symbol"`.
 */
export function useMultiErc20WeightedLockerSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useMultiErc20WeightedLockerTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"userDepositIds"`.
 */
export function useMultiErc20WeightedLockerUserDepositIds<
  TFunctionName extends 'userDepositIds',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'userDepositIds',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"userDeposits"`.
 */
export function useMultiErc20WeightedLockerUserDeposits<
  TFunctionName extends 'userDeposits',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'userDeposits',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"userDepositsCount"`.
 */
export function useMultiErc20WeightedLockerUserDepositsCount<
  TFunctionName extends 'userDepositsCount',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'userDepositsCount',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"userLockedAssetAmount"`.
 */
export function useMultiErc20WeightedLockerUserLockedAssetAmount<
  TFunctionName extends 'userLockedAssetAmount',
  TSelectData = ReadContractResult<typeof multiErc20WeightedLockerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: multiErc20WeightedLockerABI,
    functionName: 'userLockedAssetAmount',
    ...config,
  } as UseContractReadConfig<
    typeof multiErc20WeightedLockerABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__.
 */
export function useMultiErc20WeightedLockerWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof multiErc20WeightedLockerABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, TFunctionName, TMode>({
    abi: multiErc20WeightedLockerABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"addLockableAsset"`.
 */
export function useMultiErc20WeightedLockerAddLockableAsset<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'addLockableAsset'
        >['request']['abi'],
        'addLockableAsset',
        TMode
      > & { functionName?: 'addLockableAsset' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'addLockableAsset',
        TMode
      > & {
        abi?: never;
        functionName?: 'addLockableAsset';
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, 'addLockableAsset', TMode>({
    abi: multiErc20WeightedLockerABI,
    functionName: 'addLockableAsset',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"addStakingContract"`.
 */
export function useMultiErc20WeightedLockerAddStakingContract<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'addStakingContract'
        >['request']['abi'],
        'addStakingContract',
        TMode
      > & { functionName?: 'addStakingContract' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'addStakingContract',
        TMode
      > & {
        abi?: never;
        functionName?: 'addStakingContract';
      } = {} as any,
) {
  return useContractWrite<
    typeof multiErc20WeightedLockerABI,
    'addStakingContract',
    TMode
  >({
    abi: multiErc20WeightedLockerABI,
    functionName: 'addStakingContract',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"approve"`.
 */
export function useMultiErc20WeightedLockerApprove<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'approve'
        >['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof multiErc20WeightedLockerABI, 'approve', TMode> & {
        abi?: never;
        functionName?: 'approve';
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, 'approve', TMode>({
    abi: multiErc20WeightedLockerABI,
    functionName: 'approve',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"burn"`.
 */
export function useMultiErc20WeightedLockerBurn<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'burn'
        >['request']['abi'],
        'burn',
        TMode
      > & { functionName?: 'burn' }
    : UseContractWriteConfig<typeof multiErc20WeightedLockerABI, 'burn', TMode> & {
        abi?: never;
        functionName?: 'burn';
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, 'burn', TMode>({
    abi: multiErc20WeightedLockerABI,
    functionName: 'burn',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"burnFrom"`.
 */
export function useMultiErc20WeightedLockerBurnFrom<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'burnFrom'
        >['request']['abi'],
        'burnFrom',
        TMode
      > & { functionName?: 'burnFrom' }
    : UseContractWriteConfig<typeof multiErc20WeightedLockerABI, 'burnFrom', TMode> & {
        abi?: never;
        functionName?: 'burnFrom';
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, 'burnFrom', TMode>({
    abi: multiErc20WeightedLockerABI,
    functionName: 'burnFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"collectRewards"`.
 */
export function useMultiErc20WeightedLockerCollectRewards<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'collectRewards'
        >['request']['abi'],
        'collectRewards',
        TMode
      > & { functionName?: 'collectRewards' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'collectRewards',
        TMode
      > & {
        abi?: never;
        functionName?: 'collectRewards';
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, 'collectRewards', TMode>({
    abi: multiErc20WeightedLockerABI,
    functionName: 'collectRewards',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useMultiErc20WeightedLockerDecreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'decreaseAllowance'
        >['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'decreaseAllowance',
        TMode
      > & {
        abi?: never;
        functionName?: 'decreaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, 'decreaseAllowance', TMode>(
    {
      abi: multiErc20WeightedLockerABI,
      functionName: 'decreaseAllowance',
      ...config,
    } as any,
  );
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"disableDepositsForAsset"`.
 */
export function useMultiErc20WeightedLockerDisableDepositsForAsset<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'disableDepositsForAsset'
        >['request']['abi'],
        'disableDepositsForAsset',
        TMode
      > & { functionName?: 'disableDepositsForAsset' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'disableDepositsForAsset',
        TMode
      > & {
        abi?: never;
        functionName?: 'disableDepositsForAsset';
      } = {} as any,
) {
  return useContractWrite<
    typeof multiErc20WeightedLockerABI,
    'disableDepositsForAsset',
    TMode
  >({
    abi: multiErc20WeightedLockerABI,
    functionName: 'disableDepositsForAsset',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"emergencyWithdraw"`.
 */
export function useMultiErc20WeightedLockerEmergencyWithdraw<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'emergencyWithdraw'
        >['request']['abi'],
        'emergencyWithdraw',
        TMode
      > & { functionName?: 'emergencyWithdraw' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'emergencyWithdraw',
        TMode
      > & {
        abi?: never;
        functionName?: 'emergencyWithdraw';
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, 'emergencyWithdraw', TMode>(
    {
      abi: multiErc20WeightedLockerABI,
      functionName: 'emergencyWithdraw',
      ...config,
    } as any,
  );
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"enableDepositsForAsset"`.
 */
export function useMultiErc20WeightedLockerEnableDepositsForAsset<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'enableDepositsForAsset'
        >['request']['abi'],
        'enableDepositsForAsset',
        TMode
      > & { functionName?: 'enableDepositsForAsset' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'enableDepositsForAsset',
        TMode
      > & {
        abi?: never;
        functionName?: 'enableDepositsForAsset';
      } = {} as any,
) {
  return useContractWrite<
    typeof multiErc20WeightedLockerABI,
    'enableDepositsForAsset',
    TMode
  >({
    abi: multiErc20WeightedLockerABI,
    functionName: 'enableDepositsForAsset',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useMultiErc20WeightedLockerIncreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'increaseAllowance'
        >['request']['abi'],
        'increaseAllowance',
        TMode
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'increaseAllowance',
        TMode
      > & {
        abi?: never;
        functionName?: 'increaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, 'increaseAllowance', TMode>(
    {
      abi: multiErc20WeightedLockerABI,
      functionName: 'increaseAllowance',
      ...config,
    } as any,
  );
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"liquidateStaleDeposit"`.
 */
export function useMultiErc20WeightedLockerLiquidateStaleDeposit<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'liquidateStaleDeposit'
        >['request']['abi'],
        'liquidateStaleDeposit',
        TMode
      > & { functionName?: 'liquidateStaleDeposit' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'liquidateStaleDeposit',
        TMode
      > & {
        abi?: never;
        functionName?: 'liquidateStaleDeposit';
      } = {} as any,
) {
  return useContractWrite<
    typeof multiErc20WeightedLockerABI,
    'liquidateStaleDeposit',
    TMode
  >({
    abi: multiErc20WeightedLockerABI,
    functionName: 'liquidateStaleDeposit',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"permit"`.
 */
export function useMultiErc20WeightedLockerPermit<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'permit'
        >['request']['abi'],
        'permit',
        TMode
      > & { functionName?: 'permit' }
    : UseContractWriteConfig<typeof multiErc20WeightedLockerABI, 'permit', TMode> & {
        abi?: never;
        functionName?: 'permit';
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, 'permit', TMode>({
    abi: multiErc20WeightedLockerABI,
    functionName: 'permit',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useMultiErc20WeightedLockerRenounceOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & { functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'renounceOwnership',
        TMode
      > & {
        abi?: never;
        functionName?: 'renounceOwnership';
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, 'renounceOwnership', TMode>(
    {
      abi: multiErc20WeightedLockerABI,
      functionName: 'renounceOwnership',
      ...config,
    } as any,
  );
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"setSlashingPenaltyPercentage"`.
 */
export function useMultiErc20WeightedLockerSetSlashingPenaltyPercentage<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'setSlashingPenaltyPercentage'
        >['request']['abi'],
        'setSlashingPenaltyPercentage',
        TMode
      > & { functionName?: 'setSlashingPenaltyPercentage' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'setSlashingPenaltyPercentage',
        TMode
      > & {
        abi?: never;
        functionName?: 'setSlashingPenaltyPercentage';
      } = {} as any,
) {
  return useContractWrite<
    typeof multiErc20WeightedLockerABI,
    'setSlashingPenaltyPercentage',
    TMode
  >({
    abi: multiErc20WeightedLockerABI,
    functionName: 'setSlashingPenaltyPercentage',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"stake"`.
 */
export function useMultiErc20WeightedLockerStake<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'stake'
        >['request']['abi'],
        'stake',
        TMode
      > & { functionName?: 'stake' }
    : UseContractWriteConfig<typeof multiErc20WeightedLockerABI, 'stake', TMode> & {
        abi?: never;
        functionName?: 'stake';
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, 'stake', TMode>({
    abi: multiErc20WeightedLockerABI,
    functionName: 'stake',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"stakeWithPermit"`.
 */
export function useMultiErc20WeightedLockerStakeWithPermit<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'stakeWithPermit'
        >['request']['abi'],
        'stakeWithPermit',
        TMode
      > & { functionName?: 'stakeWithPermit' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'stakeWithPermit',
        TMode
      > & {
        abi?: never;
        functionName?: 'stakeWithPermit';
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, 'stakeWithPermit', TMode>({
    abi: multiErc20WeightedLockerABI,
    functionName: 'stakeWithPermit',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"transfer"`.
 */
export function useMultiErc20WeightedLockerTransfer<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'transfer'
        >['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof multiErc20WeightedLockerABI, 'transfer', TMode> & {
        abi?: never;
        functionName?: 'transfer';
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, 'transfer', TMode>({
    abi: multiErc20WeightedLockerABI,
    functionName: 'transfer',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useMultiErc20WeightedLockerTransferFrom<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'transferFrom',
        TMode
      > & {
        abi?: never;
        functionName?: 'transferFrom';
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, 'transferFrom', TMode>({
    abi: multiErc20WeightedLockerABI,
    functionName: 'transferFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useMultiErc20WeightedLockerTransferOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & { functionName?: 'transferOwnership' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'transferOwnership',
        TMode
      > & {
        abi?: never;
        functionName?: 'transferOwnership';
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, 'transferOwnership', TMode>(
    {
      abi: multiErc20WeightedLockerABI,
      functionName: 'transferOwnership',
      ...config,
    } as any,
  );
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"updateLockableAsset"`.
 */
export function useMultiErc20WeightedLockerUpdateLockableAsset<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'updateLockableAsset'
        >['request']['abi'],
        'updateLockableAsset',
        TMode
      > & { functionName?: 'updateLockableAsset' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'updateLockableAsset',
        TMode
      > & {
        abi?: never;
        functionName?: 'updateLockableAsset';
      } = {} as any,
) {
  return useContractWrite<
    typeof multiErc20WeightedLockerABI,
    'updateLockableAsset',
    TMode
  >({
    abi: multiErc20WeightedLockerABI,
    functionName: 'updateLockableAsset',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"withdraw"`.
 */
export function useMultiErc20WeightedLockerWithdraw<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'withdraw'
        >['request']['abi'],
        'withdraw',
        TMode
      > & { functionName?: 'withdraw' }
    : UseContractWriteConfig<typeof multiErc20WeightedLockerABI, 'withdraw', TMode> & {
        abi?: never;
        functionName?: 'withdraw';
      } = {} as any,
) {
  return useContractWrite<typeof multiErc20WeightedLockerABI, 'withdraw', TMode>({
    abi: multiErc20WeightedLockerABI,
    functionName: 'withdraw',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"withdrawSlashedTokens"`.
 */
export function useMultiErc20WeightedLockerWithdrawSlashedTokens<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'withdrawSlashedTokens'
        >['request']['abi'],
        'withdrawSlashedTokens',
        TMode
      > & { functionName?: 'withdrawSlashedTokens' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'withdrawSlashedTokens',
        TMode
      > & {
        abi?: never;
        functionName?: 'withdrawSlashedTokens';
      } = {} as any,
) {
  return useContractWrite<
    typeof multiErc20WeightedLockerABI,
    'withdrawSlashedTokens',
    TMode
  >({
    abi: multiErc20WeightedLockerABI,
    functionName: 'withdrawSlashedTokens',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"withdrawStakeAndReward"`.
 */
export function useMultiErc20WeightedLockerWithdrawStakeAndReward<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof multiErc20WeightedLockerABI,
          'withdrawStakeAndReward'
        >['request']['abi'],
        'withdrawStakeAndReward',
        TMode
      > & { functionName?: 'withdrawStakeAndReward' }
    : UseContractWriteConfig<
        typeof multiErc20WeightedLockerABI,
        'withdrawStakeAndReward',
        TMode
      > & {
        abi?: never;
        functionName?: 'withdrawStakeAndReward';
      } = {} as any,
) {
  return useContractWrite<
    typeof multiErc20WeightedLockerABI,
    'withdrawStakeAndReward',
    TMode
  >({
    abi: multiErc20WeightedLockerABI,
    functionName: 'withdrawStakeAndReward',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__.
 */
export function usePrepareMultiErc20WeightedLockerWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"addLockableAsset"`.
 */
export function usePrepareMultiErc20WeightedLockerAddLockableAsset(
  config: Omit<
    UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'addLockableAsset'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'addLockableAsset',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof multiErc20WeightedLockerABI,
    'addLockableAsset'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"addStakingContract"`.
 */
export function usePrepareMultiErc20WeightedLockerAddStakingContract(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof multiErc20WeightedLockerABI,
      'addStakingContract'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'addStakingContract',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof multiErc20WeightedLockerABI,
    'addStakingContract'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareMultiErc20WeightedLockerApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'approve'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"burn"`.
 */
export function usePrepareMultiErc20WeightedLockerBurn(
  config: Omit<
    UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'burn'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'burn',
    ...config,
  } as UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'burn'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"burnFrom"`.
 */
export function usePrepareMultiErc20WeightedLockerBurnFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'burnFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'burnFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'burnFrom'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"collectRewards"`.
 */
export function usePrepareMultiErc20WeightedLockerCollectRewards(
  config: Omit<
    UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'collectRewards'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'collectRewards',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof multiErc20WeightedLockerABI,
    'collectRewards'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareMultiErc20WeightedLockerDecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof multiErc20WeightedLockerABI,
      'decreaseAllowance'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof multiErc20WeightedLockerABI,
    'decreaseAllowance'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"disableDepositsForAsset"`.
 */
export function usePrepareMultiErc20WeightedLockerDisableDepositsForAsset(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof multiErc20WeightedLockerABI,
      'disableDepositsForAsset'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'disableDepositsForAsset',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof multiErc20WeightedLockerABI,
    'disableDepositsForAsset'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"emergencyWithdraw"`.
 */
export function usePrepareMultiErc20WeightedLockerEmergencyWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof multiErc20WeightedLockerABI,
      'emergencyWithdraw'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'emergencyWithdraw',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof multiErc20WeightedLockerABI,
    'emergencyWithdraw'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"enableDepositsForAsset"`.
 */
export function usePrepareMultiErc20WeightedLockerEnableDepositsForAsset(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof multiErc20WeightedLockerABI,
      'enableDepositsForAsset'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'enableDepositsForAsset',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof multiErc20WeightedLockerABI,
    'enableDepositsForAsset'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareMultiErc20WeightedLockerIncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof multiErc20WeightedLockerABI,
      'increaseAllowance'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof multiErc20WeightedLockerABI,
    'increaseAllowance'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"liquidateStaleDeposit"`.
 */
export function usePrepareMultiErc20WeightedLockerLiquidateStaleDeposit(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof multiErc20WeightedLockerABI,
      'liquidateStaleDeposit'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'liquidateStaleDeposit',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof multiErc20WeightedLockerABI,
    'liquidateStaleDeposit'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"permit"`.
 */
export function usePrepareMultiErc20WeightedLockerPermit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'permit'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'permit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'permit'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareMultiErc20WeightedLockerRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof multiErc20WeightedLockerABI,
      'renounceOwnership'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof multiErc20WeightedLockerABI,
    'renounceOwnership'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"setSlashingPenaltyPercentage"`.
 */
export function usePrepareMultiErc20WeightedLockerSetSlashingPenaltyPercentage(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof multiErc20WeightedLockerABI,
      'setSlashingPenaltyPercentage'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'setSlashingPenaltyPercentage',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof multiErc20WeightedLockerABI,
    'setSlashingPenaltyPercentage'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"stake"`.
 */
export function usePrepareMultiErc20WeightedLockerStake(
  config: Omit<
    UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'stake'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'stake',
    ...config,
  } as UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'stake'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"stakeWithPermit"`.
 */
export function usePrepareMultiErc20WeightedLockerStakeWithPermit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'stakeWithPermit'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'stakeWithPermit',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof multiErc20WeightedLockerABI,
    'stakeWithPermit'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareMultiErc20WeightedLockerTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'transfer'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareMultiErc20WeightedLockerTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'transferFrom'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareMultiErc20WeightedLockerTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof multiErc20WeightedLockerABI,
      'transferOwnership'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof multiErc20WeightedLockerABI,
    'transferOwnership'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"updateLockableAsset"`.
 */
export function usePrepareMultiErc20WeightedLockerUpdateLockableAsset(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof multiErc20WeightedLockerABI,
      'updateLockableAsset'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'updateLockableAsset',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof multiErc20WeightedLockerABI,
    'updateLockableAsset'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"withdraw"`.
 */
export function usePrepareMultiErc20WeightedLockerWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'withdraw'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'withdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof multiErc20WeightedLockerABI, 'withdraw'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"withdrawSlashedTokens"`.
 */
export function usePrepareMultiErc20WeightedLockerWithdrawSlashedTokens(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof multiErc20WeightedLockerABI,
      'withdrawSlashedTokens'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'withdrawSlashedTokens',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof multiErc20WeightedLockerABI,
    'withdrawSlashedTokens'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link multiErc20WeightedLockerABI}__ and `functionName` set to `"withdrawStakeAndReward"`.
 */
export function usePrepareMultiErc20WeightedLockerWithdrawStakeAndReward(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof multiErc20WeightedLockerABI,
      'withdrawStakeAndReward'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: multiErc20WeightedLockerABI,
    functionName: 'withdrawStakeAndReward',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof multiErc20WeightedLockerABI,
    'withdrawStakeAndReward'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link nonTransferableTokenABI}__.
 */
export function useNonTransferableTokenRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof nonTransferableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof nonTransferableTokenABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: nonTransferableTokenABI,
    ...config,
  } as UseContractReadConfig<typeof nonTransferableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link nonTransferableTokenABI}__ and `functionName` set to `"allowance"`.
 */
export function useNonTransferableTokenAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof nonTransferableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof nonTransferableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: nonTransferableTokenABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof nonTransferableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link nonTransferableTokenABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useNonTransferableTokenBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof nonTransferableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof nonTransferableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: nonTransferableTokenABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof nonTransferableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link nonTransferableTokenABI}__ and `functionName` set to `"decimals"`.
 */
export function useNonTransferableTokenDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof nonTransferableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof nonTransferableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: nonTransferableTokenABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof nonTransferableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link nonTransferableTokenABI}__ and `functionName` set to `"name"`.
 */
export function useNonTransferableTokenName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof nonTransferableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof nonTransferableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: nonTransferableTokenABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof nonTransferableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link nonTransferableTokenABI}__ and `functionName` set to `"symbol"`.
 */
export function useNonTransferableTokenSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof nonTransferableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof nonTransferableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: nonTransferableTokenABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof nonTransferableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link nonTransferableTokenABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useNonTransferableTokenTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof nonTransferableTokenABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof nonTransferableTokenABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: nonTransferableTokenABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof nonTransferableTokenABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link nonTransferableTokenABI}__.
 */
export function useNonTransferableTokenWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof nonTransferableTokenABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof nonTransferableTokenABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof nonTransferableTokenABI, TFunctionName, TMode>({
    abi: nonTransferableTokenABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link nonTransferableTokenABI}__ and `functionName` set to `"approve"`.
 */
export function useNonTransferableTokenApprove<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof nonTransferableTokenABI,
          'approve'
        >['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof nonTransferableTokenABI, 'approve', TMode> & {
        abi?: never;
        functionName?: 'approve';
      } = {} as any,
) {
  return useContractWrite<typeof nonTransferableTokenABI, 'approve', TMode>({
    abi: nonTransferableTokenABI,
    functionName: 'approve',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link nonTransferableTokenABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useNonTransferableTokenDecreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof nonTransferableTokenABI,
          'decreaseAllowance'
        >['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<
        typeof nonTransferableTokenABI,
        'decreaseAllowance',
        TMode
      > & {
        abi?: never;
        functionName?: 'decreaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof nonTransferableTokenABI, 'decreaseAllowance', TMode>({
    abi: nonTransferableTokenABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link nonTransferableTokenABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useNonTransferableTokenIncreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof nonTransferableTokenABI,
          'increaseAllowance'
        >['request']['abi'],
        'increaseAllowance',
        TMode
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<
        typeof nonTransferableTokenABI,
        'increaseAllowance',
        TMode
      > & {
        abi?: never;
        functionName?: 'increaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof nonTransferableTokenABI, 'increaseAllowance', TMode>({
    abi: nonTransferableTokenABI,
    functionName: 'increaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link nonTransferableTokenABI}__ and `functionName` set to `"transfer"`.
 */
export function useNonTransferableTokenTransfer<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof nonTransferableTokenABI,
          'transfer'
        >['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof nonTransferableTokenABI, 'transfer', TMode> & {
        abi?: never;
        functionName?: 'transfer';
      } = {} as any,
) {
  return useContractWrite<typeof nonTransferableTokenABI, 'transfer', TMode>({
    abi: nonTransferableTokenABI,
    functionName: 'transfer',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link nonTransferableTokenABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useNonTransferableTokenTransferFrom<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof nonTransferableTokenABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof nonTransferableTokenABI, 'transferFrom', TMode> & {
        abi?: never;
        functionName?: 'transferFrom';
      } = {} as any,
) {
  return useContractWrite<typeof nonTransferableTokenABI, 'transferFrom', TMode>({
    abi: nonTransferableTokenABI,
    functionName: 'transferFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link nonTransferableTokenABI}__.
 */
export function usePrepareNonTransferableTokenWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof nonTransferableTokenABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: nonTransferableTokenABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof nonTransferableTokenABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link nonTransferableTokenABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareNonTransferableTokenApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof nonTransferableTokenABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: nonTransferableTokenABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof nonTransferableTokenABI, 'approve'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link nonTransferableTokenABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareNonTransferableTokenDecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof nonTransferableTokenABI, 'decreaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: nonTransferableTokenABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof nonTransferableTokenABI,
    'decreaseAllowance'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link nonTransferableTokenABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareNonTransferableTokenIncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof nonTransferableTokenABI, 'increaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: nonTransferableTokenABI,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof nonTransferableTokenABI,
    'increaseAllowance'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link nonTransferableTokenABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareNonTransferableTokenTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof nonTransferableTokenABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: nonTransferableTokenABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof nonTransferableTokenABI, 'transfer'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link nonTransferableTokenABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareNonTransferableTokenTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof nonTransferableTokenABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: nonTransferableTokenABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof nonTransferableTokenABI, 'transferFrom'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link basisPointNumberMathABI}__.
 */
export function useBasisPointNumberMathRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof basisPointNumberMathABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof basisPointNumberMathABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: basisPointNumberMathABI,
    ...config,
  } as UseContractReadConfig<typeof basisPointNumberMathABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link basisPointNumberMathABI}__ and `functionName` set to `"BASIS_POINT"`.
 */
export function useBasisPointNumberMathBasisPoint<
  TFunctionName extends 'BASIS_POINT',
  TSelectData = ReadContractResult<typeof basisPointNumberMathABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof basisPointNumberMathABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: basisPointNumberMathABI,
    functionName: 'BASIS_POINT',
    ...config,
  } as UseContractReadConfig<typeof basisPointNumberMathABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stepVestingWalletABI}__.
 */
export function useStepVestingWalletRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof stepVestingWalletABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: stepVestingWalletABI,
    ...config,
  } as UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"CLIFF_DURATION"`.
 */
export function useStepVestingWalletCliffDuration<
  TFunctionName extends 'CLIFF_DURATION',
  TSelectData = ReadContractResult<typeof stepVestingWalletABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stepVestingWalletABI,
    functionName: 'CLIFF_DURATION',
    ...config,
  } as UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"PERIOD_DURATION"`.
 */
export function useStepVestingWalletPeriodDuration<
  TFunctionName extends 'PERIOD_DURATION',
  TSelectData = ReadContractResult<typeof stepVestingWalletABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stepVestingWalletABI,
    functionName: 'PERIOD_DURATION',
    ...config,
  } as UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"STEPS_COUNT"`.
 */
export function useStepVestingWalletStepsCount<
  TFunctionName extends 'STEPS_COUNT',
  TSelectData = ReadContractResult<typeof stepVestingWalletABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stepVestingWalletABI,
    functionName: 'STEPS_COUNT',
    ...config,
  } as UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"VESTED_TOKEN"`.
 */
export function useStepVestingWalletVestedToken<
  TFunctionName extends 'VESTED_TOKEN',
  TSelectData = ReadContractResult<typeof stepVestingWalletABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stepVestingWalletABI,
    functionName: 'VESTED_TOKEN',
    ...config,
  } as UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"getAllTimelocks"`.
 */
export function useStepVestingWalletGetAllTimelocks<
  TFunctionName extends 'getAllTimelocks',
  TSelectData = ReadContractResult<typeof stepVestingWalletABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stepVestingWalletABI,
    functionName: 'getAllTimelocks',
    ...config,
  } as UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"getBeneficiaries"`.
 */
export function useStepVestingWalletGetBeneficiaries<
  TFunctionName extends 'getBeneficiaries',
  TSelectData = ReadContractResult<typeof stepVestingWalletABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stepVestingWalletABI,
    functionName: 'getBeneficiaries',
    ...config,
  } as UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"getBeneficiaryTimelocks"`.
 */
export function useStepVestingWalletGetBeneficiaryTimelocks<
  TFunctionName extends 'getBeneficiaryTimelocks',
  TSelectData = ReadContractResult<typeof stepVestingWalletABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stepVestingWalletABI,
    functionName: 'getBeneficiaryTimelocks',
    ...config,
  } as UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"isBeneficiary"`.
 */
export function useStepVestingWalletIsBeneficiary<
  TFunctionName extends 'isBeneficiary',
  TSelectData = ReadContractResult<typeof stepVestingWalletABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stepVestingWalletABI,
    functionName: 'isBeneficiary',
    ...config,
  } as UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"owner"`.
 */
export function useStepVestingWalletOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof stepVestingWalletABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: stepVestingWalletABI,
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<typeof stepVestingWalletABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link stepVestingWalletABI}__.
 */
export function useStepVestingWalletWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof stepVestingWalletABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof stepVestingWalletABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof stepVestingWalletABI, TFunctionName, TMode>({
    abi: stepVestingWalletABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"addBeneficiary"`.
 */
export function useStepVestingWalletAddBeneficiary<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof stepVestingWalletABI,
          'addBeneficiary'
        >['request']['abi'],
        'addBeneficiary',
        TMode
      > & { functionName?: 'addBeneficiary' }
    : UseContractWriteConfig<typeof stepVestingWalletABI, 'addBeneficiary', TMode> & {
        abi?: never;
        functionName?: 'addBeneficiary';
      } = {} as any,
) {
  return useContractWrite<typeof stepVestingWalletABI, 'addBeneficiary', TMode>({
    abi: stepVestingWalletABI,
    functionName: 'addBeneficiary',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"batchAddBeneficiary"`.
 */
export function useStepVestingWalletBatchAddBeneficiary<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof stepVestingWalletABI,
          'batchAddBeneficiary'
        >['request']['abi'],
        'batchAddBeneficiary',
        TMode
      > & { functionName?: 'batchAddBeneficiary' }
    : UseContractWriteConfig<
        typeof stepVestingWalletABI,
        'batchAddBeneficiary',
        TMode
      > & {
        abi?: never;
        functionName?: 'batchAddBeneficiary';
      } = {} as any,
) {
  return useContractWrite<typeof stepVestingWalletABI, 'batchAddBeneficiary', TMode>({
    abi: stepVestingWalletABI,
    functionName: 'batchAddBeneficiary',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useStepVestingWalletRenounceOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof stepVestingWalletABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & { functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<typeof stepVestingWalletABI, 'renounceOwnership', TMode> & {
        abi?: never;
        functionName?: 'renounceOwnership';
      } = {} as any,
) {
  return useContractWrite<typeof stepVestingWalletABI, 'renounceOwnership', TMode>({
    abi: stepVestingWalletABI,
    functionName: 'renounceOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useStepVestingWalletTransferOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof stepVestingWalletABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & { functionName?: 'transferOwnership' }
    : UseContractWriteConfig<typeof stepVestingWalletABI, 'transferOwnership', TMode> & {
        abi?: never;
        functionName?: 'transferOwnership';
      } = {} as any,
) {
  return useContractWrite<typeof stepVestingWalletABI, 'transferOwnership', TMode>({
    abi: stepVestingWalletABI,
    functionName: 'transferOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"withdrawTokens"`.
 */
export function useStepVestingWalletWithdrawTokens<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof stepVestingWalletABI,
          'withdrawTokens'
        >['request']['abi'],
        'withdrawTokens',
        TMode
      > & { functionName?: 'withdrawTokens' }
    : UseContractWriteConfig<typeof stepVestingWalletABI, 'withdrawTokens', TMode> & {
        abi?: never;
        functionName?: 'withdrawTokens';
      } = {} as any,
) {
  return useContractWrite<typeof stepVestingWalletABI, 'withdrawTokens', TMode>({
    abi: stepVestingWalletABI,
    functionName: 'withdrawTokens',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link stepVestingWalletABI}__.
 */
export function usePrepareStepVestingWalletWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof stepVestingWalletABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: stepVestingWalletABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof stepVestingWalletABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"addBeneficiary"`.
 */
export function usePrepareStepVestingWalletAddBeneficiary(
  config: Omit<
    UsePrepareContractWriteConfig<typeof stepVestingWalletABI, 'addBeneficiary'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: stepVestingWalletABI,
    functionName: 'addBeneficiary',
    ...config,
  } as UsePrepareContractWriteConfig<typeof stepVestingWalletABI, 'addBeneficiary'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"batchAddBeneficiary"`.
 */
export function usePrepareStepVestingWalletBatchAddBeneficiary(
  config: Omit<
    UsePrepareContractWriteConfig<typeof stepVestingWalletABI, 'batchAddBeneficiary'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: stepVestingWalletABI,
    functionName: 'batchAddBeneficiary',
    ...config,
  } as UsePrepareContractWriteConfig<typeof stepVestingWalletABI, 'batchAddBeneficiary'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareStepVestingWalletRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof stepVestingWalletABI, 'renounceOwnership'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: stepVestingWalletABI,
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof stepVestingWalletABI, 'renounceOwnership'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareStepVestingWalletTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof stepVestingWalletABI, 'transferOwnership'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: stepVestingWalletABI,
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof stepVestingWalletABI, 'transferOwnership'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link stepVestingWalletABI}__ and `functionName` set to `"withdrawTokens"`.
 */
export function usePrepareStepVestingWalletWithdrawTokens(
  config: Omit<
    UsePrepareContractWriteConfig<typeof stepVestingWalletABI, 'withdrawTokens'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: stepVestingWalletABI,
    functionName: 'withdrawTokens',
    ...config,
  } as UsePrepareContractWriteConfig<typeof stepVestingWalletABI, 'withdrawTokens'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link accessControlABI}__.
 */
export function useAccessControlRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof accessControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof accessControlABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: accessControlABI, ...config } as UseContractReadConfig<
    typeof accessControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link accessControlABI}__ and `functionName` set to `"DEFAULT_ADMIN_ROLE"`.
 */
export function useAccessControlDefaultAdminRole<
  TFunctionName extends 'DEFAULT_ADMIN_ROLE',
  TSelectData = ReadContractResult<typeof accessControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof accessControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: accessControlABI,
    functionName: 'DEFAULT_ADMIN_ROLE',
    ...config,
  } as UseContractReadConfig<typeof accessControlABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link accessControlABI}__ and `functionName` set to `"getRoleAdmin"`.
 */
export function useAccessControlGetRoleAdmin<
  TFunctionName extends 'getRoleAdmin',
  TSelectData = ReadContractResult<typeof accessControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof accessControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: accessControlABI,
    functionName: 'getRoleAdmin',
    ...config,
  } as UseContractReadConfig<typeof accessControlABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link accessControlABI}__ and `functionName` set to `"hasRole"`.
 */
export function useAccessControlHasRole<
  TFunctionName extends 'hasRole',
  TSelectData = ReadContractResult<typeof accessControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof accessControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: accessControlABI,
    functionName: 'hasRole',
    ...config,
  } as UseContractReadConfig<typeof accessControlABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link accessControlABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useAccessControlSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof accessControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof accessControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: accessControlABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof accessControlABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link accessControlABI}__.
 */
export function useAccessControlWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof accessControlABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof accessControlABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof accessControlABI, TFunctionName, TMode>({
    abi: accessControlABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link accessControlABI}__ and `functionName` set to `"grantRole"`.
 */
export function useAccessControlGrantRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof accessControlABI,
          'grantRole'
        >['request']['abi'],
        'grantRole',
        TMode
      > & { functionName?: 'grantRole' }
    : UseContractWriteConfig<typeof accessControlABI, 'grantRole', TMode> & {
        abi?: never;
        functionName?: 'grantRole';
      } = {} as any,
) {
  return useContractWrite<typeof accessControlABI, 'grantRole', TMode>({
    abi: accessControlABI,
    functionName: 'grantRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link accessControlABI}__ and `functionName` set to `"renounceRole"`.
 */
export function useAccessControlRenounceRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof accessControlABI,
          'renounceRole'
        >['request']['abi'],
        'renounceRole',
        TMode
      > & { functionName?: 'renounceRole' }
    : UseContractWriteConfig<typeof accessControlABI, 'renounceRole', TMode> & {
        abi?: never;
        functionName?: 'renounceRole';
      } = {} as any,
) {
  return useContractWrite<typeof accessControlABI, 'renounceRole', TMode>({
    abi: accessControlABI,
    functionName: 'renounceRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link accessControlABI}__ and `functionName` set to `"revokeRole"`.
 */
export function useAccessControlRevokeRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof accessControlABI,
          'revokeRole'
        >['request']['abi'],
        'revokeRole',
        TMode
      > & { functionName?: 'revokeRole' }
    : UseContractWriteConfig<typeof accessControlABI, 'revokeRole', TMode> & {
        abi?: never;
        functionName?: 'revokeRole';
      } = {} as any,
) {
  return useContractWrite<typeof accessControlABI, 'revokeRole', TMode>({
    abi: accessControlABI,
    functionName: 'revokeRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link accessControlABI}__.
 */
export function usePrepareAccessControlWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof accessControlABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: accessControlABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof accessControlABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link accessControlABI}__ and `functionName` set to `"grantRole"`.
 */
export function usePrepareAccessControlGrantRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof accessControlABI, 'grantRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: accessControlABI,
    functionName: 'grantRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof accessControlABI, 'grantRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link accessControlABI}__ and `functionName` set to `"renounceRole"`.
 */
export function usePrepareAccessControlRenounceRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof accessControlABI, 'renounceRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: accessControlABI,
    functionName: 'renounceRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof accessControlABI, 'renounceRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link accessControlABI}__ and `functionName` set to `"revokeRole"`.
 */
export function usePrepareAccessControlRevokeRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof accessControlABI, 'revokeRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: accessControlABI,
    functionName: 'revokeRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof accessControlABI, 'revokeRole'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iAccessControlABI}__.
 */
export function useIAccessControlRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof iAccessControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iAccessControlABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: iAccessControlABI, ...config } as UseContractReadConfig<
    typeof iAccessControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iAccessControlABI}__ and `functionName` set to `"getRoleAdmin"`.
 */
export function useIAccessControlGetRoleAdmin<
  TFunctionName extends 'getRoleAdmin',
  TSelectData = ReadContractResult<typeof iAccessControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iAccessControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iAccessControlABI,
    functionName: 'getRoleAdmin',
    ...config,
  } as UseContractReadConfig<typeof iAccessControlABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iAccessControlABI}__ and `functionName` set to `"hasRole"`.
 */
export function useIAccessControlHasRole<
  TFunctionName extends 'hasRole',
  TSelectData = ReadContractResult<typeof iAccessControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iAccessControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iAccessControlABI,
    functionName: 'hasRole',
    ...config,
  } as UseContractReadConfig<typeof iAccessControlABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iAccessControlABI}__.
 */
export function useIAccessControlWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iAccessControlABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iAccessControlABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof iAccessControlABI, TFunctionName, TMode>({
    abi: iAccessControlABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iAccessControlABI}__ and `functionName` set to `"grantRole"`.
 */
export function useIAccessControlGrantRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iAccessControlABI,
          'grantRole'
        >['request']['abi'],
        'grantRole',
        TMode
      > & { functionName?: 'grantRole' }
    : UseContractWriteConfig<typeof iAccessControlABI, 'grantRole', TMode> & {
        abi?: never;
        functionName?: 'grantRole';
      } = {} as any,
) {
  return useContractWrite<typeof iAccessControlABI, 'grantRole', TMode>({
    abi: iAccessControlABI,
    functionName: 'grantRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iAccessControlABI}__ and `functionName` set to `"renounceRole"`.
 */
export function useIAccessControlRenounceRole<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iAccessControlABI,
          'renounceRole'
        >['request']['abi'],
        'renounceRole',
        TMode
      > & { functionName?: 'renounceRole' }
    : UseContractWriteConfig<typeof iAccessControlABI, 'renounceRole', TMode> & {
        abi?: never;
        functionName?: 'renounceRole';
      } = {} as any,
) {
  return useContractWrite<typeof iAccessControlABI, 'renounceRole', TMode>({
    abi: iAccessControlABI,
    functionName: 'renounceRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iAccessControlABI}__ and `functionName` set to `"revokeRole"`.
 */
export function useIAccessControlRevokeRole<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iAccessControlABI,
          'revokeRole'
        >['request']['abi'],
        'revokeRole',
        TMode
      > & { functionName?: 'revokeRole' }
    : UseContractWriteConfig<typeof iAccessControlABI, 'revokeRole', TMode> & {
        abi?: never;
        functionName?: 'revokeRole';
      } = {} as any,
) {
  return useContractWrite<typeof iAccessControlABI, 'revokeRole', TMode>({
    abi: iAccessControlABI,
    functionName: 'revokeRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iAccessControlABI}__.
 */
export function usePrepareIAccessControlWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iAccessControlABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iAccessControlABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof iAccessControlABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iAccessControlABI}__ and `functionName` set to `"grantRole"`.
 */
export function usePrepareIAccessControlGrantRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iAccessControlABI, 'grantRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iAccessControlABI,
    functionName: 'grantRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iAccessControlABI, 'grantRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iAccessControlABI}__ and `functionName` set to `"renounceRole"`.
 */
export function usePrepareIAccessControlRenounceRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iAccessControlABI, 'renounceRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iAccessControlABI,
    functionName: 'renounceRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iAccessControlABI, 'renounceRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iAccessControlABI}__ and `functionName` set to `"revokeRole"`.
 */
export function usePrepareIAccessControlRevokeRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iAccessControlABI, 'revokeRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iAccessControlABI,
    functionName: 'revokeRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iAccessControlABI, 'revokeRole'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ownableABI}__.
 */
export function useOwnableRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof ownableABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ownableABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: ownableABI, ...config } as UseContractReadConfig<
    typeof ownableABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ownableABI}__ and `functionName` set to `"owner"`.
 */
export function useOwnableOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof ownableABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ownableABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ownableABI,
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<typeof ownableABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ownableABI}__.
 */
export function useOwnableWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ownableABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof ownableABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof ownableABI, TFunctionName, TMode>({
    abi: ownableABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ownableABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useOwnableRenounceOwnership<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ownableABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & { functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<typeof ownableABI, 'renounceOwnership', TMode> & {
        abi?: never;
        functionName?: 'renounceOwnership';
      } = {} as any,
) {
  return useContractWrite<typeof ownableABI, 'renounceOwnership', TMode>({
    abi: ownableABI,
    functionName: 'renounceOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ownableABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useOwnableTransferOwnership<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ownableABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & { functionName?: 'transferOwnership' }
    : UseContractWriteConfig<typeof ownableABI, 'transferOwnership', TMode> & {
        abi?: never;
        functionName?: 'transferOwnership';
      } = {} as any,
) {
  return useContractWrite<typeof ownableABI, 'transferOwnership', TMode>({
    abi: ownableABI,
    functionName: 'transferOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ownableABI}__.
 */
export function usePrepareOwnableWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ownableABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ownableABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof ownableABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ownableABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareOwnableRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ownableABI, 'renounceOwnership'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ownableABI,
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ownableABI, 'renounceOwnership'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ownableABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareOwnableTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ownableABI, 'transferOwnership'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ownableABI,
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ownableABI, 'transferOwnership'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__.
 */
export function useGovernorRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: governorABI, ...config } as UseContractReadConfig<
    typeof governorABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"BALLOT_TYPEHASH"`.
 */
export function useGovernorBallotTypehash<
  TFunctionName extends 'BALLOT_TYPEHASH',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'BALLOT_TYPEHASH',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"COUNTING_MODE"`.
 */
export function useGovernorCountingMode<
  TFunctionName extends 'COUNTING_MODE',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'COUNTING_MODE',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"EXTENDED_BALLOT_TYPEHASH"`.
 */
export function useGovernorExtendedBallotTypehash<
  TFunctionName extends 'EXTENDED_BALLOT_TYPEHASH',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'EXTENDED_BALLOT_TYPEHASH',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"getVotes"`.
 */
export function useGovernorGetVotes<
  TFunctionName extends 'getVotes',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'getVotes',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"getVotesWithParams"`.
 */
export function useGovernorGetVotesWithParams<
  TFunctionName extends 'getVotesWithParams',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'getVotesWithParams',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"hasVoted"`.
 */
export function useGovernorHasVoted<
  TFunctionName extends 'hasVoted',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'hasVoted',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"hashProposal"`.
 */
export function useGovernorHashProposal<
  TFunctionName extends 'hashProposal',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'hashProposal',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"name"`.
 */
export function useGovernorName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"proposalDeadline"`.
 */
export function useGovernorProposalDeadline<
  TFunctionName extends 'proposalDeadline',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'proposalDeadline',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"proposalSnapshot"`.
 */
export function useGovernorProposalSnapshot<
  TFunctionName extends 'proposalSnapshot',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'proposalSnapshot',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"proposalThreshold"`.
 */
export function useGovernorProposalThreshold<
  TFunctionName extends 'proposalThreshold',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'proposalThreshold',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"quorum"`.
 */
export function useGovernorQuorum<
  TFunctionName extends 'quorum',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'quorum',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"state"`.
 */
export function useGovernorState<
  TFunctionName extends 'state',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'state',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useGovernorSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"version"`.
 */
export function useGovernorVersion<
  TFunctionName extends 'version',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'version',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"votingDelay"`.
 */
export function useGovernorVotingDelay<
  TFunctionName extends 'votingDelay',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'votingDelay',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"votingPeriod"`.
 */
export function useGovernorVotingPeriod<
  TFunctionName extends 'votingPeriod',
  TSelectData = ReadContractResult<typeof governorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorABI,
    functionName: 'votingPeriod',
    ...config,
  } as UseContractReadConfig<typeof governorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorABI}__.
 */
export function useGovernorWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof governorABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof governorABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof governorABI, TFunctionName, TMode>({
    abi: governorABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"castVote"`.
 */
export function useGovernorCastVote<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof governorABI, 'castVote'>['request']['abi'],
        'castVote',
        TMode
      > & { functionName?: 'castVote' }
    : UseContractWriteConfig<typeof governorABI, 'castVote', TMode> & {
        abi?: never;
        functionName?: 'castVote';
      } = {} as any,
) {
  return useContractWrite<typeof governorABI, 'castVote', TMode>({
    abi: governorABI,
    functionName: 'castVote',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"castVoteBySig"`.
 */
export function useGovernorCastVoteBySig<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof governorABI, 'castVoteBySig'>['request']['abi'],
        'castVoteBySig',
        TMode
      > & { functionName?: 'castVoteBySig' }
    : UseContractWriteConfig<typeof governorABI, 'castVoteBySig', TMode> & {
        abi?: never;
        functionName?: 'castVoteBySig';
      } = {} as any,
) {
  return useContractWrite<typeof governorABI, 'castVoteBySig', TMode>({
    abi: governorABI,
    functionName: 'castVoteBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"castVoteWithReason"`.
 */
export function useGovernorCastVoteWithReason<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorABI,
          'castVoteWithReason'
        >['request']['abi'],
        'castVoteWithReason',
        TMode
      > & { functionName?: 'castVoteWithReason' }
    : UseContractWriteConfig<typeof governorABI, 'castVoteWithReason', TMode> & {
        abi?: never;
        functionName?: 'castVoteWithReason';
      } = {} as any,
) {
  return useContractWrite<typeof governorABI, 'castVoteWithReason', TMode>({
    abi: governorABI,
    functionName: 'castVoteWithReason',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"castVoteWithReasonAndParams"`.
 */
export function useGovernorCastVoteWithReasonAndParams<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorABI,
          'castVoteWithReasonAndParams'
        >['request']['abi'],
        'castVoteWithReasonAndParams',
        TMode
      > & { functionName?: 'castVoteWithReasonAndParams' }
    : UseContractWriteConfig<typeof governorABI, 'castVoteWithReasonAndParams', TMode> & {
        abi?: never;
        functionName?: 'castVoteWithReasonAndParams';
      } = {} as any,
) {
  return useContractWrite<typeof governorABI, 'castVoteWithReasonAndParams', TMode>({
    abi: governorABI,
    functionName: 'castVoteWithReasonAndParams',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"castVoteWithReasonAndParamsBySig"`.
 */
export function useGovernorCastVoteWithReasonAndParamsBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorABI,
          'castVoteWithReasonAndParamsBySig'
        >['request']['abi'],
        'castVoteWithReasonAndParamsBySig',
        TMode
      > & { functionName?: 'castVoteWithReasonAndParamsBySig' }
    : UseContractWriteConfig<
        typeof governorABI,
        'castVoteWithReasonAndParamsBySig',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReasonAndParamsBySig';
      } = {} as any,
) {
  return useContractWrite<typeof governorABI, 'castVoteWithReasonAndParamsBySig', TMode>({
    abi: governorABI,
    functionName: 'castVoteWithReasonAndParamsBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"execute"`.
 */
export function useGovernorExecute<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof governorABI, 'execute'>['request']['abi'],
        'execute',
        TMode
      > & { functionName?: 'execute' }
    : UseContractWriteConfig<typeof governorABI, 'execute', TMode> & {
        abi?: never;
        functionName?: 'execute';
      } = {} as any,
) {
  return useContractWrite<typeof governorABI, 'execute', TMode>({
    abi: governorABI,
    functionName: 'execute',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function useGovernorOnErc1155BatchReceived<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorABI,
          'onERC1155BatchReceived'
        >['request']['abi'],
        'onERC1155BatchReceived',
        TMode
      > & { functionName?: 'onERC1155BatchReceived' }
    : UseContractWriteConfig<typeof governorABI, 'onERC1155BatchReceived', TMode> & {
        abi?: never;
        functionName?: 'onERC1155BatchReceived';
      } = {} as any,
) {
  return useContractWrite<typeof governorABI, 'onERC1155BatchReceived', TMode>({
    abi: governorABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function useGovernorOnErc1155Received<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorABI,
          'onERC1155Received'
        >['request']['abi'],
        'onERC1155Received',
        TMode
      > & { functionName?: 'onERC1155Received' }
    : UseContractWriteConfig<typeof governorABI, 'onERC1155Received', TMode> & {
        abi?: never;
        functionName?: 'onERC1155Received';
      } = {} as any,
) {
  return useContractWrite<typeof governorABI, 'onERC1155Received', TMode>({
    abi: governorABI,
    functionName: 'onERC1155Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function useGovernorOnErc721Received<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorABI,
          'onERC721Received'
        >['request']['abi'],
        'onERC721Received',
        TMode
      > & { functionName?: 'onERC721Received' }
    : UseContractWriteConfig<typeof governorABI, 'onERC721Received', TMode> & {
        abi?: never;
        functionName?: 'onERC721Received';
      } = {} as any,
) {
  return useContractWrite<typeof governorABI, 'onERC721Received', TMode>({
    abi: governorABI,
    functionName: 'onERC721Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"propose"`.
 */
export function useGovernorPropose<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof governorABI, 'propose'>['request']['abi'],
        'propose',
        TMode
      > & { functionName?: 'propose' }
    : UseContractWriteConfig<typeof governorABI, 'propose', TMode> & {
        abi?: never;
        functionName?: 'propose';
      } = {} as any,
) {
  return useContractWrite<typeof governorABI, 'propose', TMode>({
    abi: governorABI,
    functionName: 'propose',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"relay"`.
 */
export function useGovernorRelay<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof governorABI, 'relay'>['request']['abi'],
        'relay',
        TMode
      > & { functionName?: 'relay' }
    : UseContractWriteConfig<typeof governorABI, 'relay', TMode> & {
        abi?: never;
        functionName?: 'relay';
      } = {} as any,
) {
  return useContractWrite<typeof governorABI, 'relay', TMode>({
    abi: governorABI,
    functionName: 'relay',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorABI}__.
 */
export function usePrepareGovernorWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"castVote"`.
 */
export function usePrepareGovernorCastVote(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorABI, 'castVote'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorABI,
    functionName: 'castVote',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorABI, 'castVote'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"castVoteBySig"`.
 */
export function usePrepareGovernorCastVoteBySig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorABI, 'castVoteBySig'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorABI,
    functionName: 'castVoteBySig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorABI, 'castVoteBySig'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"castVoteWithReason"`.
 */
export function usePrepareGovernorCastVoteWithReason(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorABI, 'castVoteWithReason'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorABI,
    functionName: 'castVoteWithReason',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorABI, 'castVoteWithReason'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"castVoteWithReasonAndParams"`.
 */
export function usePrepareGovernorCastVoteWithReasonAndParams(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorABI, 'castVoteWithReasonAndParams'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorABI,
    functionName: 'castVoteWithReasonAndParams',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorABI, 'castVoteWithReasonAndParams'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"castVoteWithReasonAndParamsBySig"`.
 */
export function usePrepareGovernorCastVoteWithReasonAndParamsBySig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorABI, 'castVoteWithReasonAndParamsBySig'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorABI,
    functionName: 'castVoteWithReasonAndParamsBySig',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorABI,
    'castVoteWithReasonAndParamsBySig'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"execute"`.
 */
export function usePrepareGovernorExecute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorABI, 'execute'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorABI,
    functionName: 'execute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorABI, 'execute'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function usePrepareGovernorOnErc1155BatchReceived(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorABI, 'onERC1155BatchReceived'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorABI, 'onERC1155BatchReceived'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function usePrepareGovernorOnErc1155Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorABI, 'onERC1155Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorABI,
    functionName: 'onERC1155Received',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorABI, 'onERC1155Received'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function usePrepareGovernorOnErc721Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorABI, 'onERC721Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorABI,
    functionName: 'onERC721Received',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorABI, 'onERC721Received'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"propose"`.
 */
export function usePrepareGovernorPropose(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorABI, 'propose'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorABI,
    functionName: 'propose',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorABI, 'propose'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorABI}__ and `functionName` set to `"relay"`.
 */
export function usePrepareGovernorRelay(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorABI, 'relay'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorABI,
    functionName: 'relay',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorABI, 'relay'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorABI}__.
 */
export function useIGovernorRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof iGovernorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: iGovernorABI, ...config } as UseContractReadConfig<
    typeof iGovernorABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"COUNTING_MODE"`.
 */
export function useIGovernorCountingMode<
  TFunctionName extends 'COUNTING_MODE',
  TSelectData = ReadContractResult<typeof iGovernorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorABI,
    functionName: 'COUNTING_MODE',
    ...config,
  } as UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"getVotes"`.
 */
export function useIGovernorGetVotes<
  TFunctionName extends 'getVotes',
  TSelectData = ReadContractResult<typeof iGovernorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorABI,
    functionName: 'getVotes',
    ...config,
  } as UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"getVotesWithParams"`.
 */
export function useIGovernorGetVotesWithParams<
  TFunctionName extends 'getVotesWithParams',
  TSelectData = ReadContractResult<typeof iGovernorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorABI,
    functionName: 'getVotesWithParams',
    ...config,
  } as UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"hasVoted"`.
 */
export function useIGovernorHasVoted<
  TFunctionName extends 'hasVoted',
  TSelectData = ReadContractResult<typeof iGovernorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorABI,
    functionName: 'hasVoted',
    ...config,
  } as UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"hashProposal"`.
 */
export function useIGovernorHashProposal<
  TFunctionName extends 'hashProposal',
  TSelectData = ReadContractResult<typeof iGovernorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorABI,
    functionName: 'hashProposal',
    ...config,
  } as UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"name"`.
 */
export function useIGovernorName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof iGovernorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"proposalDeadline"`.
 */
export function useIGovernorProposalDeadline<
  TFunctionName extends 'proposalDeadline',
  TSelectData = ReadContractResult<typeof iGovernorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorABI,
    functionName: 'proposalDeadline',
    ...config,
  } as UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"proposalSnapshot"`.
 */
export function useIGovernorProposalSnapshot<
  TFunctionName extends 'proposalSnapshot',
  TSelectData = ReadContractResult<typeof iGovernorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorABI,
    functionName: 'proposalSnapshot',
    ...config,
  } as UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"quorum"`.
 */
export function useIGovernorQuorum<
  TFunctionName extends 'quorum',
  TSelectData = ReadContractResult<typeof iGovernorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorABI,
    functionName: 'quorum',
    ...config,
  } as UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"state"`.
 */
export function useIGovernorState<
  TFunctionName extends 'state',
  TSelectData = ReadContractResult<typeof iGovernorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorABI,
    functionName: 'state',
    ...config,
  } as UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useIGovernorSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof iGovernorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"version"`.
 */
export function useIGovernorVersion<
  TFunctionName extends 'version',
  TSelectData = ReadContractResult<typeof iGovernorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorABI,
    functionName: 'version',
    ...config,
  } as UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"votingDelay"`.
 */
export function useIGovernorVotingDelay<
  TFunctionName extends 'votingDelay',
  TSelectData = ReadContractResult<typeof iGovernorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorABI,
    functionName: 'votingDelay',
    ...config,
  } as UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"votingPeriod"`.
 */
export function useIGovernorVotingPeriod<
  TFunctionName extends 'votingPeriod',
  TSelectData = ReadContractResult<typeof iGovernorABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorABI,
    functionName: 'votingPeriod',
    ...config,
  } as UseContractReadConfig<typeof iGovernorABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorABI}__.
 */
export function useIGovernorWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iGovernorABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iGovernorABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof iGovernorABI, TFunctionName, TMode>({
    abi: iGovernorABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"castVote"`.
 */
export function useIGovernorCastVote<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iGovernorABI, 'castVote'>['request']['abi'],
        'castVote',
        TMode
      > & { functionName?: 'castVote' }
    : UseContractWriteConfig<typeof iGovernorABI, 'castVote', TMode> & {
        abi?: never;
        functionName?: 'castVote';
      } = {} as any,
) {
  return useContractWrite<typeof iGovernorABI, 'castVote', TMode>({
    abi: iGovernorABI,
    functionName: 'castVote',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"castVoteBySig"`.
 */
export function useIGovernorCastVoteBySig<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iGovernorABI,
          'castVoteBySig'
        >['request']['abi'],
        'castVoteBySig',
        TMode
      > & { functionName?: 'castVoteBySig' }
    : UseContractWriteConfig<typeof iGovernorABI, 'castVoteBySig', TMode> & {
        abi?: never;
        functionName?: 'castVoteBySig';
      } = {} as any,
) {
  return useContractWrite<typeof iGovernorABI, 'castVoteBySig', TMode>({
    abi: iGovernorABI,
    functionName: 'castVoteBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"castVoteWithReason"`.
 */
export function useIGovernorCastVoteWithReason<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iGovernorABI,
          'castVoteWithReason'
        >['request']['abi'],
        'castVoteWithReason',
        TMode
      > & { functionName?: 'castVoteWithReason' }
    : UseContractWriteConfig<typeof iGovernorABI, 'castVoteWithReason', TMode> & {
        abi?: never;
        functionName?: 'castVoteWithReason';
      } = {} as any,
) {
  return useContractWrite<typeof iGovernorABI, 'castVoteWithReason', TMode>({
    abi: iGovernorABI,
    functionName: 'castVoteWithReason',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"castVoteWithReasonAndParams"`.
 */
export function useIGovernorCastVoteWithReasonAndParams<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iGovernorABI,
          'castVoteWithReasonAndParams'
        >['request']['abi'],
        'castVoteWithReasonAndParams',
        TMode
      > & { functionName?: 'castVoteWithReasonAndParams' }
    : UseContractWriteConfig<
        typeof iGovernorABI,
        'castVoteWithReasonAndParams',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReasonAndParams';
      } = {} as any,
) {
  return useContractWrite<typeof iGovernorABI, 'castVoteWithReasonAndParams', TMode>({
    abi: iGovernorABI,
    functionName: 'castVoteWithReasonAndParams',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"castVoteWithReasonAndParamsBySig"`.
 */
export function useIGovernorCastVoteWithReasonAndParamsBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iGovernorABI,
          'castVoteWithReasonAndParamsBySig'
        >['request']['abi'],
        'castVoteWithReasonAndParamsBySig',
        TMode
      > & { functionName?: 'castVoteWithReasonAndParamsBySig' }
    : UseContractWriteConfig<
        typeof iGovernorABI,
        'castVoteWithReasonAndParamsBySig',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReasonAndParamsBySig';
      } = {} as any,
) {
  return useContractWrite<typeof iGovernorABI, 'castVoteWithReasonAndParamsBySig', TMode>(
    {
      abi: iGovernorABI,
      functionName: 'castVoteWithReasonAndParamsBySig',
      ...config,
    } as any,
  );
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"execute"`.
 */
export function useIGovernorExecute<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iGovernorABI, 'execute'>['request']['abi'],
        'execute',
        TMode
      > & { functionName?: 'execute' }
    : UseContractWriteConfig<typeof iGovernorABI, 'execute', TMode> & {
        abi?: never;
        functionName?: 'execute';
      } = {} as any,
) {
  return useContractWrite<typeof iGovernorABI, 'execute', TMode>({
    abi: iGovernorABI,
    functionName: 'execute',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"propose"`.
 */
export function useIGovernorPropose<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iGovernorABI, 'propose'>['request']['abi'],
        'propose',
        TMode
      > & { functionName?: 'propose' }
    : UseContractWriteConfig<typeof iGovernorABI, 'propose', TMode> & {
        abi?: never;
        functionName?: 'propose';
      } = {} as any,
) {
  return useContractWrite<typeof iGovernorABI, 'propose', TMode>({
    abi: iGovernorABI,
    functionName: 'propose',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorABI}__.
 */
export function usePrepareIGovernorWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGovernorABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGovernorABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"castVote"`.
 */
export function usePrepareIGovernorCastVote(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGovernorABI, 'castVote'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorABI,
    functionName: 'castVote',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGovernorABI, 'castVote'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"castVoteBySig"`.
 */
export function usePrepareIGovernorCastVoteBySig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGovernorABI, 'castVoteBySig'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorABI,
    functionName: 'castVoteBySig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGovernorABI, 'castVoteBySig'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"castVoteWithReason"`.
 */
export function usePrepareIGovernorCastVoteWithReason(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGovernorABI, 'castVoteWithReason'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorABI,
    functionName: 'castVoteWithReason',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGovernorABI, 'castVoteWithReason'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"castVoteWithReasonAndParams"`.
 */
export function usePrepareIGovernorCastVoteWithReasonAndParams(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGovernorABI, 'castVoteWithReasonAndParams'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorABI,
    functionName: 'castVoteWithReasonAndParams',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGovernorABI, 'castVoteWithReasonAndParams'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"castVoteWithReasonAndParamsBySig"`.
 */
export function usePrepareIGovernorCastVoteWithReasonAndParamsBySig(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof iGovernorABI,
      'castVoteWithReasonAndParamsBySig'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorABI,
    functionName: 'castVoteWithReasonAndParamsBySig',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof iGovernorABI,
    'castVoteWithReasonAndParamsBySig'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"execute"`.
 */
export function usePrepareIGovernorExecute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGovernorABI, 'execute'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorABI,
    functionName: 'execute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGovernorABI, 'execute'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorABI}__ and `functionName` set to `"propose"`.
 */
export function usePrepareIGovernorPropose(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGovernorABI, 'propose'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorABI,
    functionName: 'propose',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGovernorABI, 'propose'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__.
 */
export function useTimelockControllerRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"CANCELLER_ROLE"`.
 */
export function useTimelockControllerCancellerRole<
  TFunctionName extends 'CANCELLER_ROLE',
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    functionName: 'CANCELLER_ROLE',
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"DEFAULT_ADMIN_ROLE"`.
 */
export function useTimelockControllerDefaultAdminRole<
  TFunctionName extends 'DEFAULT_ADMIN_ROLE',
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    functionName: 'DEFAULT_ADMIN_ROLE',
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"EXECUTOR_ROLE"`.
 */
export function useTimelockControllerExecutorRole<
  TFunctionName extends 'EXECUTOR_ROLE',
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    functionName: 'EXECUTOR_ROLE',
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"PROPOSER_ROLE"`.
 */
export function useTimelockControllerProposerRole<
  TFunctionName extends 'PROPOSER_ROLE',
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    functionName: 'PROPOSER_ROLE',
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"TIMELOCK_ADMIN_ROLE"`.
 */
export function useTimelockControllerTimelockAdminRole<
  TFunctionName extends 'TIMELOCK_ADMIN_ROLE',
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    functionName: 'TIMELOCK_ADMIN_ROLE',
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"getMinDelay"`.
 */
export function useTimelockControllerGetMinDelay<
  TFunctionName extends 'getMinDelay',
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    functionName: 'getMinDelay',
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"getRoleAdmin"`.
 */
export function useTimelockControllerGetRoleAdmin<
  TFunctionName extends 'getRoleAdmin',
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    functionName: 'getRoleAdmin',
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"getTimestamp"`.
 */
export function useTimelockControllerGetTimestamp<
  TFunctionName extends 'getTimestamp',
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    functionName: 'getTimestamp',
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"hasRole"`.
 */
export function useTimelockControllerHasRole<
  TFunctionName extends 'hasRole',
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    functionName: 'hasRole',
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"hashOperation"`.
 */
export function useTimelockControllerHashOperation<
  TFunctionName extends 'hashOperation',
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    functionName: 'hashOperation',
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"hashOperationBatch"`.
 */
export function useTimelockControllerHashOperationBatch<
  TFunctionName extends 'hashOperationBatch',
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    functionName: 'hashOperationBatch',
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"isOperation"`.
 */
export function useTimelockControllerIsOperation<
  TFunctionName extends 'isOperation',
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    functionName: 'isOperation',
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"isOperationDone"`.
 */
export function useTimelockControllerIsOperationDone<
  TFunctionName extends 'isOperationDone',
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    functionName: 'isOperationDone',
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"isOperationPending"`.
 */
export function useTimelockControllerIsOperationPending<
  TFunctionName extends 'isOperationPending',
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    functionName: 'isOperationPending',
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"isOperationReady"`.
 */
export function useTimelockControllerIsOperationReady<
  TFunctionName extends 'isOperationReady',
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    functionName: 'isOperationReady',
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useTimelockControllerSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof timelockControllerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: timelockControllerABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof timelockControllerABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timelockControllerABI}__.
 */
export function useTimelockControllerWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof timelockControllerABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof timelockControllerABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof timelockControllerABI, TFunctionName, TMode>({
    abi: timelockControllerABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"cancel"`.
 */
export function useTimelockControllerCancel<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof timelockControllerABI,
          'cancel'
        >['request']['abi'],
        'cancel',
        TMode
      > & { functionName?: 'cancel' }
    : UseContractWriteConfig<typeof timelockControllerABI, 'cancel', TMode> & {
        abi?: never;
        functionName?: 'cancel';
      } = {} as any,
) {
  return useContractWrite<typeof timelockControllerABI, 'cancel', TMode>({
    abi: timelockControllerABI,
    functionName: 'cancel',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"execute"`.
 */
export function useTimelockControllerExecute<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof timelockControllerABI,
          'execute'
        >['request']['abi'],
        'execute',
        TMode
      > & { functionName?: 'execute' }
    : UseContractWriteConfig<typeof timelockControllerABI, 'execute', TMode> & {
        abi?: never;
        functionName?: 'execute';
      } = {} as any,
) {
  return useContractWrite<typeof timelockControllerABI, 'execute', TMode>({
    abi: timelockControllerABI,
    functionName: 'execute',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"executeBatch"`.
 */
export function useTimelockControllerExecuteBatch<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof timelockControllerABI,
          'executeBatch'
        >['request']['abi'],
        'executeBatch',
        TMode
      > & { functionName?: 'executeBatch' }
    : UseContractWriteConfig<typeof timelockControllerABI, 'executeBatch', TMode> & {
        abi?: never;
        functionName?: 'executeBatch';
      } = {} as any,
) {
  return useContractWrite<typeof timelockControllerABI, 'executeBatch', TMode>({
    abi: timelockControllerABI,
    functionName: 'executeBatch',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"grantRole"`.
 */
export function useTimelockControllerGrantRole<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof timelockControllerABI,
          'grantRole'
        >['request']['abi'],
        'grantRole',
        TMode
      > & { functionName?: 'grantRole' }
    : UseContractWriteConfig<typeof timelockControllerABI, 'grantRole', TMode> & {
        abi?: never;
        functionName?: 'grantRole';
      } = {} as any,
) {
  return useContractWrite<typeof timelockControllerABI, 'grantRole', TMode>({
    abi: timelockControllerABI,
    functionName: 'grantRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function useTimelockControllerOnErc1155BatchReceived<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof timelockControllerABI,
          'onERC1155BatchReceived'
        >['request']['abi'],
        'onERC1155BatchReceived',
        TMode
      > & { functionName?: 'onERC1155BatchReceived' }
    : UseContractWriteConfig<
        typeof timelockControllerABI,
        'onERC1155BatchReceived',
        TMode
      > & {
        abi?: never;
        functionName?: 'onERC1155BatchReceived';
      } = {} as any,
) {
  return useContractWrite<typeof timelockControllerABI, 'onERC1155BatchReceived', TMode>({
    abi: timelockControllerABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function useTimelockControllerOnErc1155Received<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof timelockControllerABI,
          'onERC1155Received'
        >['request']['abi'],
        'onERC1155Received',
        TMode
      > & { functionName?: 'onERC1155Received' }
    : UseContractWriteConfig<typeof timelockControllerABI, 'onERC1155Received', TMode> & {
        abi?: never;
        functionName?: 'onERC1155Received';
      } = {} as any,
) {
  return useContractWrite<typeof timelockControllerABI, 'onERC1155Received', TMode>({
    abi: timelockControllerABI,
    functionName: 'onERC1155Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function useTimelockControllerOnErc721Received<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof timelockControllerABI,
          'onERC721Received'
        >['request']['abi'],
        'onERC721Received',
        TMode
      > & { functionName?: 'onERC721Received' }
    : UseContractWriteConfig<typeof timelockControllerABI, 'onERC721Received', TMode> & {
        abi?: never;
        functionName?: 'onERC721Received';
      } = {} as any,
) {
  return useContractWrite<typeof timelockControllerABI, 'onERC721Received', TMode>({
    abi: timelockControllerABI,
    functionName: 'onERC721Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"renounceRole"`.
 */
export function useTimelockControllerRenounceRole<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof timelockControllerABI,
          'renounceRole'
        >['request']['abi'],
        'renounceRole',
        TMode
      > & { functionName?: 'renounceRole' }
    : UseContractWriteConfig<typeof timelockControllerABI, 'renounceRole', TMode> & {
        abi?: never;
        functionName?: 'renounceRole';
      } = {} as any,
) {
  return useContractWrite<typeof timelockControllerABI, 'renounceRole', TMode>({
    abi: timelockControllerABI,
    functionName: 'renounceRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"revokeRole"`.
 */
export function useTimelockControllerRevokeRole<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof timelockControllerABI,
          'revokeRole'
        >['request']['abi'],
        'revokeRole',
        TMode
      > & { functionName?: 'revokeRole' }
    : UseContractWriteConfig<typeof timelockControllerABI, 'revokeRole', TMode> & {
        abi?: never;
        functionName?: 'revokeRole';
      } = {} as any,
) {
  return useContractWrite<typeof timelockControllerABI, 'revokeRole', TMode>({
    abi: timelockControllerABI,
    functionName: 'revokeRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"schedule"`.
 */
export function useTimelockControllerSchedule<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof timelockControllerABI,
          'schedule'
        >['request']['abi'],
        'schedule',
        TMode
      > & { functionName?: 'schedule' }
    : UseContractWriteConfig<typeof timelockControllerABI, 'schedule', TMode> & {
        abi?: never;
        functionName?: 'schedule';
      } = {} as any,
) {
  return useContractWrite<typeof timelockControllerABI, 'schedule', TMode>({
    abi: timelockControllerABI,
    functionName: 'schedule',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"scheduleBatch"`.
 */
export function useTimelockControllerScheduleBatch<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof timelockControllerABI,
          'scheduleBatch'
        >['request']['abi'],
        'scheduleBatch',
        TMode
      > & { functionName?: 'scheduleBatch' }
    : UseContractWriteConfig<typeof timelockControllerABI, 'scheduleBatch', TMode> & {
        abi?: never;
        functionName?: 'scheduleBatch';
      } = {} as any,
) {
  return useContractWrite<typeof timelockControllerABI, 'scheduleBatch', TMode>({
    abi: timelockControllerABI,
    functionName: 'scheduleBatch',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"updateDelay"`.
 */
export function useTimelockControllerUpdateDelay<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof timelockControllerABI,
          'updateDelay'
        >['request']['abi'],
        'updateDelay',
        TMode
      > & { functionName?: 'updateDelay' }
    : UseContractWriteConfig<typeof timelockControllerABI, 'updateDelay', TMode> & {
        abi?: never;
        functionName?: 'updateDelay';
      } = {} as any,
) {
  return useContractWrite<typeof timelockControllerABI, 'updateDelay', TMode>({
    abi: timelockControllerABI,
    functionName: 'updateDelay',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timelockControllerABI}__.
 */
export function usePrepareTimelockControllerWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timelockControllerABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timelockControllerABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof timelockControllerABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"cancel"`.
 */
export function usePrepareTimelockControllerCancel(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timelockControllerABI, 'cancel'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timelockControllerABI,
    functionName: 'cancel',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timelockControllerABI, 'cancel'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"execute"`.
 */
export function usePrepareTimelockControllerExecute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timelockControllerABI, 'execute'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timelockControllerABI,
    functionName: 'execute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timelockControllerABI, 'execute'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"executeBatch"`.
 */
export function usePrepareTimelockControllerExecuteBatch(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timelockControllerABI, 'executeBatch'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timelockControllerABI,
    functionName: 'executeBatch',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timelockControllerABI, 'executeBatch'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"grantRole"`.
 */
export function usePrepareTimelockControllerGrantRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timelockControllerABI, 'grantRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timelockControllerABI,
    functionName: 'grantRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timelockControllerABI, 'grantRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function usePrepareTimelockControllerOnErc1155BatchReceived(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timelockControllerABI, 'onERC1155BatchReceived'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timelockControllerABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof timelockControllerABI,
    'onERC1155BatchReceived'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function usePrepareTimelockControllerOnErc1155Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timelockControllerABI, 'onERC1155Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timelockControllerABI,
    functionName: 'onERC1155Received',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timelockControllerABI, 'onERC1155Received'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function usePrepareTimelockControllerOnErc721Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timelockControllerABI, 'onERC721Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timelockControllerABI,
    functionName: 'onERC721Received',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timelockControllerABI, 'onERC721Received'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"renounceRole"`.
 */
export function usePrepareTimelockControllerRenounceRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timelockControllerABI, 'renounceRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timelockControllerABI,
    functionName: 'renounceRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timelockControllerABI, 'renounceRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"revokeRole"`.
 */
export function usePrepareTimelockControllerRevokeRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timelockControllerABI, 'revokeRole'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timelockControllerABI,
    functionName: 'revokeRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timelockControllerABI, 'revokeRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"schedule"`.
 */
export function usePrepareTimelockControllerSchedule(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timelockControllerABI, 'schedule'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timelockControllerABI,
    functionName: 'schedule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timelockControllerABI, 'schedule'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"scheduleBatch"`.
 */
export function usePrepareTimelockControllerScheduleBatch(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timelockControllerABI, 'scheduleBatch'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timelockControllerABI,
    functionName: 'scheduleBatch',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timelockControllerABI, 'scheduleBatch'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link timelockControllerABI}__ and `functionName` set to `"updateDelay"`.
 */
export function usePrepareTimelockControllerUpdateDelay(
  config: Omit<
    UsePrepareContractWriteConfig<typeof timelockControllerABI, 'updateDelay'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: timelockControllerABI,
    functionName: 'updateDelay',
    ...config,
  } as UsePrepareContractWriteConfig<typeof timelockControllerABI, 'updateDelay'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pausableABI}__.
 */
export function usePausableRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof pausableABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof pausableABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: pausableABI, ...config } as UseContractReadConfig<
    typeof pausableABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link pausableABI}__ and `functionName` set to `"paused"`.
 */
export function usePausablePaused<
  TFunctionName extends 'paused',
  TSelectData = ReadContractResult<typeof pausableABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof pausableABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: pausableABI,
    functionName: 'paused',
    ...config,
  } as UseContractReadConfig<typeof pausableABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iLiquidityValueCalculatorABI}__.
 */
export function useILiquidityValueCalculatorWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iLiquidityValueCalculatorABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<
        typeof iLiquidityValueCalculatorABI,
        TFunctionName,
        TMode
      > & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof iLiquidityValueCalculatorABI, TFunctionName, TMode>({
    abi: iLiquidityValueCalculatorABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iLiquidityValueCalculatorABI}__ and `functionName` set to `"computeLiquidityShareValue"`.
 */
export function useILiquidityValueCalculatorComputeLiquidityShareValue<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iLiquidityValueCalculatorABI,
          'computeLiquidityShareValue'
        >['request']['abi'],
        'computeLiquidityShareValue',
        TMode
      > & { functionName?: 'computeLiquidityShareValue' }
    : UseContractWriteConfig<
        typeof iLiquidityValueCalculatorABI,
        'computeLiquidityShareValue',
        TMode
      > & {
        abi?: never;
        functionName?: 'computeLiquidityShareValue';
      } = {} as any,
) {
  return useContractWrite<
    typeof iLiquidityValueCalculatorABI,
    'computeLiquidityShareValue',
    TMode
  >({
    abi: iLiquidityValueCalculatorABI,
    functionName: 'computeLiquidityShareValue',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iLiquidityValueCalculatorABI}__.
 */
export function usePrepareILiquidityValueCalculatorWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iLiquidityValueCalculatorABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iLiquidityValueCalculatorABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof iLiquidityValueCalculatorABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iLiquidityValueCalculatorABI}__ and `functionName` set to `"computeLiquidityShareValue"`.
 */
export function usePrepareILiquidityValueCalculatorComputeLiquidityShareValue(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof iLiquidityValueCalculatorABI,
      'computeLiquidityShareValue'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iLiquidityValueCalculatorABI,
    functionName: 'computeLiquidityShareValue',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof iLiquidityValueCalculatorABI,
    'computeLiquidityShareValue'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2TwapOracleABI}__.
 */
export function useIUniswapV2TwapOracleRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof iUniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2TwapOracleABI,
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2TwapOracleABI}__ and `functionName` set to `"consult"`.
 */
export function useIUniswapV2TwapOracleConsult<
  TFunctionName extends 'consult',
  TSelectData = ReadContractResult<typeof iUniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2TwapOracleABI,
    functionName: 'consult',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2TwapOracleABI}__ and `functionName` set to `"getObservedPrices"`.
 */
export function useIUniswapV2TwapOracleGetObservedPrices<
  TFunctionName extends 'getObservedPrices',
  TSelectData = ReadContractResult<typeof iUniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2TwapOracleABI,
    functionName: 'getObservedPrices',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2TwapOracleABI}__ and `functionName` set to `"getPriceRatios"`.
 */
export function useIUniswapV2TwapOracleGetPriceRatios<
  TFunctionName extends 'getPriceRatios',
  TSelectData = ReadContractResult<typeof iUniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2TwapOracleABI,
    functionName: 'getPriceRatios',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2TwapOracleABI}__ and `functionName` set to `"pair"`.
 */
export function useIUniswapV2TwapOraclePair<
  TFunctionName extends 'pair',
  TSelectData = ReadContractResult<typeof iUniswapV2TwapOracleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2TwapOracleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2TwapOracleABI,
    functionName: 'pair',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2TwapOracleABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2TwapOracleABI}__.
 */
export function useIUniswapV2TwapOracleWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2TwapOracleABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iUniswapV2TwapOracleABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2TwapOracleABI, TFunctionName, TMode>({
    abi: iUniswapV2TwapOracleABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2TwapOracleABI}__ and `functionName` set to `"update"`.
 */
export function useIUniswapV2TwapOracleUpdate<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2TwapOracleABI,
          'update'
        >['request']['abi'],
        'update',
        TMode
      > & { functionName?: 'update' }
    : UseContractWriteConfig<typeof iUniswapV2TwapOracleABI, 'update', TMode> & {
        abi?: never;
        functionName?: 'update';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2TwapOracleABI, 'update', TMode>({
    abi: iUniswapV2TwapOracleABI,
    functionName: 'update',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2TwapOracleABI}__.
 */
export function usePrepareIUniswapV2TwapOracleWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2TwapOracleABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2TwapOracleABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2TwapOracleABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2TwapOracleABI}__ and `functionName` set to `"update"`.
 */
export function usePrepareIUniswapV2TwapOracleUpdate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2TwapOracleABI, 'update'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2TwapOracleABI,
    functionName: 'update',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2TwapOracleABI, 'update'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link fixedPointABI}__.
 */
export function useFixedPointRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof fixedPointABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof fixedPointABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: fixedPointABI, ...config } as UseContractReadConfig<
    typeof fixedPointABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link fixedPointABI}__ and `functionName` set to `"Q112"`.
 */
export function useFixedPointQ112<
  TFunctionName extends 'Q112',
  TSelectData = ReadContractResult<typeof fixedPointABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof fixedPointABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: fixedPointABI,
    functionName: 'Q112',
    ...config,
  } as UseContractReadConfig<typeof fixedPointABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link fixedPointABI}__ and `functionName` set to `"RESOLUTION"`.
 */
export function useFixedPointResolution<
  TFunctionName extends 'RESOLUTION',
  TSelectData = ReadContractResult<typeof fixedPointABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof fixedPointABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: fixedPointABI,
    functionName: 'RESOLUTION',
    ...config,
  } as UseContractReadConfig<typeof fixedPointABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGelatoAutomationABI}__.
 */
export function useIGelatoAutomationWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iGelatoAutomationABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iGelatoAutomationABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof iGelatoAutomationABI, TFunctionName, TMode>({
    abi: iGelatoAutomationABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGelatoAutomationABI}__ and `functionName` set to `"cancelTask"`.
 */
export function useIGelatoAutomationCancelTask<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iGelatoAutomationABI,
          'cancelTask'
        >['request']['abi'],
        'cancelTask',
        TMode
      > & { functionName?: 'cancelTask' }
    : UseContractWriteConfig<typeof iGelatoAutomationABI, 'cancelTask', TMode> & {
        abi?: never;
        functionName?: 'cancelTask';
      } = {} as any,
) {
  return useContractWrite<typeof iGelatoAutomationABI, 'cancelTask', TMode>({
    abi: iGelatoAutomationABI,
    functionName: 'cancelTask',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGelatoAutomationABI}__ and `functionName` set to `"createTask"`.
 */
export function useIGelatoAutomationCreateTask<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iGelatoAutomationABI,
          'createTask'
        >['request']['abi'],
        'createTask',
        TMode
      > & { functionName?: 'createTask' }
    : UseContractWriteConfig<typeof iGelatoAutomationABI, 'createTask', TMode> & {
        abi?: never;
        functionName?: 'createTask';
      } = {} as any,
) {
  return useContractWrite<typeof iGelatoAutomationABI, 'createTask', TMode>({
    abi: iGelatoAutomationABI,
    functionName: 'createTask',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGelatoAutomationABI}__.
 */
export function usePrepareIGelatoAutomationWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGelatoAutomationABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGelatoAutomationABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGelatoAutomationABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGelatoAutomationABI}__ and `functionName` set to `"cancelTask"`.
 */
export function usePrepareIGelatoAutomationCancelTask(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGelatoAutomationABI, 'cancelTask'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGelatoAutomationABI,
    functionName: 'cancelTask',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGelatoAutomationABI, 'cancelTask'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGelatoAutomationABI}__ and `functionName` set to `"createTask"`.
 */
export function usePrepareIGelatoAutomationCreateTask(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGelatoAutomationABI, 'createTask'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGelatoAutomationABI,
    functionName: 'createTask',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGelatoAutomationABI, 'createTask'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ownableSol6ABI}__.
 */
export function useOwnableSol6Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof ownableSol6ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ownableSol6ABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: ownableSol6ABI, ...config } as UseContractReadConfig<
    typeof ownableSol6ABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ownableSol6ABI}__ and `functionName` set to `"owner"`.
 */
export function useOwnableSol6Owner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof ownableSol6ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ownableSol6ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ownableSol6ABI,
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<typeof ownableSol6ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ownableSol6ABI}__.
 */
export function useOwnableSol6Write<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ownableSol6ABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof ownableSol6ABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof ownableSol6ABI, TFunctionName, TMode>({
    abi: ownableSol6ABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ownableSol6ABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useOwnableSol6RenounceOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ownableSol6ABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & { functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<typeof ownableSol6ABI, 'renounceOwnership', TMode> & {
        abi?: never;
        functionName?: 'renounceOwnership';
      } = {} as any,
) {
  return useContractWrite<typeof ownableSol6ABI, 'renounceOwnership', TMode>({
    abi: ownableSol6ABI,
    functionName: 'renounceOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ownableSol6ABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useOwnableSol6TransferOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ownableSol6ABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & { functionName?: 'transferOwnership' }
    : UseContractWriteConfig<typeof ownableSol6ABI, 'transferOwnership', TMode> & {
        abi?: never;
        functionName?: 'transferOwnership';
      } = {} as any,
) {
  return useContractWrite<typeof ownableSol6ABI, 'transferOwnership', TMode>({
    abi: ownableSol6ABI,
    functionName: 'transferOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ownableSol6ABI}__.
 */
export function usePrepareOwnableSol6Write<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ownableSol6ABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ownableSol6ABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof ownableSol6ABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ownableSol6ABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareOwnableSol6RenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ownableSol6ABI, 'renounceOwnership'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ownableSol6ABI,
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ownableSol6ABI, 'renounceOwnership'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ownableSol6ABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareOwnableSol6TransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ownableSol6ABI, 'transferOwnership'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ownableSol6ABI,
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ownableSol6ABI, 'transferOwnership'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iStakingABI}__.
 */
export function useIStakingRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof iStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iStakingABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: iStakingABI, ...config } as UseContractReadConfig<
    typeof iStakingABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iStakingABI}__ and `functionName` set to `"earnedReward"`.
 */
export function useIStakingEarnedReward<
  TFunctionName extends 'earnedReward',
  TSelectData = ReadContractResult<typeof iStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iStakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iStakingABI,
    functionName: 'earnedReward',
    ...config,
  } as UseContractReadConfig<typeof iStakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iStakingABI}__ and `functionName` set to `"getRewardToken"`.
 */
export function useIStakingGetRewardToken<
  TFunctionName extends 'getRewardToken',
  TSelectData = ReadContractResult<typeof iStakingABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iStakingABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iStakingABI,
    functionName: 'getRewardToken',
    ...config,
  } as UseContractReadConfig<typeof iStakingABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iStakingABI}__.
 */
export function useIStakingWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iStakingABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iStakingABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof iStakingABI, TFunctionName, TMode>({
    abi: iStakingABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iStakingABI}__ and `functionName` set to `"collectRewardsFor"`.
 */
export function useIStakingCollectRewardsFor<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iStakingABI,
          'collectRewardsFor'
        >['request']['abi'],
        'collectRewardsFor',
        TMode
      > & { functionName?: 'collectRewardsFor' }
    : UseContractWriteConfig<typeof iStakingABI, 'collectRewardsFor', TMode> & {
        abi?: never;
        functionName?: 'collectRewardsFor';
      } = {} as any,
) {
  return useContractWrite<typeof iStakingABI, 'collectRewardsFor', TMode>({
    abi: iStakingABI,
    functionName: 'collectRewardsFor',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iStakingABI}__ and `functionName` set to `"stakeFor"`.
 */
export function useIStakingStakeFor<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iStakingABI, 'stakeFor'>['request']['abi'],
        'stakeFor',
        TMode
      > & { functionName?: 'stakeFor' }
    : UseContractWriteConfig<typeof iStakingABI, 'stakeFor', TMode> & {
        abi?: never;
        functionName?: 'stakeFor';
      } = {} as any,
) {
  return useContractWrite<typeof iStakingABI, 'stakeFor', TMode>({
    abi: iStakingABI,
    functionName: 'stakeFor',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iStakingABI}__ and `functionName` set to `"withdrawFor"`.
 */
export function useIStakingWithdrawFor<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iStakingABI, 'withdrawFor'>['request']['abi'],
        'withdrawFor',
        TMode
      > & { functionName?: 'withdrawFor' }
    : UseContractWriteConfig<typeof iStakingABI, 'withdrawFor', TMode> & {
        abi?: never;
        functionName?: 'withdrawFor';
      } = {} as any,
) {
  return useContractWrite<typeof iStakingABI, 'withdrawFor', TMode>({
    abi: iStakingABI,
    functionName: 'withdrawFor',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iStakingABI}__.
 */
export function usePrepareIStakingWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iStakingABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iStakingABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof iStakingABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iStakingABI}__ and `functionName` set to `"collectRewardsFor"`.
 */
export function usePrepareIStakingCollectRewardsFor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iStakingABI, 'collectRewardsFor'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iStakingABI,
    functionName: 'collectRewardsFor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iStakingABI, 'collectRewardsFor'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iStakingABI}__ and `functionName` set to `"stakeFor"`.
 */
export function usePrepareIStakingStakeFor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iStakingABI, 'stakeFor'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iStakingABI,
    functionName: 'stakeFor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iStakingABI, 'stakeFor'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iStakingABI}__ and `functionName` set to `"withdrawFor"`.
 */
export function usePrepareIStakingWithdrawFor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iStakingABI, 'withdrawFor'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iStakingABI,
    functionName: 'withdrawFor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iStakingABI, 'withdrawFor'>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iMintableBurnableTokenABI}__.
 */
export function useIMintableBurnableTokenWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iMintableBurnableTokenABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iMintableBurnableTokenABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof iMintableBurnableTokenABI, TFunctionName, TMode>({
    abi: iMintableBurnableTokenABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iMintableBurnableTokenABI}__ and `functionName` set to `"burn"`.
 */
export function useIMintableBurnableTokenBurn<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iMintableBurnableTokenABI,
          'burn'
        >['request']['abi'],
        'burn',
        TMode
      > & { functionName?: 'burn' }
    : UseContractWriteConfig<typeof iMintableBurnableTokenABI, 'burn', TMode> & {
        abi?: never;
        functionName?: 'burn';
      } = {} as any,
) {
  return useContractWrite<typeof iMintableBurnableTokenABI, 'burn', TMode>({
    abi: iMintableBurnableTokenABI,
    functionName: 'burn',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iMintableBurnableTokenABI}__ and `functionName` set to `"mint"`.
 */
export function useIMintableBurnableTokenMint<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iMintableBurnableTokenABI,
          'mint'
        >['request']['abi'],
        'mint',
        TMode
      > & { functionName?: 'mint' }
    : UseContractWriteConfig<typeof iMintableBurnableTokenABI, 'mint', TMode> & {
        abi?: never;
        functionName?: 'mint';
      } = {} as any,
) {
  return useContractWrite<typeof iMintableBurnableTokenABI, 'mint', TMode>({
    abi: iMintableBurnableTokenABI,
    functionName: 'mint',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iMintableBurnableTokenABI}__.
 */
export function usePrepareIMintableBurnableTokenWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iMintableBurnableTokenABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iMintableBurnableTokenABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof iMintableBurnableTokenABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iMintableBurnableTokenABI}__ and `functionName` set to `"burn"`.
 */
export function usePrepareIMintableBurnableTokenBurn(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iMintableBurnableTokenABI, 'burn'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iMintableBurnableTokenABI,
    functionName: 'burn',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iMintableBurnableTokenABI, 'burn'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iMintableBurnableTokenABI}__ and `functionName` set to `"mint"`.
 */
export function usePrepareIMintableBurnableTokenMint(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iMintableBurnableTokenABI, 'mint'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iMintableBurnableTokenABI,
    functionName: 'mint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iMintableBurnableTokenABI, 'mint'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20Sol6ABI}__.
 */
export function useIerc20Sol6Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof ierc20Sol6ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20Sol6ABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: ierc20Sol6ABI, ...config } as UseContractReadConfig<
    typeof ierc20Sol6ABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20Sol6ABI}__ and `functionName` set to `"allowance"`.
 */
export function useIerc20Sol6Allowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof ierc20Sol6ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20Sol6ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20Sol6ABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof ierc20Sol6ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20Sol6ABI}__.
 */
export function useIerc20Sol6Write<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ierc20Sol6ABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof ierc20Sol6ABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof ierc20Sol6ABI, TFunctionName, TMode>({
    abi: ierc20Sol6ABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20Sol6ABI}__ and `functionName` set to `"approve"`.
 */
export function useIerc20Sol6Approve<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ierc20Sol6ABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof ierc20Sol6ABI, 'approve', TMode> & {
        abi?: never;
        functionName?: 'approve';
      } = {} as any,
) {
  return useContractWrite<typeof ierc20Sol6ABI, 'approve', TMode>({
    abi: ierc20Sol6ABI,
    functionName: 'approve',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20Sol6ABI}__ and `functionName` set to `"transfer"`.
 */
export function useIerc20Sol6Transfer<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ierc20Sol6ABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof ierc20Sol6ABI, 'transfer', TMode> & {
        abi?: never;
        functionName?: 'transfer';
      } = {} as any,
) {
  return useContractWrite<typeof ierc20Sol6ABI, 'transfer', TMode>({
    abi: ierc20Sol6ABI,
    functionName: 'transfer',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20Sol6ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useIerc20Sol6TransferFrom<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20Sol6ABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof ierc20Sol6ABI, 'transferFrom', TMode> & {
        abi?: never;
        functionName?: 'transferFrom';
      } = {} as any,
) {
  return useContractWrite<typeof ierc20Sol6ABI, 'transferFrom', TMode>({
    abi: ierc20Sol6ABI,
    functionName: 'transferFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20Sol6ABI}__.
 */
export function usePrepareIerc20Sol6Write<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20Sol6ABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20Sol6ABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20Sol6ABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20Sol6ABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareIerc20Sol6Approve(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20Sol6ABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20Sol6ABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20Sol6ABI, 'approve'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20Sol6ABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareIerc20Sol6Transfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20Sol6ABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20Sol6ABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20Sol6ABI, 'transfer'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20Sol6ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareIerc20Sol6TransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20Sol6ABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20Sol6ABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20Sol6ABI, 'transferFrom'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__.
 */
export function useGovernorCountingSimpleRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"BALLOT_TYPEHASH"`.
 */
export function useGovernorCountingSimpleBallotTypehash<
  TFunctionName extends 'BALLOT_TYPEHASH',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'BALLOT_TYPEHASH',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"COUNTING_MODE"`.
 */
export function useGovernorCountingSimpleCountingMode<
  TFunctionName extends 'COUNTING_MODE',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'COUNTING_MODE',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"EXTENDED_BALLOT_TYPEHASH"`.
 */
export function useGovernorCountingSimpleExtendedBallotTypehash<
  TFunctionName extends 'EXTENDED_BALLOT_TYPEHASH',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'EXTENDED_BALLOT_TYPEHASH',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"getVotes"`.
 */
export function useGovernorCountingSimpleGetVotes<
  TFunctionName extends 'getVotes',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'getVotes',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"getVotesWithParams"`.
 */
export function useGovernorCountingSimpleGetVotesWithParams<
  TFunctionName extends 'getVotesWithParams',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'getVotesWithParams',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"hasVoted"`.
 */
export function useGovernorCountingSimpleHasVoted<
  TFunctionName extends 'hasVoted',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'hasVoted',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"hashProposal"`.
 */
export function useGovernorCountingSimpleHashProposal<
  TFunctionName extends 'hashProposal',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'hashProposal',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"name"`.
 */
export function useGovernorCountingSimpleName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"proposalDeadline"`.
 */
export function useGovernorCountingSimpleProposalDeadline<
  TFunctionName extends 'proposalDeadline',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'proposalDeadline',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"proposalSnapshot"`.
 */
export function useGovernorCountingSimpleProposalSnapshot<
  TFunctionName extends 'proposalSnapshot',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'proposalSnapshot',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"proposalThreshold"`.
 */
export function useGovernorCountingSimpleProposalThreshold<
  TFunctionName extends 'proposalThreshold',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'proposalThreshold',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"proposalVotes"`.
 */
export function useGovernorCountingSimpleProposalVotes<
  TFunctionName extends 'proposalVotes',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'proposalVotes',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"quorum"`.
 */
export function useGovernorCountingSimpleQuorum<
  TFunctionName extends 'quorum',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'quorum',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"state"`.
 */
export function useGovernorCountingSimpleState<
  TFunctionName extends 'state',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'state',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useGovernorCountingSimpleSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"version"`.
 */
export function useGovernorCountingSimpleVersion<
  TFunctionName extends 'version',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'version',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"votingDelay"`.
 */
export function useGovernorCountingSimpleVotingDelay<
  TFunctionName extends 'votingDelay',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'votingDelay',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"votingPeriod"`.
 */
export function useGovernorCountingSimpleVotingPeriod<
  TFunctionName extends 'votingPeriod',
  TSelectData = ReadContractResult<typeof governorCountingSimpleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorCountingSimpleABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorCountingSimpleABI,
    functionName: 'votingPeriod',
    ...config,
  } as UseContractReadConfig<
    typeof governorCountingSimpleABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__.
 */
export function useGovernorCountingSimpleWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorCountingSimpleABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof governorCountingSimpleABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof governorCountingSimpleABI, TFunctionName, TMode>({
    abi: governorCountingSimpleABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"castVote"`.
 */
export function useGovernorCountingSimpleCastVote<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorCountingSimpleABI,
          'castVote'
        >['request']['abi'],
        'castVote',
        TMode
      > & { functionName?: 'castVote' }
    : UseContractWriteConfig<typeof governorCountingSimpleABI, 'castVote', TMode> & {
        abi?: never;
        functionName?: 'castVote';
      } = {} as any,
) {
  return useContractWrite<typeof governorCountingSimpleABI, 'castVote', TMode>({
    abi: governorCountingSimpleABI,
    functionName: 'castVote',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"castVoteBySig"`.
 */
export function useGovernorCountingSimpleCastVoteBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorCountingSimpleABI,
          'castVoteBySig'
        >['request']['abi'],
        'castVoteBySig',
        TMode
      > & { functionName?: 'castVoteBySig' }
    : UseContractWriteConfig<typeof governorCountingSimpleABI, 'castVoteBySig', TMode> & {
        abi?: never;
        functionName?: 'castVoteBySig';
      } = {} as any,
) {
  return useContractWrite<typeof governorCountingSimpleABI, 'castVoteBySig', TMode>({
    abi: governorCountingSimpleABI,
    functionName: 'castVoteBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"castVoteWithReason"`.
 */
export function useGovernorCountingSimpleCastVoteWithReason<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorCountingSimpleABI,
          'castVoteWithReason'
        >['request']['abi'],
        'castVoteWithReason',
        TMode
      > & { functionName?: 'castVoteWithReason' }
    : UseContractWriteConfig<
        typeof governorCountingSimpleABI,
        'castVoteWithReason',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReason';
      } = {} as any,
) {
  return useContractWrite<typeof governorCountingSimpleABI, 'castVoteWithReason', TMode>({
    abi: governorCountingSimpleABI,
    functionName: 'castVoteWithReason',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"castVoteWithReasonAndParams"`.
 */
export function useGovernorCountingSimpleCastVoteWithReasonAndParams<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorCountingSimpleABI,
          'castVoteWithReasonAndParams'
        >['request']['abi'],
        'castVoteWithReasonAndParams',
        TMode
      > & { functionName?: 'castVoteWithReasonAndParams' }
    : UseContractWriteConfig<
        typeof governorCountingSimpleABI,
        'castVoteWithReasonAndParams',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReasonAndParams';
      } = {} as any,
) {
  return useContractWrite<
    typeof governorCountingSimpleABI,
    'castVoteWithReasonAndParams',
    TMode
  >({
    abi: governorCountingSimpleABI,
    functionName: 'castVoteWithReasonAndParams',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"castVoteWithReasonAndParamsBySig"`.
 */
export function useGovernorCountingSimpleCastVoteWithReasonAndParamsBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorCountingSimpleABI,
          'castVoteWithReasonAndParamsBySig'
        >['request']['abi'],
        'castVoteWithReasonAndParamsBySig',
        TMode
      > & { functionName?: 'castVoteWithReasonAndParamsBySig' }
    : UseContractWriteConfig<
        typeof governorCountingSimpleABI,
        'castVoteWithReasonAndParamsBySig',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReasonAndParamsBySig';
      } = {} as any,
) {
  return useContractWrite<
    typeof governorCountingSimpleABI,
    'castVoteWithReasonAndParamsBySig',
    TMode
  >({
    abi: governorCountingSimpleABI,
    functionName: 'castVoteWithReasonAndParamsBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"execute"`.
 */
export function useGovernorCountingSimpleExecute<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorCountingSimpleABI,
          'execute'
        >['request']['abi'],
        'execute',
        TMode
      > & { functionName?: 'execute' }
    : UseContractWriteConfig<typeof governorCountingSimpleABI, 'execute', TMode> & {
        abi?: never;
        functionName?: 'execute';
      } = {} as any,
) {
  return useContractWrite<typeof governorCountingSimpleABI, 'execute', TMode>({
    abi: governorCountingSimpleABI,
    functionName: 'execute',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function useGovernorCountingSimpleOnErc1155BatchReceived<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorCountingSimpleABI,
          'onERC1155BatchReceived'
        >['request']['abi'],
        'onERC1155BatchReceived',
        TMode
      > & { functionName?: 'onERC1155BatchReceived' }
    : UseContractWriteConfig<
        typeof governorCountingSimpleABI,
        'onERC1155BatchReceived',
        TMode
      > & {
        abi?: never;
        functionName?: 'onERC1155BatchReceived';
      } = {} as any,
) {
  return useContractWrite<
    typeof governorCountingSimpleABI,
    'onERC1155BatchReceived',
    TMode
  >({
    abi: governorCountingSimpleABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function useGovernorCountingSimpleOnErc1155Received<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorCountingSimpleABI,
          'onERC1155Received'
        >['request']['abi'],
        'onERC1155Received',
        TMode
      > & { functionName?: 'onERC1155Received' }
    : UseContractWriteConfig<
        typeof governorCountingSimpleABI,
        'onERC1155Received',
        TMode
      > & {
        abi?: never;
        functionName?: 'onERC1155Received';
      } = {} as any,
) {
  return useContractWrite<typeof governorCountingSimpleABI, 'onERC1155Received', TMode>({
    abi: governorCountingSimpleABI,
    functionName: 'onERC1155Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function useGovernorCountingSimpleOnErc721Received<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorCountingSimpleABI,
          'onERC721Received'
        >['request']['abi'],
        'onERC721Received',
        TMode
      > & { functionName?: 'onERC721Received' }
    : UseContractWriteConfig<
        typeof governorCountingSimpleABI,
        'onERC721Received',
        TMode
      > & {
        abi?: never;
        functionName?: 'onERC721Received';
      } = {} as any,
) {
  return useContractWrite<typeof governorCountingSimpleABI, 'onERC721Received', TMode>({
    abi: governorCountingSimpleABI,
    functionName: 'onERC721Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"propose"`.
 */
export function useGovernorCountingSimplePropose<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorCountingSimpleABI,
          'propose'
        >['request']['abi'],
        'propose',
        TMode
      > & { functionName?: 'propose' }
    : UseContractWriteConfig<typeof governorCountingSimpleABI, 'propose', TMode> & {
        abi?: never;
        functionName?: 'propose';
      } = {} as any,
) {
  return useContractWrite<typeof governorCountingSimpleABI, 'propose', TMode>({
    abi: governorCountingSimpleABI,
    functionName: 'propose',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"relay"`.
 */
export function useGovernorCountingSimpleRelay<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorCountingSimpleABI,
          'relay'
        >['request']['abi'],
        'relay',
        TMode
      > & { functionName?: 'relay' }
    : UseContractWriteConfig<typeof governorCountingSimpleABI, 'relay', TMode> & {
        abi?: never;
        functionName?: 'relay';
      } = {} as any,
) {
  return useContractWrite<typeof governorCountingSimpleABI, 'relay', TMode>({
    abi: governorCountingSimpleABI,
    functionName: 'relay',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__.
 */
export function usePrepareGovernorCountingSimpleWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorCountingSimpleABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorCountingSimpleABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorCountingSimpleABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"castVote"`.
 */
export function usePrepareGovernorCountingSimpleCastVote(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorCountingSimpleABI, 'castVote'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorCountingSimpleABI,
    functionName: 'castVote',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorCountingSimpleABI, 'castVote'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"castVoteBySig"`.
 */
export function usePrepareGovernorCountingSimpleCastVoteBySig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorCountingSimpleABI, 'castVoteBySig'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorCountingSimpleABI,
    functionName: 'castVoteBySig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorCountingSimpleABI, 'castVoteBySig'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"castVoteWithReason"`.
 */
export function usePrepareGovernorCountingSimpleCastVoteWithReason(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorCountingSimpleABI, 'castVoteWithReason'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorCountingSimpleABI,
    functionName: 'castVoteWithReason',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorCountingSimpleABI,
    'castVoteWithReason'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"castVoteWithReasonAndParams"`.
 */
export function usePrepareGovernorCountingSimpleCastVoteWithReasonAndParams(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governorCountingSimpleABI,
      'castVoteWithReasonAndParams'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorCountingSimpleABI,
    functionName: 'castVoteWithReasonAndParams',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorCountingSimpleABI,
    'castVoteWithReasonAndParams'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"castVoteWithReasonAndParamsBySig"`.
 */
export function usePrepareGovernorCountingSimpleCastVoteWithReasonAndParamsBySig(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governorCountingSimpleABI,
      'castVoteWithReasonAndParamsBySig'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorCountingSimpleABI,
    functionName: 'castVoteWithReasonAndParamsBySig',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorCountingSimpleABI,
    'castVoteWithReasonAndParamsBySig'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"execute"`.
 */
export function usePrepareGovernorCountingSimpleExecute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorCountingSimpleABI, 'execute'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorCountingSimpleABI,
    functionName: 'execute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorCountingSimpleABI, 'execute'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function usePrepareGovernorCountingSimpleOnErc1155BatchReceived(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governorCountingSimpleABI,
      'onERC1155BatchReceived'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorCountingSimpleABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorCountingSimpleABI,
    'onERC1155BatchReceived'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function usePrepareGovernorCountingSimpleOnErc1155Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorCountingSimpleABI, 'onERC1155Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorCountingSimpleABI,
    functionName: 'onERC1155Received',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorCountingSimpleABI,
    'onERC1155Received'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function usePrepareGovernorCountingSimpleOnErc721Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorCountingSimpleABI, 'onERC721Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorCountingSimpleABI,
    functionName: 'onERC721Received',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorCountingSimpleABI,
    'onERC721Received'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"propose"`.
 */
export function usePrepareGovernorCountingSimplePropose(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorCountingSimpleABI, 'propose'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorCountingSimpleABI,
    functionName: 'propose',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorCountingSimpleABI, 'propose'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorCountingSimpleABI}__ and `functionName` set to `"relay"`.
 */
export function usePrepareGovernorCountingSimpleRelay(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorCountingSimpleABI, 'relay'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorCountingSimpleABI,
    functionName: 'relay',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorCountingSimpleABI, 'relay'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__.
 */
export function useGovernorSettingsRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: governorSettingsABI, ...config } as UseContractReadConfig<
    typeof governorSettingsABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"BALLOT_TYPEHASH"`.
 */
export function useGovernorSettingsBallotTypehash<
  TFunctionName extends 'BALLOT_TYPEHASH',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'BALLOT_TYPEHASH',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"COUNTING_MODE"`.
 */
export function useGovernorSettingsCountingMode<
  TFunctionName extends 'COUNTING_MODE',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'COUNTING_MODE',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"EXTENDED_BALLOT_TYPEHASH"`.
 */
export function useGovernorSettingsExtendedBallotTypehash<
  TFunctionName extends 'EXTENDED_BALLOT_TYPEHASH',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'EXTENDED_BALLOT_TYPEHASH',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"getVotes"`.
 */
export function useGovernorSettingsGetVotes<
  TFunctionName extends 'getVotes',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'getVotes',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"getVotesWithParams"`.
 */
export function useGovernorSettingsGetVotesWithParams<
  TFunctionName extends 'getVotesWithParams',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'getVotesWithParams',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"hasVoted"`.
 */
export function useGovernorSettingsHasVoted<
  TFunctionName extends 'hasVoted',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'hasVoted',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"hashProposal"`.
 */
export function useGovernorSettingsHashProposal<
  TFunctionName extends 'hashProposal',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'hashProposal',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"name"`.
 */
export function useGovernorSettingsName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"proposalDeadline"`.
 */
export function useGovernorSettingsProposalDeadline<
  TFunctionName extends 'proposalDeadline',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'proposalDeadline',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"proposalSnapshot"`.
 */
export function useGovernorSettingsProposalSnapshot<
  TFunctionName extends 'proposalSnapshot',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'proposalSnapshot',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"proposalThreshold"`.
 */
export function useGovernorSettingsProposalThreshold<
  TFunctionName extends 'proposalThreshold',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'proposalThreshold',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"quorum"`.
 */
export function useGovernorSettingsQuorum<
  TFunctionName extends 'quorum',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'quorum',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"state"`.
 */
export function useGovernorSettingsState<
  TFunctionName extends 'state',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'state',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useGovernorSettingsSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"version"`.
 */
export function useGovernorSettingsVersion<
  TFunctionName extends 'version',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'version',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"votingDelay"`.
 */
export function useGovernorSettingsVotingDelay<
  TFunctionName extends 'votingDelay',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'votingDelay',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"votingPeriod"`.
 */
export function useGovernorSettingsVotingPeriod<
  TFunctionName extends 'votingPeriod',
  TSelectData = ReadContractResult<typeof governorSettingsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorSettingsABI,
    functionName: 'votingPeriod',
    ...config,
  } as UseContractReadConfig<typeof governorSettingsABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorSettingsABI}__.
 */
export function useGovernorSettingsWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof governorSettingsABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof governorSettingsABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof governorSettingsABI, TFunctionName, TMode>({
    abi: governorSettingsABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"castVote"`.
 */
export function useGovernorSettingsCastVote<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorSettingsABI,
          'castVote'
        >['request']['abi'],
        'castVote',
        TMode
      > & { functionName?: 'castVote' }
    : UseContractWriteConfig<typeof governorSettingsABI, 'castVote', TMode> & {
        abi?: never;
        functionName?: 'castVote';
      } = {} as any,
) {
  return useContractWrite<typeof governorSettingsABI, 'castVote', TMode>({
    abi: governorSettingsABI,
    functionName: 'castVote',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"castVoteBySig"`.
 */
export function useGovernorSettingsCastVoteBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorSettingsABI,
          'castVoteBySig'
        >['request']['abi'],
        'castVoteBySig',
        TMode
      > & { functionName?: 'castVoteBySig' }
    : UseContractWriteConfig<typeof governorSettingsABI, 'castVoteBySig', TMode> & {
        abi?: never;
        functionName?: 'castVoteBySig';
      } = {} as any,
) {
  return useContractWrite<typeof governorSettingsABI, 'castVoteBySig', TMode>({
    abi: governorSettingsABI,
    functionName: 'castVoteBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"castVoteWithReason"`.
 */
export function useGovernorSettingsCastVoteWithReason<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorSettingsABI,
          'castVoteWithReason'
        >['request']['abi'],
        'castVoteWithReason',
        TMode
      > & { functionName?: 'castVoteWithReason' }
    : UseContractWriteConfig<typeof governorSettingsABI, 'castVoteWithReason', TMode> & {
        abi?: never;
        functionName?: 'castVoteWithReason';
      } = {} as any,
) {
  return useContractWrite<typeof governorSettingsABI, 'castVoteWithReason', TMode>({
    abi: governorSettingsABI,
    functionName: 'castVoteWithReason',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"castVoteWithReasonAndParams"`.
 */
export function useGovernorSettingsCastVoteWithReasonAndParams<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorSettingsABI,
          'castVoteWithReasonAndParams'
        >['request']['abi'],
        'castVoteWithReasonAndParams',
        TMode
      > & { functionName?: 'castVoteWithReasonAndParams' }
    : UseContractWriteConfig<
        typeof governorSettingsABI,
        'castVoteWithReasonAndParams',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReasonAndParams';
      } = {} as any,
) {
  return useContractWrite<
    typeof governorSettingsABI,
    'castVoteWithReasonAndParams',
    TMode
  >({
    abi: governorSettingsABI,
    functionName: 'castVoteWithReasonAndParams',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"castVoteWithReasonAndParamsBySig"`.
 */
export function useGovernorSettingsCastVoteWithReasonAndParamsBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorSettingsABI,
          'castVoteWithReasonAndParamsBySig'
        >['request']['abi'],
        'castVoteWithReasonAndParamsBySig',
        TMode
      > & { functionName?: 'castVoteWithReasonAndParamsBySig' }
    : UseContractWriteConfig<
        typeof governorSettingsABI,
        'castVoteWithReasonAndParamsBySig',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReasonAndParamsBySig';
      } = {} as any,
) {
  return useContractWrite<
    typeof governorSettingsABI,
    'castVoteWithReasonAndParamsBySig',
    TMode
  >({
    abi: governorSettingsABI,
    functionName: 'castVoteWithReasonAndParamsBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"execute"`.
 */
export function useGovernorSettingsExecute<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorSettingsABI,
          'execute'
        >['request']['abi'],
        'execute',
        TMode
      > & { functionName?: 'execute' }
    : UseContractWriteConfig<typeof governorSettingsABI, 'execute', TMode> & {
        abi?: never;
        functionName?: 'execute';
      } = {} as any,
) {
  return useContractWrite<typeof governorSettingsABI, 'execute', TMode>({
    abi: governorSettingsABI,
    functionName: 'execute',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function useGovernorSettingsOnErc1155BatchReceived<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorSettingsABI,
          'onERC1155BatchReceived'
        >['request']['abi'],
        'onERC1155BatchReceived',
        TMode
      > & { functionName?: 'onERC1155BatchReceived' }
    : UseContractWriteConfig<
        typeof governorSettingsABI,
        'onERC1155BatchReceived',
        TMode
      > & {
        abi?: never;
        functionName?: 'onERC1155BatchReceived';
      } = {} as any,
) {
  return useContractWrite<typeof governorSettingsABI, 'onERC1155BatchReceived', TMode>({
    abi: governorSettingsABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function useGovernorSettingsOnErc1155Received<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorSettingsABI,
          'onERC1155Received'
        >['request']['abi'],
        'onERC1155Received',
        TMode
      > & { functionName?: 'onERC1155Received' }
    : UseContractWriteConfig<typeof governorSettingsABI, 'onERC1155Received', TMode> & {
        abi?: never;
        functionName?: 'onERC1155Received';
      } = {} as any,
) {
  return useContractWrite<typeof governorSettingsABI, 'onERC1155Received', TMode>({
    abi: governorSettingsABI,
    functionName: 'onERC1155Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function useGovernorSettingsOnErc721Received<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorSettingsABI,
          'onERC721Received'
        >['request']['abi'],
        'onERC721Received',
        TMode
      > & { functionName?: 'onERC721Received' }
    : UseContractWriteConfig<typeof governorSettingsABI, 'onERC721Received', TMode> & {
        abi?: never;
        functionName?: 'onERC721Received';
      } = {} as any,
) {
  return useContractWrite<typeof governorSettingsABI, 'onERC721Received', TMode>({
    abi: governorSettingsABI,
    functionName: 'onERC721Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"propose"`.
 */
export function useGovernorSettingsPropose<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorSettingsABI,
          'propose'
        >['request']['abi'],
        'propose',
        TMode
      > & { functionName?: 'propose' }
    : UseContractWriteConfig<typeof governorSettingsABI, 'propose', TMode> & {
        abi?: never;
        functionName?: 'propose';
      } = {} as any,
) {
  return useContractWrite<typeof governorSettingsABI, 'propose', TMode>({
    abi: governorSettingsABI,
    functionName: 'propose',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"relay"`.
 */
export function useGovernorSettingsRelay<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof governorSettingsABI, 'relay'>['request']['abi'],
        'relay',
        TMode
      > & { functionName?: 'relay' }
    : UseContractWriteConfig<typeof governorSettingsABI, 'relay', TMode> & {
        abi?: never;
        functionName?: 'relay';
      } = {} as any,
) {
  return useContractWrite<typeof governorSettingsABI, 'relay', TMode>({
    abi: governorSettingsABI,
    functionName: 'relay',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"setProposalThreshold"`.
 */
export function useGovernorSettingsSetProposalThreshold<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorSettingsABI,
          'setProposalThreshold'
        >['request']['abi'],
        'setProposalThreshold',
        TMode
      > & { functionName?: 'setProposalThreshold' }
    : UseContractWriteConfig<
        typeof governorSettingsABI,
        'setProposalThreshold',
        TMode
      > & {
        abi?: never;
        functionName?: 'setProposalThreshold';
      } = {} as any,
) {
  return useContractWrite<typeof governorSettingsABI, 'setProposalThreshold', TMode>({
    abi: governorSettingsABI,
    functionName: 'setProposalThreshold',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"setVotingDelay"`.
 */
export function useGovernorSettingsSetVotingDelay<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorSettingsABI,
          'setVotingDelay'
        >['request']['abi'],
        'setVotingDelay',
        TMode
      > & { functionName?: 'setVotingDelay' }
    : UseContractWriteConfig<typeof governorSettingsABI, 'setVotingDelay', TMode> & {
        abi?: never;
        functionName?: 'setVotingDelay';
      } = {} as any,
) {
  return useContractWrite<typeof governorSettingsABI, 'setVotingDelay', TMode>({
    abi: governorSettingsABI,
    functionName: 'setVotingDelay',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"setVotingPeriod"`.
 */
export function useGovernorSettingsSetVotingPeriod<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorSettingsABI,
          'setVotingPeriod'
        >['request']['abi'],
        'setVotingPeriod',
        TMode
      > & { functionName?: 'setVotingPeriod' }
    : UseContractWriteConfig<typeof governorSettingsABI, 'setVotingPeriod', TMode> & {
        abi?: never;
        functionName?: 'setVotingPeriod';
      } = {} as any,
) {
  return useContractWrite<typeof governorSettingsABI, 'setVotingPeriod', TMode>({
    abi: governorSettingsABI,
    functionName: 'setVotingPeriod',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorSettingsABI}__.
 */
export function usePrepareGovernorSettingsWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorSettingsABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorSettingsABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorSettingsABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"castVote"`.
 */
export function usePrepareGovernorSettingsCastVote(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorSettingsABI, 'castVote'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorSettingsABI,
    functionName: 'castVote',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorSettingsABI, 'castVote'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"castVoteBySig"`.
 */
export function usePrepareGovernorSettingsCastVoteBySig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorSettingsABI, 'castVoteBySig'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorSettingsABI,
    functionName: 'castVoteBySig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorSettingsABI, 'castVoteBySig'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"castVoteWithReason"`.
 */
export function usePrepareGovernorSettingsCastVoteWithReason(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorSettingsABI, 'castVoteWithReason'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorSettingsABI,
    functionName: 'castVoteWithReason',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorSettingsABI, 'castVoteWithReason'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"castVoteWithReasonAndParams"`.
 */
export function usePrepareGovernorSettingsCastVoteWithReasonAndParams(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governorSettingsABI,
      'castVoteWithReasonAndParams'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorSettingsABI,
    functionName: 'castVoteWithReasonAndParams',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorSettingsABI,
    'castVoteWithReasonAndParams'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"castVoteWithReasonAndParamsBySig"`.
 */
export function usePrepareGovernorSettingsCastVoteWithReasonAndParamsBySig(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governorSettingsABI,
      'castVoteWithReasonAndParamsBySig'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorSettingsABI,
    functionName: 'castVoteWithReasonAndParamsBySig',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorSettingsABI,
    'castVoteWithReasonAndParamsBySig'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"execute"`.
 */
export function usePrepareGovernorSettingsExecute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorSettingsABI, 'execute'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorSettingsABI,
    functionName: 'execute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorSettingsABI, 'execute'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function usePrepareGovernorSettingsOnErc1155BatchReceived(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorSettingsABI, 'onERC1155BatchReceived'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorSettingsABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorSettingsABI,
    'onERC1155BatchReceived'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function usePrepareGovernorSettingsOnErc1155Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorSettingsABI, 'onERC1155Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorSettingsABI,
    functionName: 'onERC1155Received',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorSettingsABI, 'onERC1155Received'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function usePrepareGovernorSettingsOnErc721Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorSettingsABI, 'onERC721Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorSettingsABI,
    functionName: 'onERC721Received',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorSettingsABI, 'onERC721Received'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"propose"`.
 */
export function usePrepareGovernorSettingsPropose(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorSettingsABI, 'propose'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorSettingsABI,
    functionName: 'propose',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorSettingsABI, 'propose'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"relay"`.
 */
export function usePrepareGovernorSettingsRelay(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorSettingsABI, 'relay'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorSettingsABI,
    functionName: 'relay',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorSettingsABI, 'relay'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"setProposalThreshold"`.
 */
export function usePrepareGovernorSettingsSetProposalThreshold(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorSettingsABI, 'setProposalThreshold'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorSettingsABI,
    functionName: 'setProposalThreshold',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorSettingsABI, 'setProposalThreshold'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"setVotingDelay"`.
 */
export function usePrepareGovernorSettingsSetVotingDelay(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorSettingsABI, 'setVotingDelay'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorSettingsABI,
    functionName: 'setVotingDelay',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorSettingsABI, 'setVotingDelay'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorSettingsABI}__ and `functionName` set to `"setVotingPeriod"`.
 */
export function usePrepareGovernorSettingsSetVotingPeriod(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorSettingsABI, 'setVotingPeriod'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorSettingsABI,
    functionName: 'setVotingPeriod',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorSettingsABI, 'setVotingPeriod'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__.
 */
export function useGovernorVotesRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: governorVotesABI, ...config } as UseContractReadConfig<
    typeof governorVotesABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"BALLOT_TYPEHASH"`.
 */
export function useGovernorVotesBallotTypehash<
  TFunctionName extends 'BALLOT_TYPEHASH',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'BALLOT_TYPEHASH',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"COUNTING_MODE"`.
 */
export function useGovernorVotesCountingMode<
  TFunctionName extends 'COUNTING_MODE',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'COUNTING_MODE',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"EXTENDED_BALLOT_TYPEHASH"`.
 */
export function useGovernorVotesExtendedBallotTypehash<
  TFunctionName extends 'EXTENDED_BALLOT_TYPEHASH',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'EXTENDED_BALLOT_TYPEHASH',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"getVotes"`.
 */
export function useGovernorVotesGetVotes<
  TFunctionName extends 'getVotes',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'getVotes',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"getVotesWithParams"`.
 */
export function useGovernorVotesGetVotesWithParams<
  TFunctionName extends 'getVotesWithParams',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'getVotesWithParams',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"hasVoted"`.
 */
export function useGovernorVotesHasVoted<
  TFunctionName extends 'hasVoted',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'hasVoted',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"hashProposal"`.
 */
export function useGovernorVotesHashProposal<
  TFunctionName extends 'hashProposal',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'hashProposal',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"name"`.
 */
export function useGovernorVotesName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"proposalDeadline"`.
 */
export function useGovernorVotesProposalDeadline<
  TFunctionName extends 'proposalDeadline',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'proposalDeadline',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"proposalSnapshot"`.
 */
export function useGovernorVotesProposalSnapshot<
  TFunctionName extends 'proposalSnapshot',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'proposalSnapshot',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"proposalThreshold"`.
 */
export function useGovernorVotesProposalThreshold<
  TFunctionName extends 'proposalThreshold',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'proposalThreshold',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"quorum"`.
 */
export function useGovernorVotesQuorum<
  TFunctionName extends 'quorum',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'quorum',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"state"`.
 */
export function useGovernorVotesState<
  TFunctionName extends 'state',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'state',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useGovernorVotesSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"token"`.
 */
export function useGovernorVotesToken<
  TFunctionName extends 'token',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'token',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"version"`.
 */
export function useGovernorVotesVersion<
  TFunctionName extends 'version',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'version',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"votingDelay"`.
 */
export function useGovernorVotesVotingDelay<
  TFunctionName extends 'votingDelay',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'votingDelay',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"votingPeriod"`.
 */
export function useGovernorVotesVotingPeriod<
  TFunctionName extends 'votingPeriod',
  TSelectData = ReadContractResult<typeof governorVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorVotesABI,
    functionName: 'votingPeriod',
    ...config,
  } as UseContractReadConfig<typeof governorVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorVotesABI}__.
 */
export function useGovernorVotesWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof governorVotesABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof governorVotesABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof governorVotesABI, TFunctionName, TMode>({
    abi: governorVotesABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"castVote"`.
 */
export function useGovernorVotesCastVote<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof governorVotesABI, 'castVote'>['request']['abi'],
        'castVote',
        TMode
      > & { functionName?: 'castVote' }
    : UseContractWriteConfig<typeof governorVotesABI, 'castVote', TMode> & {
        abi?: never;
        functionName?: 'castVote';
      } = {} as any,
) {
  return useContractWrite<typeof governorVotesABI, 'castVote', TMode>({
    abi: governorVotesABI,
    functionName: 'castVote',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"castVoteBySig"`.
 */
export function useGovernorVotesCastVoteBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorVotesABI,
          'castVoteBySig'
        >['request']['abi'],
        'castVoteBySig',
        TMode
      > & { functionName?: 'castVoteBySig' }
    : UseContractWriteConfig<typeof governorVotesABI, 'castVoteBySig', TMode> & {
        abi?: never;
        functionName?: 'castVoteBySig';
      } = {} as any,
) {
  return useContractWrite<typeof governorVotesABI, 'castVoteBySig', TMode>({
    abi: governorVotesABI,
    functionName: 'castVoteBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"castVoteWithReason"`.
 */
export function useGovernorVotesCastVoteWithReason<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorVotesABI,
          'castVoteWithReason'
        >['request']['abi'],
        'castVoteWithReason',
        TMode
      > & { functionName?: 'castVoteWithReason' }
    : UseContractWriteConfig<typeof governorVotesABI, 'castVoteWithReason', TMode> & {
        abi?: never;
        functionName?: 'castVoteWithReason';
      } = {} as any,
) {
  return useContractWrite<typeof governorVotesABI, 'castVoteWithReason', TMode>({
    abi: governorVotesABI,
    functionName: 'castVoteWithReason',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"castVoteWithReasonAndParams"`.
 */
export function useGovernorVotesCastVoteWithReasonAndParams<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorVotesABI,
          'castVoteWithReasonAndParams'
        >['request']['abi'],
        'castVoteWithReasonAndParams',
        TMode
      > & { functionName?: 'castVoteWithReasonAndParams' }
    : UseContractWriteConfig<
        typeof governorVotesABI,
        'castVoteWithReasonAndParams',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReasonAndParams';
      } = {} as any,
) {
  return useContractWrite<typeof governorVotesABI, 'castVoteWithReasonAndParams', TMode>({
    abi: governorVotesABI,
    functionName: 'castVoteWithReasonAndParams',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"castVoteWithReasonAndParamsBySig"`.
 */
export function useGovernorVotesCastVoteWithReasonAndParamsBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorVotesABI,
          'castVoteWithReasonAndParamsBySig'
        >['request']['abi'],
        'castVoteWithReasonAndParamsBySig',
        TMode
      > & { functionName?: 'castVoteWithReasonAndParamsBySig' }
    : UseContractWriteConfig<
        typeof governorVotesABI,
        'castVoteWithReasonAndParamsBySig',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReasonAndParamsBySig';
      } = {} as any,
) {
  return useContractWrite<
    typeof governorVotesABI,
    'castVoteWithReasonAndParamsBySig',
    TMode
  >({
    abi: governorVotesABI,
    functionName: 'castVoteWithReasonAndParamsBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"execute"`.
 */
export function useGovernorVotesExecute<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof governorVotesABI, 'execute'>['request']['abi'],
        'execute',
        TMode
      > & { functionName?: 'execute' }
    : UseContractWriteConfig<typeof governorVotesABI, 'execute', TMode> & {
        abi?: never;
        functionName?: 'execute';
      } = {} as any,
) {
  return useContractWrite<typeof governorVotesABI, 'execute', TMode>({
    abi: governorVotesABI,
    functionName: 'execute',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function useGovernorVotesOnErc1155BatchReceived<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorVotesABI,
          'onERC1155BatchReceived'
        >['request']['abi'],
        'onERC1155BatchReceived',
        TMode
      > & { functionName?: 'onERC1155BatchReceived' }
    : UseContractWriteConfig<typeof governorVotesABI, 'onERC1155BatchReceived', TMode> & {
        abi?: never;
        functionName?: 'onERC1155BatchReceived';
      } = {} as any,
) {
  return useContractWrite<typeof governorVotesABI, 'onERC1155BatchReceived', TMode>({
    abi: governorVotesABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function useGovernorVotesOnErc1155Received<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorVotesABI,
          'onERC1155Received'
        >['request']['abi'],
        'onERC1155Received',
        TMode
      > & { functionName?: 'onERC1155Received' }
    : UseContractWriteConfig<typeof governorVotesABI, 'onERC1155Received', TMode> & {
        abi?: never;
        functionName?: 'onERC1155Received';
      } = {} as any,
) {
  return useContractWrite<typeof governorVotesABI, 'onERC1155Received', TMode>({
    abi: governorVotesABI,
    functionName: 'onERC1155Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function useGovernorVotesOnErc721Received<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorVotesABI,
          'onERC721Received'
        >['request']['abi'],
        'onERC721Received',
        TMode
      > & { functionName?: 'onERC721Received' }
    : UseContractWriteConfig<typeof governorVotesABI, 'onERC721Received', TMode> & {
        abi?: never;
        functionName?: 'onERC721Received';
      } = {} as any,
) {
  return useContractWrite<typeof governorVotesABI, 'onERC721Received', TMode>({
    abi: governorVotesABI,
    functionName: 'onERC721Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"propose"`.
 */
export function useGovernorVotesPropose<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof governorVotesABI, 'propose'>['request']['abi'],
        'propose',
        TMode
      > & { functionName?: 'propose' }
    : UseContractWriteConfig<typeof governorVotesABI, 'propose', TMode> & {
        abi?: never;
        functionName?: 'propose';
      } = {} as any,
) {
  return useContractWrite<typeof governorVotesABI, 'propose', TMode>({
    abi: governorVotesABI,
    functionName: 'propose',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"relay"`.
 */
export function useGovernorVotesRelay<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof governorVotesABI, 'relay'>['request']['abi'],
        'relay',
        TMode
      > & { functionName?: 'relay' }
    : UseContractWriteConfig<typeof governorVotesABI, 'relay', TMode> & {
        abi?: never;
        functionName?: 'relay';
      } = {} as any,
) {
  return useContractWrite<typeof governorVotesABI, 'relay', TMode>({
    abi: governorVotesABI,
    functionName: 'relay',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorVotesABI}__.
 */
export function usePrepareGovernorVotesWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorVotesABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorVotesABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorVotesABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"castVote"`.
 */
export function usePrepareGovernorVotesCastVote(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorVotesABI, 'castVote'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorVotesABI,
    functionName: 'castVote',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorVotesABI, 'castVote'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"castVoteBySig"`.
 */
export function usePrepareGovernorVotesCastVoteBySig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorVotesABI, 'castVoteBySig'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorVotesABI,
    functionName: 'castVoteBySig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorVotesABI, 'castVoteBySig'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"castVoteWithReason"`.
 */
export function usePrepareGovernorVotesCastVoteWithReason(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorVotesABI, 'castVoteWithReason'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorVotesABI,
    functionName: 'castVoteWithReason',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorVotesABI, 'castVoteWithReason'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"castVoteWithReasonAndParams"`.
 */
export function usePrepareGovernorVotesCastVoteWithReasonAndParams(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorVotesABI, 'castVoteWithReasonAndParams'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorVotesABI,
    functionName: 'castVoteWithReasonAndParams',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorVotesABI,
    'castVoteWithReasonAndParams'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"castVoteWithReasonAndParamsBySig"`.
 */
export function usePrepareGovernorVotesCastVoteWithReasonAndParamsBySig(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governorVotesABI,
      'castVoteWithReasonAndParamsBySig'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorVotesABI,
    functionName: 'castVoteWithReasonAndParamsBySig',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorVotesABI,
    'castVoteWithReasonAndParamsBySig'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"execute"`.
 */
export function usePrepareGovernorVotesExecute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorVotesABI, 'execute'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorVotesABI,
    functionName: 'execute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorVotesABI, 'execute'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function usePrepareGovernorVotesOnErc1155BatchReceived(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorVotesABI, 'onERC1155BatchReceived'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorVotesABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorVotesABI, 'onERC1155BatchReceived'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function usePrepareGovernorVotesOnErc1155Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorVotesABI, 'onERC1155Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorVotesABI,
    functionName: 'onERC1155Received',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorVotesABI, 'onERC1155Received'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function usePrepareGovernorVotesOnErc721Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorVotesABI, 'onERC721Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorVotesABI,
    functionName: 'onERC721Received',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorVotesABI, 'onERC721Received'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"propose"`.
 */
export function usePrepareGovernorVotesPropose(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorVotesABI, 'propose'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorVotesABI,
    functionName: 'propose',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorVotesABI, 'propose'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorVotesABI}__ and `functionName` set to `"relay"`.
 */
export function usePrepareGovernorVotesRelay(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorVotesABI, 'relay'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorVotesABI,
    functionName: 'relay',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorVotesABI, 'relay'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__.
 */
export function useGovernorTimelockControlRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"BALLOT_TYPEHASH"`.
 */
export function useGovernorTimelockControlBallotTypehash<
  TFunctionName extends 'BALLOT_TYPEHASH',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'BALLOT_TYPEHASH',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"COUNTING_MODE"`.
 */
export function useGovernorTimelockControlCountingMode<
  TFunctionName extends 'COUNTING_MODE',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'COUNTING_MODE',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"EXTENDED_BALLOT_TYPEHASH"`.
 */
export function useGovernorTimelockControlExtendedBallotTypehash<
  TFunctionName extends 'EXTENDED_BALLOT_TYPEHASH',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'EXTENDED_BALLOT_TYPEHASH',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"getVotes"`.
 */
export function useGovernorTimelockControlGetVotes<
  TFunctionName extends 'getVotes',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'getVotes',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"getVotesWithParams"`.
 */
export function useGovernorTimelockControlGetVotesWithParams<
  TFunctionName extends 'getVotesWithParams',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'getVotesWithParams',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"hasVoted"`.
 */
export function useGovernorTimelockControlHasVoted<
  TFunctionName extends 'hasVoted',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'hasVoted',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"hashProposal"`.
 */
export function useGovernorTimelockControlHashProposal<
  TFunctionName extends 'hashProposal',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'hashProposal',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"name"`.
 */
export function useGovernorTimelockControlName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"proposalDeadline"`.
 */
export function useGovernorTimelockControlProposalDeadline<
  TFunctionName extends 'proposalDeadline',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'proposalDeadline',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"proposalEta"`.
 */
export function useGovernorTimelockControlProposalEta<
  TFunctionName extends 'proposalEta',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'proposalEta',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"proposalSnapshot"`.
 */
export function useGovernorTimelockControlProposalSnapshot<
  TFunctionName extends 'proposalSnapshot',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'proposalSnapshot',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"proposalThreshold"`.
 */
export function useGovernorTimelockControlProposalThreshold<
  TFunctionName extends 'proposalThreshold',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'proposalThreshold',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"quorum"`.
 */
export function useGovernorTimelockControlQuorum<
  TFunctionName extends 'quorum',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'quorum',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"state"`.
 */
export function useGovernorTimelockControlState<
  TFunctionName extends 'state',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'state',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useGovernorTimelockControlSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"timelock"`.
 */
export function useGovernorTimelockControlTimelock<
  TFunctionName extends 'timelock',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'timelock',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"version"`.
 */
export function useGovernorTimelockControlVersion<
  TFunctionName extends 'version',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'version',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"votingDelay"`.
 */
export function useGovernorTimelockControlVotingDelay<
  TFunctionName extends 'votingDelay',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'votingDelay',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"votingPeriod"`.
 */
export function useGovernorTimelockControlVotingPeriod<
  TFunctionName extends 'votingPeriod',
  TSelectData = ReadContractResult<typeof governorTimelockControlABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof governorTimelockControlABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: governorTimelockControlABI,
    functionName: 'votingPeriod',
    ...config,
  } as UseContractReadConfig<
    typeof governorTimelockControlABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__.
 */
export function useGovernorTimelockControlWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorTimelockControlABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof governorTimelockControlABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof governorTimelockControlABI, TFunctionName, TMode>({
    abi: governorTimelockControlABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"castVote"`.
 */
export function useGovernorTimelockControlCastVote<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorTimelockControlABI,
          'castVote'
        >['request']['abi'],
        'castVote',
        TMode
      > & { functionName?: 'castVote' }
    : UseContractWriteConfig<typeof governorTimelockControlABI, 'castVote', TMode> & {
        abi?: never;
        functionName?: 'castVote';
      } = {} as any,
) {
  return useContractWrite<typeof governorTimelockControlABI, 'castVote', TMode>({
    abi: governorTimelockControlABI,
    functionName: 'castVote',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"castVoteBySig"`.
 */
export function useGovernorTimelockControlCastVoteBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorTimelockControlABI,
          'castVoteBySig'
        >['request']['abi'],
        'castVoteBySig',
        TMode
      > & { functionName?: 'castVoteBySig' }
    : UseContractWriteConfig<
        typeof governorTimelockControlABI,
        'castVoteBySig',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteBySig';
      } = {} as any,
) {
  return useContractWrite<typeof governorTimelockControlABI, 'castVoteBySig', TMode>({
    abi: governorTimelockControlABI,
    functionName: 'castVoteBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"castVoteWithReason"`.
 */
export function useGovernorTimelockControlCastVoteWithReason<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorTimelockControlABI,
          'castVoteWithReason'
        >['request']['abi'],
        'castVoteWithReason',
        TMode
      > & { functionName?: 'castVoteWithReason' }
    : UseContractWriteConfig<
        typeof governorTimelockControlABI,
        'castVoteWithReason',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReason';
      } = {} as any,
) {
  return useContractWrite<typeof governorTimelockControlABI, 'castVoteWithReason', TMode>(
    {
      abi: governorTimelockControlABI,
      functionName: 'castVoteWithReason',
      ...config,
    } as any,
  );
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"castVoteWithReasonAndParams"`.
 */
export function useGovernorTimelockControlCastVoteWithReasonAndParams<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorTimelockControlABI,
          'castVoteWithReasonAndParams'
        >['request']['abi'],
        'castVoteWithReasonAndParams',
        TMode
      > & { functionName?: 'castVoteWithReasonAndParams' }
    : UseContractWriteConfig<
        typeof governorTimelockControlABI,
        'castVoteWithReasonAndParams',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReasonAndParams';
      } = {} as any,
) {
  return useContractWrite<
    typeof governorTimelockControlABI,
    'castVoteWithReasonAndParams',
    TMode
  >({
    abi: governorTimelockControlABI,
    functionName: 'castVoteWithReasonAndParams',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"castVoteWithReasonAndParamsBySig"`.
 */
export function useGovernorTimelockControlCastVoteWithReasonAndParamsBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorTimelockControlABI,
          'castVoteWithReasonAndParamsBySig'
        >['request']['abi'],
        'castVoteWithReasonAndParamsBySig',
        TMode
      > & { functionName?: 'castVoteWithReasonAndParamsBySig' }
    : UseContractWriteConfig<
        typeof governorTimelockControlABI,
        'castVoteWithReasonAndParamsBySig',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReasonAndParamsBySig';
      } = {} as any,
) {
  return useContractWrite<
    typeof governorTimelockControlABI,
    'castVoteWithReasonAndParamsBySig',
    TMode
  >({
    abi: governorTimelockControlABI,
    functionName: 'castVoteWithReasonAndParamsBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"execute"`.
 */
export function useGovernorTimelockControlExecute<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorTimelockControlABI,
          'execute'
        >['request']['abi'],
        'execute',
        TMode
      > & { functionName?: 'execute' }
    : UseContractWriteConfig<typeof governorTimelockControlABI, 'execute', TMode> & {
        abi?: never;
        functionName?: 'execute';
      } = {} as any,
) {
  return useContractWrite<typeof governorTimelockControlABI, 'execute', TMode>({
    abi: governorTimelockControlABI,
    functionName: 'execute',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function useGovernorTimelockControlOnErc1155BatchReceived<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorTimelockControlABI,
          'onERC1155BatchReceived'
        >['request']['abi'],
        'onERC1155BatchReceived',
        TMode
      > & { functionName?: 'onERC1155BatchReceived' }
    : UseContractWriteConfig<
        typeof governorTimelockControlABI,
        'onERC1155BatchReceived',
        TMode
      > & {
        abi?: never;
        functionName?: 'onERC1155BatchReceived';
      } = {} as any,
) {
  return useContractWrite<
    typeof governorTimelockControlABI,
    'onERC1155BatchReceived',
    TMode
  >({
    abi: governorTimelockControlABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function useGovernorTimelockControlOnErc1155Received<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorTimelockControlABI,
          'onERC1155Received'
        >['request']['abi'],
        'onERC1155Received',
        TMode
      > & { functionName?: 'onERC1155Received' }
    : UseContractWriteConfig<
        typeof governorTimelockControlABI,
        'onERC1155Received',
        TMode
      > & {
        abi?: never;
        functionName?: 'onERC1155Received';
      } = {} as any,
) {
  return useContractWrite<typeof governorTimelockControlABI, 'onERC1155Received', TMode>({
    abi: governorTimelockControlABI,
    functionName: 'onERC1155Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function useGovernorTimelockControlOnErc721Received<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorTimelockControlABI,
          'onERC721Received'
        >['request']['abi'],
        'onERC721Received',
        TMode
      > & { functionName?: 'onERC721Received' }
    : UseContractWriteConfig<
        typeof governorTimelockControlABI,
        'onERC721Received',
        TMode
      > & {
        abi?: never;
        functionName?: 'onERC721Received';
      } = {} as any,
) {
  return useContractWrite<typeof governorTimelockControlABI, 'onERC721Received', TMode>({
    abi: governorTimelockControlABI,
    functionName: 'onERC721Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"propose"`.
 */
export function useGovernorTimelockControlPropose<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorTimelockControlABI,
          'propose'
        >['request']['abi'],
        'propose',
        TMode
      > & { functionName?: 'propose' }
    : UseContractWriteConfig<typeof governorTimelockControlABI, 'propose', TMode> & {
        abi?: never;
        functionName?: 'propose';
      } = {} as any,
) {
  return useContractWrite<typeof governorTimelockControlABI, 'propose', TMode>({
    abi: governorTimelockControlABI,
    functionName: 'propose',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"queue"`.
 */
export function useGovernorTimelockControlQueue<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorTimelockControlABI,
          'queue'
        >['request']['abi'],
        'queue',
        TMode
      > & { functionName?: 'queue' }
    : UseContractWriteConfig<typeof governorTimelockControlABI, 'queue', TMode> & {
        abi?: never;
        functionName?: 'queue';
      } = {} as any,
) {
  return useContractWrite<typeof governorTimelockControlABI, 'queue', TMode>({
    abi: governorTimelockControlABI,
    functionName: 'queue',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"relay"`.
 */
export function useGovernorTimelockControlRelay<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorTimelockControlABI,
          'relay'
        >['request']['abi'],
        'relay',
        TMode
      > & { functionName?: 'relay' }
    : UseContractWriteConfig<typeof governorTimelockControlABI, 'relay', TMode> & {
        abi?: never;
        functionName?: 'relay';
      } = {} as any,
) {
  return useContractWrite<typeof governorTimelockControlABI, 'relay', TMode>({
    abi: governorTimelockControlABI,
    functionName: 'relay',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"updateTimelock"`.
 */
export function useGovernorTimelockControlUpdateTimelock<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof governorTimelockControlABI,
          'updateTimelock'
        >['request']['abi'],
        'updateTimelock',
        TMode
      > & { functionName?: 'updateTimelock' }
    : UseContractWriteConfig<
        typeof governorTimelockControlABI,
        'updateTimelock',
        TMode
      > & {
        abi?: never;
        functionName?: 'updateTimelock';
      } = {} as any,
) {
  return useContractWrite<typeof governorTimelockControlABI, 'updateTimelock', TMode>({
    abi: governorTimelockControlABI,
    functionName: 'updateTimelock',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__.
 */
export function usePrepareGovernorTimelockControlWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorTimelockControlABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorTimelockControlABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorTimelockControlABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"castVote"`.
 */
export function usePrepareGovernorTimelockControlCastVote(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorTimelockControlABI, 'castVote'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorTimelockControlABI,
    functionName: 'castVote',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorTimelockControlABI, 'castVote'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"castVoteBySig"`.
 */
export function usePrepareGovernorTimelockControlCastVoteBySig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorTimelockControlABI, 'castVoteBySig'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorTimelockControlABI,
    functionName: 'castVoteBySig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorTimelockControlABI, 'castVoteBySig'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"castVoteWithReason"`.
 */
export function usePrepareGovernorTimelockControlCastVoteWithReason(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governorTimelockControlABI,
      'castVoteWithReason'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorTimelockControlABI,
    functionName: 'castVoteWithReason',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorTimelockControlABI,
    'castVoteWithReason'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"castVoteWithReasonAndParams"`.
 */
export function usePrepareGovernorTimelockControlCastVoteWithReasonAndParams(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governorTimelockControlABI,
      'castVoteWithReasonAndParams'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorTimelockControlABI,
    functionName: 'castVoteWithReasonAndParams',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorTimelockControlABI,
    'castVoteWithReasonAndParams'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"castVoteWithReasonAndParamsBySig"`.
 */
export function usePrepareGovernorTimelockControlCastVoteWithReasonAndParamsBySig(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governorTimelockControlABI,
      'castVoteWithReasonAndParamsBySig'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorTimelockControlABI,
    functionName: 'castVoteWithReasonAndParamsBySig',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorTimelockControlABI,
    'castVoteWithReasonAndParamsBySig'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"execute"`.
 */
export function usePrepareGovernorTimelockControlExecute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorTimelockControlABI, 'execute'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorTimelockControlABI,
    functionName: 'execute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorTimelockControlABI, 'execute'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function usePrepareGovernorTimelockControlOnErc1155BatchReceived(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof governorTimelockControlABI,
      'onERC1155BatchReceived'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorTimelockControlABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorTimelockControlABI,
    'onERC1155BatchReceived'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function usePrepareGovernorTimelockControlOnErc1155Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorTimelockControlABI, 'onERC1155Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorTimelockControlABI,
    functionName: 'onERC1155Received',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorTimelockControlABI,
    'onERC1155Received'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function usePrepareGovernorTimelockControlOnErc721Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorTimelockControlABI, 'onERC721Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorTimelockControlABI,
    functionName: 'onERC721Received',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorTimelockControlABI,
    'onERC721Received'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"propose"`.
 */
export function usePrepareGovernorTimelockControlPropose(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorTimelockControlABI, 'propose'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorTimelockControlABI,
    functionName: 'propose',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorTimelockControlABI, 'propose'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"queue"`.
 */
export function usePrepareGovernorTimelockControlQueue(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorTimelockControlABI, 'queue'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorTimelockControlABI,
    functionName: 'queue',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorTimelockControlABI, 'queue'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"relay"`.
 */
export function usePrepareGovernorTimelockControlRelay(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorTimelockControlABI, 'relay'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorTimelockControlABI,
    functionName: 'relay',
    ...config,
  } as UsePrepareContractWriteConfig<typeof governorTimelockControlABI, 'relay'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link governorTimelockControlABI}__ and `functionName` set to `"updateTimelock"`.
 */
export function usePrepareGovernorTimelockControlUpdateTimelock(
  config: Omit<
    UsePrepareContractWriteConfig<typeof governorTimelockControlABI, 'updateTimelock'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: governorTimelockControlABI,
    functionName: 'updateTimelock',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof governorTimelockControlABI,
    'updateTimelock'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__.
 */
export function useIGovernorTimelockRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"COUNTING_MODE"`.
 */
export function useIGovernorTimelockCountingMode<
  TFunctionName extends 'COUNTING_MODE',
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    functionName: 'COUNTING_MODE',
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"getVotes"`.
 */
export function useIGovernorTimelockGetVotes<
  TFunctionName extends 'getVotes',
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    functionName: 'getVotes',
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"getVotesWithParams"`.
 */
export function useIGovernorTimelockGetVotesWithParams<
  TFunctionName extends 'getVotesWithParams',
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    functionName: 'getVotesWithParams',
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"hasVoted"`.
 */
export function useIGovernorTimelockHasVoted<
  TFunctionName extends 'hasVoted',
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    functionName: 'hasVoted',
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"hashProposal"`.
 */
export function useIGovernorTimelockHashProposal<
  TFunctionName extends 'hashProposal',
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    functionName: 'hashProposal',
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"name"`.
 */
export function useIGovernorTimelockName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"proposalDeadline"`.
 */
export function useIGovernorTimelockProposalDeadline<
  TFunctionName extends 'proposalDeadline',
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    functionName: 'proposalDeadline',
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"proposalEta"`.
 */
export function useIGovernorTimelockProposalEta<
  TFunctionName extends 'proposalEta',
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    functionName: 'proposalEta',
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"proposalSnapshot"`.
 */
export function useIGovernorTimelockProposalSnapshot<
  TFunctionName extends 'proposalSnapshot',
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    functionName: 'proposalSnapshot',
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"quorum"`.
 */
export function useIGovernorTimelockQuorum<
  TFunctionName extends 'quorum',
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    functionName: 'quorum',
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"state"`.
 */
export function useIGovernorTimelockState<
  TFunctionName extends 'state',
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    functionName: 'state',
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useIGovernorTimelockSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"timelock"`.
 */
export function useIGovernorTimelockTimelock<
  TFunctionName extends 'timelock',
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    functionName: 'timelock',
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"version"`.
 */
export function useIGovernorTimelockVersion<
  TFunctionName extends 'version',
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    functionName: 'version',
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"votingDelay"`.
 */
export function useIGovernorTimelockVotingDelay<
  TFunctionName extends 'votingDelay',
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    functionName: 'votingDelay',
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"votingPeriod"`.
 */
export function useIGovernorTimelockVotingPeriod<
  TFunctionName extends 'votingPeriod',
  TSelectData = ReadContractResult<typeof iGovernorTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iGovernorTimelockABI,
    functionName: 'votingPeriod',
    ...config,
  } as UseContractReadConfig<typeof iGovernorTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__.
 */
export function useIGovernorTimelockWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iGovernorTimelockABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iGovernorTimelockABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof iGovernorTimelockABI, TFunctionName, TMode>({
    abi: iGovernorTimelockABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"castVote"`.
 */
export function useIGovernorTimelockCastVote<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iGovernorTimelockABI,
          'castVote'
        >['request']['abi'],
        'castVote',
        TMode
      > & { functionName?: 'castVote' }
    : UseContractWriteConfig<typeof iGovernorTimelockABI, 'castVote', TMode> & {
        abi?: never;
        functionName?: 'castVote';
      } = {} as any,
) {
  return useContractWrite<typeof iGovernorTimelockABI, 'castVote', TMode>({
    abi: iGovernorTimelockABI,
    functionName: 'castVote',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"castVoteBySig"`.
 */
export function useIGovernorTimelockCastVoteBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iGovernorTimelockABI,
          'castVoteBySig'
        >['request']['abi'],
        'castVoteBySig',
        TMode
      > & { functionName?: 'castVoteBySig' }
    : UseContractWriteConfig<typeof iGovernorTimelockABI, 'castVoteBySig', TMode> & {
        abi?: never;
        functionName?: 'castVoteBySig';
      } = {} as any,
) {
  return useContractWrite<typeof iGovernorTimelockABI, 'castVoteBySig', TMode>({
    abi: iGovernorTimelockABI,
    functionName: 'castVoteBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"castVoteWithReason"`.
 */
export function useIGovernorTimelockCastVoteWithReason<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iGovernorTimelockABI,
          'castVoteWithReason'
        >['request']['abi'],
        'castVoteWithReason',
        TMode
      > & { functionName?: 'castVoteWithReason' }
    : UseContractWriteConfig<typeof iGovernorTimelockABI, 'castVoteWithReason', TMode> & {
        abi?: never;
        functionName?: 'castVoteWithReason';
      } = {} as any,
) {
  return useContractWrite<typeof iGovernorTimelockABI, 'castVoteWithReason', TMode>({
    abi: iGovernorTimelockABI,
    functionName: 'castVoteWithReason',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"castVoteWithReasonAndParams"`.
 */
export function useIGovernorTimelockCastVoteWithReasonAndParams<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iGovernorTimelockABI,
          'castVoteWithReasonAndParams'
        >['request']['abi'],
        'castVoteWithReasonAndParams',
        TMode
      > & { functionName?: 'castVoteWithReasonAndParams' }
    : UseContractWriteConfig<
        typeof iGovernorTimelockABI,
        'castVoteWithReasonAndParams',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReasonAndParams';
      } = {} as any,
) {
  return useContractWrite<
    typeof iGovernorTimelockABI,
    'castVoteWithReasonAndParams',
    TMode
  >({
    abi: iGovernorTimelockABI,
    functionName: 'castVoteWithReasonAndParams',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"castVoteWithReasonAndParamsBySig"`.
 */
export function useIGovernorTimelockCastVoteWithReasonAndParamsBySig<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iGovernorTimelockABI,
          'castVoteWithReasonAndParamsBySig'
        >['request']['abi'],
        'castVoteWithReasonAndParamsBySig',
        TMode
      > & { functionName?: 'castVoteWithReasonAndParamsBySig' }
    : UseContractWriteConfig<
        typeof iGovernorTimelockABI,
        'castVoteWithReasonAndParamsBySig',
        TMode
      > & {
        abi?: never;
        functionName?: 'castVoteWithReasonAndParamsBySig';
      } = {} as any,
) {
  return useContractWrite<
    typeof iGovernorTimelockABI,
    'castVoteWithReasonAndParamsBySig',
    TMode
  >({
    abi: iGovernorTimelockABI,
    functionName: 'castVoteWithReasonAndParamsBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"execute"`.
 */
export function useIGovernorTimelockExecute<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iGovernorTimelockABI,
          'execute'
        >['request']['abi'],
        'execute',
        TMode
      > & { functionName?: 'execute' }
    : UseContractWriteConfig<typeof iGovernorTimelockABI, 'execute', TMode> & {
        abi?: never;
        functionName?: 'execute';
      } = {} as any,
) {
  return useContractWrite<typeof iGovernorTimelockABI, 'execute', TMode>({
    abi: iGovernorTimelockABI,
    functionName: 'execute',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"propose"`.
 */
export function useIGovernorTimelockPropose<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iGovernorTimelockABI,
          'propose'
        >['request']['abi'],
        'propose',
        TMode
      > & { functionName?: 'propose' }
    : UseContractWriteConfig<typeof iGovernorTimelockABI, 'propose', TMode> & {
        abi?: never;
        functionName?: 'propose';
      } = {} as any,
) {
  return useContractWrite<typeof iGovernorTimelockABI, 'propose', TMode>({
    abi: iGovernorTimelockABI,
    functionName: 'propose',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"queue"`.
 */
export function useIGovernorTimelockQueue<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iGovernorTimelockABI,
          'queue'
        >['request']['abi'],
        'queue',
        TMode
      > & { functionName?: 'queue' }
    : UseContractWriteConfig<typeof iGovernorTimelockABI, 'queue', TMode> & {
        abi?: never;
        functionName?: 'queue';
      } = {} as any,
) {
  return useContractWrite<typeof iGovernorTimelockABI, 'queue', TMode>({
    abi: iGovernorTimelockABI,
    functionName: 'queue',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__.
 */
export function usePrepareIGovernorTimelockWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGovernorTimelockABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorTimelockABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGovernorTimelockABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"castVote"`.
 */
export function usePrepareIGovernorTimelockCastVote(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGovernorTimelockABI, 'castVote'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorTimelockABI,
    functionName: 'castVote',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGovernorTimelockABI, 'castVote'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"castVoteBySig"`.
 */
export function usePrepareIGovernorTimelockCastVoteBySig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGovernorTimelockABI, 'castVoteBySig'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorTimelockABI,
    functionName: 'castVoteBySig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGovernorTimelockABI, 'castVoteBySig'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"castVoteWithReason"`.
 */
export function usePrepareIGovernorTimelockCastVoteWithReason(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGovernorTimelockABI, 'castVoteWithReason'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorTimelockABI,
    functionName: 'castVoteWithReason',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGovernorTimelockABI, 'castVoteWithReason'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"castVoteWithReasonAndParams"`.
 */
export function usePrepareIGovernorTimelockCastVoteWithReasonAndParams(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof iGovernorTimelockABI,
      'castVoteWithReasonAndParams'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorTimelockABI,
    functionName: 'castVoteWithReasonAndParams',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof iGovernorTimelockABI,
    'castVoteWithReasonAndParams'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"castVoteWithReasonAndParamsBySig"`.
 */
export function usePrepareIGovernorTimelockCastVoteWithReasonAndParamsBySig(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof iGovernorTimelockABI,
      'castVoteWithReasonAndParamsBySig'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorTimelockABI,
    functionName: 'castVoteWithReasonAndParamsBySig',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof iGovernorTimelockABI,
    'castVoteWithReasonAndParamsBySig'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"execute"`.
 */
export function usePrepareIGovernorTimelockExecute(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGovernorTimelockABI, 'execute'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorTimelockABI,
    functionName: 'execute',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGovernorTimelockABI, 'execute'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"propose"`.
 */
export function usePrepareIGovernorTimelockPropose(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGovernorTimelockABI, 'propose'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorTimelockABI,
    functionName: 'propose',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGovernorTimelockABI, 'propose'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iGovernorTimelockABI}__ and `functionName` set to `"queue"`.
 */
export function usePrepareIGovernorTimelockQueue(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iGovernorTimelockABI, 'queue'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iGovernorTimelockABI,
    functionName: 'queue',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iGovernorTimelockABI, 'queue'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iVotesABI}__.
 */
export function useIVotesRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof iVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iVotesABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: iVotesABI, ...config } as UseContractReadConfig<
    typeof iVotesABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iVotesABI}__ and `functionName` set to `"delegates"`.
 */
export function useIVotesDelegates<
  TFunctionName extends 'delegates',
  TSelectData = ReadContractResult<typeof iVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iVotesABI,
    functionName: 'delegates',
    ...config,
  } as UseContractReadConfig<typeof iVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iVotesABI}__ and `functionName` set to `"getPastTotalSupply"`.
 */
export function useIVotesGetPastTotalSupply<
  TFunctionName extends 'getPastTotalSupply',
  TSelectData = ReadContractResult<typeof iVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iVotesABI,
    functionName: 'getPastTotalSupply',
    ...config,
  } as UseContractReadConfig<typeof iVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iVotesABI}__ and `functionName` set to `"getPastVotes"`.
 */
export function useIVotesGetPastVotes<
  TFunctionName extends 'getPastVotes',
  TSelectData = ReadContractResult<typeof iVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iVotesABI,
    functionName: 'getPastVotes',
    ...config,
  } as UseContractReadConfig<typeof iVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iVotesABI}__ and `functionName` set to `"getVotes"`.
 */
export function useIVotesGetVotes<
  TFunctionName extends 'getVotes',
  TSelectData = ReadContractResult<typeof iVotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iVotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iVotesABI,
    functionName: 'getVotes',
    ...config,
  } as UseContractReadConfig<typeof iVotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iVotesABI}__.
 */
export function useIVotesWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iVotesABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iVotesABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof iVotesABI, TFunctionName, TMode>({
    abi: iVotesABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iVotesABI}__ and `functionName` set to `"delegate"`.
 */
export function useIVotesDelegate<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iVotesABI, 'delegate'>['request']['abi'],
        'delegate',
        TMode
      > & { functionName?: 'delegate' }
    : UseContractWriteConfig<typeof iVotesABI, 'delegate', TMode> & {
        abi?: never;
        functionName?: 'delegate';
      } = {} as any,
) {
  return useContractWrite<typeof iVotesABI, 'delegate', TMode>({
    abi: iVotesABI,
    functionName: 'delegate',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iVotesABI}__ and `functionName` set to `"delegateBySig"`.
 */
export function useIVotesDelegateBySig<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iVotesABI, 'delegateBySig'>['request']['abi'],
        'delegateBySig',
        TMode
      > & { functionName?: 'delegateBySig' }
    : UseContractWriteConfig<typeof iVotesABI, 'delegateBySig', TMode> & {
        abi?: never;
        functionName?: 'delegateBySig';
      } = {} as any,
) {
  return useContractWrite<typeof iVotesABI, 'delegateBySig', TMode>({
    abi: iVotesABI,
    functionName: 'delegateBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iVotesABI}__.
 */
export function usePrepareIVotesWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iVotesABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iVotesABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof iVotesABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iVotesABI}__ and `functionName` set to `"delegate"`.
 */
export function usePrepareIVotesDelegate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iVotesABI, 'delegate'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iVotesABI,
    functionName: 'delegate',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iVotesABI, 'delegate'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iVotesABI}__ and `functionName` set to `"delegateBySig"`.
 */
export function usePrepareIVotesDelegateBySig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iVotesABI, 'delegateBySig'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iVotesABI,
    functionName: 'delegateBySig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iVotesABI, 'delegateBySig'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc1155ReceiverABI}__.
 */
export function useIerc1155ReceiverRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof ierc1155ReceiverABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc1155ReceiverABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: ierc1155ReceiverABI, ...config } as UseContractReadConfig<
    typeof ierc1155ReceiverABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc1155ReceiverABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useIerc1155ReceiverSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof ierc1155ReceiverABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc1155ReceiverABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc1155ReceiverABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof ierc1155ReceiverABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc1155ReceiverABI}__.
 */
export function useIerc1155ReceiverWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ierc1155ReceiverABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof ierc1155ReceiverABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof ierc1155ReceiverABI, TFunctionName, TMode>({
    abi: ierc1155ReceiverABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc1155ReceiverABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function useIerc1155ReceiverOnErc1155BatchReceived<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc1155ReceiverABI,
          'onERC1155BatchReceived'
        >['request']['abi'],
        'onERC1155BatchReceived',
        TMode
      > & { functionName?: 'onERC1155BatchReceived' }
    : UseContractWriteConfig<
        typeof ierc1155ReceiverABI,
        'onERC1155BatchReceived',
        TMode
      > & {
        abi?: never;
        functionName?: 'onERC1155BatchReceived';
      } = {} as any,
) {
  return useContractWrite<typeof ierc1155ReceiverABI, 'onERC1155BatchReceived', TMode>({
    abi: ierc1155ReceiverABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc1155ReceiverABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function useIerc1155ReceiverOnErc1155Received<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc1155ReceiverABI,
          'onERC1155Received'
        >['request']['abi'],
        'onERC1155Received',
        TMode
      > & { functionName?: 'onERC1155Received' }
    : UseContractWriteConfig<typeof ierc1155ReceiverABI, 'onERC1155Received', TMode> & {
        abi?: never;
        functionName?: 'onERC1155Received';
      } = {} as any,
) {
  return useContractWrite<typeof ierc1155ReceiverABI, 'onERC1155Received', TMode>({
    abi: ierc1155ReceiverABI,
    functionName: 'onERC1155Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc1155ReceiverABI}__.
 */
export function usePrepareIerc1155ReceiverWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc1155ReceiverABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc1155ReceiverABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc1155ReceiverABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc1155ReceiverABI}__ and `functionName` set to `"onERC1155BatchReceived"`.
 */
export function usePrepareIerc1155ReceiverOnErc1155BatchReceived(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc1155ReceiverABI, 'onERC1155BatchReceived'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc1155ReceiverABI,
    functionName: 'onERC1155BatchReceived',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof ierc1155ReceiverABI,
    'onERC1155BatchReceived'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc1155ReceiverABI}__ and `functionName` set to `"onERC1155Received"`.
 */
export function usePrepareIerc1155ReceiverOnErc1155Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc1155ReceiverABI, 'onERC1155Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc1155ReceiverABI,
    functionName: 'onERC1155Received',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc1155ReceiverABI, 'onERC1155Received'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__.
 */
export function useErc20Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: erc20ABI, ...config } as UseContractReadConfig<
    typeof erc20ABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"allowance"`.
 */
export function useErc20Allowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useErc20BalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"decimals"`.
 */
export function useErc20Decimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"name"`.
 */
export function useErc20Name<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"symbol"`.
 */
export function useErc20Symbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useErc20TotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__.
 */
export function useErc20Write<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof erc20ABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof erc20ABI, TFunctionName, TMode>({
    abi: erc20ABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"approve"`.
 */
export function useErc20Approve<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof erc20ABI, 'approve', TMode> & {
        abi?: never;
        functionName?: 'approve';
      } = {} as any,
) {
  return useContractWrite<typeof erc20ABI, 'approve', TMode>({
    abi: erc20ABI,
    functionName: 'approve',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useErc20DecreaseAllowance<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof erc20ABI,
          'decreaseAllowance'
        >['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<typeof erc20ABI, 'decreaseAllowance', TMode> & {
        abi?: never;
        functionName?: 'decreaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof erc20ABI, 'decreaseAllowance', TMode>({
    abi: erc20ABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useErc20IncreaseAllowance<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof erc20ABI,
          'increaseAllowance'
        >['request']['abi'],
        'increaseAllowance',
        TMode
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<typeof erc20ABI, 'increaseAllowance', TMode> & {
        abi?: never;
        functionName?: 'increaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof erc20ABI, 'increaseAllowance', TMode>({
    abi: erc20ABI,
    functionName: 'increaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function useErc20Transfer<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof erc20ABI, 'transfer', TMode> & {
        abi?: never;
        functionName?: 'transfer';
      } = {} as any,
) {
  return useContractWrite<typeof erc20ABI, 'transfer', TMode>({
    abi: erc20ABI,
    functionName: 'transfer',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useErc20TransferFrom<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'transferFrom'>['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof erc20ABI, 'transferFrom', TMode> & {
        abi?: never;
        functionName?: 'transferFrom';
      } = {} as any,
) {
  return useContractWrite<typeof erc20ABI, 'transferFrom', TMode>({
    abi: erc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__.
 */
export function usePrepareErc20Write<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20ABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareErc20Approve(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20ABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'approve'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareErc20DecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20ABI, 'decreaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'decreaseAllowance'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareErc20IncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20ABI, 'increaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'increaseAllowance'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareErc20Transfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20ABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'transfer'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareErc20TransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20ABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'transferFrom'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20ABI}__.
 */
export function useIerc20Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof ierc20ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20ABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: ierc20ABI, ...config } as UseContractReadConfig<
    typeof ierc20ABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"allowance"`.
 */
export function useIerc20Allowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof ierc20ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20ABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof ierc20ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useIerc20BalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof ierc20ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20ABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof ierc20ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useIerc20TotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof ierc20ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20ABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof ierc20ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20ABI}__.
 */
export function useIerc20Write<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ierc20ABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof ierc20ABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof ierc20ABI, TFunctionName, TMode>({
    abi: ierc20ABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"approve"`.
 */
export function useIerc20Approve<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ierc20ABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof ierc20ABI, 'approve', TMode> & {
        abi?: never;
        functionName?: 'approve';
      } = {} as any,
) {
  return useContractWrite<typeof ierc20ABI, 'approve', TMode>({
    abi: ierc20ABI,
    functionName: 'approve',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function useIerc20Transfer<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ierc20ABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof ierc20ABI, 'transfer', TMode> & {
        abi?: never;
        functionName?: 'transfer';
      } = {} as any,
) {
  return useContractWrite<typeof ierc20ABI, 'transfer', TMode>({
    abi: ierc20ABI,
    functionName: 'transfer',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useIerc20TransferFrom<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ierc20ABI, 'transferFrom'>['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof ierc20ABI, 'transferFrom', TMode> & {
        abi?: never;
        functionName?: 'transferFrom';
      } = {} as any,
) {
  return useContractWrite<typeof ierc20ABI, 'transferFrom', TMode>({
    abi: ierc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20ABI}__.
 */
export function usePrepareIerc20Write<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20ABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20ABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20ABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareIerc20Approve(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20ABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20ABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20ABI, 'approve'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareIerc20Transfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20ABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20ABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20ABI, 'transfer'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareIerc20TransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20ABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20ABI, 'transferFrom'>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc721ReceiverABI}__.
 */
export function useIerc721ReceiverWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ierc721ReceiverABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof ierc721ReceiverABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof ierc721ReceiverABI, TFunctionName, TMode>({
    abi: ierc721ReceiverABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc721ReceiverABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function useIerc721ReceiverOnErc721Received<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc721ReceiverABI,
          'onERC721Received'
        >['request']['abi'],
        'onERC721Received',
        TMode
      > & { functionName?: 'onERC721Received' }
    : UseContractWriteConfig<typeof ierc721ReceiverABI, 'onERC721Received', TMode> & {
        abi?: never;
        functionName?: 'onERC721Received';
      } = {} as any,
) {
  return useContractWrite<typeof ierc721ReceiverABI, 'onERC721Received', TMode>({
    abi: ierc721ReceiverABI,
    functionName: 'onERC721Received',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc721ReceiverABI}__.
 */
export function usePrepareIerc721ReceiverWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc721ReceiverABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc721ReceiverABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc721ReceiverABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc721ReceiverABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function usePrepareIerc721ReceiverOnErc721Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc721ReceiverABI, 'onERC721Received'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc721ReceiverABI,
    functionName: 'onERC721Received',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc721ReceiverABI, 'onERC721Received'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc165ABI}__.
 */
export function useErc165Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof erc165ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc165ABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: erc165ABI, ...config } as UseContractReadConfig<
    typeof erc165ABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc165ABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useErc165SupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof erc165ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc165ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc165ABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof erc165ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc165ABI}__.
 */
export function useIerc165Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof ierc165ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc165ABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: ierc165ABI, ...config } as UseContractReadConfig<
    typeof ierc165ABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc165ABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useIerc165SupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof ierc165ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc165ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc165ABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof ierc165ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2FactoryABI}__.
 */
export function useIUniswapV2FactoryRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof iUniswapV2FactoryABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2FactoryABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2FactoryABI,
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2FactoryABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2FactoryABI}__ and `functionName` set to `"allPairs"`.
 */
export function useIUniswapV2FactoryAllPairs<
  TFunctionName extends 'allPairs',
  TSelectData = ReadContractResult<typeof iUniswapV2FactoryABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2FactoryABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2FactoryABI,
    functionName: 'allPairs',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2FactoryABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2FactoryABI}__ and `functionName` set to `"allPairsLength"`.
 */
export function useIUniswapV2FactoryAllPairsLength<
  TFunctionName extends 'allPairsLength',
  TSelectData = ReadContractResult<typeof iUniswapV2FactoryABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2FactoryABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2FactoryABI,
    functionName: 'allPairsLength',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2FactoryABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2FactoryABI}__ and `functionName` set to `"feeTo"`.
 */
export function useIUniswapV2FactoryFeeTo<
  TFunctionName extends 'feeTo',
  TSelectData = ReadContractResult<typeof iUniswapV2FactoryABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2FactoryABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2FactoryABI,
    functionName: 'feeTo',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2FactoryABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2FactoryABI}__ and `functionName` set to `"feeToSetter"`.
 */
export function useIUniswapV2FactoryFeeToSetter<
  TFunctionName extends 'feeToSetter',
  TSelectData = ReadContractResult<typeof iUniswapV2FactoryABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2FactoryABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2FactoryABI,
    functionName: 'feeToSetter',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2FactoryABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2FactoryABI}__ and `functionName` set to `"getPair"`.
 */
export function useIUniswapV2FactoryGetPair<
  TFunctionName extends 'getPair',
  TSelectData = ReadContractResult<typeof iUniswapV2FactoryABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2FactoryABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2FactoryABI,
    functionName: 'getPair',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2FactoryABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2FactoryABI}__.
 */
export function useIUniswapV2FactoryWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iUniswapV2FactoryABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iUniswapV2FactoryABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2FactoryABI, TFunctionName, TMode>({
    abi: iUniswapV2FactoryABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2FactoryABI}__ and `functionName` set to `"createPair"`.
 */
export function useIUniswapV2FactoryCreatePair<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2FactoryABI,
          'createPair'
        >['request']['abi'],
        'createPair',
        TMode
      > & { functionName?: 'createPair' }
    : UseContractWriteConfig<typeof iUniswapV2FactoryABI, 'createPair', TMode> & {
        abi?: never;
        functionName?: 'createPair';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2FactoryABI, 'createPair', TMode>({
    abi: iUniswapV2FactoryABI,
    functionName: 'createPair',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2FactoryABI}__ and `functionName` set to `"setFeeTo"`.
 */
export function useIUniswapV2FactorySetFeeTo<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2FactoryABI,
          'setFeeTo'
        >['request']['abi'],
        'setFeeTo',
        TMode
      > & { functionName?: 'setFeeTo' }
    : UseContractWriteConfig<typeof iUniswapV2FactoryABI, 'setFeeTo', TMode> & {
        abi?: never;
        functionName?: 'setFeeTo';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2FactoryABI, 'setFeeTo', TMode>({
    abi: iUniswapV2FactoryABI,
    functionName: 'setFeeTo',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2FactoryABI}__ and `functionName` set to `"setFeeToSetter"`.
 */
export function useIUniswapV2FactorySetFeeToSetter<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2FactoryABI,
          'setFeeToSetter'
        >['request']['abi'],
        'setFeeToSetter',
        TMode
      > & { functionName?: 'setFeeToSetter' }
    : UseContractWriteConfig<typeof iUniswapV2FactoryABI, 'setFeeToSetter', TMode> & {
        abi?: never;
        functionName?: 'setFeeToSetter';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2FactoryABI, 'setFeeToSetter', TMode>({
    abi: iUniswapV2FactoryABI,
    functionName: 'setFeeToSetter',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2FactoryABI}__.
 */
export function usePrepareIUniswapV2FactoryWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2FactoryABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2FactoryABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2FactoryABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2FactoryABI}__ and `functionName` set to `"createPair"`.
 */
export function usePrepareIUniswapV2FactoryCreatePair(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2FactoryABI, 'createPair'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2FactoryABI,
    functionName: 'createPair',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2FactoryABI, 'createPair'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2FactoryABI}__ and `functionName` set to `"setFeeTo"`.
 */
export function usePrepareIUniswapV2FactorySetFeeTo(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2FactoryABI, 'setFeeTo'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2FactoryABI,
    functionName: 'setFeeTo',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2FactoryABI, 'setFeeTo'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2FactoryABI}__ and `functionName` set to `"setFeeToSetter"`.
 */
export function usePrepareIUniswapV2FactorySetFeeToSetter(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2FactoryABI, 'setFeeToSetter'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2FactoryABI,
    functionName: 'setFeeToSetter',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2FactoryABI, 'setFeeToSetter'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__.
 */
export function useIUniswapV2PairRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: iUniswapV2PairABI, ...config } as UseContractReadConfig<
    typeof iUniswapV2PairABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"DOMAIN_SEPARATOR"`.
 */
export function useIUniswapV2PairDomainSeparator<
  TFunctionName extends 'DOMAIN_SEPARATOR',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'DOMAIN_SEPARATOR',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"MINIMUM_LIQUIDITY"`.
 */
export function useIUniswapV2PairMinimumLiquidity<
  TFunctionName extends 'MINIMUM_LIQUIDITY',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'MINIMUM_LIQUIDITY',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"PERMIT_TYPEHASH"`.
 */
export function useIUniswapV2PairPermitTypehash<
  TFunctionName extends 'PERMIT_TYPEHASH',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'PERMIT_TYPEHASH',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"allowance"`.
 */
export function useIUniswapV2PairAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useIUniswapV2PairBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"decimals"`.
 */
export function useIUniswapV2PairDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"factory"`.
 */
export function useIUniswapV2PairFactory<
  TFunctionName extends 'factory',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'factory',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"getReserves"`.
 */
export function useIUniswapV2PairGetReserves<
  TFunctionName extends 'getReserves',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'getReserves',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"kLast"`.
 */
export function useIUniswapV2PairKLast<
  TFunctionName extends 'kLast',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'kLast',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"name"`.
 */
export function useIUniswapV2PairName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"nonces"`.
 */
export function useIUniswapV2PairNonces<
  TFunctionName extends 'nonces',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'nonces',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"price0CumulativeLast"`.
 */
export function useIUniswapV2PairPrice0CumulativeLast<
  TFunctionName extends 'price0CumulativeLast',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'price0CumulativeLast',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"price1CumulativeLast"`.
 */
export function useIUniswapV2PairPrice1CumulativeLast<
  TFunctionName extends 'price1CumulativeLast',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'price1CumulativeLast',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"symbol"`.
 */
export function useIUniswapV2PairSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"token0"`.
 */
export function useIUniswapV2PairToken0<
  TFunctionName extends 'token0',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'token0',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"token1"`.
 */
export function useIUniswapV2PairToken1<
  TFunctionName extends 'token1',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'token1',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useIUniswapV2PairTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof iUniswapV2PairABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2PairABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2PairABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__.
 */
export function useIUniswapV2PairWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iUniswapV2PairABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iUniswapV2PairABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2PairABI, TFunctionName, TMode>({
    abi: iUniswapV2PairABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"approve"`.
 */
export function useIUniswapV2PairApprove<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iUniswapV2PairABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof iUniswapV2PairABI, 'approve', TMode> & {
        abi?: never;
        functionName?: 'approve';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2PairABI, 'approve', TMode>({
    abi: iUniswapV2PairABI,
    functionName: 'approve',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"burn"`.
 */
export function useIUniswapV2PairBurn<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iUniswapV2PairABI, 'burn'>['request']['abi'],
        'burn',
        TMode
      > & { functionName?: 'burn' }
    : UseContractWriteConfig<typeof iUniswapV2PairABI, 'burn', TMode> & {
        abi?: never;
        functionName?: 'burn';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2PairABI, 'burn', TMode>({
    abi: iUniswapV2PairABI,
    functionName: 'burn',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"initialize"`.
 */
export function useIUniswapV2PairInitialize<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2PairABI,
          'initialize'
        >['request']['abi'],
        'initialize',
        TMode
      > & { functionName?: 'initialize' }
    : UseContractWriteConfig<typeof iUniswapV2PairABI, 'initialize', TMode> & {
        abi?: never;
        functionName?: 'initialize';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2PairABI, 'initialize', TMode>({
    abi: iUniswapV2PairABI,
    functionName: 'initialize',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"mint"`.
 */
export function useIUniswapV2PairMint<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iUniswapV2PairABI, 'mint'>['request']['abi'],
        'mint',
        TMode
      > & { functionName?: 'mint' }
    : UseContractWriteConfig<typeof iUniswapV2PairABI, 'mint', TMode> & {
        abi?: never;
        functionName?: 'mint';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2PairABI, 'mint', TMode>({
    abi: iUniswapV2PairABI,
    functionName: 'mint',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"permit"`.
 */
export function useIUniswapV2PairPermit<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iUniswapV2PairABI, 'permit'>['request']['abi'],
        'permit',
        TMode
      > & { functionName?: 'permit' }
    : UseContractWriteConfig<typeof iUniswapV2PairABI, 'permit', TMode> & {
        abi?: never;
        functionName?: 'permit';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2PairABI, 'permit', TMode>({
    abi: iUniswapV2PairABI,
    functionName: 'permit',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"skim"`.
 */
export function useIUniswapV2PairSkim<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iUniswapV2PairABI, 'skim'>['request']['abi'],
        'skim',
        TMode
      > & { functionName?: 'skim' }
    : UseContractWriteConfig<typeof iUniswapV2PairABI, 'skim', TMode> & {
        abi?: never;
        functionName?: 'skim';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2PairABI, 'skim', TMode>({
    abi: iUniswapV2PairABI,
    functionName: 'skim',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"swap"`.
 */
export function useIUniswapV2PairSwap<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iUniswapV2PairABI, 'swap'>['request']['abi'],
        'swap',
        TMode
      > & { functionName?: 'swap' }
    : UseContractWriteConfig<typeof iUniswapV2PairABI, 'swap', TMode> & {
        abi?: never;
        functionName?: 'swap';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2PairABI, 'swap', TMode>({
    abi: iUniswapV2PairABI,
    functionName: 'swap',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"sync"`.
 */
export function useIUniswapV2PairSync<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof iUniswapV2PairABI, 'sync'>['request']['abi'],
        'sync',
        TMode
      > & { functionName?: 'sync' }
    : UseContractWriteConfig<typeof iUniswapV2PairABI, 'sync', TMode> & {
        abi?: never;
        functionName?: 'sync';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2PairABI, 'sync', TMode>({
    abi: iUniswapV2PairABI,
    functionName: 'sync',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"transfer"`.
 */
export function useIUniswapV2PairTransfer<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2PairABI,
          'transfer'
        >['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof iUniswapV2PairABI, 'transfer', TMode> & {
        abi?: never;
        functionName?: 'transfer';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2PairABI, 'transfer', TMode>({
    abi: iUniswapV2PairABI,
    functionName: 'transfer',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useIUniswapV2PairTransferFrom<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2PairABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof iUniswapV2PairABI, 'transferFrom', TMode> & {
        abi?: never;
        functionName?: 'transferFrom';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2PairABI, 'transferFrom', TMode>({
    abi: iUniswapV2PairABI,
    functionName: 'transferFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__.
 */
export function usePrepareIUniswapV2PairWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2PairABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareIUniswapV2PairApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2PairABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'approve'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"burn"`.
 */
export function usePrepareIUniswapV2PairBurn(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'burn'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2PairABI,
    functionName: 'burn',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'burn'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"initialize"`.
 */
export function usePrepareIUniswapV2PairInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'initialize'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2PairABI,
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'initialize'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"mint"`.
 */
export function usePrepareIUniswapV2PairMint(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'mint'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2PairABI,
    functionName: 'mint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'mint'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"permit"`.
 */
export function usePrepareIUniswapV2PairPermit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'permit'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2PairABI,
    functionName: 'permit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'permit'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"skim"`.
 */
export function usePrepareIUniswapV2PairSkim(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'skim'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2PairABI,
    functionName: 'skim',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'skim'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"swap"`.
 */
export function usePrepareIUniswapV2PairSwap(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'swap'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2PairABI,
    functionName: 'swap',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'swap'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"sync"`.
 */
export function usePrepareIUniswapV2PairSync(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'sync'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2PairABI,
    functionName: 'sync',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'sync'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareIUniswapV2PairTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2PairABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'transfer'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2PairABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareIUniswapV2PairTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2PairABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2PairABI, 'transferFrom'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2Router01ABI}__.
 */
export function useIUniswapV2Router01Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof iUniswapV2Router01ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2Router01ABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2Router01ABI,
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2Router01ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"WETH"`.
 */
export function useIUniswapV2Router01Weth<
  TFunctionName extends 'WETH',
  TSelectData = ReadContractResult<typeof iUniswapV2Router01ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2Router01ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2Router01ABI,
    functionName: 'WETH',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2Router01ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"factory"`.
 */
export function useIUniswapV2Router01Factory<
  TFunctionName extends 'factory',
  TSelectData = ReadContractResult<typeof iUniswapV2Router01ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2Router01ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2Router01ABI,
    functionName: 'factory',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2Router01ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"getAmountIn"`.
 */
export function useIUniswapV2Router01GetAmountIn<
  TFunctionName extends 'getAmountIn',
  TSelectData = ReadContractResult<typeof iUniswapV2Router01ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2Router01ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2Router01ABI,
    functionName: 'getAmountIn',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2Router01ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"getAmountOut"`.
 */
export function useIUniswapV2Router01GetAmountOut<
  TFunctionName extends 'getAmountOut',
  TSelectData = ReadContractResult<typeof iUniswapV2Router01ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2Router01ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2Router01ABI,
    functionName: 'getAmountOut',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2Router01ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"getAmountsIn"`.
 */
export function useIUniswapV2Router01GetAmountsIn<
  TFunctionName extends 'getAmountsIn',
  TSelectData = ReadContractResult<typeof iUniswapV2Router01ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2Router01ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2Router01ABI,
    functionName: 'getAmountsIn',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2Router01ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"getAmountsOut"`.
 */
export function useIUniswapV2Router01GetAmountsOut<
  TFunctionName extends 'getAmountsOut',
  TSelectData = ReadContractResult<typeof iUniswapV2Router01ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2Router01ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2Router01ABI,
    functionName: 'getAmountsOut',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2Router01ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"quote"`.
 */
export function useIUniswapV2Router01Quote<
  TFunctionName extends 'quote',
  TSelectData = ReadContractResult<typeof iUniswapV2Router01ABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof iUniswapV2Router01ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: iUniswapV2Router01ABI,
    functionName: 'quote',
    ...config,
  } as UseContractReadConfig<typeof iUniswapV2Router01ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__.
 */
export function useIUniswapV2Router01Write<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2Router01ABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof iUniswapV2Router01ABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2Router01ABI, TFunctionName, TMode>({
    abi: iUniswapV2Router01ABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"addLiquidity"`.
 */
export function useIUniswapV2Router01AddLiquidity<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2Router01ABI,
          'addLiquidity'
        >['request']['abi'],
        'addLiquidity',
        TMode
      > & { functionName?: 'addLiquidity' }
    : UseContractWriteConfig<typeof iUniswapV2Router01ABI, 'addLiquidity', TMode> & {
        abi?: never;
        functionName?: 'addLiquidity';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2Router01ABI, 'addLiquidity', TMode>({
    abi: iUniswapV2Router01ABI,
    functionName: 'addLiquidity',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"addLiquidityETH"`.
 */
export function useIUniswapV2Router01AddLiquidityEth<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2Router01ABI,
          'addLiquidityETH'
        >['request']['abi'],
        'addLiquidityETH',
        TMode
      > & { functionName?: 'addLiquidityETH' }
    : UseContractWriteConfig<typeof iUniswapV2Router01ABI, 'addLiquidityETH', TMode> & {
        abi?: never;
        functionName?: 'addLiquidityETH';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2Router01ABI, 'addLiquidityETH', TMode>({
    abi: iUniswapV2Router01ABI,
    functionName: 'addLiquidityETH',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"removeLiquidity"`.
 */
export function useIUniswapV2Router01RemoveLiquidity<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2Router01ABI,
          'removeLiquidity'
        >['request']['abi'],
        'removeLiquidity',
        TMode
      > & { functionName?: 'removeLiquidity' }
    : UseContractWriteConfig<typeof iUniswapV2Router01ABI, 'removeLiquidity', TMode> & {
        abi?: never;
        functionName?: 'removeLiquidity';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2Router01ABI, 'removeLiquidity', TMode>({
    abi: iUniswapV2Router01ABI,
    functionName: 'removeLiquidity',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"removeLiquidityETH"`.
 */
export function useIUniswapV2Router01RemoveLiquidityEth<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2Router01ABI,
          'removeLiquidityETH'
        >['request']['abi'],
        'removeLiquidityETH',
        TMode
      > & { functionName?: 'removeLiquidityETH' }
    : UseContractWriteConfig<
        typeof iUniswapV2Router01ABI,
        'removeLiquidityETH',
        TMode
      > & {
        abi?: never;
        functionName?: 'removeLiquidityETH';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2Router01ABI, 'removeLiquidityETH', TMode>({
    abi: iUniswapV2Router01ABI,
    functionName: 'removeLiquidityETH',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"removeLiquidityETHWithPermit"`.
 */
export function useIUniswapV2Router01RemoveLiquidityEthWithPermit<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2Router01ABI,
          'removeLiquidityETHWithPermit'
        >['request']['abi'],
        'removeLiquidityETHWithPermit',
        TMode
      > & { functionName?: 'removeLiquidityETHWithPermit' }
    : UseContractWriteConfig<
        typeof iUniswapV2Router01ABI,
        'removeLiquidityETHWithPermit',
        TMode
      > & {
        abi?: never;
        functionName?: 'removeLiquidityETHWithPermit';
      } = {} as any,
) {
  return useContractWrite<
    typeof iUniswapV2Router01ABI,
    'removeLiquidityETHWithPermit',
    TMode
  >({
    abi: iUniswapV2Router01ABI,
    functionName: 'removeLiquidityETHWithPermit',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"removeLiquidityWithPermit"`.
 */
export function useIUniswapV2Router01RemoveLiquidityWithPermit<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2Router01ABI,
          'removeLiquidityWithPermit'
        >['request']['abi'],
        'removeLiquidityWithPermit',
        TMode
      > & { functionName?: 'removeLiquidityWithPermit' }
    : UseContractWriteConfig<
        typeof iUniswapV2Router01ABI,
        'removeLiquidityWithPermit',
        TMode
      > & {
        abi?: never;
        functionName?: 'removeLiquidityWithPermit';
      } = {} as any,
) {
  return useContractWrite<
    typeof iUniswapV2Router01ABI,
    'removeLiquidityWithPermit',
    TMode
  >({
    abi: iUniswapV2Router01ABI,
    functionName: 'removeLiquidityWithPermit',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"swapETHForExactTokens"`.
 */
export function useIUniswapV2Router01SwapEthForExactTokens<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2Router01ABI,
          'swapETHForExactTokens'
        >['request']['abi'],
        'swapETHForExactTokens',
        TMode
      > & { functionName?: 'swapETHForExactTokens' }
    : UseContractWriteConfig<
        typeof iUniswapV2Router01ABI,
        'swapETHForExactTokens',
        TMode
      > & {
        abi?: never;
        functionName?: 'swapETHForExactTokens';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2Router01ABI, 'swapETHForExactTokens', TMode>({
    abi: iUniswapV2Router01ABI,
    functionName: 'swapETHForExactTokens',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"swapExactETHForTokens"`.
 */
export function useIUniswapV2Router01SwapExactEthForTokens<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2Router01ABI,
          'swapExactETHForTokens'
        >['request']['abi'],
        'swapExactETHForTokens',
        TMode
      > & { functionName?: 'swapExactETHForTokens' }
    : UseContractWriteConfig<
        typeof iUniswapV2Router01ABI,
        'swapExactETHForTokens',
        TMode
      > & {
        abi?: never;
        functionName?: 'swapExactETHForTokens';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2Router01ABI, 'swapExactETHForTokens', TMode>({
    abi: iUniswapV2Router01ABI,
    functionName: 'swapExactETHForTokens',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"swapExactTokensForETH"`.
 */
export function useIUniswapV2Router01SwapExactTokensForEth<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2Router01ABI,
          'swapExactTokensForETH'
        >['request']['abi'],
        'swapExactTokensForETH',
        TMode
      > & { functionName?: 'swapExactTokensForETH' }
    : UseContractWriteConfig<
        typeof iUniswapV2Router01ABI,
        'swapExactTokensForETH',
        TMode
      > & {
        abi?: never;
        functionName?: 'swapExactTokensForETH';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2Router01ABI, 'swapExactTokensForETH', TMode>({
    abi: iUniswapV2Router01ABI,
    functionName: 'swapExactTokensForETH',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"swapExactTokensForTokens"`.
 */
export function useIUniswapV2Router01SwapExactTokensForTokens<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2Router01ABI,
          'swapExactTokensForTokens'
        >['request']['abi'],
        'swapExactTokensForTokens',
        TMode
      > & { functionName?: 'swapExactTokensForTokens' }
    : UseContractWriteConfig<
        typeof iUniswapV2Router01ABI,
        'swapExactTokensForTokens',
        TMode
      > & {
        abi?: never;
        functionName?: 'swapExactTokensForTokens';
      } = {} as any,
) {
  return useContractWrite<
    typeof iUniswapV2Router01ABI,
    'swapExactTokensForTokens',
    TMode
  >({
    abi: iUniswapV2Router01ABI,
    functionName: 'swapExactTokensForTokens',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"swapTokensForExactETH"`.
 */
export function useIUniswapV2Router01SwapTokensForExactEth<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2Router01ABI,
          'swapTokensForExactETH'
        >['request']['abi'],
        'swapTokensForExactETH',
        TMode
      > & { functionName?: 'swapTokensForExactETH' }
    : UseContractWriteConfig<
        typeof iUniswapV2Router01ABI,
        'swapTokensForExactETH',
        TMode
      > & {
        abi?: never;
        functionName?: 'swapTokensForExactETH';
      } = {} as any,
) {
  return useContractWrite<typeof iUniswapV2Router01ABI, 'swapTokensForExactETH', TMode>({
    abi: iUniswapV2Router01ABI,
    functionName: 'swapTokensForExactETH',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"swapTokensForExactTokens"`.
 */
export function useIUniswapV2Router01SwapTokensForExactTokens<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof iUniswapV2Router01ABI,
          'swapTokensForExactTokens'
        >['request']['abi'],
        'swapTokensForExactTokens',
        TMode
      > & { functionName?: 'swapTokensForExactTokens' }
    : UseContractWriteConfig<
        typeof iUniswapV2Router01ABI,
        'swapTokensForExactTokens',
        TMode
      > & {
        abi?: never;
        functionName?: 'swapTokensForExactTokens';
      } = {} as any,
) {
  return useContractWrite<
    typeof iUniswapV2Router01ABI,
    'swapTokensForExactTokens',
    TMode
  >({
    abi: iUniswapV2Router01ABI,
    functionName: 'swapTokensForExactTokens',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__.
 */
export function usePrepareIUniswapV2Router01Write<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2Router01ABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2Router01ABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2Router01ABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"addLiquidity"`.
 */
export function usePrepareIUniswapV2Router01AddLiquidity(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2Router01ABI, 'addLiquidity'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2Router01ABI,
    functionName: 'addLiquidity',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2Router01ABI, 'addLiquidity'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"addLiquidityETH"`.
 */
export function usePrepareIUniswapV2Router01AddLiquidityEth(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2Router01ABI, 'addLiquidityETH'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2Router01ABI,
    functionName: 'addLiquidityETH',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2Router01ABI, 'addLiquidityETH'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"removeLiquidity"`.
 */
export function usePrepareIUniswapV2Router01RemoveLiquidity(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2Router01ABI, 'removeLiquidity'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2Router01ABI,
    functionName: 'removeLiquidity',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2Router01ABI, 'removeLiquidity'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"removeLiquidityETH"`.
 */
export function usePrepareIUniswapV2Router01RemoveLiquidityEth(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2Router01ABI, 'removeLiquidityETH'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2Router01ABI,
    functionName: 'removeLiquidityETH',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iUniswapV2Router01ABI, 'removeLiquidityETH'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"removeLiquidityETHWithPermit"`.
 */
export function usePrepareIUniswapV2Router01RemoveLiquidityEthWithPermit(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof iUniswapV2Router01ABI,
      'removeLiquidityETHWithPermit'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2Router01ABI,
    functionName: 'removeLiquidityETHWithPermit',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof iUniswapV2Router01ABI,
    'removeLiquidityETHWithPermit'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"removeLiquidityWithPermit"`.
 */
export function usePrepareIUniswapV2Router01RemoveLiquidityWithPermit(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof iUniswapV2Router01ABI,
      'removeLiquidityWithPermit'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2Router01ABI,
    functionName: 'removeLiquidityWithPermit',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof iUniswapV2Router01ABI,
    'removeLiquidityWithPermit'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"swapETHForExactTokens"`.
 */
export function usePrepareIUniswapV2Router01SwapEthForExactTokens(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2Router01ABI, 'swapETHForExactTokens'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2Router01ABI,
    functionName: 'swapETHForExactTokens',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof iUniswapV2Router01ABI,
    'swapETHForExactTokens'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"swapExactETHForTokens"`.
 */
export function usePrepareIUniswapV2Router01SwapExactEthForTokens(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2Router01ABI, 'swapExactETHForTokens'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2Router01ABI,
    functionName: 'swapExactETHForTokens',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof iUniswapV2Router01ABI,
    'swapExactETHForTokens'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"swapExactTokensForETH"`.
 */
export function usePrepareIUniswapV2Router01SwapExactTokensForEth(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2Router01ABI, 'swapExactTokensForETH'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2Router01ABI,
    functionName: 'swapExactTokensForETH',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof iUniswapV2Router01ABI,
    'swapExactTokensForETH'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"swapExactTokensForTokens"`.
 */
export function usePrepareIUniswapV2Router01SwapExactTokensForTokens(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof iUniswapV2Router01ABI,
      'swapExactTokensForTokens'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2Router01ABI,
    functionName: 'swapExactTokensForTokens',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof iUniswapV2Router01ABI,
    'swapExactTokensForTokens'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"swapTokensForExactETH"`.
 */
export function usePrepareIUniswapV2Router01SwapTokensForExactEth(
  config: Omit<
    UsePrepareContractWriteConfig<typeof iUniswapV2Router01ABI, 'swapTokensForExactETH'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2Router01ABI,
    functionName: 'swapTokensForExactETH',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof iUniswapV2Router01ABI,
    'swapTokensForExactETH'
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iUniswapV2Router01ABI}__ and `functionName` set to `"swapTokensForExactTokens"`.
 */
export function usePrepareIUniswapV2Router01SwapTokensForExactTokens(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof iUniswapV2Router01ABI,
      'swapTokensForExactTokens'
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: iUniswapV2Router01ABI,
    functionName: 'swapTokensForExactTokens',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof iUniswapV2Router01ABI,
    'swapTokensForExactTokens'
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20BurnableABI}__.
 */
export function useErc20BurnableRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof erc20BurnableABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20BurnableABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: erc20BurnableABI, ...config } as UseContractReadConfig<
    typeof erc20BurnableABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"allowance"`.
 */
export function useErc20BurnableAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof erc20BurnableABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20BurnableABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20BurnableABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof erc20BurnableABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useErc20BurnableBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof erc20BurnableABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20BurnableABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20BurnableABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof erc20BurnableABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"decimals"`.
 */
export function useErc20BurnableDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof erc20BurnableABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20BurnableABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20BurnableABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof erc20BurnableABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"name"`.
 */
export function useErc20BurnableName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof erc20BurnableABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20BurnableABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20BurnableABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof erc20BurnableABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"symbol"`.
 */
export function useErc20BurnableSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof erc20BurnableABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20BurnableABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20BurnableABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof erc20BurnableABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useErc20BurnableTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof erc20BurnableABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20BurnableABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20BurnableABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof erc20BurnableABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20BurnableABI}__.
 */
export function useErc20BurnableWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20BurnableABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof erc20BurnableABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof erc20BurnableABI, TFunctionName, TMode>({
    abi: erc20BurnableABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"approve"`.
 */
export function useErc20BurnableApprove<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20BurnableABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof erc20BurnableABI, 'approve', TMode> & {
        abi?: never;
        functionName?: 'approve';
      } = {} as any,
) {
  return useContractWrite<typeof erc20BurnableABI, 'approve', TMode>({
    abi: erc20BurnableABI,
    functionName: 'approve',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"burn"`.
 */
export function useErc20BurnableBurn<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20BurnableABI, 'burn'>['request']['abi'],
        'burn',
        TMode
      > & { functionName?: 'burn' }
    : UseContractWriteConfig<typeof erc20BurnableABI, 'burn', TMode> & {
        abi?: never;
        functionName?: 'burn';
      } = {} as any,
) {
  return useContractWrite<typeof erc20BurnableABI, 'burn', TMode>({
    abi: erc20BurnableABI,
    functionName: 'burn',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"burnFrom"`.
 */
export function useErc20BurnableBurnFrom<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20BurnableABI, 'burnFrom'>['request']['abi'],
        'burnFrom',
        TMode
      > & { functionName?: 'burnFrom' }
    : UseContractWriteConfig<typeof erc20BurnableABI, 'burnFrom', TMode> & {
        abi?: never;
        functionName?: 'burnFrom';
      } = {} as any,
) {
  return useContractWrite<typeof erc20BurnableABI, 'burnFrom', TMode>({
    abi: erc20BurnableABI,
    functionName: 'burnFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useErc20BurnableDecreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof erc20BurnableABI,
          'decreaseAllowance'
        >['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<typeof erc20BurnableABI, 'decreaseAllowance', TMode> & {
        abi?: never;
        functionName?: 'decreaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof erc20BurnableABI, 'decreaseAllowance', TMode>({
    abi: erc20BurnableABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useErc20BurnableIncreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof erc20BurnableABI,
          'increaseAllowance'
        >['request']['abi'],
        'increaseAllowance',
        TMode
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<typeof erc20BurnableABI, 'increaseAllowance', TMode> & {
        abi?: never;
        functionName?: 'increaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof erc20BurnableABI, 'increaseAllowance', TMode>({
    abi: erc20BurnableABI,
    functionName: 'increaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"transfer"`.
 */
export function useErc20BurnableTransfer<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20BurnableABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof erc20BurnableABI, 'transfer', TMode> & {
        abi?: never;
        functionName?: 'transfer';
      } = {} as any,
) {
  return useContractWrite<typeof erc20BurnableABI, 'transfer', TMode>({
    abi: erc20BurnableABI,
    functionName: 'transfer',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useErc20BurnableTransferFrom<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof erc20BurnableABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof erc20BurnableABI, 'transferFrom', TMode> & {
        abi?: never;
        functionName?: 'transferFrom';
      } = {} as any,
) {
  return useContractWrite<typeof erc20BurnableABI, 'transferFrom', TMode>({
    abi: erc20BurnableABI,
    functionName: 'transferFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20BurnableABI}__.
 */
export function usePrepareErc20BurnableWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20BurnableABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20BurnableABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20BurnableABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareErc20BurnableApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20BurnableABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20BurnableABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20BurnableABI, 'approve'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"burn"`.
 */
export function usePrepareErc20BurnableBurn(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20BurnableABI, 'burn'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20BurnableABI,
    functionName: 'burn',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20BurnableABI, 'burn'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"burnFrom"`.
 */
export function usePrepareErc20BurnableBurnFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20BurnableABI, 'burnFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20BurnableABI,
    functionName: 'burnFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20BurnableABI, 'burnFrom'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareErc20BurnableDecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20BurnableABI, 'decreaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20BurnableABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20BurnableABI, 'decreaseAllowance'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareErc20BurnableIncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20BurnableABI, 'increaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20BurnableABI,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20BurnableABI, 'increaseAllowance'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareErc20BurnableTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20BurnableABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20BurnableABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20BurnableABI, 'transfer'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20BurnableABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareErc20BurnableTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20BurnableABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20BurnableABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20BurnableABI, 'transferFrom'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20VotesABI}__.
 */
export function useErc20VotesRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof erc20VotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: erc20VotesABI, ...config } as UseContractReadConfig<
    typeof erc20VotesABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"DOMAIN_SEPARATOR"`.
 */
export function useErc20VotesDomainSeparator<
  TFunctionName extends 'DOMAIN_SEPARATOR',
  TSelectData = ReadContractResult<typeof erc20VotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20VotesABI,
    functionName: 'DOMAIN_SEPARATOR',
    ...config,
  } as UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"allowance"`.
 */
export function useErc20VotesAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof erc20VotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20VotesABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useErc20VotesBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof erc20VotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20VotesABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"checkpoints"`.
 */
export function useErc20VotesCheckpoints<
  TFunctionName extends 'checkpoints',
  TSelectData = ReadContractResult<typeof erc20VotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20VotesABI,
    functionName: 'checkpoints',
    ...config,
  } as UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"decimals"`.
 */
export function useErc20VotesDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof erc20VotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20VotesABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"delegates"`.
 */
export function useErc20VotesDelegates<
  TFunctionName extends 'delegates',
  TSelectData = ReadContractResult<typeof erc20VotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20VotesABI,
    functionName: 'delegates',
    ...config,
  } as UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"getPastTotalSupply"`.
 */
export function useErc20VotesGetPastTotalSupply<
  TFunctionName extends 'getPastTotalSupply',
  TSelectData = ReadContractResult<typeof erc20VotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20VotesABI,
    functionName: 'getPastTotalSupply',
    ...config,
  } as UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"getPastVotes"`.
 */
export function useErc20VotesGetPastVotes<
  TFunctionName extends 'getPastVotes',
  TSelectData = ReadContractResult<typeof erc20VotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20VotesABI,
    functionName: 'getPastVotes',
    ...config,
  } as UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"getVotes"`.
 */
export function useErc20VotesGetVotes<
  TFunctionName extends 'getVotes',
  TSelectData = ReadContractResult<typeof erc20VotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20VotesABI,
    functionName: 'getVotes',
    ...config,
  } as UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"name"`.
 */
export function useErc20VotesName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof erc20VotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20VotesABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"nonces"`.
 */
export function useErc20VotesNonces<
  TFunctionName extends 'nonces',
  TSelectData = ReadContractResult<typeof erc20VotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20VotesABI,
    functionName: 'nonces',
    ...config,
  } as UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"numCheckpoints"`.
 */
export function useErc20VotesNumCheckpoints<
  TFunctionName extends 'numCheckpoints',
  TSelectData = ReadContractResult<typeof erc20VotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20VotesABI,
    functionName: 'numCheckpoints',
    ...config,
  } as UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"symbol"`.
 */
export function useErc20VotesSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof erc20VotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20VotesABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useErc20VotesTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof erc20VotesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20VotesABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof erc20VotesABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20VotesABI}__.
 */
export function useErc20VotesWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20VotesABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof erc20VotesABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof erc20VotesABI, TFunctionName, TMode>({
    abi: erc20VotesABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"approve"`.
 */
export function useErc20VotesApprove<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20VotesABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof erc20VotesABI, 'approve', TMode> & {
        abi?: never;
        functionName?: 'approve';
      } = {} as any,
) {
  return useContractWrite<typeof erc20VotesABI, 'approve', TMode>({
    abi: erc20VotesABI,
    functionName: 'approve',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useErc20VotesDecreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof erc20VotesABI,
          'decreaseAllowance'
        >['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<typeof erc20VotesABI, 'decreaseAllowance', TMode> & {
        abi?: never;
        functionName?: 'decreaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof erc20VotesABI, 'decreaseAllowance', TMode>({
    abi: erc20VotesABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"delegate"`.
 */
export function useErc20VotesDelegate<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20VotesABI, 'delegate'>['request']['abi'],
        'delegate',
        TMode
      > & { functionName?: 'delegate' }
    : UseContractWriteConfig<typeof erc20VotesABI, 'delegate', TMode> & {
        abi?: never;
        functionName?: 'delegate';
      } = {} as any,
) {
  return useContractWrite<typeof erc20VotesABI, 'delegate', TMode>({
    abi: erc20VotesABI,
    functionName: 'delegate',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"delegateBySig"`.
 */
export function useErc20VotesDelegateBySig<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof erc20VotesABI,
          'delegateBySig'
        >['request']['abi'],
        'delegateBySig',
        TMode
      > & { functionName?: 'delegateBySig' }
    : UseContractWriteConfig<typeof erc20VotesABI, 'delegateBySig', TMode> & {
        abi?: never;
        functionName?: 'delegateBySig';
      } = {} as any,
) {
  return useContractWrite<typeof erc20VotesABI, 'delegateBySig', TMode>({
    abi: erc20VotesABI,
    functionName: 'delegateBySig',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useErc20VotesIncreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof erc20VotesABI,
          'increaseAllowance'
        >['request']['abi'],
        'increaseAllowance',
        TMode
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<typeof erc20VotesABI, 'increaseAllowance', TMode> & {
        abi?: never;
        functionName?: 'increaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof erc20VotesABI, 'increaseAllowance', TMode>({
    abi: erc20VotesABI,
    functionName: 'increaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"permit"`.
 */
export function useErc20VotesPermit<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20VotesABI, 'permit'>['request']['abi'],
        'permit',
        TMode
      > & { functionName?: 'permit' }
    : UseContractWriteConfig<typeof erc20VotesABI, 'permit', TMode> & {
        abi?: never;
        functionName?: 'permit';
      } = {} as any,
) {
  return useContractWrite<typeof erc20VotesABI, 'permit', TMode>({
    abi: erc20VotesABI,
    functionName: 'permit',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"transfer"`.
 */
export function useErc20VotesTransfer<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20VotesABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof erc20VotesABI, 'transfer', TMode> & {
        abi?: never;
        functionName?: 'transfer';
      } = {} as any,
) {
  return useContractWrite<typeof erc20VotesABI, 'transfer', TMode>({
    abi: erc20VotesABI,
    functionName: 'transfer',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useErc20VotesTransferFrom<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof erc20VotesABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof erc20VotesABI, 'transferFrom', TMode> & {
        abi?: never;
        functionName?: 'transferFrom';
      } = {} as any,
) {
  return useContractWrite<typeof erc20VotesABI, 'transferFrom', TMode>({
    abi: erc20VotesABI,
    functionName: 'transferFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20VotesABI}__.
 */
export function usePrepareErc20VotesWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20VotesABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20VotesABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20VotesABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareErc20VotesApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20VotesABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20VotesABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20VotesABI, 'approve'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareErc20VotesDecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20VotesABI, 'decreaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20VotesABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20VotesABI, 'decreaseAllowance'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"delegate"`.
 */
export function usePrepareErc20VotesDelegate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20VotesABI, 'delegate'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20VotesABI,
    functionName: 'delegate',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20VotesABI, 'delegate'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"delegateBySig"`.
 */
export function usePrepareErc20VotesDelegateBySig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20VotesABI, 'delegateBySig'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20VotesABI,
    functionName: 'delegateBySig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20VotesABI, 'delegateBySig'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareErc20VotesIncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20VotesABI, 'increaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20VotesABI,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20VotesABI, 'increaseAllowance'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"permit"`.
 */
export function usePrepareErc20VotesPermit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20VotesABI, 'permit'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20VotesABI,
    functionName: 'permit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20VotesABI, 'permit'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareErc20VotesTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20VotesABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20VotesABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20VotesABI, 'transfer'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20VotesABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareErc20VotesTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20VotesABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20VotesABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20VotesABI, 'transferFrom'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__.
 */
export function useIerc20MetadataRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof ierc20MetadataABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: ierc20MetadataABI, ...config } as UseContractReadConfig<
    typeof ierc20MetadataABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"allowance"`.
 */
export function useIerc20MetadataAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof ierc20MetadataABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useIerc20MetadataBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof ierc20MetadataABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"decimals"`.
 */
export function useIerc20MetadataDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof ierc20MetadataABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"name"`.
 */
export function useIerc20MetadataName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof ierc20MetadataABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"symbol"`.
 */
export function useIerc20MetadataSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof ierc20MetadataABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useIerc20MetadataTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof ierc20MetadataABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20MetadataABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__.
 */
export function useIerc20MetadataWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ierc20MetadataABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof ierc20MetadataABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof ierc20MetadataABI, TFunctionName, TMode>({
    abi: ierc20MetadataABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"approve"`.
 */
export function useIerc20MetadataApprove<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ierc20MetadataABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof ierc20MetadataABI, 'approve', TMode> & {
        abi?: never;
        functionName?: 'approve';
      } = {} as any,
) {
  return useContractWrite<typeof ierc20MetadataABI, 'approve', TMode>({
    abi: ierc20MetadataABI,
    functionName: 'approve',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"transfer"`.
 */
export function useIerc20MetadataTransfer<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20MetadataABI,
          'transfer'
        >['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof ierc20MetadataABI, 'transfer', TMode> & {
        abi?: never;
        functionName?: 'transfer';
      } = {} as any,
) {
  return useContractWrite<typeof ierc20MetadataABI, 'transfer', TMode>({
    abi: ierc20MetadataABI,
    functionName: 'transfer',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useIerc20MetadataTransferFrom<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ierc20MetadataABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof ierc20MetadataABI, 'transferFrom', TMode> & {
        abi?: never;
        functionName?: 'transferFrom';
      } = {} as any,
) {
  return useContractWrite<typeof ierc20MetadataABI, 'transferFrom', TMode>({
    abi: ierc20MetadataABI,
    functionName: 'transferFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__.
 */
export function usePrepareIerc20MetadataWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20MetadataABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20MetadataABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20MetadataABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareIerc20MetadataApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20MetadataABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'approve'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareIerc20MetadataTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20MetadataABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'transfer'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareIerc20MetadataTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20MetadataABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'transferFrom'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20PermitABI}__.
 */
export function useErc20PermitRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof erc20PermitABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: erc20PermitABI, ...config } as UseContractReadConfig<
    typeof erc20PermitABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"DOMAIN_SEPARATOR"`.
 */
export function useErc20PermitDomainSeparator<
  TFunctionName extends 'DOMAIN_SEPARATOR',
  TSelectData = ReadContractResult<typeof erc20PermitABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20PermitABI,
    functionName: 'DOMAIN_SEPARATOR',
    ...config,
  } as UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"allowance"`.
 */
export function useErc20PermitAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof erc20PermitABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20PermitABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useErc20PermitBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof erc20PermitABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20PermitABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"decimals"`.
 */
export function useErc20PermitDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof erc20PermitABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20PermitABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"name"`.
 */
export function useErc20PermitName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof erc20PermitABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20PermitABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"nonces"`.
 */
export function useErc20PermitNonces<
  TFunctionName extends 'nonces',
  TSelectData = ReadContractResult<typeof erc20PermitABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20PermitABI,
    functionName: 'nonces',
    ...config,
  } as UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"symbol"`.
 */
export function useErc20PermitSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof erc20PermitABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20PermitABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useErc20PermitTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof erc20PermitABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: erc20PermitABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof erc20PermitABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20PermitABI}__.
 */
export function useErc20PermitWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20PermitABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof erc20PermitABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof erc20PermitABI, TFunctionName, TMode>({
    abi: erc20PermitABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"approve"`.
 */
export function useErc20PermitApprove<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20PermitABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof erc20PermitABI, 'approve', TMode> & {
        abi?: never;
        functionName?: 'approve';
      } = {} as any,
) {
  return useContractWrite<typeof erc20PermitABI, 'approve', TMode>({
    abi: erc20PermitABI,
    functionName: 'approve',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useErc20PermitDecreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof erc20PermitABI,
          'decreaseAllowance'
        >['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<typeof erc20PermitABI, 'decreaseAllowance', TMode> & {
        abi?: never;
        functionName?: 'decreaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof erc20PermitABI, 'decreaseAllowance', TMode>({
    abi: erc20PermitABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useErc20PermitIncreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof erc20PermitABI,
          'increaseAllowance'
        >['request']['abi'],
        'increaseAllowance',
        TMode
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<typeof erc20PermitABI, 'increaseAllowance', TMode> & {
        abi?: never;
        functionName?: 'increaseAllowance';
      } = {} as any,
) {
  return useContractWrite<typeof erc20PermitABI, 'increaseAllowance', TMode>({
    abi: erc20PermitABI,
    functionName: 'increaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"permit"`.
 */
export function useErc20PermitPermit<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20PermitABI, 'permit'>['request']['abi'],
        'permit',
        TMode
      > & { functionName?: 'permit' }
    : UseContractWriteConfig<typeof erc20PermitABI, 'permit', TMode> & {
        abi?: never;
        functionName?: 'permit';
      } = {} as any,
) {
  return useContractWrite<typeof erc20PermitABI, 'permit', TMode>({
    abi: erc20PermitABI,
    functionName: 'permit',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"transfer"`.
 */
export function useErc20PermitTransfer<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20PermitABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof erc20PermitABI, 'transfer', TMode> & {
        abi?: never;
        functionName?: 'transfer';
      } = {} as any,
) {
  return useContractWrite<typeof erc20PermitABI, 'transfer', TMode>({
    abi: erc20PermitABI,
    functionName: 'transfer',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useErc20PermitTransferFrom<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof erc20PermitABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof erc20PermitABI, 'transferFrom', TMode> & {
        abi?: never;
        functionName?: 'transferFrom';
      } = {} as any,
) {
  return useContractWrite<typeof erc20PermitABI, 'transferFrom', TMode>({
    abi: erc20PermitABI,
    functionName: 'transferFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20PermitABI}__.
 */
export function usePrepareErc20PermitWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20PermitABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20PermitABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20PermitABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareErc20PermitApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20PermitABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20PermitABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20PermitABI, 'approve'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareErc20PermitDecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20PermitABI, 'decreaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20PermitABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20PermitABI, 'decreaseAllowance'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareErc20PermitIncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20PermitABI, 'increaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20PermitABI,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20PermitABI, 'increaseAllowance'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"permit"`.
 */
export function usePrepareErc20PermitPermit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20PermitABI, 'permit'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20PermitABI,
    functionName: 'permit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20PermitABI, 'permit'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareErc20PermitTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20PermitABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20PermitABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20PermitABI, 'transfer'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20PermitABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareErc20PermitTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20PermitABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20PermitABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20PermitABI, 'transferFrom'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20PermitABI}__.
 */
export function useIerc20PermitRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof ierc20PermitABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20PermitABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: ierc20PermitABI, ...config } as UseContractReadConfig<
    typeof ierc20PermitABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20PermitABI}__ and `functionName` set to `"DOMAIN_SEPARATOR"`.
 */
export function useIerc20PermitDomainSeparator<
  TFunctionName extends 'DOMAIN_SEPARATOR',
  TSelectData = ReadContractResult<typeof ierc20PermitABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20PermitABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20PermitABI,
    functionName: 'DOMAIN_SEPARATOR',
    ...config,
  } as UseContractReadConfig<typeof ierc20PermitABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20PermitABI}__ and `functionName` set to `"nonces"`.
 */
export function useIerc20PermitNonces<
  TFunctionName extends 'nonces',
  TSelectData = ReadContractResult<typeof ierc20PermitABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ierc20PermitABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: ierc20PermitABI,
    functionName: 'nonces',
    ...config,
  } as UseContractReadConfig<typeof ierc20PermitABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20PermitABI}__.
 */
export function useIerc20PermitWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ierc20PermitABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof ierc20PermitABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof ierc20PermitABI, TFunctionName, TMode>({
    abi: ierc20PermitABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20PermitABI}__ and `functionName` set to `"permit"`.
 */
export function useIerc20PermitPermit<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof ierc20PermitABI, 'permit'>['request']['abi'],
        'permit',
        TMode
      > & { functionName?: 'permit' }
    : UseContractWriteConfig<typeof ierc20PermitABI, 'permit', TMode> & {
        abi?: never;
        functionName?: 'permit';
      } = {} as any,
) {
  return useContractWrite<typeof ierc20PermitABI, 'permit', TMode>({
    abi: ierc20PermitABI,
    functionName: 'permit',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20PermitABI}__.
 */
export function usePrepareIerc20PermitWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20PermitABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20PermitABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20PermitABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20PermitABI}__ and `functionName` set to `"permit"`.
 */
export function usePrepareIerc20PermitPermit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20PermitABI, 'permit'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: ierc20PermitABI,
    functionName: 'permit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20PermitABI, 'permit'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTimelockABI}__.
 */
export function useTokenTimelockRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof tokenTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTimelockABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any,
) {
  return useContractRead({ abi: tokenTimelockABI, ...config } as UseContractReadConfig<
    typeof tokenTimelockABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTimelockABI}__ and `functionName` set to `"beneficiary"`.
 */
export function useTokenTimelockBeneficiary<
  TFunctionName extends 'beneficiary',
  TSelectData = ReadContractResult<typeof tokenTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTimelockABI,
    functionName: 'beneficiary',
    ...config,
  } as UseContractReadConfig<typeof tokenTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTimelockABI}__ and `functionName` set to `"releaseTime"`.
 */
export function useTokenTimelockReleaseTime<
  TFunctionName extends 'releaseTime',
  TSelectData = ReadContractResult<typeof tokenTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTimelockABI,
    functionName: 'releaseTime',
    ...config,
  } as UseContractReadConfig<typeof tokenTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link tokenTimelockABI}__ and `functionName` set to `"token"`.
 */
export function useTokenTimelockToken<
  TFunctionName extends 'token',
  TSelectData = ReadContractResult<typeof tokenTimelockABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof tokenTimelockABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: tokenTimelockABI,
    functionName: 'token',
    ...config,
  } as UseContractReadConfig<typeof tokenTimelockABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTimelockABI}__.
 */
export function useTokenTimelockWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof tokenTimelockABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof tokenTimelockABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any,
) {
  return useContractWrite<typeof tokenTimelockABI, TFunctionName, TMode>({
    abi: tokenTimelockABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link tokenTimelockABI}__ and `functionName` set to `"release"`.
 */
export function useTokenTimelockRelease<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof tokenTimelockABI, 'release'>['request']['abi'],
        'release',
        TMode
      > & { functionName?: 'release' }
    : UseContractWriteConfig<typeof tokenTimelockABI, 'release', TMode> & {
        abi?: never;
        functionName?: 'release';
      } = {} as any,
) {
  return useContractWrite<typeof tokenTimelockABI, 'release', TMode>({
    abi: tokenTimelockABI,
    functionName: 'release',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTimelockABI}__.
 */
export function usePrepareTokenTimelockWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTimelockABI, TFunctionName>,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTimelockABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTimelockABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link tokenTimelockABI}__ and `functionName` set to `"release"`.
 */
export function usePrepareTokenTimelockRelease(
  config: Omit<
    UsePrepareContractWriteConfig<typeof tokenTimelockABI, 'release'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: tokenTimelockABI,
    functionName: 'release',
    ...config,
  } as UsePrepareContractWriteConfig<typeof tokenTimelockABI, 'release'>);
}
