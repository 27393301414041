import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { LoaderIcon } from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';

type BaseButtonProps = PropsWithChildren & {
  variant?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'extra-small';
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'reset' | 'submit';
  isLoading?: boolean;
  onClick?: () => void;
};

const BaseButton: React.FC<BaseButtonProps> = ({
  children,
  variant,
  disabled,
  size,
  className,
  type = 'button',
  isLoading,
  onClick,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={twMerge(clsx(
        'flex items-center justify-center rounded-full bg-brand--4 px-10 py-3 text-white no-underline underline-offset-8 shadow-lg shadow-brand--4/25 transition hover:bg-brand--3 hover:no-underline dark:text-white',
        {
          'bg-brand--4 text-white shadow-lg shadow-brand--4/25 hover:bg-brand--3':
            variant === 'primary',
          'border border-brand--4 bg-transparent text-brand--4 shadow-none hover:border-brand--3 hover:bg-brand--4 hover:text-white':
            variant === 'secondary',
          'px-6 py-2': size === 'small',
          'px-3 py-1 text-xs': size === 'extra-small',
          'pointer-events-none cursor-not-allowed opacity-50': disabled,
        },
        className,
      ))}
      disabled={disabled}
    >
      {isLoading && <LoaderIcon className="mr-1 w-5 animate-spin inline-block" />}
      <span className="first-letter:capitalize">{children}</span>
    </button>
  );
};

export default BaseButton;
