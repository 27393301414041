import { PropsWithChildren } from 'react';
import SiteFooter from '../components/common/SiteFooter';
import TopBar from '../components/common/TopBar';

const DefaultLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex min-h-screen flex-col pt-20">
      <TopBar />

      <div className="container mx-auto flex-grow py-4 px-4 sm:py-10">{children}</div>

      <SiteFooter className="self-end" />
    </div>
  );
};

export default DefaultLayout;
