import { darkTheme, lightTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { useTheme } from 'next-themes';
import { PropsWithChildren } from 'react';
import { COLORS } from '../utils/constants';
import { chains } from '../utils/setupWagmi';

const WalletProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { theme } = useTheme();
  const walletTheme = theme === 'light' ? lightTheme : darkTheme;

  return (
    <RainbowKitProvider
      chains={chains}
      theme={walletTheme({
        accentColor: COLORS['brand--4'],
        overlayBlur: 'small',
      })}
      showRecentTransactions={true}
    >
      {children}
    </RainbowKitProvider>
  );
};

export default WalletProvider;
