import { create } from 'zustand';

interface TransactionNotification {
  tx: string;
  toastId: string;
}

interface NotificationsStore {
  notifications: TransactionNotification[];
  addNotification: (tx: string, toastId: string) => void;
  removeNotification: (tx: string) => void;
}

export const useNotificationsStore = create<NotificationsStore>((set) => ({
  notifications: [],
  addNotification: (tx, toastId) =>
    set((state) => ({
      notifications: [...state.notifications, { tx, toastId }],
    })),
  removeNotification: (tx) =>
    set((state) => ({
      notifications: state.notifications.filter((n) => n.tx !== tx),
    })),
}));
